import ModalEvents from '@/enums/modalEvents';
import PermissionTypes from '@/enums/permissionTypes';
import EventBus from '@/eventBus';
import Events from '@/events';
import { userCanAccessRouteSynchronously } from '@/helpers/routingHelper';
import Organization from '@/models/permission/organization';
import RouterPaths from '@/router/paths';
import iconChevron from '@/shared/icons/chevronRight/chevronRight.vue';
import activity from '@/shared/icons/activity/activity.vue';
import iconDocumentation from '@/shared/icons/documentation/documentation.vue';
import iconHelpBordered from '@/shared/icons/helpBordered/helpBordered.vue';
import iconFeedback from '@/shared/icons/iconFeedback/iconFeedback.vue';
import iconQuickStartGuide from '@/shared/icons/iconQuickStartGuide/iconQuickStartGuide.vue';
import iconLogOut from '@/shared/icons/logOut/logOut.vue';
import iconNavMenuChevron from '@/shared/icons/navMenuChevron/navMenuChevron.vue';
import iconOrders from '@/shared/icons/orders/orders.vue';
import organizations from '@/views/organizations/organizations.vue';
import organizationsIcon from '@/shared/icons/organizations/organizations.vue';
import iconProfile from '@/shared/icons/profile/profile.vue';
import iconProjects from '@/shared/icons/projects/projects.vue';
import iconStyleOptions from '@/shared/icons/styleOptionsIcon/styleOptionsIcon.vue';
import RouteObject from '@/interfaces/routeObject';
import iconUsers from '@/shared/icons/users/users.vue';
import hadrianTooltip from '@/shared/viewParts/hadrianTooltip/hadrianTooltip.vue';
import resourceCenterViewer from '@/shared/viewParts/resourceCenterViewer/resourceCenterViewer.vue';
import switchCheckbox from '@/shared/viewParts/switchCheckbox/switchCheckbox.vue';
import { vxManager } from '@/store/store';
import feedbackModal from '@/views/feedbackModal/feedbackModal.vue';
import { Component, defineComponent } from 'vue';
import helpBox from '../helpBox/helpBox.vue';
import { RouteLocationRaw } from 'vue-router';
import UserContentTypes from '@/enums/userContentTypes';
import { ActivityPage } from '@/enums/activityPage';
import User from '@/models/user/user';
import { TranslateResult } from 'vue-i18n';

interface MenuItem {
  url: string | null | RouteLocationRaw;
  label: TranslateResult;
  icon: Component;
  enabled: boolean;
  tooltip?: TranslateResult;
  subLinks?: Sublink[];
}

interface Sublink {
  name: string;
}

const NAV_MENU_LOGIC_MIN_WIDTH = 1200;

export default defineComponent({
  name: 'navMenu',
  props: {
    showProfilerModal: {
      type: Function as never as () => () => void,
      required: true,
    },
  },
  components: {
    iconChevron,
    iconNavMenuChevron,
    iconProfile,
    activity,
    iconProjects,
    iconOrders,
    organizationsIcon,
    organizations,
    iconUsers,
    iconQuickStartGuide,
    iconLogOut,
    iconFeedback,
    iconDocumentation,
    iconHelpBordered,
    iconStyleOptions,
    helpBox,
    feedbackModal,
    hadrianTooltip,
    resourceCenterViewer,
    switchCheckbox,
    UserContentTypes,
  },
  data() {
    return {
      isOpen: false,
      windowWidth: window.innerWidth,
      RouterPaths,
    };
  },
  mounted() {
    if (this.isNavMenuTogglingSupported()) {
      this.isOpen = vxManager.appStateModule.isNavMenuOpen;
      this.addClassToAppContainer();
    }

    window.addEventListener('resize', this.onResize);
    (this.$refs.navMenuWrapper as HTMLElement).addEventListener('scroll', () => this.removeSubMenus());
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
    (this.$refs.navMenuWrapper as HTMLElement).removeEventListener('scroll', () => this.removeSubMenus());
  },
  methods: {
    isClickable(url: RouteLocationRaw): boolean {
      if (url === RouterPaths.articles || !url) {
        return false;
      }

      return true;
    },
    hasNewContent(contentType: string): boolean {
      const userContentViews = vxManager.userModule.getUserContentViews;
      if (userContentViews === null) {
        return false;
      }
      const specifiedContentView = userContentViews.find(v => v.contentType === contentType);
      return specifiedContentView != null && !specifiedContentView?.isUpToDate;
    },
    onQuickStartGuideClick() {
      window.open(
        'https://project-center-next-assets.s3.us-east-2.amazonaws.com/Next+Quick+Start+Guide+2.pdf',
        '_blank'
      );
    },
    isActive(url: string | RouteObject): boolean {
      if (url === RouterPaths.projects && (!this.$route.path || this.$route.path === '/')) {
        return true;
      }

      if (url === RouterPaths.articles) {
        const paths = [RouterPaths.articles, RouterPaths.releaseNotes, RouterPaths.expediteRequests];
        return paths.some(routerPath => this.$route.path.startsWith(routerPath));
      }

      if (typeof url === 'string') {
        return this.$route.path.startsWith(url);
      }

      if (!!url && !!url.name) {
        return this.$route.path.startsWith(`/${url.name}`);
      }

      return false;
    },
    logoHasNotification(label: string): boolean {
      return label === this.$t('mainMenu.menu.activity') && vxManager.userModule.hasPendingActivityNotifications;
    },
    onToggleIsNavMenuOpen() {
      this.isOpen = !this.isOpen;

      if (this.isNavMenuTogglingSupported()) {
        vxManager.appStateModule.setIsNavMenuOpen(this.isOpen);
        this.addClassToAppContainer();
      }
    },
    addClassToAppContainer() {
      const appElement = document.getElementById('app');
      if (!appElement) {
        return;
      }

      if (this.isOpen) {
        appElement.classList.add('nav-menu-is-open');
      } else {
        appElement.classList.remove('nav-menu-is-open');
      }
    },
    async onLogOut() {
      await vxManager.userModule.logout();
    },
    isNavMenuTogglingSupported(): boolean {
      return window.innerWidth >= NAV_MENU_LOGIC_MIN_WIDTH;
    },
    isWindowWidthChanging(): boolean {
      return (
        (window.innerWidth < NAV_MENU_LOGIC_MIN_WIDTH && this.windowWidth >= NAV_MENU_LOGIC_MIN_WIDTH) ||
        (window.innerWidth >= NAV_MENU_LOGIC_MIN_WIDTH && this.windowWidth < NAV_MENU_LOGIC_MIN_WIDTH)
      );
    },
    onResize() {
      if (!this.isWindowWidthChanging) {
        return;
      }

      if (this.isNavMenuTogglingSupported()) {
        this.isOpen = vxManager.appStateModule.isNavMenuOpen;
      } else {
        this.isOpen = false;
      }

      this.windowWidth = window.innerWidth;
      this.addClassToAppContainer();
    },
    onMouseEnter(event: MouseEvent, key: string, force = false) {
      this.removeSubMenus();

      if (this.isOpen && !force) {
        return;
      }

      let target = event.target as HTMLElement;
      if (
        !!target &&
        !target.classList.contains('nav-menu__main-link') &&
        !target.classList.contains('nav-menu__secondary-link')
      ) {
        target = target.closest('.nav-menu__main-link,.nav-menu__secondary-link') as HTMLElement;
      }

      if (!target) {
        return;
      }

      this.displaySubMenu(target, key);
    },
    displaySubMenu(target: HTMLElement, key: string) {
      const subMenu = this.getSubMenu(key);
      if (!subMenu) {
        return;
      }

      subMenu.style.display = 'block';
      target.classList.add('hover');
      this.positionSubMenu(subMenu, target);
    },
    getSubMenu(key: string): HTMLElement | undefined {
      return document.getElementById(`sub-menu-${key}`) as HTMLElement | undefined;
    },
    positionSubMenu(subMenu: HTMLElement, menuItem: HTMLElement) {
      const rect = menuItem.getBoundingClientRect();
      if (!rect) {
        return;
      }

      let top = rect.top;
      if (!this.isOpen) {
        top += 5;
      }

      const bottom = rect.bottom - subMenu.clientHeight;
      const subMenuBottom = top + subMenu.clientHeight;

      if (subMenuBottom > window.innerHeight) {
        subMenu.style.top = `${bottom}px`;
      } else {
        subMenu.style.top = `${top}px`;
      }
    },
    onMouseLeaveOrClick(event: MouseEvent, force = false) {
      if (this.isOpen && !force) {
        return;
      }

      if (
        !!(event.relatedTarget as HTMLElement)?.classList.contains('nav-menu__wrapper') ||
        !!(event.relatedTarget as HTMLElement)?.closest('.nav-menu__sub-menu')
      ) {
        return;
      }

      this.removeSubMenus();
    },
    removeSubMenus(closeMenuOnSmallScreens = false) {
      if (closeMenuOnSmallScreens && window.innerWidth < NAV_MENU_LOGIC_MIN_WIDTH) {
        this.isOpen = false;
      }

      for (const subMenu of Array.from(document.querySelectorAll('.nav-menu__sub-menu'))) {
        (subMenu as HTMLElement).style.display = 'none';
      }

      for (const mainLink of Array.from(document.querySelectorAll('.nav-menu__main-link'))) {
        mainLink.classList.remove('hover');
      }

      for (const secondaryLink of Array.from(document.querySelectorAll('.nav-menu__secondary-link'))) {
        secondaryLink.classList.remove('hover');
      }
    },
    sublinkUrl(menu: string, submenu: string) {
      return `/${menu.toLowerCase()}/${submenu}`;
    },
    onFeedBackClick() {
      EventBus.$emit(ModalEvents.Feedback);
      this.removeSubMenus(true);
    },
    onResourceCenterClick() {
      EventBus.$emit(Events.openResourceCenterModal);
      this.removeSubMenus(true);
    },
    onProfilerModalClick() {
      this.showProfilerModal();
      this.removeSubMenus(true);
    },
    onCounterSalesToggle() {
      vxManager.appStateModule.toggleIsCounterSalesOn();
    },
  },
  computed: {
    organizations(): Organization[] {
      return vxManager.organizationsModule.getOrganizations;
    },
    currentUser(): User | undefined {
      return vxManager.userModule.getUserInfo;
    },
    menuItems(): MenuItem[] {
      const profileItem = {
        url: RouterPaths.userSettings,
        label: this.$t('mainMenu.menu.profile'),
        icon: iconProfile,
        enabled: userCanAccessRouteSynchronously(RouterPaths.userSettings, this.organizations),
      };

      const activitySubLinks = [{ name: ActivityPage.Articles }, { name: ActivityPage.ReleaseNotes }];
      if (vxManager.userModule.userHasAccessToExpediteRequests) {
        activitySubLinks.push({ name: ActivityPage.ExpediteRequests });
      }
      const activityItem = {
        url: RouterPaths.articles,
        label: this.$t('mainMenu.menu.activity'),
        icon: activity,
        enabled: true,
        subLinks: activitySubLinks,
      };

      const projectsItem = {
        url: RouterPaths.projects,
        label: this.$t('mainMenu.menu.projects'),
        icon: iconProjects,
        enabled: userCanAccessRouteSynchronously(RouterPaths.projects, this.organizations),
      };

      const ordersItem = {
        url: RouterPaths.orders,
        label: this.$t('mainMenu.menu.orders'),
        icon: iconOrders,
        enabled: userCanAccessRouteSynchronously(RouterPaths.orders, this.organizations),
      };

      const organizationItemUrl: RouteLocationRaw =
        this.organizations.length === 1
          ? { name: 'organization', params: { organizationId: this.organizations[0].id } }
          : { name: 'organizations' };

      const organizationsItem = {
        url: organizationItemUrl,
        label: this.$t('mainMenu.menu.organizations'),
        icon: organizationsIcon,
        enabled: userCanAccessRouteSynchronously(RouterPaths.organizations, this.organizations),
      };

      const usersItem = {
        url: RouterPaths.users,
        label: this.$t('mainMenu.menu.users'),
        icon: iconUsers,
        enabled:
          userCanAccessRouteSynchronously(RouterPaths.users, this.organizations) &&
          process.env.VUE_APP_SHOW_OTHER_USERS_SECTION === 'true',
      };

      return [profileItem, activityItem, projectsItem, ordersItem, organizationsItem, usersItem].filter(
        item => item.enabled
      );
    },
    isResourceCenterAdmin(): boolean {
      const permissions = vxManager.userModule.getUserPermissions;
      return !!permissions && permissions[PermissionTypes.ResourceCenterAdmin];
    },
    userCanAccessProjects(): boolean {
      return vxManager.userModule.userCanAccessProjects;
    },
    isCounterSalesOn(): boolean {
      return vxManager.appStateModule.getIsCounterSalesOn;
    },
    isProfilingEnabled(): boolean {
      return (
        (vxManager.userModule.isGodEmperor && process.env.VUE_APP_PROFILING_ENABLED_FOR_GOD_EMPERORS === 'true') ||
        process.env.VUE_APP_PROFILING_ENABLED === 'true'
      );
    },
  },
  watch: {
    $route() {
      if (window.innerWidth < NAV_MENU_LOGIC_MIN_WIDTH) {
        this.isOpen = false;
      }
    },
  },
});
