import { defineComponent } from 'vue';

export interface ICustomTabItem {
  readonly label: string;
  readonly value: string;
}

export default defineComponent({
  name: 'customTabs',
  props: {
    tabs: {
      type: Array as () => ICustomTabItem[],
      required: true,
    },
    selectedTab: {
      type: String,
    },
  },
  methods: {
    changeTab(tab: ICustomTabItem) {
      if (!this.tabs.includes(tab)) {
        return;
      }
      this.$emit('onTabChange', tab.value);
    },
  },
});
