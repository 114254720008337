import HadrianBase from '../hadrianBase';

export interface MaterialTotal {
  readonly unitCount: number;
  readonly unorderedListPrice: number;
  readonly orderedListPrice: number;
  readonly orderedNetPrice: number;
}

export interface CommissionRate {
  readonly discount: number;
  readonly commissionRate: number;
}

export class DiscountSchedule {
  readonly listPriceModifier: number;
  readonly minUnitCount: number;
  readonly maxUnitCount: number;
}

export interface MaterialDiscount {
  readonly materialId: string;
  readonly quantity: number;
  readonly percentage: number;
  readonly commissionRate?: number;
}

export class DiscountOverride extends HadrianBase {
  readonly reason: string;
  readonly enabled: boolean;
  readonly expiresAt: string;
  readonly isExpired: boolean;
  readonly createdAt: string;
  readonly createdBy: string;
  readonly updatedAt: string;
  readonly updatedBy: string;
  readonly materialDiscounts: MaterialDiscount[];
  readonly originalDiscountId?: string;
}

export class ParentDiscountOverride extends DiscountOverride {
  subsequentVersions: DiscountOverride[];
}

export interface InactiveDiscountOverride {
  readonly discount: DiscountOverride;
  readonly history: DiscountOverride[];
}
