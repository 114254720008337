import ModalEvents from '@/enums/modalEvents';
import EventBus from '@/eventBus';
import errorModal from '@/views/errorModal/errorModal.vue';

export interface CommonErrorModalData {
  readonly showLogo?: boolean;
  readonly title: string;
  readonly text?: string;
  readonly icon: any;
  readonly iconClass?: string;
  readonly actionLabel?: string;
  readonly onActionClick?: () => void;
  readonly useAlphaBackDrop?: boolean;
}

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'commonErrorModal',
  components: {
    errorModal,
  },
  data() {
    return {
      showLogo: true,
      title: '',
      text: '',
      icon: undefined as any | undefined,
      iconClass: '',
      actionLabel: '',
      onActionClick: undefined as (() => void) | undefined,
      useAlphaBackDrop: false,
      isVisible: false,
    };
  },
  created() {
    EventBus.$on(ModalEvents.CommonErrorModal, this.show);
  },
  beforeUnmount() {
    EventBus.$off(ModalEvents.CommonErrorModal, this.hide);
  },
  methods: {
    show(data: CommonErrorModalData) {
      this.showLogo = data.showLogo ?? true;
      this.title = data.title;
      this.text = data.text ?? '';
      this.icon = data.icon;
      this.iconClass = data.iconClass ?? '';
      this.actionLabel = data.actionLabel ?? '';
      this.onActionClick = data.onActionClick;
      this.useAlphaBackDrop = data.useAlphaBackDrop ?? false;
      this.isVisible = true;
      this.modifyScrollBar();
    },
    hide() {
      this.modifyScrollBar(false);
      this.showLogo = true;
      this.title = '';
      this.text = '';
      this.icon = undefined;
      this.iconClass = '';
      this.actionLabel = '';
      this.onActionClick = undefined;
      this.useAlphaBackDrop = false;
      this.isVisible = false;
    },
    modifyScrollBar(addClass = true) {
      const body = document.querySelector('body');
      if (body) {
        if (addClass) {
          body.classList.add('modal-open');
        } else {
          body.classList.remove('modal-open');
        }
      }
    },
  },
});
