import HttpService from '@/endpoints/HttpService';
import PatchOperation from '@/models/patchOperation/patchOperation';
import MeasurementOption from '@/models/userSettings/measurementOption';
import Timezone from '@/models/userSettings/timezonesSettings';
import ToolbarSettings, { BowlCenterType } from '@/models/userSettings/toolbarSettings';
import UserSettings from '@/models/userSettings/userSettings';

const userSettingsUrl = `${process.env.VUE_APP_API_PREFIX}userSettings`;

export enum ToolbarSettingsPaths {
  ShowBothMeasurementsTypes = 'ShowBothMeasurementsTypes',
  ShowNotesOnPdfDrawings = 'ShowNotesOnPdfDrawings',
}

enum Suffixes {
  ToolbarSettings = 'ToolbarSettings',
  BowlCenterDisplayOptions = 'BowlCenterDisplayOptions',
  MeasurementOptions = 'MeasurementOptions',
  UserSettings = 'UserSettings',
  Timezones = 'timezones?regions=America',
}

export default class UserSettingsService {
  public static async getToolbarSettings(): Promise<ToolbarSettings> {
    const url = this.getUserSettingsUrl(Suffixes.ToolbarSettings);
    const response = await HttpService.get(url);
    return response.data;
  }

  public static async getBowlCenterDisplayOptions(): Promise<BowlCenterType[]> {
    const url = this.getUserSettingsUrl(Suffixes.BowlCenterDisplayOptions);
    const response = await HttpService.get(url);
    return response.data.entities;
  }

  public static async getMeasurementOptions(): Promise<MeasurementOption[]> {
    const url = this.getUserSettingsUrl(Suffixes.MeasurementOptions);
    const response = await HttpService.get(url);
    return response.data.entities;
  }

  public static async getTimezones(): Promise<Timezone[]> {
    const url = this.getUserSettingsUrl(Suffixes.Timezones);
    const response = await HttpService.get(url);
    return response.data.value;
  }

  public static async getUserSettings(): Promise<UserSettings> {
    const url = this.getUserSettingsUrl(Suffixes.UserSettings);
    const response = await HttpService.get(url);
    return response.data;
  }

  public static async patchUserSettings(operations: PatchOperation[]): Promise<UserSettings> {
    const url = this.getUserSettingsUrl(Suffixes.UserSettings);
    const response = await HttpService.patch(url, operations);
    return response.data.entity;
  }

  public static async patchToolbarSettings(operations: PatchOperation[]): Promise<ToolbarSettings> {
    const url = this.getUserSettingsUrl(Suffixes.ToolbarSettings);
    const response = await HttpService.patch(url, operations);
    return response.data.entity;
  }

  private static getUserSettingsUrl(suffix: Suffixes): string {
    return `${userSettingsUrl}/${suffix}`;
  }
}
