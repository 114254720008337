import DistributorsService from '@/endpoints/distributorsService';
import PermissionTypes from '@/enums/permissionTypes';
import Organization from '@/models/permission/organization';
import filteredSelector from '@/shared/viewParts/filteredSelector/filteredSelector.vue';
import { FilteredSelectorItem, SearchMode } from '@/shared/viewParts/filteredSelector/filteredSelector';
import { defineComponent } from 'vue';
import pill from '../pill/pill.vue';
import { OrganizationFlag } from '@/enums/organizationFlag';
import { PillThemes } from '../pill/pill';
import { vxManager } from '@/store/store';
import PermissionsHelper from '@/helpers/permissionsHelper';
import MaterialCategories from '@/enums/materialCategories';
import Material from '@/models/material/material';

export enum OrganizationSelectorMode {
  Distributors = 'Distributors',
  SalesAgencies = 'SalesAgencies',
  All = 'All',
}

export default defineComponent({
  name: 'organizationSelector',
  components: {
    filteredSelector,
    pill,
  },
  props: {
    value: {
      type: Object as () => Organization,
    },
    selectorMode: {
      type: String as () => OrganizationSelectorMode,
      default: OrganizationSelectorMode.All,
    },
    eagerFetch: {
      type: Boolean,
      default: false,
    },
    nullable: {
      type: Boolean,
      default: false,
    },
    permissions: {
      type: Array as () => PermissionTypes[],
      default: () => [] as PermissionTypes[],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    fetchOrgPermissionsOnChange: {
      type: Boolean,
      default: false,
    },
    referenceOrganization: {
      type: Object as () => Organization,
    },
    fallbackOrganization: {
      type: Object as () => Organization,
    },
    currentMaterial: {
      type: Object as () => Material,
    },
    setProjectDefaults: {
      type: Function as unknown as () => () => void,
    },
  },
  data() {
    return {
      allOrganizations: [] as Organization[],
      input: '',
      internalValue: this.value as Organization | undefined,
      SearchMode,
      showLoading: false,
    };
  },
  async created() {
    if (this.eagerFetch) {
      await this.fetchOrganizations();
    }
  },
  methods: {
    pillTheme(flag: string): string {
      return flag === OrganizationFlag.WorldDryer ? PillThemes.Alert : PillThemes.Blue;
    },
    formatOrganizationLabel(org: Organization) {
      return org.jdeCustomerId + ' - ' + org.name;
    },
    select(organization: Organization | undefined) {
      if (!organization || typeof organization === 'string') {
        this.internalValue = undefined;
        return;
      }
      this.internalValue = organization!;
    },
    async fetchOrganizations() {
      if (this.showLoading) {
        return;
      }

      this.allOrganizations = [];
      this.showLoading = true;

      try {
        this.allOrganizations = await DistributorsService.getAllOrganizations({
          organizationPermissions: this.permissions,
          organizationTypeLabel: this.selectorMode,
        });

        if (this.referenceOrganization) {
          if (this.referenceOrganization.isSalesAgency) {
            this.allOrganizations = this.allOrganizations.filter(
              org => org.salesAgencyId === this.referenceOrganization?.id
            );
          } else {
            let filteredOrganizations = this.allOrganizations.filter(org => org.id === this.referenceOrganization?.id);
            if (filteredOrganizations.length === 0 && !!this.fallbackOrganization) {
              filteredOrganizations = this.allOrganizations.filter(org => org.id === this.fallbackOrganization?.id);
            }
            this.allOrganizations = filteredOrganizations;
          }
        }

        if (this.allOrganizations.length === 1) {
          this.internalValue = this.allOrganizations[0];
          return;
        }

        if (this.internalValue && !this.allOrganizations.find(o => o.id === this.internalValue?.id)) {
          this.internalValue = undefined;
          return;
        }
      } finally {
        this.showLoading = false;
      }
    },
  },
  computed: {
    placeholder(): string {
      switch (this.selectorMode) {
        case OrganizationSelectorMode.All:
          return this.$t('projects.allOrganizations').toString();
        case OrganizationSelectorMode.Distributors:
          return this.$t('projects.onlyDistributors').toString();
        case OrganizationSelectorMode.SalesAgencies:
          return this.$t('projects.onlySalesAgencies').toString();
        default:
          return '';
      }
    },
    organizations(): FilteredSelectorItem[] {
      return this.allOrganizations.map(d => ({
        label: this.formatOrganizationLabel(d),
        value: d,
        leftSpan: d.flag ?? null,
      }));
    },
  },
  watch: {
    value() {
      this.internalValue = this.value;
      if (this.fetchOrgPermissionsOnChange) vxManager.permissionsModule.fetchOrganizationPermissions(this.value?.id);
      if (this.setProjectDefaults) this.setProjectDefaults();
    },
    internalValue() {
      if (this.value?.id === this.internalValue?.id) {
        return;
      }
      this.$emit('input', this.internalValue);
    },
  },
});
