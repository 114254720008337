import cancel from '@/shared/icons/cancel/cancel.vue';
import hadrianLabel from '@/shared/viewParts/hadrianLabel/hadrianLabel.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'inputGroup',
  props: {
    label: {
      type: String,
    },
    name: {
      type: String,
    },
    mandatory: {
      type: Boolean,
    },
    value: {
      type: [String, Number],
    },
    placeholder: {
      type: String,
    },
    maxLength: {
      type: Number,
    },
    showMaxLengthLabel: {
      type: Boolean,
      default: true,
    },
    numLines: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
    },
    invalid: {
      type: Boolean,
    },
    inputClass: {
      type: String,
    },
    transform: {
      type: Function as unknown as () => ((input: string) => string) | undefined,
    },
    validate: {
      type: Function as unknown as () => ((input: string, acceptUndefined?: boolean) => boolean) | undefined,
    },
    validationRuleLabel: {
      type: String,
    },
    validationRuleLabelClasses: {
      type: String,
    },
    autofocus: {
      type: Boolean,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    hasIcon: {
      type: Boolean,
      default: false,
    },
    trim: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    autocomplete: {
      type: String,
    },
    id: {
      type: String,
    },
  },
  components: {
    cancel,
    hadrianLabel,
  },
  data() {
    return {
      internalValue: this.value,
      internalInvalid: false,
      inputChanged: false,
    };
  },
  mounted() {
    if (this.autofocus) {
      // Hack, all "clean" attempts at autofocus fail when used in a HadrianModal.
      setTimeout(() => this.focus(), 0);
    }
  },
  computed: {
    inputRef(): HTMLInputElement {
      return this.$refs.input as HTMLInputElement;
    },
    inputClasses(): string {
      const classes: string[] = [];

      if (this.invalid || this.internalInvalid) {
        classes.push('input-group__input--invalid');
      }

      if (this.inputClass) {
        classes.push(this.inputClass);
      }

      if (this.clearable) {
        classes.push('input-group__input--clearable');
      }

      if (this.hasIcon) {
        classes.push('input-group__input--spaced');
      }

      return classes.join(' ');
    },
    computedValidationRuleLabelClasses(): string {
      const classes: string[] = this.validationRuleLabelClasses ? [this.validationRuleLabelClasses] : [];

      if (!this.inputChanged) {
        return classes.join(' ');
      }

      if (!this.isValid) {
        classes.push('input-group__validation-rules-label--invalid');
      }

      return classes.join(' ');
    },
    isValid(): boolean {
      if (this.invalid) {
        return false;
      }

      if (this.mandatory && (this.internalValue as string)?.trim() === '') {
        return false;
      }

      if (this.internalValue == undefined) {
        return !this.mandatory;
      }

      if (this.validate) {
        return this.validate(this.internalValue.toString(), !this.mandatory);
      }

      return true;
    },
  },
  methods: {
    focus() {
      this.inputRef.focus();
    },
    select() {
      this.inputRef.select();
    },
    formatInput(input: string) {
      if (this.maxLength) {
        input = input.slice(0, this.maxLength);
      }

      if (this.transform) {
        input = this.transform(input);
      }

      return input;
    },
    cleanInput(input: string) {
      if (this.trim) {
        input = input.trim();
      }

      return input;
    },
    onInput(input: string): void {
      this.inputChanged = true;

      this.internalValue = this.formatInput(input);
      this.inputRef.value = this.internalValue;

      this.$emit('input', this.cleanInput(this.internalValue));
    },
    checkIsValid(): void {
      this.internalInvalid = !this.isValid;
    },
    onBlur() {
      this.checkIsValid();
      this.$emit('blur');
    },
    onFocus(): void {
      this.internalInvalid = false;
      if (this.internalValue == null) {
        this.internalValue = '';
      }
      this.$emit('focus');
    },
    clearFilter(): void {
      this.internalValue = '';
      this.$emit('input', this.internalValue);
      this.focus();
    },
  },
  watch: {
    value() {
      if (this.value == null) {
        this.internalValue = '';
      } else if (this.transform) {
        this.internalValue = this.transform(this.value.toString());
      } else {
        this.internalValue = this.value;
      }

      this.checkIsValid();
    },
    isValid() {
      this.$emit('valid', this.isValid);
    },
    mandatory() {
      this.internalInvalid = !this.isValid;
    },
  },
});
