import { defineComponent } from 'vue';
import iconErrorBrowserNotSupported from '@/shared/icons/iconErrorBrowserNotSupported/iconErrorBrowserNotSupported.vue';
import browserListSupported from '../browserListSupported/browserListSupported.vue';
import authenticationPage from '../authentication/authenticationPage/authenticationPage.vue';

export default defineComponent({
  name: 'browserNotSupported',
  components: {
    authenticationPage,
    browserListSupported,
    iconErrorBrowserNotSupported,
  },
});
