import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "view-title" }
const _hoisted_2 = { class: "top-section" }
const _hoisted_3 = {
  key: 0,
  class: "star"
}
const _hoisted_4 = { href: "#" }
const _hoisted_5 = { class: "top-section__project-infos-container" }
const _hoisted_6 = {
  key: 0,
  class: "main-title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_hadrianEditableField = _resolveComponent("hadrianEditableField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.getShowStar())
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("a", _hoisted_4, [
              _createElementVNode("i", {
                class: _normalizeClass(["star", _ctx.getStarredClass()])
              }, null, 2)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "title-icon"),
        _renderSlot(_ctx.$slots, "top-subtitle")
      ])
    ]),
    (this.title !== '' && !this.isEditable)
      ? (_openBlock(), _createElementBlock("h1", _hoisted_6, _toDisplayString(this.title), 1))
      : _createCommentVNode("", true),
    (this.title !== '' && this.isEditable)
      ? (_openBlock(), _createBlock(_component_hadrianEditableField, {
          key: 1,
          isHeader: true,
          onEditableFieldSaved: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onTitleSaved($event))),
          value: this.title,
          autoSave: _ctx.autoSave
        }, null, 8, ["value", "autoSave"]))
      : _createCommentVNode("", true)
  ]))
}