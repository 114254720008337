import ProjectService from '@/endpoints/projectService';
import { RoomDesignerService } from '@/endpoints/roomDesignerService';
import RoomsService from '@/endpoints/roomsService';
import ScreensService from '@/endpoints/screensService';
import AdaRestriction from '@/enums/adaRestriction';
import { HttpStatusCode } from '@/enums/httpCodes';
import EventBus from '@/eventBus';
import Events from '@/events';
import ArrayHelper from '@/helpers/arrayHelper';
import Room, { buildRoomOrderRequest } from '@/models/room/room';
import {
  AddStyleToRoomRequest,
  DeleteRoomStyleRequest,
  LockerRoomCreationRequest,
  NewOrderReply,
  PartitionRoomCreationRequest,
  PartitionRoomUpdateRequest,
  RoomsGetParams,
  RoomGetParams,
  RoomPatchOperation,
  RoomPriceObject,
} from '@/models/room/roomApiInterfaces';
import RoomLayout from '@/models/room/roomLayout';
import newRoomScreen from '@/models/room/screens/CreateRoomScreenRequest';
import RoomScreen from '@/models/room/screens/roomScreens';
import RdDrawingNote, { RdDrawingNoteDeleteRequest, RdDrawingNoteRequest } from '@/models/roomDesigner/rdDrawingNote';
import { PersistentDataKey } from '@/store/persistentDataModule/persistentDataModule';
import { action, mutation, Module, VuexModule } from 'vuex-class-component';
import { vxManager } from '../store';

interface DeleteRoomRequest {
  readonly roomId: string;
  readonly screenId: string;
}

interface AddRoomsRequest {
  readonly projectId: string;
  readonly rooms: Room[];
  readonly propKey?: keyof Room;
}

interface AddRoomRequest {
  readonly projectId: string;
  readonly room: Room;
}

interface UpdateRoomPriceRequest {
  readonly projectId: string;
  readonly roomId: string;
  readonly price: number;
}

interface UpdateRoomPricesRequest {
  readonly projectId: string;
  readonly values: RoomPriceObject[];
}

interface AddScreenRequest {
  readonly screen: RoomScreen;
}

interface PatchScreenRequest {
  readonly screen: RoomScreen;
}

interface DeleteScreenRequest {
  readonly screenId: string;
  readonly roomId: string;
}

interface RemoveRoomsRequest {
  readonly projectId: string;
  readonly roomsIds: string[];
}

interface ReorderRoomsRequest {
  readonly projectId: string;
  readonly newRooms: Room[];
}
interface DuplicateRoomsRequest {
  readonly roomIds: string[];
  readonly quantity: number;
  readonly mirror: boolean;
}

@Module()
export class RoomsModule extends VuexModule {
  private roomsByProject: { [projectId: string]: Room[] } = {};
  private roomLayouts: RoomLayout[] = [];
  private areRoomsLoading = false;

  @action({ mode: 'mutate' })
  public async clearState() {
    this.clearRoomsData();
  }

  @action({ mode: 'mutate' })
  public async clearAllRoomsData() {
    this.clearRoomsData();
  }

  @action({ mode: 'mutate' })
  public async reorderRooms(params: ReorderRoomsRequest): Promise<void> {
    const projectId = params.projectId;
    const currentRooms = this.roomsByProject[projectId];

    // Optimistically update the rooms, before the backend finishes; this means
    // that the newly placed room will keep its place while the request is
    // pending.
    this.replaceRooms({ projectId, rooms: params.newRooms });

    const reorderRequest = buildRoomOrderRequest(currentRooms, params.newRooms);
    if (Object.keys(reorderRequest).length < 2) {
      return;
    }

    try {
      const rooms = await ProjectService.reorderRooms(params.projectId, reorderRequest);
      this.replaceRooms({ projectId: params.projectId, rooms });
    } catch {
      // If the request failed, roll back up our optimistic update from earlier.
      this.replaceRooms({ projectId: params.projectId, rooms: currentRooms });
    }
  }

  @action({ mode: 'mutate' })
  public async fetchAllRoomsForProject(projectId: string): Promise<void> {
    this.startRoomsLoading();
    const response = await RoomsService.getRooms(projectId);
    this.roomsByProject[projectId] = response.entities;
    this.finishRoomLoading();
  }

  @action({ mode: 'mutate' })
  public async prepareRoomsForOrdering(projectId: string): Promise<NewOrderReply> {
    this.startRoomsLoading();
    const { rooms, notes } = await RoomsService.getRoomsForQuotingOrOrdering(projectId);
    this.roomsByProject[projectId] = rooms;
    this.finishRoomLoading();
    return { rooms, notes };
  }

  @action({ mode: 'mutate' })
  public async prepareRoomsForOrderingNoUpdate(projectId: string): Promise<NewOrderReply> {
    this.startRoomsLoading();
    const { rooms, notes } = await RoomsService.getRoomsForQuotingOrOrdering(projectId);
    this.finishRoomLoading();
    return { rooms, notes };
  }

  @action({ mode: 'mutate' })
  public async createLockerRoomNote(request: RdDrawingNoteRequest): Promise<RdDrawingNote | null> {
    const currentProject = vxManager.projectsModule.getCurrentProject;
    if (!currentProject) {
      return null;
    }

    const room = this.roomsByProject[currentProject.id].find(r => r.id === request.roomId);
    if (!room) {
      return null;
    }

    try {
      const notes = await RoomDesignerService.postDrawingNotes(request);
      const note = notes[0];
      this.addRooms({
        projectId: currentProject.id,
        rooms: [{ ...room, drawingNotes: [note] }],
      });
      return note;
    } catch (exception: any) {
      if (exception?.response?.status === HttpStatusCode.Conflict) {
        await this.fetchRoomForProject({ projectId: currentProject.id, roomId: room.id });
        const newRoom = this.getRoomForProject(currentProject.id, room.id);
        const note = newRoom?.drawingNotes ? newRoom.drawingNotes[0] : undefined;
        this.addRooms({
          projectId: currentProject.id,
          rooms: [{ ...room, drawingNotes: note ? [note] : [] }],
        });
      }

      throw exception;
    }
  }

  @action({ mode: 'mutate' })
  public async updateLockerRoomNote(request: RdDrawingNoteRequest): Promise<RdDrawingNote | null> {
    const currentProject = vxManager.projectsModule.getCurrentProject;
    if (!currentProject) {
      return null;
    }

    const room = this.roomsByProject[currentProject.id].find(r => r.id === request.roomId);
    if (!room) {
      return null;
    }

    try {
      const note = await RoomDesignerService.putDrawingNotes(request);
      const addRooms: AddRoomsRequest = {
        projectId: currentProject.id,
        rooms: [{ ...room, drawingNotes: [note] }],
      };
      this.addRooms(addRooms);
      return note;
    } catch (exception: any) {
      if (exception?.response?.status === HttpStatusCode.NotFound) {
        this.addRooms({
          projectId: currentProject.id,
          rooms: [{ ...room, drawingNotes: [] }],
        });
      }

      throw exception;
    }
  }

  @action({ mode: 'mutate' })
  public async deleteLockerRoomNote(request: RdDrawingNoteDeleteRequest) {
    const currentProject = vxManager.projectsModule.getCurrentProject;
    if (!currentProject) {
      return null;
    }

    const room = this.roomsByProject[currentProject.id].find(r => r.id === request.roomId);
    if (!room) {
      return null;
    }

    try {
      await RoomDesignerService.deleteDrawingNotes(request);
      this.addRooms({
        projectId: currentProject.id,
        rooms: [{ ...room, drawingNotes: [] }],
      });
    } catch (exception: any) {
      if (exception?.response?.status === HttpStatusCode.NotFound) {
        this.addRooms({
          projectId: currentProject.id,
          rooms: [{ ...room, drawingNotes: [] }],
        });
      }

      throw exception;
    }
  }

  /**
   * Recursive method used to fetch all rooms for a project The quote and order
   * processes require ALL rooms instances to be loaded.
   * When the initial POST call to create a quote/order is made
   * it returns a list of copied rooms ids which we need to map to our existing
   * rooms to be able to change the selection.
   *
   * This call is kept "just in case", but it causes issues.
   * When an order is placed, the room changes id on backend side, so the mapping causes duplicates.
   * Also, fetching rooms page per page, no matter what, puts more stress on the network and backend than just one big call.
   * In a real-life situation, a project with more than 1000 rooms would be gianormous, yet fetching 1000 entries should be no problem for the backend.
   */
  @action({ mode: 'mutate' })
  public async fetchPaginatedRoomsForProject(params: RoomsGetParams): Promise<void> {
    const { projectId, ...rest } = params;
    this.startRoomsLoading();
    const response = await RoomsService.getRooms(params.projectId, rest);
    if (response.total === 0) {
      this.finishRoomLoading();
      return;
    }
    const addRooms: AddRoomsRequest = {
      projectId,
      rooms: response.entities,
    };
    this.addRooms(addRooms);
    if (response.to < response.total) {
      this.fetchPaginatedRoomsForProject({
        page: params.page! + 1,
        perPage: params.perPage,
        projectId: params.projectId,
      });
    }
    this.finishRoomLoading();
  }

  @action({ mode: 'mutate' })
  public async fetchRoomForProject(params: RoomGetParams): Promise<void> {
    const { projectId, roomId } = params;
    const response = await RoomsService.getRoom(roomId);

    const addRooms: AddRoomsRequest = {
      projectId,
      rooms: [response],
    };
    this.addRooms(addRooms);
  }

  @action({ mode: 'mutate' })
  public async fetchRoomForProjectNoAuth(params: RoomGetParams): Promise<void> {
    const { projectId, roomId } = params;
    const response = await RoomsService.getRoomNoAuth(roomId);

    const addRooms: AddRoomsRequest = {
      projectId,
      rooms: [response],
    };
    this.addRooms(addRooms);
  }

  @action({ mode: 'mutate' })
  public async fetchRoomPrice(params: RoomGetParams): Promise<void> {
    const { projectId, roomId } = params;
    const price = await RoomsService.getRoomPrice(roomId);

    const updateRoom: UpdateRoomPriceRequest = {
      projectId,
      roomId,
      price,
    };
    this.setRoomPrice(updateRoom);
  }

  @action({ mode: 'mutate' })
  public async fetchRoomPrices(projectId: string): Promise<void> {
    const values = await ProjectService.updateRoomPrices(projectId);
    this.setRoomPrices({
      projectId,
      values,
    });
  }

  @action({ mode: 'mutate' })
  public async createRoom(roomRequest: PartitionRoomCreationRequest | LockerRoomCreationRequest): Promise<string> {
    const response = await RoomsService.createRoom(roomRequest);
    const currentProject = vxManager.projectsModule.getCurrentProject;
    if (currentProject) {
      const addRoom: AddRoomRequest = {
        projectId: currentProject.id,
        room: response,
      };
      this.insertRoom(addRoom);
    }

    return response.id;
  }

  @action({ mode: 'mutate' })
  public async updateRoom(roomRequest: PartitionRoomUpdateRequest): Promise<string> {
    const response = await RoomsService.updateRoom(roomRequest);
    const addRooms: AddRoomsRequest = {
      projectId: roomRequest.projectId,
      rooms: [response],
    };
    this.addRooms(addRooms);
    return response.id;
  }

  @action({ mode: 'mutate' })
  public async addStyleToRoom(request: AddStyleToRoomRequest): Promise<string> {
    const response = await RoomsService.addStyleToRoom(request);
    const addRooms: AddRoomsRequest = {
      projectId: request.projectId,
      rooms: [response],
    };
    this.addRooms(addRooms);
    return response.id;
  }

  @action({ mode: 'mutate' })
  public async patchRoom(operations: RoomPatchOperation[]): Promise<void> {
    return await vxManager.roomDesignerModule.withRoomLoading(async () => {
      const updatedRoom = await RoomsService.patchRoom(operations, operations[0].roomId);
      const currentProject = vxManager.projectsModule.getCurrentProject;

      if (!currentProject) {
        return;
      }

      this.addRooms({
        projectId: currentProject.id,
        rooms: [updatedRoom],
      });
    });
  }

  @action({ mode: 'mutate' })
  public async patchRoomWithoutPrice(operations: RoomPatchOperation[]): Promise<void> {
    return await vxManager.roomDesignerModule.withRoomLoading(async () => {
      const operationsByRoomId: any[] = [];
      operations.forEach(operation => {
        const existingRoomIdIndex = operationsByRoomId.findIndex(e => e.roomId === operation.roomId);
        if (existingRoomIdIndex !== -1) {
          operationsByRoomId[existingRoomIdIndex].push(operation);
        } else {
          operationsByRoomId.push([operation]);
        }
      });

      const currentProject = vxManager.projectsModule.getCurrentProject;
      if (operationsByRoomId.length > 0) {
        EventBus.$emit(Events.getRoomBom);
        await ArrayHelper.asyncForEach(operationsByRoomId, async (object: any, index: number, array: any[]) => {
          const operationsForId = object as any[];
          const response = await RoomsService.patchRoom(operationsForId, operationsForId[0].roomId);
          if (currentProject) {
            const addRooms: AddRoomsRequest = {
              projectId: currentProject.id,
              rooms: [response],
              propKey: 'netPrice',
            };
            this.addRooms(addRooms);
          }
        });
        if (currentProject) {
          await vxManager.roomDesignerModule.withRoomLoading(async () => {
            await vxManager.projectsModule.fetchRoomBom({
              projectId: currentProject.id,
              roomId: operationsByRoomId[0][0].roomId,
            });
          });
        } else {
          EventBus.$emit(Events.getRoomBomDone);
        }
      }
    });
  }

  @action({ mode: 'mutate' })
  public async patchRoomWithoutUpdate(operation: RoomPatchOperation): Promise<void> {
    return await vxManager.roomDesignerModule.withRoomLoading(async () => {
      await RoomsService.patchRoom([operation], operation.roomId);
    });
  }

  @action({ mode: 'mutate' })
  public async patchRoomMultipleOperations(operations: RoomPatchOperation[]): Promise<void> {
    return await vxManager.roomDesignerModule.withRoomLoading(async () => {
      if (operations.length === 0) {
        return;
      }
      const response = await RoomsService.patchRoom(operations, operations[0].roomId);
      const currentProject = vxManager.projectsModule.getCurrentProject;
      if (currentProject) {
        const addRooms: AddRoomsRequest = {
          projectId: currentProject.id,
          rooms: [response],
        };
        this.addRooms(addRooms);
      }
    });
  }

  @action({ mode: 'mutate' })
  public async patchRoomMultipleOperationsWithoutUpdate(operations: RoomPatchOperation[]): Promise<void> {
    return await vxManager.roomDesignerModule.withRoomLoading(async () => {
      await RoomsService.patchRoom(operations, operations[0].roomId);
    });
  }

  @action({ mode: 'mutate' })
  public async deleteRoom(roomId: string): Promise<void> {
    await RoomsService.deleteRoom(roomId);
    const currentProject = vxManager.projectsModule.getCurrentProject;
    if (currentProject) {
      const removeRooms: RemoveRoomsRequest = {
        projectId: currentProject.id,
        roomsIds: [roomId],
      };
      this.removeRooms(removeRooms);
    }
  }

  @action({ mode: 'mutate' })
  public async deleteProjectRooms(roomsIds: string[]): Promise<void> {
    const currentProject = vxManager.projectsModule.getCurrentProject;
    if (!currentProject) {
      return;
    }

    await ProjectService.deleteRooms(currentProject.id, roomsIds);
    const removeRooms: RemoveRoomsRequest = {
      projectId: currentProject.id,
      roomsIds,
    };
    this.removeRooms(removeRooms);
  }

  @action({ mode: 'mutate' })
  public async duplicateProjectRooms(request: DuplicateRoomsRequest): Promise<void> {
    const currentProject = vxManager.projectsModule.getCurrentProject;
    if (!currentProject) {
      return;
    }
    await ProjectService.duplicateRooms(currentProject.id, request.roomIds, request.quantity, request.mirror);

    const response = await RoomsService.getRooms(currentProject.id);
    this.roomsByProject[currentProject.id] = response.entities;

    await vxManager.projectsModule.refreshProject(currentProject.id);
  }

  @action({ mode: 'mutate' })
  public async deleteRoomStyle(request: DeleteRoomStyleRequest): Promise<void> {
    await RoomsService.deleteRoomStyle(request);
  }

  @action({ mode: 'mutate' })
  public async setRoomLayouts(layouts: RoomLayout[]): Promise<void> {
    this.setRoomLayoutsInternal(layouts);
  }

  @action({ mode: 'mutate' })
  public async createScreenForRoom(screen: newRoomScreen): Promise<void> {
    const response = await ScreensService.putRoomScreen(screen);
    const request = {
      screen: response,
    };
    this.addRoomScreen(request);
  }

  @action({ mode: 'mutate' })
  public async deleteScreenForRoom(delScreenReq: DeleteRoomRequest): Promise<void> {
    await ScreensService.deleteRoomScreen(delScreenReq.screenId);
    this.deleteRoomScreen(delScreenReq);
  }

  @action({ mode: 'mutate' })
  public async patchScreenForRoom(screen: newRoomScreen): Promise<void> {
    const response = await ScreensService.patchRoomScreen(screen);
    const request = { screen: response };
    this.patchRoomScreen(request);
  }

  get getRoomByIdForCurrentProject() {
    return (roomId: string) => {
      const currentProject = vxManager.projectsModule.getCurrentProject;
      if (!currentProject) {
        return;
      }
      return this.roomsByProject[currentProject.id].find(room => room.id === roomId);
    };
  }

  get getRoomsForProject() {
    return (projectId: string) => {
      return this.roomsByProject[projectId] ? this.roomsByProject[projectId] : [];
    };
  }

  get getRoomForProject() {
    return (projectId: string, roomId: string) => {
      const rooms = this.roomsByProject[projectId];
      if (rooms) {
        return rooms.find(room => room.id === roomId);
      }
      return undefined;
    };
  }

  get getRoomLayouts() {
    return (typeFilter?: string) => {
      if (typeFilter) {
        return this.roomLayouts.filter(layout => layout.type === typeFilter);
      }
      return this.roomLayouts;
    };
  }

  get roomsLoading() {
    return this.areRoomsLoading;
  }

  get getRoomSettings() {
    return (roomId: string) => {
      const currentProject = vxManager.projectsModule.getCurrentProject;
      if (!currentProject) {
        return;
      }
      return this.roomsByProject[currentProject.id].find(room => room.id === roomId)?.roomSettings;
    };
  }

  @mutation
  private addRooms(request: AddRoomsRequest) {
    if (this.roomsByProject[request.projectId] === undefined) {
      this.roomsByProject[request.projectId] = [];
    }
    ArrayHelper.addOrReplace(this.roomsByProject[request.projectId], request.rooms, request.propKey);
  }

  @mutation
  private replaceRooms(request: AddRoomsRequest) {
    // In contrast with `addRooms`, this permits reordering of rooms.
    this.roomsByProject[request.projectId] = request.rooms;
  }

  @mutation
  private insertRoom(request: AddRoomRequest) {
    if (this.roomsByProject[request.projectId] === undefined) {
      this.roomsByProject[request.projectId] = [];
    }
    this.roomsByProject[request.projectId] = [...this.roomsByProject[request.projectId], request.room];
  }

  @mutation
  private setRoomPrice(request: UpdateRoomPriceRequest) {
    const rooms = this.roomsByProject[request.projectId];
    if (!rooms) {
      return;
    }
    const room = rooms.find(r => r.id === request.roomId);
    if (!room) {
      return;
    }

    room.netPrice = request.price;
  }

  @mutation
  private setRoomPrices(request: UpdateRoomPricesRequest) {
    const rooms = this.roomsByProject[request.projectId];
    if (!rooms) {
      return;
    }

    request.values.forEach(p => {
      const room = rooms.find(r => r.id === p.key);
      if (room) {
        room.netPrice = p.value;
      }
    });
  }

  @mutation
  private removeRooms(request: RemoveRoomsRequest) {
    const { projectId, roomsIds } = request;
    roomsIds.forEach(roomId => {
      const index = this.roomsByProject[projectId].findIndex(room => room.id === roomId);
      this.roomsByProject[projectId].splice(index, 1);
      vxManager.persistentDataModule.removeData({ id: PersistentDataKey.UNCOLLAPSED_ROOM, value: roomId });
    });

    const reorderedRooms = this.roomsByProject[projectId].map((room, index) => ({
      ...room,
      displayOrder: index + 1,
    }));
    this.roomsByProject[projectId] = reorderedRooms;
  }

  @mutation
  private finishRoomLoading() {
    this.areRoomsLoading = false;
  }

  @mutation
  private startRoomsLoading() {
    this.areRoomsLoading = true;
  }

  @mutation private addRoomScreen(request: AddScreenRequest) {
    const room = vxManager.roomsModule.getRoomByIdForCurrentProject(request.screen.roomId);
    if (!room) {
      return;
    }
    const { roomScreens } = room;
    if (roomScreens) {
      roomScreens.push(request.screen);
    } else {
      room.roomScreens = [request.screen];
    }
  }

  @mutation private deleteRoomScreen(request: DeleteScreenRequest) {
    const room = vxManager.roomsModule.getRoomByIdForCurrentProject(request.roomId);
    if (!room) {
      return;
    }
    const screens = room.roomScreens.filter(x => x.id !== request.screenId);
    room.roomScreens = screens;
  }

  @mutation private patchRoomScreen(request: PatchScreenRequest) {
    const room = vxManager.roomsModule.getRoomByIdForCurrentProject(request.screen.roomId);
    if (room) {
      const screenIndex = room.roomScreens.findIndex(screen => screen.id === request.screen.id);
      room.roomScreens[screenIndex] = request.screen;
    }
  }

  @mutation private setRoomLayoutsInternal(layouts: RoomLayout[]) {
    // FIXME: This should be set in the API in the future and this hack should be removed.
    layouts.forEach(element => {
      switch (element.rlid) {
        // Corner - Left Wall with Wide ADA/BF Unit
        case 'ce33feb6-9e61-42f2-9d52-9941f95c3ef0':
          (element as any).restrictions = {
            adaRestriction: AdaRestriction.left,
          };
          break;
        // Corner - Right Wall with Wide ADA/BF Unit
        case '63c6f0ba-ba96-43b6-830a-c75e0c64da81':
          (element as any).restrictions = {
            adaRestriction: AdaRestriction.right,
          };
          break;
        // Corner - Left Wall with Extended ADA/BF Units
        case 'ed7baffd-226a-4aab-bad6-09a7952a9c2d':
          (element as any).restrictions = {
            minUnits: 2,
            adaRestriction: AdaRestriction.left,
          };
          break;
        // Corner - Right Wall with Extended ADA/BF Units
        case 'b98234b9-3fcb-45e5-959f-e5ece353af7c':
          (element as any).restrictions = {
            minUnits: 2,
            adaRestriction: AdaRestriction.right,
          };
          break;
        // Between Walls with Extended ADA/BF Unit
        case '4d73e5f4-874c-4b0d-9810-98b9b835867c':
          (element as any).restrictions = {
            adaRestriction: AdaRestriction.eitherEnd,
          };
          break;
        // Between Walls with Wide ADA/BF Unit
        case '70cc3b6c-df53-45e6-859a-9218520ddea9':
          (element as any).restrictions = {
            adaRestriction: AdaRestriction.eitherEnd,
          };
          break;
      }
    });

    this.roomLayouts = layouts;
  }

  @mutation private clearRoomsData() {
    this.roomsByProject = {};
    this.areRoomsLoading = false;
  }
}
