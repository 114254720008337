import EventBus from '@/eventBus';
import Events from '@/events';
import { labelDate } from '@/helpers/dateHelper';
import { FilterType } from '@/models/filterBox/filterBox';
import calendar from '@/shared/icons/calendar/calendar.vue';
import cancel from '@/shared/icons/cancel/cancel.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'dateRangeButton',
  props: {
    showPicker: {
      type: Boolean,
    },
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
    useComponentEvents: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    calendar,
    cancel,
  },
  computed: {
    openedAndNoDateSelected(): boolean {
      return this.noDateSelected && this.showPicker;
    },
    noDateSelected(): boolean {
      return !this.startDate && !this.endDate;
    },
    currentLabel(): string {
      if (this.openedAndNoDateSelected) {
        return '';
      } else if (this.noDateSelected) {
        return this.$t('labels.selectRange').toString();
      }
      return labelDate(this.startDate, this.endDate);
    },
  },
  methods: {
    toggleModal(): void {
      EventBus.$emit(Events.toggleDateModal);
    },
    clearDates(): void {
      EventBus.$emit(Events.onDeleteFilter, FilterType.DateRange);
    },
    parentClick(): void {
      this.$emit('clickEvent');
    },
    parentClearDates(): void {
      this.$emit('clearEvent');
    },
  },
});
