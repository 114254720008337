import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "browser-not-supported-content" }
const _hoisted_2 = { class: "browser-not-supported-content__logo" }
const _hoisted_3 = { class: "browser-not-supported-content__title" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_iconErrorBrowserNotSupported = _resolveComponent("iconErrorBrowserNotSupported")!
  const _component_browserListSupported = _resolveComponent("browserListSupported")!
  const _component_authenticationPage = _resolveComponent("authenticationPage")!

  return (_openBlock(), _createBlock(_component_authenticationPage, { showHeader: false }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_iconErrorBrowserNotSupported)
        ]),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('browserNotSupportedModal.title')), 1),
        _createElementVNode("p", {
          class: "browser-not-supported-content__body",
          innerHTML: _ctx.$t('browserNotSupportedModal.text')
        }, null, 8, _hoisted_4),
        _createVNode(_component_browserListSupported)
      ])
    ], undefined, true),
    _: 1
  }))
}