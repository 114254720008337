export const ASCENDING = 'asc';
export const DESCENDING = 'desc';
export const USER_INPUT_DEBOUNCE = 1500;
export const CODE_UPDATE_DEBOUNCE = 500;
export const ADDRESS_AUTOCOMPLETE_DEBOUNCE = 300;

export const MAX_SCREENS_OF_TYPE = 99;
export const MAX_DUPLICATE_QUANTITY = 99;

export const MAX_FLOOR_OPTIONS = 100;

export const MAX_ROOMS_PER_PROJECT = 100;

export const MAX_SINGLE_PART = 99999;
