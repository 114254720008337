<template>
  <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15 30a15.003 15.003 0 0 0 15-15A15.003 15.003 0 0 0 15 0 15.003 15.003 0 0 0 0 15a15.003 15.003 0 0 0 15 15Z"
      fill="#F06800"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15 7.031c-1.035 0-1.875.84-1.875 1.875v6.563a1.875 1.875 0 0 0 3.75 0V8.906c0-1.035-.84-1.875-1.875-1.875Zm1.732 13.345a1.875 1.875 0 1 0-3.464 1.436 1.875 1.875 0 0 0 3.464-1.436Z"
      fill="#fff"
    />
  </svg>
</template>
