enum RouterPaths {
  login = '/login',
  loginEmailAutoFill = '/login/:emailAutoFill',
  loginAutoFill = '/login/:emailAutoFill/:passwordAutoFill',
  changePassword = '/changePassword',
  changePasswordEmailAutoFill = '/changePassword/:emailAutoFill',
  setUpAccount = '/setUpAccount',
  setUpAccountEmailAutoFill = '/setUpAccount/:emailAutoFill',
  forgotPassword = '/forgotPassword',
  forgotPasswordEmailAutoFill = '/forgotPassword/:emailAutoFill',
  confirmForgotPassword = '/confirmForgotPassword',
  confirmForgotPasswordEmailAutoFill = '/confirmForgotPassword/:emailAutoFill',
  unauthenticatedTermsConditions = '/noauth/terms-and-conditions',
  unauthenticatedLegalNotice = '/noauth/legal-notice',
  unauthenticatedPrivacyPolicy = '/noauth/privacy-policy',
  unauthenticatedRoomDesigner = '/projects/rooms/noauth/:projectId/:roomId',
  unauthenticatedRoomDesignerPattern = '/projects/rooms/noauth/',
  home = '/',
  releaseNote = '/releaseNotes/:id',
  profile = '/profile',
  projects = '/projects',
  createProject = '/projects/new',
  createLockerStyle = '/projects/:projectId/styles/new',
  editLockerStyle = '/projects/:projectId/styles/:lockerStyleId',
  projectDetails = '/projects/:projectId',
  projectDetailsTab = '/projects/:projectId/tab/:openTab',
  orderProject = '/projects/:projectId/orders/new/:orderStep(\\d+)',
  orderProjectAddressStep = '/projects/:projectId/orders/new/2/:view',
  orderPartial = '/projects/:projectId/orders/:orderId/:orderStep(\\d+)',
  orders = '/orders',
  quoteCreation = '/projects/:projectId/quote/:quoteStep([1-4]+)',
  quoteCreationAddressStep = '/projects/:projectId/quote/2/:view',
  organizations = '/organizations',
  organization = '/organizations/:organizationId',
  organizationGeneralPreferences = '/organizations/:organizationId/generalPreferences',
  users = '/users',
  createUser = '/user/new',
  userDetails = '/users/:userId',
  news = '/news',
  contactUs = '/contact-us',
  termsConditions = '/terms-and-conditions',
  legalNotice = '/legal-notice',
  privacyPolicy = '/privacy-policy',
  userSettings = '/userSettings',
  userSettingsPersonalInfos = '/userSettings/personal-informations',
  userSettingsPartitionPreferences = '/userSettings/partition-preferences',
  pdfLoader = '/pdfLoader',
  createRoom = '/projects/:projectId/rooms/new/:step(\\d)',
  createLockerRoom = '/projects/:projectId/lockerRooms/new/:step(\\d)',
  addStyleToRoom = '/projects/:projectId/lockerRooms/addStyle/:roomId/:step(\\d)',
  editLockerRoomConfiguration = '/projects/:projectId/lockerRooms/:roomId/edit/:configurationId/:step(\\d)',
  editRoom = '/projects/:projectId/rooms/:roomId',
  icons = '/icons',
  components = '/components',
  resourceAdmin = '/resourceAdmin',
  networkError = '/networkError',
  releaseNotes = '/releaseNotes',
  releaseNotePreview = '/release-notes/preview/:id',
  articles = '/articles',
  article = '/articles/:id',
  articlePreview = '/articles/preview/:id',
  expediteRequests = '/expediteRequests',
}

export default RouterPaths;
