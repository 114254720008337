import DiscountsService, {
  CreateDiscountParams,
  CreateNewDiscountVersionParams,
  UpdateDiscountParams,
} from '@/endpoints/discountsService';
import ArrayHelper from '@/helpers/arrayHelper';
import { makePatchOperation } from '@/helpers/patchOperations/patchBuilder';
import {
  CommissionRate,
  DiscountOverride,
  DiscountSchedule,
  MaterialTotal,
  ParentDiscountOverride,
} from '@/models/discounts/discountOverride';
import { DiscountResponse } from '@/models/discounts/discountResponse';
import { action, mutation, Module, VuexModule } from 'vuex-class-component';
import { vxManager } from '../store';

@Module()
export class DiscountOverridesModule extends VuexModule {
  private _discountSchedules: { [materialId: string]: DiscountSchedule[] } = {};
  private _discountOverrides: ParentDiscountOverride[] = [];
  private _commissionRates: { [materialId: string]: CommissionRate[] } = {};
  private _materialTotals: { [materialId: string]: MaterialTotal } = {};

  get discountSchedules(): { [materialId: string]: DiscountSchedule[] } {
    return this._discountSchedules;
  }

  get discountOverrides(): ParentDiscountOverride[] {
    return this._discountOverrides;
  }

  get commissionRates(): { [materialId: string]: CommissionRate[] } {
    return this._commissionRates;
  }

  get materialTotals(): { [materialId: string]: MaterialTotal } {
    return this._materialTotals;
  }

  @action({ mode: 'mutate' })
  public async fetchProjectDiscountOverrides(projectId: string) {
    this.setProjectDiscountsInformation(await DiscountsService.getProjectDiscountsInfo(projectId));
  }

  @action({ mode: 'mutate' })
  public async createDiscount(params: CreateDiscountParams): Promise<void> {
    this.addOrUpdateDiscount(await DiscountsService.createProjectDiscount(params));
    vxManager.projectsModule.refreshProject(params.projectId);
  }

  @action({ mode: 'mutate' })
  public async editDiscount(params: CreateNewDiscountVersionParams): Promise<void> {
    this.addDiscountToParentHistory(await DiscountsService.createNewDiscountVersion(params));
    vxManager.projectsModule.refreshProject(params.projectId);
  }

  @action({ mode: 'mutate' })
  public async disableDiscount({
    projectId,
    discountId,
  }: Pick<UpdateDiscountParams, 'discountId' | 'projectId'>): Promise<void> {
    const params: UpdateDiscountParams = {
      discountId,
      projectId,
      patchOperations: [makePatchOperation(DiscountOverride, 'enabled', false)],
    };
    this.addOrUpdateDiscount(await DiscountsService.updateProjectDiscount(params));
    vxManager.projectsModule.refreshProject(params.projectId);
  }

  @mutation private setProjectDiscountsInformation(discountResponse: DiscountResponse) {
    this._discountSchedules = discountResponse.discountSchedules;
    this._discountOverrides = discountResponse.discountOverrides;
    this._commissionRates = discountResponse.commissionRates;
    this._materialTotals = discountResponse.materialTotals;
  }

  @mutation
  private addOrUpdateDiscount(discount: DiscountOverride) {
    ArrayHelper.addOrReplace(this._discountOverrides, [discount]);
  }

  @mutation
  private addDiscountToParentHistory(discount: DiscountOverride) {
    const parent = this._discountOverrides.find(p => p.id === discount.originalDiscountId);
    if (parent) {
      if (parent.subsequentVersions) {
        parent.subsequentVersions.unshift(discount);
      } else {
        parent.subsequentVersions = [discount];
      }
    }
  }
}
