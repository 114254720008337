import DistributorsService from '@/endpoints/distributorsService';
import i18n from '@/i18n';
import Organization from '@/models/permission/organization';
import paths from '@/router/paths';
import RouterPaths from '@/router/paths';
import { vxManager } from '@/store/store';

/**
 * If the route matches with any of the conditions this will return the result of
 * the validation method for that given route tree.
 * @param route route the user is trying to access
 */
export async function userCanAccessRoute(route: string): Promise<boolean> {
  if (route.toLowerCase().startsWith(paths.unauthenticatedRoomDesignerPattern.toLowerCase())) {
    return true;
  }

  let organizations: Organization[];
  if (process.env.VUE_APP_ORGANIZATIONS_ROUTER_HOOK_ENABLED === 'false') {
    organizations = vxManager.organizationsModule.getOrganizations;
  } else {
    if (vxManager.userModule.getIsLoggedIn || vxManager.userModule.getStorageContainsToken) {
      organizations = vxManager.organizationsModule.getOrganizations.length
        ? vxManager.organizationsModule.getOrganizations
        : await DistributorsService.getAllOrganizations();
    } else {
      organizations = [];
    }
  }

  if (route.startsWith(RouterPaths.projects)) {
    if (route.startsWith(RouterPaths.createProject)) {
      return userCanCreateProject(organizations);
    }
    return userCanAccessProjectPages(organizations);
  } else if (route.startsWith(RouterPaths.users)) {
    return userCanAccessUsersPages(organizations);
  } else if (route === RouterPaths.createUser) {
    return userCanAccessCreateUserPage(organizations);
  } else if (route === RouterPaths.home) {
    return userCanAccessProjectPages(organizations);
  } else if (route === RouterPaths.orders) {
    return userCanAccessOrdersPage(organizations);
  } else if (route === RouterPaths.organizations) {
    return userCanAccessOrganizationsPage(organizations);
  }

  return true;
}

export function userCanAccessRouteSynchronously(route: string, organizations: Organization[]): boolean {
  if (route.startsWith(RouterPaths.projects)) {
    if (route.startsWith(RouterPaths.createProject)) {
      return userCanCreateProject(organizations);
    }
    return userCanAccessProjectPages(organizations);
  } else if (route.startsWith(RouterPaths.users)) {
    return userCanAccessUsersPages(organizations);
  } else if (route === RouterPaths.createUser) {
    return userCanAccessCreateUserPage(organizations);
  } else if (route === RouterPaths.home) {
    return userCanAccessProjectPages(organizations);
  } else if (route === RouterPaths.orders) {
    return userCanAccessOrdersPage(organizations);
  } else if (route === RouterPaths.organizations) {
    return userCanAccessOrganizationsPage(organizations);
  }

  return true;
}

export function userHomePage(organizations?: Organization[]): RouterPaths {
  if (organizations == null || userCanAccessProjectPages(organizations)) {
    return RouterPaths.home;
  } else {
    return RouterPaths.profile;
  }
}

export function userDetailsPage(currentUserId: string, otherUserId: string): string {
  return currentUserId !== otherUserId ? `${RouterPaths.users}/${otherUserId}` : RouterPaths.userSettings;
}

export function setPageTabTitle(tabTitle: string | undefined) {
  window.document.title = tabTitle ? tabTitle : i18n.global.t('pageTabTiles.generic');
}

/**
 * Checks that the user has the Can access projects permission and that he belongs to at least one organization
 */
// TODO: All of these checks should be done backend.
function userCanCreateProject(organizations: Organization[]): boolean {
  return vxManager.userModule.userCanCreateProjects && userCanAccessOrdersPage(organizations);
}

function userCanAccessProjectPages(organizations: Organization[]): boolean {
  return vxManager.userModule.userCanAccessProjects && userCanAccessOrdersPage(organizations);
}

function userCanAccessUsersPages(organizations: Organization[]): boolean {
  return vxManager.userModule.userCanAccessUsersList && userCanAccessOrdersPage(organizations);
}

function userCanAccessCreateUserPage(organizations: Organization[]): boolean {
  return vxManager.userModule.userCanCreateUser && userCanAccessOrdersPage(organizations);
}

function userCanAccessOrdersPage(organizations: Organization[]): boolean {
  return organizations.length > 0;
}

function userCanAccessOrganizationsPage(organizations: Organization[]): boolean {
  return (
    organizations.length > 0 &&
    vxManager.userModule.userCanAccessOrganizationsSection &&
    process.env.VUE_APP_ORGANIZATIONS_SECTION_ENABLED === 'true'
  );
}
