import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.showFooter)
    ? (_openBlock(), _createElementBlock("footer", {
        key: 0,
        class: _normalizeClass(["environment-footer", _ctx.environmentClass])
      }, [
        _createElementVNode("pre", null, _toDisplayString(_ctx.userInfo), 1)
      ], 2))
    : _createCommentVNode("", true)
}