import Toast from '@/helpers/toast';
import iconToastClose from '@/shared/icons/toastClose/toastClose.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'h-toast-close-button',
  components: {
    iconToastClose,
  },
  methods: {
    onClick(event: MouseEvent) {
      Toast.dismissParentToast(event);
    },
  },
});
