import ModalEvents from '@/enums/modalEvents';
import EventBus from '@/eventBus';
import ProjectParts, { ProjectPartsCustom } from '@/models/part/projectParts';
import ProjectPartsCreation from '@/models/part/projectPartsCreation';
import {
  CustomPartsData,
  ModalSaveActionType,
  SuggestedPart,
  SuggestedPartsData,
  SuggestedPartsModalData,
  TemporaryParts,
  TemporaryPartData,
} from '@/models/part/suggestedParts';
import { RoomPartsEditRequest } from '@/models/room/roomApiInterfaces';
import RoomParts from '@/models/room/roomParts';
import hadrianModal from '@/shared/viewParts/hadrianModal/hadrianModal.vue';
import relatedPartsTable from '@/shared/viewParts/relatedPartsTable/relatedPartsTable.vue';
import { vxManager } from '@/store/store';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'suggestedPartsModal',
  components: {
    hadrianModal,
    relatedPartsTable,
  },
  data() {
    return {
      isRemovingPart: false,
      isRoomPart: false,
      currentPartNumber: '',
      selectedPart: {} as ProjectParts | RoomParts,
      partsData: {} as SuggestedPartsData,
      onSave: undefined as ModalSaveActionType | undefined,
      entityId: '',
      temporaryParts: {} as TemporaryParts,
    };
  },
  async created() {
    EventBus.$on(ModalEvents.SuggestedPartsModal, this.show);
  },
  beforeUnmount() {
    EventBus.$off(ModalEvents.SuggestedPartsModal, this.show);
  },
  computed: {
    removeOrAddKey(): string {
      return this.isRemovingPart ? 'modal.suggestedParts.removingPartText' : 'modal.suggestedParts.addingPartText';
    },
    hasSuggestedPartsOnly(): boolean {
      return (
        !!this.temporaryParts.suggested &&
        this.temporaryParts.suggested.length > 0 &&
        this.temporaryParts.autoAdded.length === 0
      );
    },
  },
  methods: {
    async show(data: SuggestedPartsModalData) {
      this.isRemovingPart = data.isRemovingPart;
      this.isRoomPart = data.isRoomPart;
      this.currentPartNumber = data.currentPartNumber;
      this.selectedPart = (data.selectedPart as ProjectPartsCustom) ?? undefined;
      this.partsData = data.partsData;
      this.partsData.parts = this.getCustomPartsData(data.partsData.fullPartList);
      this.onSave = data.onSave;
      this.entityId = data.entityId;
      this.temporaryParts = {
        autoAdded: this.getTemporaryParts(data.partsData.autoAddedParts),
        suggested: this.getTemporaryParts(data.partsData.suggestedParts),
      };
      (this.$refs.suggestedPartsModal as any).show();
    },
    getCustomPartsData(parts: ProjectParts[] | RoomParts[]): CustomPartsData[] {
      if (this.isRoomPart) {
        return this.getProjectRoomPartsData(parts as RoomParts[]);
      } else {
        return this.getProjectPartsData(parts as ProjectParts[]);
      }
    },
    getProjectRoomPartsData(parts: RoomParts[]): CustomPartsData[] {
      return parts.map(part => {
        return {
          quantity: part.quantity,
          label: part.roomPart.partLabel,
          partNumber: part.roomPart.partNumber,
          partId: part.roomPart.partId,
        };
      });
    },
    getProjectPartsData(parts: ProjectParts[]): CustomPartsData[] {
      return parts.map(part => {
        return {
          quantity: part.quantity,
          label: part.label,
          partNumber: part.partNumber,
          partId: part.partId,
        };
      });
    },
    getTemporaryParts(parts: SuggestedPart[]): TemporaryPartData[] {
      if (parts) {
        return parts.map(part => {
          return {
            partId: part.part.id,
            quantity: Math.abs(part.quantity),
          };
        });
      }
      return [];
    },
    getNewParts(): ProjectPartsCreation[] | RoomPartsEditRequest[] {
      let allParts: any[] = [];

      for (const key in this.temporaryParts) {
        if (Object.prototype.hasOwnProperty.call(this.temporaryParts, key)) {
          const newParts = this.temporaryParts[key]
            .filter(part => part.quantity !== 0)
            .map(part => {
              if (this.isRoomPart) {
                return {
                  roomId: this.entityId,
                  partId: part.partId,
                  quantityDelta: this.isRemovingPart ? part.quantity * -1 : part.quantity,
                };
              } else {
                return {
                  projectId: this.entityId,
                  partId: part.partId,
                  quantity: this.isRemovingPart ? part.quantity * -1 : part.quantity,
                };
              }
            });

          allParts = [...allParts, ...newParts];
        }
      }

      return allParts;
    },
    async rightClick() {
      const newParts = this.getNewParts();
      let partList = this.partsData.fullPartList;

      if (newParts.length > 0) {
        if (this.isRoomPart) {
          await vxManager.projectsModule.postRoomSuggestedPartsEdit({
            roomId: this.entityId,
            parts: newParts as RoomPartsEditRequest[],
          });
        } else {
          const response = await vxManager.projectPartsModule.postProjectSuggestedParts(
            newParts as ProjectPartsCreation[]
          );
          partList = response.projectParts;
        }

        this.verifySaveAction(partList);
      }
    },
    leftClick() {
      this.verifySaveAction(this.partsData.fullPartList);
    },
    verifySaveAction(parts: RoomParts[] | ProjectParts[]) {
      if (this.onSave) {
        this.onSave(this.selectedPart, this.partsData.addedPaint, parts);
      }
    },
  },
});
