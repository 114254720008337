import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-132e9de0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "error-modal__container" }
const _hoisted_2 = { class: "error-modal__title" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_projectCenterBlack = _resolveComponent("projectCenterBlack")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["error-modal__wrapper", { 'error-modal__wrapper--alpha': _ctx.useAlphaBackDrop }]),
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.useAlphaBackDrop ? _ctx.onBackdropClick() : undefined))
  }, [
    (_ctx.showLogo)
      ? (_openBlock(), _createBlock(_component_projectCenterBlack, {
          key: 0,
          class: "error-modal__logo"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["error-modal__icon-wrapper", _ctx.iconClass])
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 2),
      _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.title), 1),
      (_ctx.text)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: "error-modal__text",
            innerHTML: _ctx.text
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true),
      (_ctx.actionLabel)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            type: "button",
            class: "error-modal__btn btn btn-primary btn-round",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (!!_ctx.onActionClick ? _ctx.onClick() : _ctx.goToHomePage()))
          }, _toDisplayString(_ctx.actionLabel), 1))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "moreContent", {}, undefined, true)
    ])
  ], 2))
}