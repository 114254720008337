import { defineComponent } from 'vue';
import clock from '@/shared/icons/clock/clock.vue';

export default defineComponent({
  name: 'h-timepicker',
  compatConfig: {
    MODE: 2,
    COMPONENT_V_MODEL: false,
  },
  components: {
    clock,
  },
  props: {
    modelValue: {
      type: Date,
    },
    default: {
      type: String,
      default: '12:00',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      time: this.default as string | null,
    };
  },
  mounted() {
    this.onChange();
  },
  computed: {
    hours(): number {
      return Number((this.time || this.default).split(':')[0]);
    },
    minutes(): number {
      return Number((this.time || this.default).split(':')[1]);
    },
  },
  methods: {
    onChange(emitUpdate = true) {
      if (this.modelValue == null) {
        this.time = null;
        return;
      }
      if (this.time == null) {
        this.time = this.hours.toString().padStart(2, '0') + ':' + this.minutes.toString().padStart(2, '0');
      }
      const newDate = new Date(this.modelValue);
      newDate.setHours(this.hours);
      newDate.setMinutes(this.minutes);
      newDate.setSeconds(0);
      newDate.setMilliseconds(0);
      if (emitUpdate) {
        this.$emit('update:modelValue', newDate);
      }
    },
  },
  watch: {
    modelValue() {
      this.onChange(false);
    },
    time() {
      if (this.time == null) {
        return;
      }
      this.onChange();
    },
  },
});
