import HttpService from '@/endpoints/HttpService';
import {
  ResourceCenterFileUploadRequest,
  ResourceCenterFileDeleteRequest,
  ResourceCenterChangeDisplayOrderRequest,
} from '@/models/resourceCenter/resourceCenterFileRequests';
import ResourceCenterObject from '@/models/resourceCenter/resourceCenterObject';

const resourceCenterURL = `${process.env.VUE_APP_API_PREFIX}resourceCenter`;

export default class ResourceCenterService {
  public static async getAllFiles(): Promise<ResourceCenterObject[]> {
    const res = await HttpService.get(resourceCenterURL);
    return res.data;
  }

  public static async uploadFile(fileUploadRequest: ResourceCenterFileUploadRequest): Promise<ResourceCenterObject[]> {
    const res = await HttpService.postWithLoading(
      `${resourceCenterURL}/File/${fileUploadRequest.displayOrder}`,
      fileUploadRequest.formData
    );
    return res.data;
  }

  public static async deleteFile(fileDeleteRequest: ResourceCenterFileDeleteRequest): Promise<ResourceCenterObject[]> {
    const res = await HttpService.deleteWithLoading(`${resourceCenterURL}/File/${fileDeleteRequest.keyName}`);
    return res.data;
  }

  public static async changeDisplayOrder(
    changeDisplayOrder: ResourceCenterChangeDisplayOrderRequest
  ): Promise<ResourceCenterObject[]> {
    const res = await HttpService.post(
      `${resourceCenterURL}/File/${changeDisplayOrder.keyName}/${changeDisplayOrder.displayOrder}`
    );
    return res.data;
  }
}
