import iconDots from '@/shared/icons/dots/dots.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'h-options',
  components: {
    iconDots,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  watch: {
    isOpen() {
      if (this.isOpen) {
        this.$emit('open');
      }
    },
  },
});
