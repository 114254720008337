import { HTableColumn } from '@/shared/viewParts/ui/h-table/h-table';

/**
 * Computes the template column percentage values to add to the
 * `grid-template-columns` property for a grid.
 * @param columns - The columns to use.
 * @param addOns - The fixed width columns to add to the end of the grid.
 * @returns {string} - The template columns.
 */
function computePercentageTemplateColumns(columns: HTableColumn[], addOns: number[] = []): string {
  const widths = columns.map(column => column.width ?? 1);
  const total = widths.reduce((a, b) => a + b, 0);
  const percentages = widths.map(width => (width / total) * 100);

  const totalToDiscount = addOns.reduce((a, b) => a + b, 0);

  const count = percentages.length;
  const toDiscount = totalToDiscount / count;

  const percentageValues =
    toDiscount > 0
      ? percentages.map(percentage => `calc(${percentage}% - ${toDiscount}rem)`)
      : percentages.map(percentage => `${percentage}%`);

  const addOnValues = addOns.map(addOn => `${addOn}rem`);
  return percentageValues.concat(addOnValues).join(' ');
}

const GridHelper = {
  computePercentageTemplateColumns,
};

export default GridHelper;
