import ShippingAddress from '../addresses/shippingAddress';
import HadrianBase from '../hadrianBase';

export enum ShippingMethodTypes {
  PrePayAndAddFreight = 'Pre-Pay and Add Freight',
  FreightCollect = 'Freight Collect',
  ThirdPartyBilling = '3rd Party Billing',
  Others = 'Others',
  PickUp = 'Pick up',
  FedEx = 'FedEx Small Package',
  DryerPrePay = 'World Dryer Shipping',
  DryerFreeShipping = 'Free Shipping',
  HadrianDryerPrePay = 'Hadrian Shipping',
  HadrianDryerFreeShipping = 'Free Shipping',
}

export default class FreightInformationOptions extends HadrianBase {
  readonly freightInformations: FreightInformation[];
  readonly liftGateTruckIsSelected: boolean;
  readonly reDeliveryCoverageIsSelected: boolean;
  readonly limitedAccessIsSelected: boolean;
  readonly residentialAreaDeliveryIsSelected: boolean;
  readonly scheduledAppointmentsIsSelected: boolean;
}

export class FreightInformation extends HadrianBase {
  readonly nmfcClass: number;
  readonly cubes: number;
  readonly density: number;
  readonly weight: number;
  readonly isLargeShipment: boolean;
  readonly palletQuantity: number;
  readonly pickUpDate: Date;
  readonly destinationAddress: ShippingAddress;
  readonly originAddress: ShippingAddress;
  readonly materialType: string;
  readonly allowLiftgateOption: boolean;
  readonly maxPartCount: number;
}
