import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "order-status__label" }
const _hoisted_2 = {
  key: 0,
  class: "order-status__text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_hadrianTooltip = _resolveComponent("hadrianTooltip")!

  return (_openBlock(), _createBlock(_component_hadrianTooltip, {
    tooltipText: _ctx.computedStatus.statusText,
    class: "order-status--fit-content",
    anchor: "right",
    verticalPosition: "below",
    enabled: _ctx.tooltipIsEnabled && !!_ctx.computedStatus.statusText
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass({ 'order-status__wrapper': !_ctx.tooltipEnabled })
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["order-status__label-wrapper", _ctx.computedStatus.statusColor])
        }, [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.computedStatus.statusLabel), 1)
        ], 2),
        (!_ctx.tooltipEnabled)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.computedStatus.statusText), 1))
          : _createCommentVNode("", true)
      ], 2)
    ], undefined, true),
    _: 1
  }, 8, ["tooltipText", "enabled"]))
}