import debounce from 'lodash/debounce';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'switchCheckbox',
  props: {
    defaultValue: {
      type: Boolean,
    },
  },
  data() {
    return {
      value: false,
    };
  },
  created() {
    this.value = this.defaultValue;
  },
  methods: {
    emitValue() {
      this.value = !this.value;
      this.emitDebounce();
    },
    emitDebounce: debounce(async function (this: any) {
      this.$emit('change', this.value);
    }, 300),
  },
  watch: {
    defaultValue() {
      this.value = this.defaultValue;
    },
  },
});
