import HttpService from '@/endpoints/HttpService';
const feedbackUrl = `${process.env.VUE_APP_API_PREFIX}feedback`;

export default class FeedbackService {
  public static async SendFeedback(subject: string, message: string): Promise<void> {
    await HttpService.post(feedbackUrl, {
      subject,
      message,
    });
    return;
  }
}
