import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_clock = _resolveComponent("clock")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["h-timepicker", { 'h-timepicker--disabled': _ctx.disabled }])
  }, [
    _createVNode(_component_clock, { class: "h-timepicker__clock-icon" }),
    _withDirectives(_createElementVNode("input", {
      type: "time",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.time) = $event)),
      class: "h-timepicker__input",
      disabled: _ctx.disabled
    }, null, 8, _hoisted_1), [
      [_vModelText, _ctx.time]
    ])
  ], 2))
}