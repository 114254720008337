import HttpService from '@/endpoints/HttpService';
import PatchOperation from '@/models/patchOperation/patchOperation';
import Room from '@/models/room/room';
import {
  AddStyleToRoomRequest,
  DeleteRoomStyleRequest,
  LockerRoomCreationRequest,
  NewOrderReply,
  PartitionRoomCreationRequest,
  PartitionRoomUpdateRequest,
  RoomsResponse,
} from '@/models/room/roomApiInterfaces';
import RoomLayout from '@/models/room/roomLayout';

const baseUrl = `${process.env.VUE_APP_API_PREFIX}`;
const noAuthUrl = `${process.env.VUE_APP_API_PREFIX}WizardNoAuthv1`;

export enum RoomsPath {
  RoomName = '/RoomName',
  Finish = '/RoomSettings/Finish',
  Partition = '/RoomSettings/Partition',
  Material = '/RoomSettings/Material',
  HardwarePackage = '/RoomSettings/HardwarePackage',
  DoorHinge = '/RoomSettings/HardwarePackage/doorHinge',
  Gender = 'gender',
  Floor = 'floor',
  Notes = 'notes',
  DrawingDataUrl = '/DrawingDataUrl',
}

export default class RoomsService {
  public static async getRooms(projectId: string, params?: any): Promise<RoomsResponse> {
    const res = await HttpService.getWithLoading(`${baseUrl}projects/${projectId}/rooms`, { params });
    return res.data;
  }

  public static async getRoomsForQuotingOrOrdering(projectId: string): Promise<NewOrderReply> {
    return (await HttpService.postWithLoading(`${baseUrl}projects/${projectId}/quoteableRooms`)).data.entity;
  }

  public static async getRoom(roomId: string): Promise<Room> {
    const res = await HttpService.getWithLoading(`${baseUrl}rooms/${roomId}`);
    return res.data.entity;
  }

  public static async getRoomNoAuth(roomId: string): Promise<Room> {
    const res = await HttpService.getWithLoading(`${noAuthUrl}/${roomId}`);
    return res.data.entity;
  }

  public static async getRoomPrice(roomId: string): Promise<number> {
    const res = await HttpService.get(`${baseUrl}rooms/${roomId}/price`);
    return res.data;
  }

  public static async createRoom(room: PartitionRoomCreationRequest | LockerRoomCreationRequest): Promise<Room> {
    const res = await HttpService.post(`${baseUrl}rooms`, room);
    return res.data.entity;
  }

  public static async updateRoom(room: PartitionRoomUpdateRequest): Promise<Room> {
    const res = await HttpService.post(`${baseUrl}rooms`, room);
    return res.data.entity;
  }

  public static async addStyleToRoom(request: AddStyleToRoomRequest): Promise<Room> {
    const res = await HttpService.post(`${baseUrl}rooms/${request.roomId}/addStyle`, request.lockerRoomConfiguration);
    return res.data.entity;
  }

  public static async deleteRoomStyle(request: DeleteRoomStyleRequest): Promise<Room> {
    const res = await HttpService.deleteWithLoading(
      `${baseUrl}rooms/${request.roomId}/styles/${request.lockerRoomConfigurationId}`
    );
    return res.data.entity;
  }

  public static async patchRoom(operations: PatchOperation[], roomId: string): Promise<Room> {
    const res = await HttpService.patchWithLoading(`${baseUrl}rooms/${roomId}/withoutprice`, operations);
    return res.data.entity;
  }

  public static async getRoomLayouts(): Promise<RoomLayout[]> {
    const res = await HttpService.get(`${baseUrl}rooms/layouts`);
    return res.data.entities;
  }

  public static async deleteRoom(roomId: string) {
    const res = await HttpService.deleteWithLoading(`${baseUrl}rooms/${roomId}`);
    return res.data;
  }
}
