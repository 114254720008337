<template>
  <svg
    class="hadrian-icons profile"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 26.898 26.898"
    style="enable-background: new 0 0 26.898 26.898"
    xml:space="preserve"
    fill="currentColor"
  >
    <!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <path
      d="M26.898,13.449C26.898,6.033,20.865,0,13.449,0S0,6.033,0,13.449c0,4.507,2.236,8.493,5.648,10.935
    c0.008,0.006,0.01,0.017,0.019,0.023c0.033,0.023,0.067,0.043,0.1,0.065c0.162,0.113,0.329,0.219,0.496,0.325
    c0.17,0.108,0.34,0.213,0.514,0.313c0.162,0.093,0.325,0.184,0.491,0.271c0.21,0.109,0.423,0.21,0.638,0.308
    c0.138,0.063,0.274,0.13,0.415,0.188c0.356,0.148,0.717,0.281,1.084,0.398c0.087,0.028,0.177,0.048,0.265,0.074
    c0.301,0.089,0.605,0.168,0.912,0.235c0.129,0.028,0.26,0.052,0.39,0.076c0.286,0.054,0.574,0.097,0.864,0.132
    c0.124,0.015,0.248,0.032,0.373,0.043c0.41,0.038,0.823,0.063,1.239,0.063c0.417,0,0.83-0.025,1.24-0.063
    c0.124-0.011,0.245-0.028,0.368-0.043c0.294-0.035,0.585-0.08,0.875-0.134c0.126-0.024,0.252-0.047,0.377-0.074
    c0.32-0.07,0.636-0.152,0.95-0.245c0.075-0.022,0.152-0.039,0.226-0.063c0.374-0.118,0.743-0.254,1.106-0.405
    c0.121-0.05,0.238-0.109,0.358-0.162c0.237-0.107,0.473-0.218,0.704-0.34c0.149-0.078,0.295-0.16,0.441-0.243
    c0.197-0.113,0.39-0.231,0.582-0.354c0.146-0.094,0.293-0.186,0.435-0.285c0.041-0.028,0.083-0.052,0.123-0.081
    c0.011-0.008,0.014-0.021,0.024-0.029C24.666,21.936,26.898,17.952,26.898,13.449z M19.407,24.029
    c-0.439,0.248-0.89,0.469-1.353,0.66c-0.03,0.012-0.062,0.022-0.092,0.034c-0.41,0.165-0.83,0.303-1.254,0.422
    c-0.117,0.033-0.236,0.062-0.355,0.091c-0.357,0.088-0.719,0.158-1.084,0.214c-0.126,0.019-0.25,0.042-0.377,0.058
    c-0.478,0.057-0.959,0.094-1.443,0.094s-0.966-0.036-1.444-0.094c-0.124-0.015-0.246-0.038-0.369-0.057
    c-0.368-0.056-0.734-0.127-1.096-0.216c-0.115-0.028-0.229-0.057-0.342-0.088c-0.43-0.12-0.854-0.259-1.269-0.427
    c-0.026-0.011-0.054-0.019-0.081-0.03c-0.462-0.19-0.912-0.411-1.35-0.658c-0.014-0.008-0.028-0.018-0.042-0.026
    c2.491-1.112,3.348-2.329,3.348-4.644c0-0.839-0.267-1.225-0.503-1.566c-0.229-0.336-0.516-0.754-0.736-1.942
    c-0.132-0.732-0.586-0.948-0.829-1.064c-0.184-0.089-0.374-0.181-0.452-1.188c0-0.404,0.122-0.558,0.16-0.595
    c0.248-0.132,0.381-0.405,0.332-0.692c0-0.002-0.12-0.661-0.229-1.425c-0.336-2.056-0.314-4.094,2.102-5.072
    c2.554-1.033,4.284-0.249,4.416-0.044c0.102,0.175,0.349,0.292,0.495,0.329c0.037,0.009,0.073,0.013,0.173,0.019
    c0.038-0.006,1.201-0.036,1.902,0.776c0.925,1.077,0.729,4.114,0.49,5.416c-0.05,0.272,0.088,0.558,0.314,0.682
    c0.019,0.013,0.178,0.141,0.179,0.561c-0.079,1.057-0.269,1.147-0.453,1.235c-0.243,0.117-0.696,0.336-0.833,1.062
    c-0.221,1.197-0.505,1.615-0.732,1.949c-0.249,0.358-0.506,0.729-0.506,1.564c0,2.307,0.857,3.524,3.349,4.64
    C19.431,24.015,19.419,24.022,19.407,24.029z M17.395,19.367c0-0.428,0.079-0.544,0.271-0.83c0.269-0.387,0.674-0.972,0.942-2.447
    c0.01-0.051,0.017-0.073,0.014-0.074c0.018-0.011,0.062-0.032,0.104-0.052c0.533-0.257,1.065-0.648,1.188-2.358
    c0-0.714-0.23-1.16-0.454-1.425c0.168-1.183,0.506-4.565-0.841-6.129c-0.867-1.007-2.097-1.201-2.677-1.234
    c-0.776-0.712-2.938-1.354-5.782-0.205c-3.328,1.347-3.249,4.343-2.9,6.474c0.061,0.43,0.127,0.831,0.175,1.105
    c-0.224,0.263-0.452,0.712-0.451,1.461c0.128,1.664,0.657,2.054,1.192,2.313c0.043,0.02,0.093,0.041,0.091,0.038
    c0.002,0.004,0.009,0.022,0.021,0.084c0.27,1.479,0.675,2.062,0.945,2.452c0.19,0.282,0.27,0.398,0.27,0.827
    c0,1.662-0.347,2.602-3.358,3.77c-2.936-2.22-4.845-5.731-4.845-9.688c0-6.699,5.45-12.148,12.148-12.148
    c6.701,0,12.152,5.45,12.152,12.148c0,3.958-1.91,7.469-4.848,9.689C17.742,21.97,17.395,21.03,17.395,19.367z"
    />
  </svg>
</template>
