export default class PaginationFormatter {
  public static getRangeWithEllipsis(
    pageNumbers: number[],
    value: number,
    maxDistanceWithEdge: number,
    displayLimit: number
  ) {
    const firstPage = 1;
    const lastPage = pageNumbers[pageNumbers.length - 1];

    const pages: any[] = [];
    let addLeftEllipsis = false;
    let addRightEllipsis = false;

    pages.push(firstPage.toString());

    if (lastPage - value > maxDistanceWithEdge && value - 1 > maxDistanceWithEdge) {
      addLeftEllipsis = true;
      addRightEllipsis = true;
      pages.push(
        ...pageNumbers
          .filter(
            x =>
              x > value - Math.ceil((displayLimit - 4) / 2) &&
              x < value + Math.ceil((displayLimit - 4) / 2) &&
              x > firstPage &&
              x < lastPage
          )
          .map(x => x.toString())
      );
    } else if (lastPage - value > maxDistanceWithEdge) {
      addRightEllipsis = true;
      pages.push(...pageNumbers.filter(x => x > firstPage && x <= displayLimit - 2).map(x => x.toString()));
    } else if (value - 1 > maxDistanceWithEdge) {
      addLeftEllipsis = true;
      pages.push(...pageNumbers.filter(x => x < lastPage && x > lastPage - displayLimit + 2).map(x => x.toString()));
    }

    if (addLeftEllipsis) {
      pages.splice(1, 0, '...');
    }

    if (addRightEllipsis) {
      pages.push('...');
    }

    pages.push(lastPage.toString());

    return pages;
  }

  public static getRangeWithoutEllipsis(pageNumbers: number[]) {
    return pageNumbers.map(numb => {
      return numb.toString();
    });
  }

  public static getPagesRange(
    pageNumber: number,
    displayLimit: number,
    value: number,
    maxDistanceWithEdge: number
  ): string[] {
    const pageNumbers = Array.from({ length: pageNumber }, (_, i) => i + 1);
    if (pageNumber < displayLimit + 1) {
      return this.getRangeWithoutEllipsis(pageNumbers);
    } else {
      return this.getRangeWithEllipsis(pageNumbers, value, maxDistanceWithEdge, displayLimit);
    }
  }
}
