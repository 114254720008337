import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "pagination__numbers" }
const _hoisted_2 = ["onClick", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chevronLeft = _resolveComponent("chevronLeft")!
  const _component_chevronRight = _resolveComponent("chevronRight")!

  return (_ctx.pageNumber > 1)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(`pagination pagination--align-${_ctx.align}`)
      }, [
        _createElementVNode("div", {
          class: _normalizeClass({ pagination__wrapper: true, 'pagination__wrapper--border': _ctx.withBorder })
        }, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setToPreviousPage && _ctx.setToPreviousPage(...args))),
            type: "button"
          }, [
            _createVNode(_component_chevronLeft, {
              class: _normalizeClass({ inactive: !_ctx.canGoToPreviousPage, pagination__button: true })
            }, null, 8, ["class"])
          ]),
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getPagesRange(), (pageNumber, index) => {
              return (_openBlock(), _createElementBlock("button", {
                class: _normalizeClass({
            'pagination__number--active': parseInt(pageNumber) === _ctx.modelValue,
            pagination__number: true,
            large: pageNumber.length > 1,
            dots: _ctx.isEllipsis(pageNumber),
          }),
                onClick: ($event: any) => (_ctx.setPage(parseInt(pageNumber))),
                key: index,
                disabled: _ctx.isEllipsis(pageNumber),
                type: "button"
              }, _toDisplayString(pageNumber), 11, _hoisted_2))
            }), 128))
          ]),
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.setToNextPage && _ctx.setToNextPage(...args))),
            type: "button"
          }, [
            _createVNode(_component_chevronRight, {
              class: _normalizeClass({ inactive: !_ctx.canGoToNextPage(), pagination__button: true })
            }, null, 8, ["class"])
          ])
        ], 2)
      ], 2))
    : _createCommentVNode("", true)
}