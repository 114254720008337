<script src="./orderStatusSelector.ts"></script>

<template>
  <filteredSelector
    :nullable="true"
    :placeHolder="$t('labels.allOrderStatus').toString()"
    :suggestionList="allTypes"
    @select="select"
    :searchMode="SearchMode.Includes"
    :disabled="false"
    :defaultSelectedName="getOrderStatusName(internalValue)"
  />
</template>
