import HttpService from '@/endpoints/HttpService';
import HttpResponse from '@/interfaces/HttpResponse';
import {
  RoomCustomLengthPartRemovalRequest,
  RoomPartsDeleteBomEditRequest,
  RoomPartsEditRequest,
  RoomPartsResponse,
  RoomSuggestedPartsEditRequest,
  SingleRoomPartsResponse,
} from '@/models/room/roomApiInterfaces';

export default class RoomPartsService {
  public static async postRoomPartsEdit(request: RoomPartsEditRequest): Promise<HttpResponse<RoomPartsResponse>> {
    const { roomId, ...rest } = request;
    const res = await HttpService.post(`${process.env.VUE_APP_API_PREFIX}parts/bom/edit/${roomId}`, rest);
    return res;
  }

  public static async removeCustomLengthPart(
    request: RoomCustomLengthPartRemovalRequest
  ): Promise<HttpResponse<RoomPartsResponse>> {
    return await HttpService.delete(
      `${process.env.VUE_APP_API_PREFIX}parts/bom/edit/${request.roomId}/customLengthPart/${request.partId}`
    );
  }

  public static async postRoomSuggestedPartsEdit(
    request: RoomSuggestedPartsEditRequest
  ): Promise<HttpResponse<RoomPartsResponse>> {
    const { roomId, parts } = request;
    return await HttpService.post(`${process.env.VUE_APP_API_PREFIX}parts/bom/edit/${roomId}/suggestedParts`, parts);
  }

  public static async deleteRoomPartsBomEdit(
    request: RoomPartsDeleteBomEditRequest
  ): Promise<HttpResponse<RoomPartsResponse>> {
    const { roomId, roomPartId } = request;
    const res = await HttpService.delete(`${process.env.VUE_APP_API_PREFIX}parts/bom/edit/${roomId}/${roomPartId}`);
    return res;
  }

  public static async deleteRoomPartsEdit(roomId: string): Promise<HttpResponse<SingleRoomPartsResponse>> {
    const res = await HttpService.delete(`${process.env.VUE_APP_API_PREFIX}parts/bom/edit/${roomId}`);
    return res;
  }

  public static async saveImportantProductionNote(
    partsNotes: string,
    projectId: string
  ): Promise<HttpResponse<RoomPartsResponse>> {
    return await HttpService.post(`api/Projects/${projectId}/partsNotes`, {
      partsNotes,
    });
  }
}
