import MaterialTypes from '@/enums/materialTypes';
import rdUnitType from '@/enums/rdUnitType';
import Room from '@/models/room/room';
import RdRoomData from '@/models/roomDesigner/rdRoomData';
import RdRoomUnit, {
  SmallUnitMinimumDistance,
  StandardUnitMinimumDistance,
  StandardUnitMinimumWidth,
} from '@/models/roomDesigner/rdRoomUnit';
import { MIN_OFFSET_PHENOLIC_DOOR, MIN_OFFSET_PHENOLIC_EDGE } from '../drawing/DrawingConstants';
import { RoomSettingsHelper } from './roomSettingsHelper';
import RdDragRoomUnit from '@/models/roomDesigner/rdDragRoomUnit';

export class RoomAnalysisHelper {
  public static getMinimumPanelPilasterOffset(room: Room, isEdge: boolean): number {
    return room.roomSettings.material.label === MaterialTypes.Phenolic
      ? isEdge
        ? MIN_OFFSET_PHENOLIC_EDGE
        : MIN_OFFSET_PHENOLIC_DOOR
      : 1;
  }

  public static getUnitBaseWidthRange(
    unit: RdRoomUnit,
    prev: RdRoomUnit | undefined,
    room: Room,
    breakRange = false
  ): number[] {
    const width = unit.baseWidth;
    let widthWithoutPilaster = 0;
    let panelPilasterWidth = 0;

    if (unit.isNextToRightWall || !!unit.panel?.leftSidePilaster || !!unit.rightWallPilaster) {
      if (breakRange) {
        return [];
      }
      return RoomAnalysisHelper.getUnitBaseWidthRangeFromRight(unit, prev, room, true);
    }

    if (unit.panel) {
      const panelPilaster = unit.panel.panelPilaster;
      panelPilasterWidth = panelPilaster.width;
      widthWithoutPilaster = width - (unit.panel.positionX - panelPilaster.positionX);
    }

    const hasFollowing = room.roomUnits.some(u => u.unitIndex > unit.index);
    const followingUnit = RdDragRoomUnit.followingUnit(unit);
    const isEdge = !hasFollowing || (!!followingUnit && followingUnit.depth < unit.depth);
    const minOffset = RoomAnalysisHelper.getMinimumPanelPilasterOffset(room, isEdge);
    const max = panelPilasterWidth - minOffset;
    const minOffsetInside = RoomSettingsHelper.isRoomPhenolic(room.roomSettings) ? MIN_OFFSET_PHENOLIC_DOOR : minOffset;
    return [widthWithoutPilaster + minOffsetInside, widthWithoutPilaster + max];
  }

  public static getUnitBaseWidthRangeFromRight(
    unit: RdRoomUnit,
    prev: RdRoomUnit | undefined,
    room: Room,
    breakRange = false
  ): number[] {
    const width = unit.baseWidth;

    let widthWithoutPilaster = 0;
    let panelPilasterWidth = 0;

    if (!prev) {
      if (!unit.leftPanel) {
        if (breakRange) {
          return [];
        }
        return RoomAnalysisHelper.getUnitBaseWidthRange(unit, prev, room, true);
      }

      panelPilasterWidth = unit.leftPanel.panelPilaster.width;
      widthWithoutPilaster = width - unit.leftPanel.panelPilaster.offset;
    } else {
      if (
        !!prev.extendedDepthExtensions ||
        (!!prev.alcoveParts && !prev.alcoveParts?.splitPilaster) ||
        !!prev.panel?.rightSidePilaster
      ) {
        if (breakRange) {
          return [];
        }
        return RoomAnalysisHelper.getUnitBaseWidthRange(unit, prev, room, true);
      } else if (prev.panel) {
        panelPilasterWidth = prev.panel.panelPilaster.width;
        widthWithoutPilaster = width - prev.panel.panelPilaster.offset;
      }
    }

    const hasPreceding = room.roomUnits.some(u => u.unitIndex < unit.index);
    const precedingUnit = RdDragRoomUnit.precedingUnit(unit);
    const isEdge = !hasPreceding || (!!precedingUnit && precedingUnit.depth < unit.depth);
    const minOffset = RoomAnalysisHelper.getMinimumPanelPilasterOffset(room, isEdge);
    const max = panelPilasterWidth - minOffset;
    const minOffsetInside = RoomSettingsHelper.isRoomPhenolic(room.roomSettings) ? MIN_OFFSET_PHENOLIC_DOOR : minOffset;
    return [widthWithoutPilaster + minOffsetInside, widthWithoutPilaster + max];
  }

  public static getFirstUnit(room: RdRoomData) {
    return room.units[0];
  }

  public static getLastUnit(room: RdRoomData) {
    return room.units[room.units.length - 1];
  }

  public static getNextUnit(unit: RdRoomUnit, room: RdRoomData): RdRoomUnit | undefined {
    return room.units[unit.index + 1];
  }

  public static getPreviousUnit(unit: RdRoomUnit, room: RdRoomData): RdRoomUnit | undefined {
    return room.units[unit.index - 1];
  }

  public static getIsAda(unit: RdRoomUnit) {
    return unit.unitType === rdUnitType.Ada;
  }

  public static getIsAmb(unit: RdRoomUnit) {
    return unit.unitType === rdUnitType.Ambulatory;
  }

  public static minimumBowlCenterDistance(unit: RdRoomUnit): number {
    if (!unit) {
      return 0;
    }
    return unit.width < StandardUnitMinimumWidth ? SmallUnitMinimumDistance : StandardUnitMinimumDistance;
  }

  public static minimumBowlCenterValue(unit: RdRoomUnit, withinUnit: boolean, roomData: RdRoomData): number {
    if (!unit) {
      return 0;
    }

    if (withinUnit) {
      return RoomAnalysisHelper.minimumBowlCenterDistance(unit);
    }
    const previousUnit = RoomAnalysisHelper.getPreviousUnit(unit, roomData);
    if (previousUnit && previousUnit.panel) {
      return previousUnit.panel.positionX + RoomAnalysisHelper.minimumBowlCenterDistance(unit);
    }
    if (unit.leftPanel) {
      return unit.leftPanel.positionX + RoomAnalysisHelper.minimumBowlCenterDistance(unit);
    }
    return RoomAnalysisHelper.minimumBowlCenterDistance(unit);
  }

  public static maximumBowlCenterValue(unit: RdRoomUnit, withinUnit: boolean, roomData: RdRoomData): number {
    if (!unit) {
      return 0;
    }

    if (withinUnit) {
      return unit.width - RoomAnalysisHelper.minimumBowlCenterValue(unit, withinUnit, roomData);
    }
    if (unit.isNextToRightWall) {
      const previousUnit = RoomAnalysisHelper.getPreviousUnit(unit, roomData);
      if (previousUnit && previousUnit.panel) {
        return previousUnit.panel.positionX + unit.width - RoomAnalysisHelper.minimumBowlCenterDistance(unit);
      } else if (unit.isNextToLeftWall) {
        return unit.width - RoomAnalysisHelper.minimumBowlCenterDistance(unit);
      }
    }

    if (unit.leftPanel) {
      return unit.leftPanel.positionX + unit.width - RoomAnalysisHelper.minimumBowlCenterDistance(unit);
    }

    const nextUnit = RoomAnalysisHelper.getNextUnit(unit, roomData);
    if (nextUnit && nextUnit.leftPanel) {
      return nextUnit.leftPanel.positionX - RoomAnalysisHelper.minimumBowlCenterDistance(unit);
    }

    if (!unit.panel) {
      return 0;
    }

    return unit.panel.positionX - RoomAnalysisHelper.minimumBowlCenterDistance(unit);
  }
}
