import iconContact from '@/shared/icons/contact/contact.vue';
import iconMail from '@/shared/icons/mail/mail.vue';
import iconWebsite from '@/shared/icons/website/website.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'helpBox',
  components: {
    iconMail,
    iconContact,
    iconWebsite,
  },
});
