<script src="./productTypeSelector.ts"></script>

<template>
  <filteredSelector
    :nullable="nullable"
    :placeHolder="$t('projects.allTypes')"
    :suggestionList="allTypes"
    @select="select"
    :searchMode="SearchMode.Includes"
    :disabled="isDisabled"
    :defaultSelectedName="getProductTypeName(internalValue)"
  />
</template>
