import HttpService from '@/endpoints/HttpService';
import HttpResponse from '@/interfaces/HttpResponse';
import RdRoomDrawingData from '@/models/roomDesigner/rdDirtyRoomData';
import RdDrawingNote, { RdDrawingNoteDeleteRequest, RdDrawingNoteRequest } from '@/models/roomDesigner/rdDrawingNote';
import RdPatchOperations from '@/models/roomDesigner/rdPatchOperations';
import RdPatchResponseData from '@/models/roomDesigner/rdPatchResponseData';
import RdRoomData from '@/models/roomDesigner/rdRoomData';
import urlBuilder from '@/helpers/urlBuilder';
import RdRoomNote from '@/models/roomDesigner/rdRoomNote';

const baseUrl = `${process.env.VUE_APP_ROOM_DESIGNER_API_PREFIX}`;
const noAuthUrl = `${process.env.VUE_APP_API_PREFIX}WizardNoAuthv1`;

enum Suffixes {
  Rooms = 'Rooms',
  RoomDrawingNotes = 'RoomDrawingNotes',
}

export class RoomDesignerService {
  public static async getRoom(id: string): Promise<RdRoomData> {
    const roomResponse = await HttpService.get(`${baseUrl}${Suffixes.Rooms}/${id}`);

    const data = roomResponse.data.entity;
    data.notes = (data as RdRoomData).notes.map(n => {
      return { ...n, roomId: id };
    });
    // The id is not in the data we receive from the backend, adding it
    data.id = id;
    return data;
  }

  public static async getRoomNoAuth(id: string): Promise<RdRoomData> {
    const roomResponse = await HttpService.get(`${noAuthUrl}/designer/${id}`);

    const data = roomResponse.data.entity;
    data.notes = (data as RdRoomData).notes.map(n => {
      return { ...n, roomId: id };
    });
    // The id is not in the data we receive from the backend, adding it
    data.id = id;
    return data;
  }

  public static async getRoomDrawingData(projectId: string): Promise<RdRoomDrawingData[]> {
    const res = await HttpService.post(`${baseUrl}${Suffixes.Rooms}/${projectId}/roomDrawingData`);
    return res.data.entities;
  }

  public static async patchUnit(operations: RdPatchOperations): Promise<HttpResponse<RdPatchResponseData>> {
    const path = `${baseUrl}rooms/${operations.roomId}/unit/${operations.unitIndex}`;
    const response = await HttpService.patch(path, operations.operations);
    return response;
  }

  public static async patchAllUnits(operations: RdPatchOperations): Promise<HttpResponse<RdPatchResponseData>> {
    const response = await HttpService.patch(`${baseUrl}rooms/${operations.roomId}/units`, operations.operations);
    return response;
  }

  public static async postNote(note: Partial<RdRoomNote>): Promise<HttpResponse<any>> {
    const url = urlBuilder(`${baseUrl}roomNotes/${note.roomId}`, {
      PositionX: note.positionX,
      PositionY: note.positionY,
      Note: note.note,
    });

    const response = await HttpService.post(url);
    response.data.entities = response.data.entities.map((n: RdRoomNote) => {
      return { ...n, roomId: note.roomId };
    });
    return response;
  }

  public static async putNote(note: RdRoomNote): Promise<HttpResponse<any>> {
    const url = urlBuilder(`${baseUrl}roomNotes/${note.roomId}`, {
      PositionX: note.positionX,
      PositionY: note.positionY,
      Note: note.note,
      Number: note.number,
    });

    const response = await HttpService.put(url);
    response.data.entities = response.data.entities.map((n: RdRoomNote) => {
      return { ...n, roomId: note.roomId };
    });
    return response;
  }

  public static async deleteNote(note: RdRoomNote): Promise<HttpResponse<any>> {
    const response = await HttpService.delete(`${baseUrl}roomNotes/${note.roomId}?noteNumber=${note.number}`);
    response.data.entities = response.data.entities.map((n: RdRoomNote) => {
      return { ...n, roomId: note.roomId };
    });
    return response;
  }

  public static async postDrawingNotes(request: RdDrawingNoteRequest): Promise<RdDrawingNote[]> {
    const { roomId, ...rest } = request;
    if (!roomId) {
      return Promise.reject('invalid room id');
    }
    const response = await HttpService.postWithLoading(`${baseUrl}${Suffixes.RoomDrawingNotes}/${roomId}`, rest);
    return response.data.entities;
  }

  public static async putDrawingNotes(request: RdDrawingNoteRequest): Promise<RdDrawingNote> {
    const response = await HttpService.putWithLoading(
      `${baseUrl}${Suffixes.RoomDrawingNotes}?roomId=${request.roomId}`,
      request
    );
    return response.data;
  }

  public static async deleteDrawingNotes(request: RdDrawingNoteDeleteRequest) {
    await HttpService.deleteWithLoading(
      `${baseUrl}${Suffixes.RoomDrawingNotes}/${request.roomId}?noteId=${request.id}`
    );
  }
}
