import { formatDate, DateFormat } from '@/helpers/dateHelper';
import hadrianModal from '@/shared/viewParts/hadrianModal/hadrianModal.vue';
import { vxManager } from '@/store/store';
import { Component } from 'vue';

let prevDate = new Date();

export interface HadrianUpdatesIcon {
  icon: Component;
}

export enum HadrianUpdateType {
  drawingUpdates,
}

export interface HadrianUpdatesData {
  updateDate: Date;
  updateType: HadrianUpdateType;
  title: string;
  description: string;
  imageUrl?: string | undefined;
  expanded?: boolean;
  needsReadMore?: boolean;
}

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'hadrianUpdates',
  components: {
    hadrianModal,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    variant: {
      type: String as () => 'default' | 'thin' | 'wide' | 'wide-2',
      default: 'default',
    },
    hideOnRightClick: {
      type: Boolean,
      default: true,
    },
    blockKeyboardConfirmation: {
      type: Boolean,
      default: true,
    },
    onConfirm: {
      type: Function as unknown as () => (checked: boolean) => void,
      required: false,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    updateType: {
      type: Number as () => HadrianUpdateType,
      required: true,
    },
  },
  data() {
    return {
      checkboxValue: false,
      previousDate: new Date(),
      isShown: false,
      updates: [] as HadrianUpdatesData[],
      showRetry: 0,
    };
  },
  methods: {
    async show() {
      this.showRetry = 0;
      this.previousDate = new Date();
      prevDate = new Date();

      await vxManager.updatesModule.fetchUpdates(this.updateType);
      const updateIndex = vxManager.updatesModule.getUserUpdates.findIndex(x => x.Type === this.updateType);

      if (updateIndex >= 0) {
        const updates = vxManager.updatesModule.getUserUpdates[updateIndex].Data;
        if (updates.length === 1) {
          updates[0].expanded = true;
        }
        this.updates = updates;
      }

      if (!!this.updates && this.updates.length > 0) {
        this.modal.show();
        this.updateUpdatesReadMore();
      } else {
        this.hide();
      }
    },
    hide() {
      this.modal.hide();
    },
    updateUpdatesReadMore() {
      this.$nextTick(() => {
        this.updates = this.updates.map(update => {
          update.needsReadMore = this.needsUpdateReadMore(this.getUpdateKey(update));
          return update;
        });
      });
    },
    rightButtonClick(event: KeyboardEvent) {
      if (event && event.type === 'keyup' && this.blockKeyboardConfirmation) {
        return;
      }

      if (this.hideOnRightClick) {
        this.hide();
        vxManager.updatesModule.saveUserDismiss(this.updateType);
      }
    },
    handleShown() {
      this.handleShowReady();
      this.$emit('handleShown');
    },
    canShowDate(time: Date): boolean {
      const prev = prevDate;
      return prev.getTime() !== new Date(time.toDateString()).getTime();
    },
    getUpdateTimeFormatted(time: Date): string {
      const prev = prevDate;
      prevDate = new Date(time.toDateString());
      return prev.getTime() !== prevDate.getTime() ? formatDate(time, DateFormat.Short) : '';
    },
    setPreviousDate(date: Date) {
      this.previousDate = date;
    },
    needsUpdateReadMore(key: string): boolean {
      const descriptionDiv = this.$refs[key] as any;
      return !!descriptionDiv && descriptionDiv[0].clientHeight !== descriptionDiv[0].scrollHeight;
    },
    async handleShowReady() {
      const descriptionDiv = this.$refs[this.getUpdateKey(this.updates[0])] as any;
      if (descriptionDiv) {
        this.isShown = true;
      } else if (!!this.updates && this.updates.length > 0 && this.showRetry < 1000) {
        this.$nextTick(() => {
          ++this.showRetry;
          this.handleShowReady();
        });
      }
    },
    expandUpdate(update: HadrianUpdatesData, event: PointerEvent) {
      if (!update.expanded) {
        update.expanded = true;
        const descriptionDiv = this.$refs[this.getUpdateKey(update)] as any;
        descriptionDiv[0].classList.add('hadrian-updates__update-description-expanded');
        (event.target as any).hidden = true;
      }
    },
    getUpdateKey(update: HadrianUpdatesData) {
      return `hadrian-updates-description-${update.updateDate}-${update.title}`;
    },
  },
  computed: {
    modal(): any {
      return this.$refs.updates;
    },
    hasHeaderSlot() {
      return !!this.$slots['modal-header'];
    },
    sizeClass(): any {
      return {
        'updates-thin': this.variant === 'thin',
        'updates-wide': this.variant === 'wide',
        'updates-wide-2': this.variant === 'wide-2',
        'updates-default': this.variant === 'default',
      };
    },
    rightButtonClasses() {
      return 'btn btn-round btn-primary hadrian-updates__right-button';
    },
    shownMax(): boolean {
      return this.isShown;
    },
  },
});
