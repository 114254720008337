enum SpinnerColor {
  Blue = 'blue',
  Current = 'current',
}

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'h-spinner',
  props: {
    color: {
      type: String as () => SpinnerColor,
      default: SpinnerColor.Blue,
    },
  },
});
