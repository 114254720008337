import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["section-header", {
      'section-header--top-margin': !_ctx.noTopMargin,
      'section-header--bottom-margin': !_ctx.noBottomMargin,
      'section-header--no-left-margin': !_ctx.text,
    }])
  }, _toDisplayString(_ctx.text), 3))
}