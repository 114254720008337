// Gets the max value from an integer enum
export function getMaxEnumValue(enumeration: object): number {
  return Math.max(...getEnumValues(enumeration));
}

// Gets all integer values from an enum
export function getEnumValues(enumeration: object): number[] {
  return Object.values(enumeration).filter(Number.isInteger);
}

// Gets all integer values from an enum
export function getStringValues(enumeration: object): string[] {
  return Object.values(enumeration).filter(value => !Number.isInteger(value));
}

const EnumHelper = {
  getMaxValue: getMaxEnumValue,
  getNumberValues: getEnumValues,
  getStringValues,
};

export default EnumHelper;
