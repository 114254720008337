import { LockerBankQuantities } from '@/models/room/roomApiInterfaces';
import { sum } from 'lodash';

interface ResetBankQuantitiesProps {
  defaultBankSize?: number;
  totalNumberOfLockers: number;
}

export const BanksCalculator = {
  defaultBankQuantities: (params: ResetBankQuantitiesProps): LockerBankQuantities | null => {
    if (params.defaultBankSize == null) {
      return null;
    }

    const { defaultBankSize, totalNumberOfLockers } = params;

    const bankQuantites = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 } as Record<number, number>;
    let numAssignedLockers = 0;
    let currentBankSize = defaultBankSize;

    while (numAssignedLockers < totalNumberOfLockers) {
      const numBanksToAdd = Math.floor((totalNumberOfLockers - numAssignedLockers) / currentBankSize);

      bankQuantites[currentBankSize] += numBanksToAdd;

      numAssignedLockers += numBanksToAdd * currentBankSize;
      currentBankSize -= 1;
    }

    return bankQuantites as LockerBankQuantities;
  },

  total: (banks: LockerBankQuantities): number => {
    return sum(Object.entries(banks).map(([size, quantity]) => parseInt(size, 10) * quantity));
  },

  minimumExtraSides: (banks: LockerBankQuantities): number => {
    return sum(Object.values(banks));
  },

  simplifiedQuantities: (banks: LockerBankQuantities): Record<number, number> => {
    const nonZeroEntries = Object.entries(banks).filter(([_, quantity]) => quantity !== 0);
    return Object.fromEntries(nonZeroEntries);
  },
};
