import i18n from '@/i18n';
import CreationViewModel from '@/models/orders/CreationViewModel';
import ProjectParts from '@/models/part/projectParts';
import SpecialCharges from '@/models/project/specialCharges';
import Room, { roomContainsCustomColor } from '@/models/room/room';
import NumberFormatter from './numberFormatter';

export function selectedRoomsContainSoldOutPart(rooms: Room[]): boolean {
  return rooms.filter(room => room.isSoldOut).length > 0;
}

export function projectContainsSoldOutPart(parts: ProjectParts[]): boolean {
  if (!parts) {
    return false;
  }
  return parts.some(part => part.isSoldOut);
}

export function selectedRoomsContainCustomColorPart(allRooms: Room[], selectedRoomsIds: string[]): boolean {
  if (!allRooms || !selectedRoomsIds) {
    return false;
  }
  return allRooms.filter(room => selectedRoomsIds.includes(room.id) && roomContainsCustomColor(room)).length > 0;
}

export function projectContainsCustomColorPart(parts: ProjectParts[]): boolean {
  if (!parts) {
    return false;
  }
  return parts.some(part => part.isCustomColorPart);
}

export function validateFreightChargeInput(input: string): boolean {
  if (!input) {
    return true;
  }
  const asNum = parseFloat(input.replace(/,/g, ''));
  return !isNaN(asNum) && asNum >= 0 && asNum <= 999999.99;
}

export function validateEstimatedTransitTimeInput(input: string): boolean {
  if (!input) {
    return true;
  }

  const asNum = parseInt(input);
  const areInvalidCharsPresent = /[a-zA-Z,.]/g.test(input);
  return asNum > 0 && asNum < 100 && !areInvalidCharsPresent;
}

/**
 * This function mutates the view model.
 * @param specialChargesHistory List containing existing special charges for the current project
 * @param viewModel The current view model being used by quote or order process
 * Checks if there are existing special charges for a given project.
 * Sets the latest as the default value if so, else sets placeholder values.
 */
export function setInitialSpecialCharge(specialChargesHistory: SpecialCharges[], viewModel: CreationViewModel) {
  if (specialChargesHistory.length) {
    const specialCharges = specialChargesHistory[0];
    viewModel.specialCharges = NumberFormatter.strictToFixed(specialCharges.specialCharges, 2);
    viewModel.specialChargesDescription = specialCharges.specialChargesDescription;
  } else {
    viewModel.specialCharges = undefined;
    viewModel.specialChargesDescription = i18n.global.t('newQuoteModal.firstScreen.specialCharge.defaultDescription');
  }
}

export function unwrapSpecialChargesFromViewModel(viewModel: CreationViewModel): number {
  const { specialCharges } = viewModel;
  if (!specialCharges) {
    return 0;
  }
  if (typeof specialCharges === 'string') {
    return NumberFormatter.strictParseFloat(specialCharges);
  }
  return specialCharges!;
}
