import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_h_spinner = _resolveComponent("h-spinner")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([{
      'loading-overlay--semi-transparent': _ctx.semiTransparent,
      'loading-overlay--fixed': _ctx.fixed,
      'loading-overlay--fixed-fullscreen': _ctx.fixedFullscreen,
    }, "loading-overlay"])
      }, [
        _createVNode(_component_h_spinner, {
          class: _normalizeClass(["loading-spinner", _ctx.spinnerClass]),
          color: "current"
        }, null, 8, ["class"]),
        (_ctx.message)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              innerHTML: _ctx.message,
              class: "loading-message"
            }, null, 8, _hoisted_1))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}