enum ModalEvents {
  Finish = 'showFinishModal',
  Material = 'showMaterialModal',
  HardwarePackage = 'showHardwarePackageModal',
  PartitionType = 'showPartionTypeModal',
  Screens = 'showScreensModal',
  Series = 'showSeriesModal',
  Feedback = 'showFeedback',
  Notes = 'showNotesModal',
  DuplicateNotesToRooms = 'duplicateNotesToRooms',
  CancelConfirm = 'showCancelConfirmModal',
  ProjectDuplicate = 'showProjectDuplicate',
  LockersStyleUpdatePrompt = 'showLockersStyleUpdatePrompt',
  GenderAndFloor = 'genderAndFloorModal',
  CommonErrorModal = 'showCommonErrorModal',
  SuggestedPartsModal = 'showSuggestedPartsModal',
  PaintModal = 'showPaintModal',
  LargeShipmentRequestFeedbackModal = 'showLargeShipmentRequestFeedbackModal',
  LargeShipmentRequestCompletionModal = 'showLargeShipmentRequestCompletionModal',
}

export default ModalEvents;
