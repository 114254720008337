import iconErrorBrowserNotSupported from '@/shared/icons/iconErrorBrowserNotSupported/iconErrorBrowserNotSupported.vue';
import cancelConfirmModal from '@/shared/viewParts/cancelConfirmModal/cancelConfirmModal.vue';
import commonErrorModal from '@/shared/viewParts/commonErrorModal/commonErrorModal.vue';
import listExportModal from '@/shared/viewParts/listExportModal/listExportModal.vue';
import orderStatusLegendModal from '@/shared/viewParts/orderStatusLegendModal/orderStatusLegendModal.vue';
import browserListSupported from '@/views/browserListSupported/browserListSupported.vue';
import browserNotSupported from './views/browserNotSupported/browserNotSupported.vue';
import errorModal from '@/views/errorModal/errorModal.vue';
import suggestedPartsModal from '@/views/projects/projectDetails/projectDetailsTabs/projectDetailsParts/suggestedPartsModal/suggestedPartsModal.vue';
import { defineComponent } from 'vue';
import AuthenticationService from './endpoints/authenticationService';
import BrowserHelper from './helpers/browser';
import { UpdateHelper } from './helpers/updateHelper';
import User from './models/user/user';
import paths from './router/paths';
import navMenu from './shared/navigation/navMenu/navMenu.vue';
import environmentFooter from './shared/viewParts/environmentFooter/environmentFooter.vue';
import { vxManager } from './store/store';
import maintenance from './views/maintenance/maintenance.vue';
import profilerModal from './views/profilerModal/profilerModal.vue';

declare global {
  interface Window {
    chrome: any;
    safari: any;
    opera: any;
  }
}

const routesWithoutMenu = [
  paths.setUpAccount,
  paths.changePassword,
  paths.forgotPassword,
  paths.confirmForgotPassword,
  paths.login,
  paths.unauthenticatedLegalNotice,
  paths.unauthenticatedTermsConditions,
  paths.unauthenticatedPrivacyPolicy,
  paths.networkError,
  paths.components,
  paths.unauthenticatedRoomDesignerPattern,
];

export default defineComponent({
  name: 'app',
  components: {
    cancelConfirmModal,
    errorModal,
    suggestedPartsModal,
    commonErrorModal,
    environmentFooter,
    navMenu,
    profilerModal,
    listExportModal,
    orderStatusLegendModal,
    maintenance,
    iconErrorBrowserNotSupported,
    browserListSupported,
    browserNotSupported,
  },
  data() {
    return {
      isInMaintenanceMode: process.env.VUE_APP_MAINTENANCE_MODE === 'true',
      bodyClassName: '',
      showMenu: false,
      isBrowserSupported: true,
    };
  },
  async created() {
    this.isBrowserSupported = BrowserHelper.isBrowserSupported();
    if (!this.isBrowserSupported) {
      return;
    }

    this.$newRelic.resetUserId();
    const keepMeLoggedIn = localStorage.getItem('hadrian-keepmeloggedin');
    if (keepMeLoggedIn) {
      vxManager.userModule.changeKeepMeLoggedIn(keepMeLoggedIn === 'true');
    }

    UpdateHelper.initialize();

    if (vxManager.userModule.getStorageContainsToken) {
      if (!(await vxManager.userModule.login(await AuthenticationService.fetchSignInUserData()))) {
        await vxManager.userModule.logout();
      }
      await vxManager.permissionsModule.fetchPermissions();
      await vxManager.userModule.fetchUserContentViews();
    }

    window.addEventListener('keydown', this.handleFirstTab);
  },
  mounted() {
    this.syncAppClasses();
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleFirstTab);
  },
  computed: {
    appClasses(): any {
      return {
        'nav-menu-is-hidden': !this.showMenu,
        'in-maintenance-mode': this.isInMaintenanceMode,
        'app--hidden': (window as any).HADRIAN_UNSUPPORTED_BROWSER,
      };
    },
    currentUser(): User | undefined {
      return vxManager.userModule.getUserInfo;
    },
  },
  methods: {
    showProfilerModal(): void {
      (this.$refs.profilerModal as any).show();
    },
    handleFirstTab({ key }: KeyboardEvent) {
      if (key === 'Tab') {
        document.body.classList.add('user-is-tabbing');
        window.removeEventListener('keydown', this.handleFirstTab);
        window.addEventListener('mousedown', this.handleMouseDownOnce);
      }
    },
    handleMouseDownOnce() {
      document.body.classList.remove('user-is-tabbing');

      window.removeEventListener('mousedown', this.handleMouseDownOnce);
      window.addEventListener('keydown', this.handleFirstTab);
    },
    syncAppClasses() {
      const app = document.getElementById('app');
      if (!app) {
        return;
      }

      for (const className in this.appClasses) {
        if (this.appClasses[className]) {
          app.classList.add(className);
        } else {
          app.classList.remove(className);
        }
      }
    },
  },
  watch: {
    currentUser(newUser, oldUser) {
      if (!!oldUser && !newUser) {
        this.$router.push(paths.login);
      }
    },
    $route() {
      this.showMenu = !routesWithoutMenu.some(route => {
        return this.$route.path.toLowerCase().startsWith(route.toLowerCase());
      });
    },
    appClasses() {
      this.syncAppClasses();
    },
  },
});
