import ModalEvents from '@/enums/modalEvents';
import EventBus from '@/eventBus';
import notice from '@/shared/icons/notice/notice.vue';
import hadrianModal from '@/shared/viewParts/hadrianModal/hadrianModal.vue';

export interface CancelConfirmModalData {
  readonly title?: string;
  readonly bodyMain?: string;
  readonly bodySub: string;
  readonly icon?: any;
  readonly iconClasses?: string;
  readonly iconDivClasses?: string;
  readonly leftButtonTextOverride?: string;
  readonly rightButtonTextOverride?: string;
  readonly modalVariant?: string;
  readonly bodySubClasses?: string;
  readonly warningText?: string;
  // Defaults to false
  readonly hideLeftButton?: boolean;
  readonly onCancel?: () => void;
  readonly onConfirm: () => void;
}

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'cancelConfirmModal',
  components: {
    hadrianModal,
    notice,
  },
  data() {
    return {
      title: '',
      bodyMain: '',
      bodySub: '',
      icon: undefined as any | undefined,
      iconDivClasses: '' as string | undefined,
      iconClasses: '' as string | undefined,
      leftButtonTextOverride: '' as string | undefined,
      rightButtonTextOverride: '' as string | undefined,
      hideLeftButton: false,
      onCancel: undefined as (() => void) | undefined,
      onConfirm: undefined as (() => void) | undefined,
      variant: '',
      bodySubClasses: '',
      warningText: '',
    };
  },
  created() {
    EventBus.$on(ModalEvents.CancelConfirm, this.show);
  },
  beforeUnmount() {
    EventBus.$off(ModalEvents.CancelConfirm, this.show);
  },
  computed: {
    leftButtonText(): string {
      return this.leftButtonTextOverride ? this.leftButtonTextOverride : this.$t('generic.cancel').toString();
    },
    rightButtonText(): string {
      return this.rightButtonTextOverride ? this.rightButtonTextOverride : this.$t('generic.confirm').toString();
    },
  },
  methods: {
    show(data: CancelConfirmModalData) {
      this.title = data.title ?? '';
      this.bodyMain = data.bodyMain ?? '';
      this.bodySub = data.bodySub;
      this.icon = data.icon;
      this.iconClasses = data.iconClasses;
      this.leftButtonTextOverride = data.leftButtonTextOverride;
      this.rightButtonTextOverride = data.rightButtonTextOverride;
      this.hideLeftButton = data.hideLeftButton ?? false;
      this.onCancel = data.onCancel;
      this.onConfirm = data.onConfirm;
      this.variant = data.modalVariant || 'thin';
      this.bodySubClasses = data.bodySubClasses ?? '';
      this.warningText = data.warningText ?? '';
      (this.$refs.cancelConfirmModal as any).show();
    },
    onRightButtonClicked() {
      if (this.onConfirm) {
        this.onConfirm();
      }
      this.clean();
    },
    cancel() {
      if (this.onCancel) {
        this.onCancel();
      }
      this.clean();
      this.$emit('close');
    },
    clean() {
      this.title = '';
      this.bodyMain = '';
      this.bodySub = '';
      this.icon = undefined;
      this.iconClasses = undefined;
      this.leftButtonTextOverride = undefined;
      this.rightButtonTextOverride = undefined;
      this.onConfirm = undefined;
      this.onCancel = undefined;
      this.hideLeftButton = false;
      this.bodySubClasses = '';
      this.warningText = '';
    },
  },
});
