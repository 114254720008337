import ProvincesService from '@/endpoints/provincesService';
import OrganizationCountryCodes from '@/enums/organizationCountryCodes';
import ProvinceCountries from '@/enums/provinceCountries';
import ArrayHelper from '@/helpers/arrayHelper';
import Province from '@/models/addresses/province';
import { action, mutation, Module, VuexModule } from 'vuex-class-component';

@Module()
export class ProvincesModule extends VuexModule {
  private provinces: Province[] = [];

  @action({ mode: 'mutate' })
  public async setProvinces(provinces: Province[]) {
    provinces.sort((lhp, rhp) => {
      return lhp.label > rhp.label ? 1 : -1;
    });
    this.addProvinces(provinces);
  }

  get getProvinces() {
    return this.provinces;
  }

  get provincesByCountry() {
    return (countryCode: string) => {
      const country =
        countryCode === OrganizationCountryCodes.Canada ? ProvinceCountries.Canada : ProvinceCountries.UnitedStates;

      return this.provinces.filter(p => p.country.code === country);
    };
  }

  @mutation private addProvinces(provinces: Province[]) {
    ArrayHelper.addOrReplace(this.provinces, provinces);
  }
}
