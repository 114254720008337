import cancel from '@/shared/icons/cancel/cancel.vue';
import chevron from '@/shared/icons/chevron/chevron.vue';
import upDownArrow from '@/shared/icons/upDownArrow/upDownArrow.vue';
import hadrianLoader from '@/shared/viewParts/hadrianLoader/hadrianLoader.vue';
import { Component, defineComponent } from 'vue';

export interface FilteredSelectorItem {
  readonly label: string;
  readonly value: any;
  readonly key?: string;
  readonly disabled?: boolean;
  // If present, will override `label`
  readonly searchingLabel?: string;
  readonly icon?: Component;
  readonly iconColor?: IconColors;
  readonly leftSpan?: string | null;
}

export enum IconColors {
  Green = 'green',
  Blue = 'blue',
  Pink = 'pink',
  Turquoise = 'turquoise',
  Yellow = 'yellow',
  Red = 'red',
  Gray = 'gray',
  Orange = 'orange',
  Purple = 'purple',
}

export enum SearchMode {
  Includes = 'Includes',
  StartsWith = 'StartsWith',
}

enum Keycodes {
  Enter = 13,
}

export default defineComponent({
  name: 'filteredSelector',
  components: {
    cancel,
    chevron,
    hadrianLoader,
    upDownArrow,
  },
  props: {
    nullable: {
      type: Boolean,
      default: false,
    },
    suggestionList: {
      type: Array as () => FilteredSelectorItem[],
      required: true,
    },
    placeHolder: {
      type: String,
    },
    defaultSelectedName: {
      type: String,
    },
    searchMode: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    value: {
      type: String,
      required: false,
    },
    resetToDefault: {
      type: Boolean,
      default: true,
    },
    showLoading: {
      type: Boolean,
      default: false,
    },
    bypassSetDefault: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedItem: undefined as FilteredSelectorItem | undefined,
      selectorName: '',
      lastSelectedName: '',
      showSuggestions: false,
      isFocused: false,
      IconColors,
    };
  },
  created() {
    this.setDefault();
  },
  computed: {
    trimmedSuggestionList(): FilteredSelectorItem[] {
      if (!this.selectorName) {
        return this.suggestionList;
      }

      return this.suggestionList.filter(suggestion => {
        const searchingLabel = suggestion.searchingLabel ?? suggestion.label;
        if (this.searchMode === SearchMode.StartsWith) {
          return searchingLabel.toLowerCase().startsWith(this.selectorName.toLowerCase());
        } else {
          return searchingLabel.toLowerCase().includes(this.selectorName.toLowerCase());
        }
      });
    },
    isCurrentValid(): boolean {
      return this.trimmedSuggestionList.length > 0;
    },
    isInputInError(): boolean {
      return (this.selectorName.length > 0 || this.nullable) && !this.isCurrentValid;
    },
    isNullable(): boolean {
      return this.nullable && this.suggestionList.length > 1;
    },
  },
  methods: {
    setDefault() {
      if (this.bypassSetDefault) {
        return;
      }
      if (this.suggestionList.length === 1) {
        this.selectorName = this.lastSelectedName = this.suggestionList[0].label.trim();
        this.selectedItem = this.suggestionList.find(e => e.label.toLowerCase() === this.selectorName.toLowerCase());
      } else if (this.defaultSelectedName != null) {
        this.selectorName = this.lastSelectedName = this.defaultSelectedName;
        this.selectedItem = this.suggestionList.find(e => e.label.toLowerCase() === this.selectorName.toLowerCase());
      }
    },
    onFocus() {
      this.selectorName = '';
      this.showSuggestions = true;
      this.isFocused = true;
      this.$emit('focus');
    },
    onClick() {
      (this.$refs.input as HTMLElement).focus();
    },
    onClickArrows() {
      (this.$refs.input as HTMLElement).focus();
    },
    hideSuggestions() {
      this.$emit('hide');
      if (!this.showSuggestions) {
        return;
      }

      let resetInfo = false;
      if (this.isFocused) {
        this.isFocused = false;
        return;
      }
      if (!this.isCurrentValid || this.selectorName.length === 0) {
        resetInfo = true;
        if (this.resetToDefault) {
          this.setDefault();
        }
      }

      let suggestion: FilteredSelectorItem | undefined;
      suggestion = this.suggestionList.find(e => e.label.toLowerCase() === this.selectorName.toLowerCase());
      if (!suggestion) {
        this.selectorName = this.lastSelectedName;
        suggestion = this.suggestionList.find(e => e.label.toLowerCase() === this.selectorName.toLowerCase());
        this.selectedItem = suggestion;
      }

      if (suggestion) {
        this.selectorName = this.lastSelectedName = suggestion.label;
        this.selectedItem = suggestion;
        this.$emit('select', suggestion.value, resetInfo);
      } else {
        resetInfo = true;
        this.setDefault();
        this.$emit('select', this.selectorName, resetInfo);
      }

      this.showSuggestions = false;
    },
    selectSuggestion(suggestion: FilteredSelectorItem, event: Event) {
      if (suggestion.disabled) {
        return;
      }

      event.stopPropagation();
      const label = suggestion.label.trim();

      this.selectorName = label;
      this.lastSelectedName = label;
      this.showSuggestions = false;
      this.selectedItem = suggestion;

      this.$emit('select', suggestion.value, false);
      this.$emit('input', suggestion.value);
    },
    clearFilter() {
      this.showSuggestions = false;
      this.selectorName = '';
      this.lastSelectedName = '';
      this.selectedItem = undefined;
      this.$emit('select', null, false);
    },
    keydownOnItem(event: KeyboardEvent, suggestion: FilteredSelectorItem) {
      if (event.keyCode === Keycodes.Enter) {
        this.selectSuggestion(suggestion, event);
      }
    },
    getIconColorClass(color: IconColors) {
      return { [`filtered-selector__suggestion-icon--${color}`]: true };
    },
  },
  watch: {
    value() {
      if (this.isCurrentValid && this.value) {
        this.selectorName = this.value;
      }
    },
    defaultSelectedName() {
      this.setDefault();
    },
  },
});
