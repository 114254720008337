import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "list-export-modal__container" }
const _hoisted_2 = { class: "list-export-modal__header" }
const _hoisted_3 = {
  key: 0,
  class: "list-export-modal__header-title"
}
const _hoisted_4 = {
  key: 1,
  class: "list-export-modal__header-title"
}
const _hoisted_5 = { class: "list-export-modal__header-text" }
const _hoisted_6 = { class: "list-export-modal__content" }
const _hoisted_7 = { class: "list-export-modal__content-header" }
const _hoisted_8 = { class: "list-export-modal__content-title" }
const _hoisted_9 = { class: "list-export-modal__filters-wrapper" }
const _hoisted_10 = {
  key: 0,
  class: "list-export-modal__filter-container"
}
const _hoisted_11 = {
  key: 1,
  class: "list-export-modal__filter-container"
}
const _hoisted_12 = {
  key: 2,
  class: "list-export-modal__filter-container"
}
const _hoisted_13 = { class: "list-export-modal__filter-container" }
const _hoisted_14 = { class: "list-export-modal__filter-container" }
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = {
  key: 1,
  class: "list-export-modal__footer-error"
}
const _hoisted_17 = { class: "list-export-modal__date-range-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_productTypeSelector = _resolveComponent("productTypeSelector")!
  const _component_organizationSelector = _resolveComponent("organizationSelector")!
  const _component_orderStatusSelector = _resolveComponent("orderStatusSelector")!
  const _component_dateRangeButton = _resolveComponent("dateRangeButton")!
  const _component_priceRangeSelector = _resolveComponent("priceRangeSelector")!
  const _component_dateRangeModal = _resolveComponent("dateRangeModal")!
  const _component_hadrianModal = _resolveComponent("hadrianModal")!

  return (_openBlock(), _createBlock(_component_hadrianModal, {
    class: "list-export-modal",
    ref: "listExportModal",
    leftButtonText: _ctx.$t('generic.cancel'),
    rightButtonText: _ctx.$t('generic.generate'),
    rightButtonDisabled: _ctx.hasCountLimitError || _ctx.totalCount === 0,
    onLeftButtonClick: _ctx.onCloseModalFilters,
    onRightButtonClick: _ctx.onGenerateCsv,
    scrollable: false,
    hideHeader: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("header", _hoisted_2, [
          (_ctx.entityType === 'project')
            ? (_openBlock(), _createElementBlock("h4", _hoisted_3, _toDisplayString(_ctx.$t('modal.listExportModal.projectListTitle')), 1))
            : (_openBlock(), _createElementBlock("h4", _hoisted_4, _toDisplayString(_ctx.$t('modal.listExportModal.orderListTitle')), 1)),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('modal.listExportModal.exportListText')), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('labels.filters')), 1),
            (_ctx.hasActiveFilter)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  type: "button",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clearFilters())),
                  role: "link",
                  class: "list-export-modal__content-reset-filters"
                }, _toDisplayString(_ctx.$t('modal.listExportModal.resetFilters')), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", {
              class: _normalizeClass(["list-export-modal__filter-container", { 'list-export-modal__filter-container-full-width': _ctx.entityType === 'project' }])
            }, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.projectType')), 1),
              _createVNode(_component_productTypeSelector, {
                ref: "productTypeModalSelector",
                nullable: true,
                modelValue: _ctx.selectedProductType,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedProductType) = $event))
              }, null, 8, ["modelValue"])
            ], 2),
            (_ctx.showSalesAgenciesFilter)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.salesAgencies')), 1),
                  _createVNode(_component_organizationSelector, {
                    ref: "salesAgenciesModalSelector",
                    selectorMode: _ctx.OrganizationSelectorMode.SalesAgencies,
                    nullable: true,
                    modelValue: _ctx.selectedSalesAgency,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedSalesAgency) = $event)),
                    eagerFetch: true
                  }, null, 8, ["selectorMode", "modelValue"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.showDistributorsFilter)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.distributors')), 1),
                  _createVNode(_component_organizationSelector, {
                    ref: "organizationModalSelector",
                    selectorMode: _ctx.OrganizationSelectorMode.All,
                    nullable: true,
                    modelValue: _ctx.selectedDistributor,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedDistributor) = $event)),
                    eagerFetch: true,
                    referenceOrganization: _ctx.selectedSalesAgency
                  }, null, 8, ["selectorMode", "modelValue", "referenceOrganization"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.entityType === 'order')
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.orderStatus')), 1),
                  _createVNode(_component_orderStatusSelector, {
                    ref: "orderStatusModalSelector",
                    modelValue: _ctx.selectedOrderStatus,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedOrderStatus) = $event))
                  }, null, 8, ["modelValue"])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.date')), 1),
              _createVNode(_component_dateRangeButton, {
                startDate: _ctx.startDate,
                endDate: _ctx.endDate,
                useComponentEvents: false,
                onClickEvent: _ctx.openDateRangeModal,
                onClearEvent: _ctx.clearDateRangeValue
              }, null, 8, ["startDate", "endDate", "onClickEvent", "onClearEvent"])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.price')), 1),
              _createVNode(_component_priceRangeSelector, {
                onSelectMinPrice: _ctx.minPriceInput,
                onSelectMaxPrice: _ctx.maxPriceInput,
                minPrice: _ctx.minPrice,
                maxPrice: _ctx.maxPrice
              }, null, 8, ["onSelectMinPrice", "onSelectMaxPrice", "minPrice", "maxPrice"])
            ])
          ])
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["list-export-modal__footer", { 'list-export-modal__footer--is-loading': _ctx.loading }])
        }, [
          (!_ctx.hasCountLimitError)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                class: "list-export-modal__footer-counter",
                innerHTML: _ctx.totalCountLabel
              }, null, 8, _hoisted_15))
            : (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString(_ctx.$t('modal.listExportModal.footerProjectErrorMessage')), 1))
        ], 2)
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_17, [
        _createVNode(_component_dateRangeModal, {
          startDate: _ctx.startDate,
          endDate: _ctx.endDate,
          showPicker: _ctx.showDateRangePicker,
          setShowPicker: _ctx.setShowDateRangePicker,
          onStartDateInput: _ctx.startDateInput,
          onEndDateInput: _ctx.endDateInput,
          ref: "filtersDateRangeModal"
        }, null, 8, ["startDate", "endDate", "showPicker", "setShowPicker", "onStartDateInput", "onEndDateInput"])
      ], 512), [
        [_vShow, _ctx.showDateRangePicker]
      ])
    ], undefined, true),
    _: 1
  }, 8, ["leftButtonText", "rightButtonText", "rightButtonDisabled", "onLeftButtonClick", "onRightButtonClick"]))
}