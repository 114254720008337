export enum WorkingDirection {
  FromLeft = 1,
  FromRight = 2,
}

export enum AvailableWorkingDirection {
  BothDirections = 0,
  OnlyFromLeft = 1,
  OnlyFromRight = 2,
  NoDirection = 3,
}
