import PatchOperation from '@/models/patchOperation/patchOperation';

export enum PathPrefixes {
  None = '',
  ProjectSettings = '/ProjectSettings',
  RoomSettings = '/RoomSettings',
  HardwarePackage = '/HardwarePackage',
  RoomHardwarePackage = '/RoomSettings/HardwarePackage',
  FreightInformationOptions = '/FreightInformationOptions',
}

/**
 * Method used to create a patch operation with type safety.
 * The value of path must always correspond to an object's property.
 * The type of value must always correspond to the object's property's type.
 * @param _ The
 * @param path The key of the property to be updated.
 * @param value The value to be set.
 * @param pathPrefix Used for subproperties patches. E.G.: Room Settings.
 */
export function makePatchOperation<T extends object, K extends keyof T, V extends T[K]>(
  _: new () => T,
  path: K,
  value: V,
  pathPrefix: PathPrefixes = PathPrefixes.None
): PatchOperation {
  return {
    operation: 'replace',
    path: `${pathPrefix}/${capitalizePath(path as string)}`,
    value,
  };
}

/**
 * This is used to chain patch creations together and get an array containing all the operations.
 * To use it, prefer using the helper function 'makePatchBuilder' to create an instance of the builder.
 * The generic type represents the object being patched.
 * Use the add method to create a type safe patch operation.
 * Use the addIf method to create the operation if the condition passes.
 * Use the operations getter to get the list of operations created.
 * Usage example:
 *   makePatchBuilder<Project>()
 *     .add('projectName', 'My new Project')
 *     .addIf(clientName === 'Jack', 'clientName', clientName)
 *     .operations;
 * This would return an array with one or two operations depending if clientName === 'Jack'
 */
class PatchBuilder<T extends object> {
  public static make<T extends object>(): PatchBuilder<T> {
    return new PatchBuilder<T>();
  }

  get operations(): PatchOperation[] {
    return this.operationsList;
  }

  private operationsList: PatchOperation[] = [];

  public addIf<K extends keyof T>(
    condition: boolean,
    path: K,
    value: T[K],
    pathPrefix: PathPrefixes = PathPrefixes.None
  ) {
    if (!condition) {
      return this;
    }
    return this.add(path, value, pathPrefix);
  }

  /**
   * Compares two values and creates a patch operation if they differ.
   */
  public addIfDifferent<K extends keyof T>(path: K, oldValue: T[K], newValue: T[K]) {
    return this.addIf(oldValue !== newValue, path, newValue);
  }

  public add<K extends keyof T>(path: K, value: T[K], pathPrefix: PathPrefixes = PathPrefixes.None) {
    this.operationsList.push({
      operation: 'replace',
      path: `${pathPrefix}/${capitalizePath(path as string)}`,
      value,
    });
    return this;
  }

  /**
   * Returns `true` if there are any created patches.
   */
  public hasPatches(): boolean {
    return this.operationsList.length > 0;
  }
}

/// The backend expects path to start with a / and be capitalized.
/// It sometimes does string comparisons to handle edge cases.
function capitalizePath(path: string): string {
  return path.charAt(0).toUpperCase() + path.slice(1);
}

export function makePatchBuilder<T extends object>(): PatchBuilder<T> {
  return PatchBuilder.make<T>();
}
