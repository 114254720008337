import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "organizations-list__header" }
const _hoisted_2 = { class: "organizations-list__body" }
const _hoisted_3 = {
  key: 0,
  class: "organizations-list__body--error-message"
}
const _hoisted_4 = {
  key: 1,
  class: "organizations-list__body--table"
}
const _hoisted_5 = {
  key: 2,
  class: "organizations-list__body--error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_hadrianTitle = _resolveComponent("hadrianTitle")!
  const _component_searchInput = _resolveComponent("searchInput")!
  const _component_hadrianLoader = _resolveComponent("hadrianLoader")!
  const _component_pill = _resolveComponent("pill")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_h_table = _resolveComponent("h-table")!
  const _component_h_pagination = _resolveComponent("h-pagination")!
  const _component_hadrianPage = _resolveComponent("hadrianPage")!

  return (_openBlock(), _createBlock(_component_hadrianPage, null, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_hadrianTitle, {
          title: _ctx.$t('mainMenu.menu.organizations')
        }, null, 8, ["title"]),
        (_ctx.userHasOrganizations)
          ? (_openBlock(), _createBlock(_component_searchInput, {
              key: 0,
              modelValue: _ctx.search,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
              modelModifiers: { trim: true },
              placeholder: _ctx.$t('generic.search')
            }, null, 8, ["modelValue", "placeholder"]))
          : _createCommentVNode("", true)
      ])
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_hadrianLoader, {
          isLoading: !!_ctx.loading
        }, null, 8, ["isLoading"]),
        (!_ctx.userHasOrganizations && !_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('organizations.noOrganizations')), 1))
          : (!_ctx.loading && _ctx.areShownOrganizations)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_h_table, {
                  columns: _ctx.getColumns,
                  rows: _ctx.getShownRows
                }, {
                  "column-value-product": _withCtx(({ row }) => [
                    _createVNode(_component_pill, {
                      class: "organizations-list__body--product",
                      theme: _ctx.isWD(row) ? _ctx.PillThemes.Alert : _ctx.PillThemes.Blue
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(row[_ctx.$t('generic.product').toString()]), 1)
                      ], undefined, true),
                      _: 2
                    }, 1032, ["theme"])
                  ]),
                  "column-value-organization": _withCtx(({ row }) => [
                    _createVNode(_component_router_link, {
                      to: _ctx.organizationLink(row)
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(row[_ctx.$t('generic.organization').toString()]), 1)
                      ], undefined, true),
                      _: 2
                    }, 1032, ["to"])
                  ]),
                  _: 1
                }, 8, ["columns", "rows"]),
                _createVNode(_component_h_pagination, {
                  class: "organizations-list__body--table .pagination",
                  total: _ctx.getRows?.length ?? 0,
                  perPage: _ctx.perPage,
                  modelValue: _ctx.currentPage,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentPage) = $event))
                }, null, 8, ["total", "perPage", "modelValue"])
              ]))
            : (!_ctx.loading && !_ctx.areShownOrganizations)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('organizations.noOrganizationsFind')), 1))
              : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}