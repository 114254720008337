import HttpService from '@/endpoints/HttpService';
import CreateRoomScreenRequest from '@/models/room/screens/CreateRoomScreenRequest';
import ScreenTypeOptions from '@/models/room/screens/roomScreenTypeOptions';
import RoomScreen from '@/models/room/screens/roomScreens';

const baseUrl = `${process.env.VUE_APP_API_PREFIX}`;

enum Suffixes {
  ScreenTypes = 'ScreenTypes',
}

export default class ScreensService {
  public static async getScreenTypes(): Promise<ScreenTypeOptions[]> {
    const url = this.getScreensUrl(Suffixes.ScreenTypes);
    const res = await HttpService.get(url);
    return res.data.entities;
  }

  public static async putRoomScreen(screen: CreateRoomScreenRequest): Promise<RoomScreen> {
    const response = await HttpService.put(`${baseUrl}screens`, screen);
    return response.data.entity;
  }

  public static async patchRoomScreen(screen: CreateRoomScreenRequest): Promise<RoomScreen> {
    const response = await HttpService.patch(`${baseUrl}screens/replace/${screen.id}`, screen);
    return response.data.entity;
  }

  public static async deleteRoomScreen(id: string): Promise<void> {
    await HttpService.delete(`${baseUrl}screens/delete/${id}`);
  }

  private static getScreensUrl(endpoint: Suffixes, id = ''): string {
    return `${baseUrl}screens/${id ? id + '/' : ''}${endpoint}`;
  }
}
