import { CODE_UPDATE_DEBOUNCE } from '@/constants/hadrianGlobals';
import { GetAllowedProductTypes } from '@/constants/projectTypes';
import GenerateCsvService from '@/endpoints/generateCsvService';
import OrdersService from '@/endpoints/orderService';
import ProjectService from '@/endpoints/projectService';
import PermissionTypes from '@/enums/permissionTypes';
import EventBus from '@/eventBus';
import Events from '@/events';
import Toast from '@/helpers/toast';
import Organization from '@/models/permission/organization';
import { ProjectsGetParams, ProjectProductTypes } from '@/models/project/projectApiInterfaces';
import dateRangeButton from '@/shared/viewParts/dateRangeButton/dateRangeButton.vue';
import dateRangeModal from '@/shared/viewParts/dateRangeModal/dateRangeModal.vue';
import filteredSelector from '@/shared/viewParts/filteredSelector/filteredSelector.vue';
import { FilteredSelectorItem } from '@/shared/viewParts/filteredSelector/filteredSelector';
import hadrianModal from '@/shared/viewParts/hadrianModal/hadrianModal.vue';
import orderStatusSelector from '@/shared/viewParts/orderStatusSelector/orderStatusSelector.vue';
import { ALL_ORDER_STATUS } from '@/shared/viewParts/orderStatusSelector/orderStatusSelector';
import organizationSelector from '@/shared/viewParts/organizationSelector/organizationSelector.vue';
import { OrganizationSelectorMode } from '@/shared/viewParts/organizationSelector/organizationSelector';
import priceRangeSelector from '@/shared/viewParts/priceRangeSelector/priceRangeSelector.vue';
import productTypeSelector from '@/shared/viewParts/productTypeSelector/productTypeSelector.vue';
import { TypeDropdownItem } from '@/shared/viewParts/productTypeSelector/productTypeSelector';
import { OrderItemsRequest } from '@/store/ordersModule/ordersModule';
import { vxManager } from '@/store/store';
import { debounce } from 'lodash';

type ExportListEntityType = 'order' | 'project';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'listExportModal',
  components: {
    hadrianModal,
    filteredSelector,
    organizationSelector,
    dateRangeButton,
    priceRangeSelector,
    dateRangeModal,
    orderStatusSelector,
    productTypeSelector,
  },
  data() {
    return {
      OrganizationSelectorMode,
      hasCountLimitError: false,
      entityType: 'project' as ExportListEntityType,
      selectedProductType: undefined as TypeDropdownItem | undefined,
      selectedSalesAgency: undefined as Organization | undefined,
      selectedDistributor: undefined as Organization | undefined,
      selectedOrderStatus: undefined as FilteredSelectorItem | undefined,
      type: undefined as number | undefined,
      productType: undefined as ProjectProductTypes | undefined,
      minPrice: undefined as number | undefined,
      maxPrice: undefined as number | undefined,
      startDate: undefined as string | undefined,
      endDate: undefined as string | undefined,
      showFilters: false,
      totalCount: 0,
      totalCountLabel: '...',
      loading: false,
      showDateRangePicker: false,
      skipGetListCount: false,
    };
  },
  created() {
    EventBus.$on(Events.showListExportModal, this.onOpenModalFilters);
  },
  beforeUnmount() {
    EventBus.$off(Events.showListExportModal);
  },
  computed: {
    organizations(): Organization[] {
      return vxManager.organizationsModule.getOrganizations;
    },
    distributors(): Organization[] {
      return vxManager.organizationsModule.getDistributors;
    },
    salesAgencies(): Organization[] {
      return vxManager.organizationsModule.getSalesAgencies;
    },
    showSalesAgenciesFilter(): boolean {
      if (this.entityType === 'project') {
        return this.salesAgencies.length > 1;
      }
      const permissions = vxManager.userModule.getUserPermissions;
      return !!permissions && permissions[PermissionTypes.SalesAgenciesFilter] && this.salesAgencies.length > 1;
    },
    showDistributorsFilter(): boolean {
      if (this.entityType === 'project') {
        return !!this.organizations && this.organizations.length > 1;
      }
      return this.distributors.length > 1;
    },
    hasActiveFilter(): boolean {
      return (
        !!this.selectedDistributor ||
        !!this.selectedSalesAgency ||
        !!this.selectedProductType ||
        !!this.selectedOrderStatus ||
        !!this.startDate ||
        !!this.maxPrice ||
        !!this.minPrice
      );
    },
    params(): ProjectsGetParams | OrderItemsRequest {
      const params: ProjectsGetParams | OrderItemsRequest = { PerPage: 0, Page: 1 };

      if (this.startDate) {
        this.entityType === 'order'
          ? ((params as OrderItemsRequest).shipStartDate = this.startDate)
          : ((params as ProjectsGetParams).DateStart = this.startDate);
      }

      if (this.endDate) {
        this.entityType === 'order'
          ? ((params as OrderItemsRequest).shipEndDate = this.endDate)
          : ((params as ProjectsGetParams).DateEnd = this.endDate);
      }

      if (this.selectedSalesAgency?.id) {
        this.entityType === 'order'
          ? ((params as OrderItemsRequest).SalesAgencyId = this.selectedSalesAgency?.id)
          : ((params as ProjectsGetParams).SalesAgency = this.selectedSalesAgency?.id);
      }

      if (this.selectedDistributor?.id) {
        this.entityType === 'order'
          ? ((params as OrderItemsRequest).DistributorId = this.selectedDistributor?.id)
          : ((params as ProjectsGetParams).Distributor = this.selectedDistributor?.id);
      }

      // this can be 0, so we check if is set or equal 0
      if (!!this.selectedProductType?.id || this.selectedProductType?.id === 0) {
        params.ProductTypes = this.selectedProductType?.id;
      }

      if (this.selectedOrderStatus?.value) {
        (params as OrderItemsRequest).OrderStatus = this.selectedOrderStatus.value;
      }

      if (this.minPrice) {
        params.MinPrice = this.minPrice;
      }

      if (this.maxPrice) {
        params.MaxPrice = this.maxPrice;
      }

      return params;
    },
    countLabel(): string {
      return this.$tc('modal.listExportModal.footerEntityListCount', this.totalCount, {
        entity: this.$t(`modal.listExportModal.${this.entityType}Entity`).toString(),
      }).toString();
    },
  },
  methods: {
    async onOpenModalFilters(entityType: ExportListEntityType) {
      this.entityType = entityType;

      if (this.entityType === 'project') {
        this.setDefaultFilterValues(vxManager.projectsModule.projectGetParams);
      } else {
        this.setDefaultFilterValues(vxManager.orderModule.getOrderParams);
      }
      this.skipGetListCount = false;
      (this.$refs.listExportModal as any).show();
    },
    onCloseModalFilters() {
      this.skipGetListCount = true;
      this.clearFilters();
    },
    async onGenerateCsv() {
      this.closeDateRangeModal();
      try {
        let infoText = '';
        if (this.entityType === 'project') {
          infoText = this.$t('export.projectList').toString();
          await GenerateCsvService.getProjectListAsCsv(this.params);
        } else {
          infoText = this.$t('export.orderList').toString();
          await GenerateCsvService.getOrderListAsCsv(this.params);
        }
        Toast.success({ body: infoText });
      } catch {
        Toast.error({ body: this.$t('modal.listExportModal.exportCsvFailedError') });
      } finally {
        this.skipGetListCount = true;
        this.clearFilters();
      }
    },

    setDefaultFilterValues(storeParams: ProjectsGetParams | OrderItemsRequest) {
      this.showDateRangePicker = false;
      if (!!storeParams.ProductTypes || storeParams.ProductTypes === 0) {
        this.selectedProductType = this.getProductTypes().find(x => x.value.id === storeParams.ProductTypes)?.value;
      }
      const organization =
        this.entityType === 'order'
          ? (storeParams as OrderItemsRequest).DistributorId
          : (storeParams as ProjectsGetParams).Distributor;
      if (organization) {
        this.selectedDistributor = this.organizations.find(x => x.id === organization);
      }
      const salesAgency =
        this.entityType === 'order'
          ? (storeParams as OrderItemsRequest).SalesAgencyId
          : (storeParams as ProjectsGetParams).SalesAgency;
      if (salesAgency) {
        this.selectedSalesAgency = this.salesAgencies.find(x => x.id === salesAgency);
      }

      this.minPrice = storeParams.MinPrice ?? undefined;
      this.maxPrice = storeParams.MaxPrice ?? undefined;

      if (this.entityType === 'order') {
        const orderStatus = (storeParams as OrderItemsRequest).OrderStatus;
        if (orderStatus) {
          this.selectedOrderStatus = ALL_ORDER_STATUS.find(x => x.value === orderStatus);
        }
        this.startDate = (storeParams as OrderItemsRequest).shipStartDate ?? '';
        this.endDate = (storeParams as OrderItemsRequest).shipEndDate ?? '';
      } else {
        this.startDate = (storeParams as ProjectsGetParams).DateStart ?? '';
        this.endDate = (storeParams as ProjectsGetParams).DateEnd ?? '';
      }
    },
    clearFilters() {
      this.closeDateRangeModal();
      this.clearProductType();
      this.clearSalesAgency();
      this.clearOrganization();
      this.clearOrderStatus();
      this.startDate = undefined;
      this.endDate = undefined;
      this.minPrice = undefined;
      this.maxPrice = undefined;
      this.totalCountLabel = '...';
    },
    clearProductType() {
      this.selectedProductType = undefined;
    },
    clearSalesAgency() {
      this.selectedSalesAgency = undefined;
    },
    clearOrganization() {
      this.selectedDistributor = undefined;
    },
    clearOrderStatus() {
      this.selectedOrderStatus = undefined;
    },
    getProductTypes(): FilteredSelectorItem[] {
      return GetAllowedProductTypes();
    },
    minPriceInput(minPrice: number) {
      this.minPrice = minPrice;
    },
    maxPriceInput(maxPrice: number) {
      this.maxPrice = maxPrice;
    },
    clearDateRangeValue() {
      this.startDate = undefined;
      this.endDate = undefined;
    },
    setShowDateRangePicker(newState: boolean) {
      this.showDateRangePicker = newState;
    },
    openDateRangeModal() {
      (this.$refs.filtersDateRangeModal as any).show();
    },
    closeDateRangeModal() {
      (this.$refs.filtersDateRangeModal as any)?.clearDates();
      (this.$refs.filtersDateRangeModal as any)?.hide();
    },
    startDateInput(startDate: Date) {
      this.startDate = startDate ? startDate?.toISOString() : undefined;
    },
    endDateInput(enDate: Date) {
      this.endDate = enDate ? enDate?.toISOString() : undefined;
    },
    async getListCount() {
      this.loading = true;
      this.hasCountLimitError = false;

      try {
        const params = this.params;
        if (this.entityType === 'project') {
          this.totalCount = await ProjectService.getProjectCount(params as ProjectsGetParams);
        } else {
          this.totalCount = await OrdersService.getOrdersCount(params as OrderItemsRequest);
        }
        this.hasCountLimitError = this.totalCount > 10000;
        this.totalCountLabel = this.countLabel;
      } catch {
        Toast.error({ body: this.$t('modal.listExportModal.fetchListCountError') });
      } finally {
        this.loading = false;
      }
    },
    refreshEntitiesCount: debounce(function (this: any) {
      this.getListCount();
    }, CODE_UPDATE_DEBOUNCE),
  },
  watch: {
    params() {
      if (this.skipGetListCount) {
        this.skipGetListCount = false;
        return;
      }
      this.refreshEntitiesCount();
    },
  },
});
