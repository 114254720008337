import DistributorsService from '@/endpoints/distributorsService';
import HydrateService from '@/endpoints/hydrateService';
import { UserData } from '@/models/signIn/signInResponse';
import { vxManager } from '@/store/store';
import PermissionsHelper from './permissionsHelper';

export default class StoreHelper {
  public static async hydrateStore(userData?: UserData) {
    if (!vxManager.appStateModule.getIsHydrated) {
      // We've reached the maximum amount of calls that we could put inside this Promise all
      const initialHydrate: Array<Promise<any>> = [vxManager.appStateModule.fetchState(), this.fetchStaticData()];

      if (process.env.VUE_APP_ORGANIZATIONS_ROUTER_HOOK_ENABLED === 'false') {
        if (vxManager.userModule.getIsLoggedIn) {
          initialHydrate.push(DistributorsService.getAllOrganizations());
        }
      }

      Promise.all(initialHydrate)
        .then(async () => {
          if (process.env.VUE_APP_ORGANIZATIONS_ROUTER_HOOK_ENABLED === 'false') {
            // The permissions checks often require the user's organizations.
            // We delay executing some permission checks until the organizations are in store.
            PermissionsHelper.runQueue();
          }

          // These content views can be loaded after that the app is considerated as 'hydrated'
          vxManager.userModule.fetchUserContentViews();

          if (!userData) {
            await Promise.all([
              vxManager.userSettingsModule.fetchUserSettings(),
              vxManager.userSettingsModule.fetchToolbarSettings(),
            ]);
          }
        })
        .then(async () => {
          await vxManager.appStateModule.changeHydrateState(true);
        });
    }
  }

  public static async fetchStaticData(): Promise<void> {
    const data = await HydrateService.getStaticData();
    if (data) {
      vxManager.projectSettingsModule.hydrateProject(data.projectSettingsStaticData);
      vxManager.roomDesignerModule.hydrateRoomDesign(data.drawingPropertiesStaticData);
      vxManager.roomsModule.setRoomLayouts(data.roomLayouts);
      vxManager.provincesModule.setProvinces(data.provinces);
      vxManager.roomScreenModule.setScreenTypes(data.screenTypeOptions);
      vxManager.userSettingsModule.hydrateUserSettings(data.userSettingsStaticData);
      vxManager.projectPartsModule.setupPartTypes(data.partTypes);
      vxManager.lockersModule.fetchStaticLockersData();
      vxManager.expediteRequestsModule.setDisplayedExpediteRequestStatusData(data.displayedExpediteRequestStatus);
    }
  }

  public static async clearUserDataFromStore() {
    Promise.all([
      vxManager.projectsModule.clearState(),
      vxManager.quotesModule.clearState(),
      vxManager.orderModule.clearState(),
      vxManager.roomsModule.clearState(),
      vxManager.userModule.clearState(),
      vxManager.userSettingsModule.clearState(),
      vxManager.roomDesignerModule.clearState(),
      vxManager.projectPartsModule.clearState(),
      vxManager.appStateModule.clearState(),
      vxManager.roomScreenModule.clearState(),
    ]);
  }
}
