import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "h-toast" }
const _hoisted_2 = {
  key: 0,
  class: "h-toast__title"
}
const _hoisted_3 = {
  key: 1,
  class: "h-toast__body"
}
const _hoisted_4 = {
  key: 1,
  class: "h-toast__body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_iconChevronRight = _resolveComponent("iconChevronRight")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!!_ctx.icon || !!_ctx.title)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["h-toast__header", { 'h-toast__header--icon-right': !!_ctx.icon && _ctx.iconPosition === 'right' }])
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), { class: "h-toast__icon" })),
          (!!_ctx.title)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.title), 1))
            : _createCommentVNode("", true),
          (!!_ctx.body && _ctx.displayBodyInHeader)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.body), 1))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    (!!_ctx.body && !_ctx.displayBodyInHeader)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.body), 1))
      : _createCommentVNode("", true),
    (!!_ctx.link)
      ? (_openBlock(), _createElementBlock("button", {
          key: 2,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickLink && _ctx.onClickLink(...args))),
          class: "h-toast__link"
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.linkText), 1),
          _createVNode(_component_iconChevronRight)
        ]))
      : _createCommentVNode("", true)
  ]))
}