import LockerRoomConfiguration from './lockerRoomConfiguration';

export interface LockerTrimModel {
  quantity: number;
  width: number;
}

export interface LockerTrimAndFillers {
  starterTrims: Record<number, number>;
  starterTrimsFollowRda: boolean;
  expansionTrims: Record<number, number>;
  expansionTrimsFollowRda: boolean;
  uFillerPanels: Record<number, number>;
  dressEnds: number;
  dressEndsFollowRda: boolean;
  recessTopTrim60: number;
  recessSplicePlates: number;
  recessSideTrimPairs: number;
}

export class LockerTrimAndFillersViewModel {
  static fromData(config: LockerRoomConfiguration): LockerTrimAndFillersViewModel {
    const viewModel = new this();
    viewModel._starterTrims = Object.entries(config.trimAndFillers.starterTrims).map(([width, quantity]) => ({
      width: parseInt(width, 10),
      quantity: quantity as number,
    }));
    viewModel._expansionTrims = Object.entries(config.trimAndFillers.expansionTrims).map(([width, quantity]) => ({
      width: parseInt(width, 10),
      quantity: quantity as number,
    }));
    viewModel._uFillerPanels = Object.entries(config.trimAndFillers.uFillerPanels).map(([width, quantity]) => ({
      width: parseInt(width, 10),
      quantity: quantity as number,
    }));
    viewModel._dressEndsAreDirty = !config.trimAndFillers.dressEndsFollowRda;
    viewModel._starterTrimsAreDirty = !config.trimAndFillers.starterTrimsFollowRda;
    viewModel._expansionTrimsAreDirty = !config.trimAndFillers.expansionTrimsFollowRda;
    viewModel._showDressEnds = !!config.trimAndFillers.dressEnds;

    viewModel._dressEnds = config.trimAndFillers.dressEnds;

    viewModel._recessTopTrim60 = config.trimAndFillers.recessTopTrim60;
    viewModel._recessSplicePlate = config.trimAndFillers.recessSplicePlates;
    viewModel._recessSideTrim = config.trimAndFillers.recessSideTrimPairs;

    viewModel._showRecessedOptions =
      !!config.trimAndFillers.recessSplicePlates ||
      !!config.trimAndFillers.recessSideTrimPairs ||
      !!config.trimAndFillers.recessTopTrim60;
    return viewModel;
  }

  private _starterTrims: LockerTrimModel[];
  private _starterTrimsAreDirty: boolean;
  private _expansionTrims: LockerTrimModel[];
  private _expansionTrimsAreDirty: boolean;
  private _uFillerPanels: LockerTrimModel[];
  private _showDressEnds: boolean;
  private _dressEnds: number;
  private _dressEndsAreDirty: boolean;
  private _showRecessedOptions: boolean;
  private _recessTopTrim60: number;
  private _recessSplicePlate: number;
  private _recessSideTrim: number;

  constructor() {
    this.starterTrims = [];
    this.starterTrimsAreDirty = false;
    this.expansionTrims = [];
    this.expansionTrimsAreDirty = false;
    this.uFillerPanels = [];
    this.showDressEnds = false;
    this.dressEnds = 0;
    this.dressEndsAreDirty = false;
    this.showRecessedOptions = false;
    this.recessTopTrim60 = 0;
    this.recessSplicePlate = 0;
    this.recessSideTrim = 0;
  }

  public get starterTrims(): LockerTrimModel[] {
    return this._starterTrims;
  }

  public set starterTrims(value: LockerTrimModel[]) {
    this._starterTrims = value;
  }

  public get starterTrimsAreDirty(): boolean {
    return this._starterTrimsAreDirty;
  }

  public set starterTrimsAreDirty(value: boolean) {
    this._starterTrimsAreDirty = value;
  }

  public get expansionTrims(): LockerTrimModel[] {
    return this._expansionTrims;
  }

  public set expansionTrims(value: LockerTrimModel[]) {
    this._expansionTrims = value;
  }

  public get expansionTrimsAreDirty(): boolean {
    return this._expansionTrimsAreDirty;
  }

  public set expansionTrimsAreDirty(value: boolean) {
    this._expansionTrimsAreDirty = value;
  }

  public get uFillerPanels(): LockerTrimModel[] {
    return this._uFillerPanels;
  }

  public set uFillerPanels(value: LockerTrimModel[]) {
    this._uFillerPanels = value;
  }

  public get showDressEnds(): boolean {
    return this._showDressEnds;
  }

  public set showDressEnds(value: boolean) {
    this._showDressEnds = value;
  }

  public get dressEnds(): number {
    return this._dressEnds;
  }

  public set dressEnds(value: number) {
    this._dressEnds = value;
  }

  public get dressEndsAreDirty(): boolean {
    return this._dressEndsAreDirty;
  }

  public set dressEndsAreDirty(value: boolean) {
    this._dressEndsAreDirty = value;
  }

  public get showRecessedOptions(): boolean {
    return this._showRecessedOptions;
  }

  public set showRecessedOptions(value: boolean) {
    this._showRecessedOptions = value;
  }

  public get recessTopTrim60(): number {
    return this._recessTopTrim60;
  }

  public set recessTopTrim60(value: number) {
    this._recessTopTrim60 = value;
    this.recessSplicePlate = value;
  }

  public get recessSplicePlate(): number {
    return this._recessSplicePlate;
  }

  public set recessSplicePlate(value: number) {
    this._recessSplicePlate = value;
  }

  public get recessSideTrim(): number {
    return this._recessSideTrim;
  }

  public set recessSideTrim(value: number) {
    this._recessSideTrim = value;
  }

  public partialUpdate(changes: Partial<LockerTrimAndFillersViewModel>) {
    Object.assign(this, changes);
  }
}
