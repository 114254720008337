import Events from '@/events';
import cancel from '@/shared/icons/cancel/cancel.vue';
import checkmark from '@/shared/icons/checkmark/checkmark.vue';
import edit from '@/shared/icons/edit/edit.vue';
import { debounce } from 'lodash';
import { defineComponent } from 'vue';

const DEFAULT_AUTO_SAVE_DURATION_MS = 3000;

export default defineComponent({
  name: 'hadrianEditableField',
  props: {
    value: {
      type: String,
      required: true,
    },
    isHeader: {
      type: Boolean,
    },
    hasTitle: {
      type: Boolean,
    },
    maximumInputLength: {
      type: Number,
      default: 255,
    },
    showMaxLengthLabel: {
      type: Boolean,
      default: false,
    },
    // If `true`, the save event will be emitted after three seconds of activity.
    autoSave: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isInEditMode: false,
      currentEditValue: '',
      isDirty: false,
    };
  },
  components: {
    edit,
    cancel,
    checkmark,
  },
  created() {
    this.currentEditValue = this.value;
  },
  methods: {
    toggleEditMode() {
      if (!this.isInEditMode) {
        this.currentEditValue = this.value;
      }
      // This is a hack to change the execution order.
      // The click outside was being triggered right after the onClick
      // Using setTimeout 0 makes the click outside happen before the toggling
      // is executed.
      setTimeout(() => {
        this.isInEditMode = !this.isInEditMode;
      }, 0);
    },
    saveEditedValue() {
      if (!this.isInEditMode) {
        return;
      }
      this.toggleEditMode();

      if (this.isDirty) {
        this.isDirty = false;
        this.$emit(Events.editableFieldSaved, { field: this.currentEditValue.slice(0, this.maximumInputLength) });
      }
    },
    debouncedSave: debounce(function (this: any) {
      this.saveEditedValue();
    }, DEFAULT_AUTO_SAVE_DURATION_MS),
  },
  watch: {
    isInEditMode() {
      this.$emit('statusChanged', this.isInEditMode);
    },
    currentEditValue() {
      if (this.maximumInputLength && this.currentEditValue != null) {
        this.currentEditValue = this.currentEditValue.slice(0, this.maximumInputLength);
      }

      this.isDirty = this.currentEditValue !== this.value;

      if (this.autoSave) {
        this.debouncedSave();
      }
    },
  },
});
