import HttpService from '@/endpoints/HttpService';
import urlBuilder from '@/helpers/urlBuilder';
import DryersData from '@/models/dryers/dryersData';

export enum DryersPath {
  Defaults = '/defaults',
  Static = '/static',
}

const dryersUrl = `${process.env.VUE_APP_API_PREFIX}dryers`;

export default class DryersService {
  public static async getStaticDryersData(projectId: string): Promise<DryersData> {
    const url = urlBuilder(this.makeUrl(DryersPath.Static), { projectId });
    const response = await HttpService.get(url);
    return response.data;
  }

  private static makeUrl(path: DryersPath): string {
    return `${dryersUrl}${path}`;
  }
}
