<template>
  <svg
    class="hadrian-icons chevron"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 6 12"
    style="enable-background: new 0 0 6 12"
    xml:space="preserve"
  >
    <polygon class="st0" points="5.9,1 5.1,0 0,5.8 5.2,12 6,11 1.7,5.9 " />
  </svg>
</template>
