import { AxiosResponse } from 'axios';

interface MiniProfilerEntryBase {
  profileId: string;
  requestUrl: string;
  requestDuration: number;
}

export class MiniProfilerEntry implements MiniProfilerEntryBase {
  readonly profileId: string;
  readonly requestDuration: number;
  readonly requestUrl: string;
  readonly requestUrlObject: URL;
  readonly requestPath: string;
  readonly profileUrl: string;

  constructor(base: MiniProfilerEntryBase) {
    this.profileId = base.profileId;
    this.requestUrl = base.requestUrl;
    this.requestDuration = base.requestDuration;
    this.requestUrlObject = new URL(this.requestUrl);

    this.requestPath = `${this.requestUrlObject.pathname}${this.requestUrlObject.search}`;
    this.profileUrl = `${process.env.VUE_APP_SERVER_URL}/profiler/results?id=${this.profileId}`;
  }
}

let savedRequests = [] as MiniProfilerEntry[];

export const MiniProfilerHelper = {
  getProfilerEntries: (): MiniProfilerEntry[] => {
    return savedRequests;
  },
  saveRequest: (response: AxiosResponse) => {
    const profileHeader = response?.headers?.['x-miniprofiler-ids'];
    if (typeof profileHeader !== 'string' || typeof response.request?.responseURL !== 'string') {
      return;
    }

    const profiles = JSON.parse(profileHeader) as unknown;
    if (!Array.isArray(profiles) || !profiles.length || typeof profiles[profiles.length - 1] !== 'string') {
      return;
    }

    const requestProfileId = profiles[profiles.length - 1] as string;
    const requestStart = (response.config as any)?.meta?.requestStartedAt ?? 0;
    const profile: MiniProfilerEntryBase = {
      requestUrl: response.request.responseURL,
      profileId: requestProfileId,
      requestDuration: new Date().getTime() - requestStart,
    };

    savedRequests.push(new MiniProfilerEntry(profile));
  },
  clearData: (): void => {
    savedRequests = [];
  },
};
