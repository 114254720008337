import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cancel = _resolveComponent("cancel")!
  const _component_search = _resolveComponent("search")!
  const _component_hadrianTooltip = _resolveComponent("hadrianTooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_hadrianTooltip, {
      class: _normalizeClass({ 'search-input__tooltip': this.showTooltip, 'search-input__tooltip-reset': !this.showTooltip }),
      tooltipText: _ctx.$t('generic.searchTooltip'),
      anchor: "right",
      verticalPosition: "below",
      enabled: this.showTooltip,
      showNoHover: this.showTooltip
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", {
          class: _normalizeClass(["search-input__wrapper", { 'search-input__wrapper-no-borders': _ctx.noBorders }])
        }, [
          _withDirectives(_createElementVNode("input", {
            name: "search",
            id: "search",
            ref: "input",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            placeholder: _ctx.placeholder,
            onKeydown: _cache[1] || (_cache[1] = ($event: any) => (_ctx.keydownOnItem($event))),
            onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.showTooltipOnBlur && _ctx.showTooltipOnBlur(...args)))
          }, null, 40, _hoisted_1), [
            [
              _vModelText,
              _ctx.search,
              void 0,
              { trim: true }
            ]
          ]),
          (_ctx.search)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "search-input__delete-icon",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.clearFilter())),
                onMousedown: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.noToolTip && _ctx.noToolTip(...args))),
                type: "button"
              }, [
                _createVNode(_component_cancel)
              ], 32))
            : _createCommentVNode("", true),
          _createElementVNode("button", {
            type: "button",
            class: _normalizeClass(["search-input__search-icon-wrapper", { 'search-input__search-icon-wrapper-button': _ctx.search }]),
            onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.emitSearch && _ctx.emitSearch(...args))),
            onMousedown: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.noToolTip && _ctx.noToolTip(...args)))
          }, [
            _createVNode(_component_search)
          ], 34)
        ], 2)
      ], undefined, true),
      _: 1
    }, 8, ["class", "tooltipText", "enabled", "showNoHover"])
  ]))
}