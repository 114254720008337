import EventBus from '@/eventBus';
import Events from '@/events';
import { FilterType } from '@/models/filterBox/filterBox';
import hadrianModal from '@/shared/viewParts/hadrianModal/hadrianModal.vue';
import { defineComponent } from 'vue';
import DatePicker from 'vuejs-datepicker';

export default defineComponent({
  name: 'dateRangeModal',
  props: {
    showPicker: {
      type: Boolean,
    },
    setShowPicker: {
      type: Function,
      required: true,
    },
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
  },
  data() {
    return {
      tempStartDate: this.startDate,
      tempEndDate: this.endDate,
    };
  },
  components: {
    DatePicker,
    hadrianModal,
  },
  created() {
    EventBus.$on(Events.toggleDateModal, this.show);
    EventBus.$on(Events.onDeleteFilter, this.deleteFilter);
  },
  beforeUnmount() {
    EventBus.$off(Events.toggleDateModal, this.hide);
    EventBus.$off(Events.onDeleteFilter, this.deleteFilter);
  },
  methods: {
    show() {
      this.tempStartDate = this.startDate;
      this.tempEndDate = this.endDate;
      this.setShowPicker(true);
      this.modal?.show();
    },
    hide() {
      this.setShowPicker(false);
      this.modal.hide();
    },
    clearDates() {
      (this as any).$refs.startDatePicker.clearDate();
      (this as any).$refs.endDatePicker.clearDate();
    },
    save() {
      this.$emit('startDateInput', this.tempStartDate ? new Date(this.tempStartDate) : undefined);
      this.$emit('endDateInput', this.tempEndDate ? new Date(this.tempEndDate) : undefined);
    },
    saveAndClose() {
      this.save();
      this.hide();
    },
    cancel() {
      this.hide();
      if (this.startDate) {
        (this as any).$refs.startDatePicker.setDate(this.startDate);
      } else {
        (this as any).$refs.startDatePicker.clearDate();
      }
      if (this.endDate) {
        (this as any).$refs.endDatePicker.setDate(this.endDate);
      } else {
        (this as any).$refs.endDatePicker.clearDate();
      }
    },
    deleteFilter(filterType: FilterType) {
      if (filterType === FilterType.DateRange) {
        this.clearDates();
        this.save();
      }
    },
  },
  computed: {
    modal(): any {
      return this.$refs.modal;
    },
    disabledStartDates(): any {
      if (this.isMissingEndDate) {
        return;
      }
      return {
        from: new Date(this.tempEndDate as string),
      };
    },
    disabledEndDates(): any {
      if (this.isMissingStartDate) {
        return;
      }
      return {
        to: new Date(this.tempStartDate as string),
      };
    },
    isMissingStartDate(): boolean {
      return !this.tempStartDate;
    },
    isMissingEndDate(): boolean {
      return !this.tempEndDate;
    },
  },
});
