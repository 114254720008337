import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withKeys as _withKeys } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chevronLeft = _resolveComponent("chevronLeft")!
  const _component_inputGroup = _resolveComponent("inputGroup")!
  const _component_chevronRight = _resolveComponent("chevronRight")!

  return (_openBlock(), _createElementBlock("div", {
    class: "integer-input",
    onKeydown: [
      _cache[2] || (_cache[2] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.decrement && _ctx.decrement(...args)), ["prevent"]), ["arrow-down"])),
      _cache[3] || (_cache[3] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.increment && _ctx.increment(...args)), ["prevent"]), ["arrow-up"]))
    ]
  }, [
    (_ctx.allowButtons)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          type: "button",
          class: "integer-input__button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.decrement && _ctx.decrement(...args))),
          disabled: _ctx.value <= _ctx.minimum || _ctx.disabled
        }, [
          _createVNode(_component_chevronLeft, {
            class: _normalizeClass({ hidden: _ctx.hideChevrons })
          }, null, 8, ["class"])
        ], 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createVNode(_component_inputGroup, {
      class: _normalizeClass(["integer-input__input-group", _ctx.customClasses]),
      value: _ctx.NumberFormatter.formatInteger(_ctx.value),
      onInput: _ctx.onInput,
      transform: _ctx.transformInputValue,
      disabled: _ctx.disabled,
      id: _ctx.id
    }, null, 8, ["class", "value", "onInput", "transform", "disabled", "id"]),
    (_ctx.allowButtons)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          type: "button",
          class: "integer-input__button",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.increment && _ctx.increment(...args))),
          disabled: !_ctx.isIncrementAllowed || _ctx.disabled
        }, [
          _createVNode(_component_chevronRight, {
            class: _normalizeClass({ hidden: _ctx.hideChevrons })
          }, null, 8, ["class"])
        ], 8, _hoisted_2))
      : _createCommentVNode("", true)
  ], 32))
}