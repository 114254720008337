<script src="./dateRangeModal.ts"></script>
<style lang="scss" src="./dateRangeModal.scss"></style>
<style lang="scss" src="./internalPicker.scss"></style>

<template>
  <hadrianModal
    ref="modal"
    class="date-range-modal"
    @left-button-click="cancel()"
    @right-button-click="saveAndClose()"
    :rightButtonText="$t('projects.apply')"
    hideHeader
    forceRenderDom
  >
    <div class="date-range-modal__date-picker">
      <div class="date-range-modal__date-picker-container">
        <div class="date-range-modal__picker-header">
          <h1>Date Range Filter</h1>
          <button type="button" v-on:click="clearDates()" class="date-range-modal__reset-filter">
            {{ $t('projects.resetFilter') }}
          </button>
        </div>

        <div class="date-range-modal__pickers">
          <DatePicker
            class="date-range-modal__range-picker-left"
            v-model="tempStartDate"
            maximumView="day"
            ref="startDatePicker"
            :fullMonthName="true"
            :inline="true"
            :disabled-dates="disabledStartDates"
          />
          <DatePicker
            class="date-range-modal__range-picker-right"
            ref="endDatePicker"
            v-model="tempEndDate"
            maximumView="day"
            :fullMonthName="true"
            :inline="true"
            :disabled-dates="disabledEndDates"
          />
        </div>
      </div>
    </div>
  </hadrianModal>
</template>
