import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.to ? 'router-link' : 'button'), {
    class: "h-option",
    type: "button",
    to: _ctx.to,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click'))),
    disabled: _ctx.disabled
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ], undefined, true),
    _: 3
  }, 8, ["to", "disabled"]))
}