<script src="./organizationSelector.ts"></script>
<style lang="scss" src="./organizationSelector.scss"></style>

<template>
  <filteredSelector
    :nullable="nullable"
    :placeHolder="placeholder"
    :suggestionList="organizations"
    :showLoading="showLoading"
    @focus="fetchOrganizations"
    @select="select"
    :searchMode="SearchMode.Includes"
    :defaultSelectedName="internalValue ? this.formatOrganizationLabel(internalValue) : ''"
    :disabled="isDisabled"
  >
    <template #left-span="{ suggestion }">
      <pill :theme="pillTheme(suggestion.leftSpan)" class="organization-selector__pill">
        {{ suggestion.leftSpan }}
      </pill>
    </template>
  </filteredSelector>
</template>
