import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "price-range-selector__dollar-facet" }
const _hoisted_2 = {
  key: 0,
  class: "price-range-selector__selector-box"
}
const _hoisted_3 = { class: "price-range-selector__input-wrapper" }
const _hoisted_4 = { class: "price-range-selector__inputs" }
const _hoisted_5 = { class: "price-range-selector__input" }
const _hoisted_6 = { class: "price-range-selector__input" }
const _hoisted_7 = { class: "price-range-selector__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_price = _resolveComponent("price")!
  const _component_cancel = _resolveComponent("cancel")!
  const _component_inputGroup = _resolveComponent("inputGroup")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["price-range-selector__wrapper", { 'is-opened': _ctx.isToggled }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        class: "btn price-range-selector__btn-dollar",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleSelector())),
        type: "button"
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["price-range-selector__dollar-icon", { 'price-range-selector__dollar-icon--active': _ctx.minPrice || _ctx.maxPrice || _ctx.isToggled }])
        }, [
          _createVNode(_component_price)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["price-range-selector__selected-price", { 'price-range-selector__selected-price--active': _ctx.minPrice || _ctx.maxPrice }])
        }, _toDisplayString(_ctx.currentLabel), 3)
      ]),
      (_ctx.minPrice || _ctx.maxPrice)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "price-range-selector__clear-filter",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clear(5))),
            type: "button",
            "aria-label": "Clear price range"
          }, [
            _createVNode(_component_cancel)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.isToggled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_inputGroup, {
                  clearable: "",
                  modelValue: _ctx.tempMinPrice,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.tempMinPrice) = $event)),
                  type: "number",
                  label: _ctx.$t('labels.minimum'),
                  placeholder: _ctx.$t('labels.optional')
                }, null, 8, ["modelValue", "label", "placeholder"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_inputGroup, {
                  clearable: "",
                  modelValue: _ctx.tempMaxPrice,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.tempMaxPrice) = $event)),
                  type: "number",
                  label: _ctx.$t('labels.maximum'),
                  placeholder: _ctx.$t('labels.optional')
                }, null, 8, ["modelValue", "label", "placeholder"])
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-round btn-primary-outline",
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.toggleSelector && _ctx.toggleSelector(...args)))
              }, _toDisplayString(_ctx.$t('generic.cancel')), 1),
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-round btn-primary",
                onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.apply && _ctx.apply(...args)))
              }, _toDisplayString(_ctx.$t('generic.apply')), 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}