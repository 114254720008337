import HttpService from '@/endpoints/HttpService';
import PermissionTypes from '@/enums/permissionTypes';
import { requestToQueryString } from '@/helpers/WebServiceHelper';
import urlBuilder from '@/helpers/urlBuilder';
import ShippingAddress from '@/models/addresses/shippingAddress';
import PaginatedResponse from '@/models/paginatedResponse';
import Organization from '@/models/permission/organization';
import User from '@/models/user/user';
import { vxManager } from '@/store/store';
import StringHelper from '@/helpers/stringHelper';

interface IUserOrganizationRequest {
  OrganizationsList: string[];
  RevokeAccess: boolean;
}

export interface DistributorsRequestFilters {
  organizationTypeLabel?: 'Distributors' | 'SalesAgencies' | 'All';
  organizationPermissions?: PermissionTypes[];
}

export interface DistributorAddressesRequestFilters {
  page: number;
  perPage: number;
  changePageToAddressId?: string;
  projectId: string;
  search: string;
}

export interface ValidatedDistributorsAddressesResult extends PaginatedResponse {
  readonly entities: ShippingAddress[];
}

export default class DistributorsService {
  public static async getAllOrganizations(filters?: DistributorsRequestFilters): Promise<Organization[]> {
    const response = await this.getDistributors(filters ?? {});
    // Only write the organizations to the global store if there are no filters applied.
    if (filters == null) {
      vxManager.organizationsModule.setOrganizations(response);
    }
    return response;
  }

  public static async getDistributors(filters: DistributorsRequestFilters): Promise<Organization[]> {
    const cleanedFilters = { ...filters };
    if (!filters.organizationPermissions?.length) {
      delete cleanedFilters.organizationPermissions;
    }

    const query = requestToQueryString(cleanedFilters);
    const res = await HttpService.get(`${process.env.VUE_APP_API_PREFIX}distributors?${query}`);
    return res.data.entities;
  }

  public static async getOrganization(id: string): Promise<Organization> {
    const res = await HttpService.get(`${process.env.VUE_APP_API_PREFIX}distributors/${id}`);
    return res.data.entity;
  }

  public static async getDistributorsAddresses(organizationId: string): Promise<ShippingAddress[]> {
    const { data } = await HttpService.get(
      `${process.env.VUE_APP_API_PREFIX}distributors/${organizationId}/shippingAddresses`
    );

    return data.entities;
  }

  public static async getValidatedDistributorsAddresses(
    organizationId: string,
    filters: DistributorAddressesRequestFilters
  ): Promise<ValidatedDistributorsAddressesResult> {
    const url = urlBuilder(
      `${process.env.VUE_APP_API_PREFIX}distributors/${organizationId}/validatedShippingAddresses`,
      { ...filters }
    );

    const { data } = await HttpService.put(url);

    return data;
  }

  public static async organizationsLength() {
    const allOrganizations = await this.getAllOrganizations();
    return allOrganizations.length ?? 0;
  }

  public static async assignOrganizationsToUser(user: User, orgs: Organization[]): Promise<User> {
    const url = `${process.env.VUE_APP_API_PREFIX}distributors/${user.id}`;
    const data: IUserOrganizationRequest = {
      OrganizationsList: orgs.map(org => org.id),
      RevokeAccess: false,
    };

    return (await HttpService.postWithLoading(url, data)).data.entity;
  }

  public static async revokeUserOrganizationAccess(user: User, org: Organization): Promise<User> {
    const url = `${process.env.VUE_APP_API_PREFIX}distributors/${user.id}`;
    const data: IUserOrganizationRequest = {
      OrganizationsList: [org.id],
      RevokeAccess: true,
    };

    return (await HttpService.postWithLoading(url, data)).data.entity;
  }

  public static async editPrintedInstructions(organizationId: string, printedInstructionsNewPreference: boolean) {
    const formData = new FormData();
    formData.append('printedInstructions', printedInstructionsNewPreference.toString());

    return await HttpService.postWithLoading(
      `${process.env.VUE_APP_API_PREFIX}distributors/${organizationId}/printedinstructions`,
      formData
    );
  }

  public static async saveCustomBranding(
    organization: Organization,
    brandingImageFile: File | undefined,
    websiteAddress: string,
    termsAndConditions: string,
    markup: string
  ) {
    const formData = new FormData();

    if (brandingImageFile) {
      formData.append('brandingImageFile', brandingImageFile, brandingImageFile.name);
    }

    formData.append('websiteAddress', websiteAddress);
    formData.append('markup', markup);

    // make sure to send empty string to BE when the wysiwyg returns just html tags
    const isCustomTermsAndConditionsEmpty = !StringHelper.stripHtml(termsAndConditions);
    const customTermsAndConditionsEditorValue = isCustomTermsAndConditionsEmpty ? '' : termsAndConditions;
    formData.append('termsAndConditions', customTermsAndConditionsEditorValue);

    return await HttpService.postWithLoading(
      `${process.env.VUE_APP_API_PREFIX}distributors/${organization.id}/customBranding`,
      formData
    );
  }

  public static async deleteCustomBrandingImage(organizationId: string) {
    const res = await HttpService.deleteWithLoading(
      `${process.env.VUE_APP_API_PREFIX}distributors/${organizationId}/customBranding`
    );
    return res;
  }

  public static async deleteTermsAndConditions(organizationId: string) {
    const res = await HttpService.deleteWithLoading(
      `${process.env.VUE_APP_API_PREFIX}distributors/${organizationId}/termsAndConditions`
    );
    return res;
  }

  public static async deleteMarkup(organizationId: string) {
    const res = await HttpService.deleteWithLoading(
      `${process.env.VUE_APP_API_PREFIX}distributors/${organizationId}/markup`
    );
    return res;
  }

  public static async generateCustomQuotePreview(
    organizationId: string,
    websiteAddress: string,
    brandingImageFile: File | undefined,
    termsAndConditions: string,
    type: string
  ) {
    const previewTypes = {
      termsAndConditions: false,
      branding: true,
    };

    const formData = new FormData();
    formData.append('showQuoteVersion', previewTypes[type] as any);

    if (brandingImageFile) {
      formData.append('brandingImageFile', brandingImageFile, brandingImageFile.name);
    }

    if (previewTypes[type]) {
      if (websiteAddress) {
        formData.append('websiteAddress', websiteAddress);
      }
    } else {
      formData.append('termsAndConditions', termsAndConditions);
    }

    const url = `${process.env.VUE_APP_API_PREFIX}quotes/${organizationId}/generateCustomQuotePreview`;
    const res = await HttpService.postWithLoading(url, formData);
    return res;
  }
}

export enum OrganizationTypesForFiltering {
  GetAll = 'All',
  Distributor = 'Distributors',
  SalesAgencies = 'SalesAgencies',
}
