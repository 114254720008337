import HadrianBase from '../hadrianBase';
import { ShippingMethodTypes } from './freightInformation';

export default class ShippingMethod extends HadrianBase {
  readonly description?: string;
  readonly label: string;
  readonly isOnlyAvailableInTheUs: boolean;
  readonly hadrianCustomerServiceInterventionReason?: string;
  readonly hasTransitTime: boolean;
}

export enum ShippingMethodIds {
  ThirdPartyBilling = '199a775c-5429-4ece-b1dd-44012422cf2d',
  DryerThirdPartyBilling = '6eecb287-2991-4d46-9f24-a93dfca65438',
  PickUp = '82545866-e485-4e2a-baba-732d55dba3ae',
  Others = '8863c43f-5300-45ae-a2d7-bda88c9a2525',
  FreightCollect = 'eef85399-8e93-44e9-9a6b-dcb0e04b98bd',
  PrePayAndAddFreight = 'f1d79342-8d83-47bd-a515-df0a07b9eae5',
  FedEx = '2779f2bd-61c0-42a1-9711-0c494c0181cd',
  DryerPrePay = '0c062980-f408-4efb-b949-8306fa7e2b34',
  DryerFreeShipping = '43233118-7b3e-4172-ab1f-1b7b15290e9c',
  HadrianDryerPrePay = '47be5c72-1daf-44e8-ba2f-00500ebead87',
  HadrianDryerFreeShipping = 'fba59961-ec03-48ba-88de-30ba6c3e55b1',
  LargeShipment = 'd9c4d013-d3eb-41f9-bf35-3c3b8b5c30c9',
  Special = '4ef8b898-97fd-4ca0-9d47-4c509a3d1203',
}

export const sortedShippingMethodTypes: string[] = [
  ShippingMethodTypes.PrePayAndAddFreight,
  ShippingMethodTypes.FreightCollect,
  ShippingMethodTypes.ThirdPartyBilling,
  ShippingMethodTypes.Others,
  ShippingMethodTypes.PickUp,
  ShippingMethodTypes.FedEx,
  ShippingMethodTypes.DryerPrePay,
  ShippingMethodTypes.DryerFreeShipping,
  ShippingMethodTypes.HadrianDryerPrePay,
  ShippingMethodTypes.HadrianDryerFreeShipping,
];
