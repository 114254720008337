import { vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["disabled", "rows"]
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isMultiline)
    ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
        key: 0,
        class: _normalizeClass(_ctx.inputClasses),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalValue) = $event)),
        onKeydown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onKeyDown && _ctx.onKeyDown(...args))),
        disabled: _ctx.disabled,
        onKeyup: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onKeyUp && _ctx.onKeyUp(...args))),
        onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
        rows: _ctx.numRows
      }, null, 42, _hoisted_1)), [
        [_vModelText, _ctx.internalValue]
      ])
    : _withDirectives((_openBlock(), _createElementBlock("input", {
        key: 1,
        type: "text",
        class: _normalizeClass(_ctx.inputClasses),
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.internalValue) = $event)),
        onKeydown: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onKeyDown && _ctx.onKeyDown(...args))),
        disabled: _ctx.disabled,
        onKeyup: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onKeyUp && _ctx.onKeyUp(...args))),
        onBlur: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args)))
      }, null, 42, _hoisted_2)), [
        [_vModelText, _ctx.internalValue]
      ])
}