import { MiniProfilerEntry, MiniProfilerHelper } from '@/helpers/miniProfilerHelper';
import hadrianModal from '@/shared/viewParts/hadrianModal/hadrianModal.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'profilerModal',
  components: {
    hadrianModal,
  },
  methods: {
    show(): void {
      (this.$refs.modal as any).show();
      this.profilerEntries = MiniProfilerHelper.getProfilerEntries().reverse();
    },
  },
  data() {
    return {
      profilerEntries: [] as MiniProfilerEntry[],
    };
  },
});
