import { DiscountOverride } from '@/models/discounts/discountOverride';
import { DiscountOverrideModificationRequest } from '@/models/discounts/discountOverrideCreateRequest';
import { DiscountResponse } from '@/models/discounts/discountResponse';
import PatchOperation from '@/models/patchOperation/patchOperation';
import HttpService from './HttpService';

export interface CreateDiscountParams {
  projectId: string;
  request: DiscountOverrideModificationRequest;
}

export interface CreateNewDiscountVersionParams extends CreateDiscountParams {
  discountId: string;
}

export interface UpdateDiscountParams {
  projectId: string;
  discountId: string;
  patchOperations: PatchOperation[];
}

const baseUrl = process.env.VUE_APP_API_PREFIX + 'projects';

export default class DiscountsService {
  public static async getProjectDiscountsInfo(projectId: string): Promise<DiscountResponse> {
    return (await HttpService.get(`${baseUrl}/${projectId}/discounts`)).data.entity;
  }

  public static async createProjectDiscount({ projectId, request }: CreateDiscountParams): Promise<DiscountOverride> {
    const response = await HttpService.postWithLoading(`${baseUrl}/${projectId}/discounts`, request);
    return response.data.entity;
  }

  public static async createNewDiscountVersion({
    projectId,
    discountId,
    request,
  }: CreateNewDiscountVersionParams): Promise<DiscountOverride> {
    const response = await HttpService.postWithLoading(`${baseUrl}/${projectId}/discounts/${discountId}`, request);
    return response.data.entity;
  }

  public static async updateProjectDiscount({ projectId, discountId, patchOperations }: UpdateDiscountParams) {
    const response = await HttpService.patchWithLoading(
      `${baseUrl}/${projectId}/discounts/${discountId}`,
      patchOperations
    );
    return response.data.entity;
  }
}
