interface IParams {
  [key: string]: string | number | boolean | undefined | null;
}

export default function urlBuilder(path: string, params: IParams) {
  const stringifiedParams: string = Object.keys(params)
    .map(key => {
      const value = params[key]?.toString() || '';
      return `${key}=${encodeURIComponent(value)}`;
    })
    .join('&');

  return `${path}?${stringifiedParams}`;
}
