const EmailHelper = {
  emailIsPotentiallyValid(email: string) {
    const validationRegex =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(".+"))@(([^<>()\[\].,;:\s@\"]+\.)+[^<>()\[\].,;:\s@"]{2,})$/i;

    return validationRegex.test(email);
  },
  emailIsForHadrianUser(email: string): boolean {
    return /@hadrian-inc\.com/i.test(email);
  },
};

export default EmailHelper;
