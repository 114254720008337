import { defineComponent } from 'vue';

export default defineComponent({
  name: 'paddedContainer',
  props: {
    noHorizontalPadding: {
      type: Boolean,
      default: false,
    },
  },
});
