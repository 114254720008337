declare global {
  interface Window {
    chrome: any;
    safari: any;
  }
}

/**
 * Returns true if the current browser is supported by our platform
 */
function isBrowserSupported(): boolean {
  return [isBrowserAgentSupported(), isFromEntriesSupported(), isFlexGapSupported(), isIntlSupported()].every(Boolean);
}

/**
 * Returns true if CSS FlexGap is supported in the current browser
 */
function isFlexGapSupported(): boolean {
  return document.createElement('div').style.flex !== undefined;
}

/**
 * Returns true if the Intl functions the app uses are supported by the current browser
 */
function isIntlSupported(): boolean {
  return [Intl, Intl?.ListFormat, Intl?.DateTimeFormat].every(Boolean);
}

/**
 * Return true if Object.fromEntries is supported in the current browser
 */
function isFromEntriesSupported(): boolean {
  return 'fromEntries' in Object;
}

/**
 * Returns true if the current browser user agent is supported
 */
function isBrowserAgentSupported(): boolean {
  if (isOperaBrowser() || isEdgeLegacyBrowser()) {
    return false;
  }

  return isChromiumBrowser() || isFirefoxBrowser() || isSafariBrowser() || isSafariMobileBrowser();
}

/**
 * Returns true if the current browser user agent is identified as Chrome/Chromium
 */
function isChromiumBrowser(): boolean {
  return !!window.chrome;
}

/**
 * Returns true if the current browser user agent is identified as Firefox
 */
function isFirefoxBrowser(): boolean {
  return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
}

/**
 * Returns true if the current browser user agent is identified as Opera
 */
function isOperaBrowser(): boolean {
  return (
    navigator.userAgent.toLowerCase().indexOf('opr') > -1 || navigator.userAgent.toLowerCase().indexOf('opera') > -1
  );
}

/**
 * Returns true if the current browser user agent is identified as Edge legacy (non chromium)
 */
function isEdgeLegacyBrowser(): boolean {
  return navigator.userAgent.indexOf('Edge/') > -1;
}

/**
 * Returns true if the current browser user agent is identified as Safari
 */
function isSafariBrowser(): boolean {
  return !!window.safari || navigator.userAgent.toLowerCase().indexOf('safari') > -1;
}

/**
 * Returns true if the current browser user agent is identified as Safari on iOS
 */
function isSafariMobileBrowser(): boolean {
  const isTouchScreenEventPossible = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  return !!navigator.userAgent.match(/iP(ad|hone)/i) || (isSafariBrowser() && isTouchScreenEventPossible);
}

function isAndroidMobileBrowser(): boolean {
  return !!navigator.userAgent.match(/android/i);
}

const BrowserHelper = {
  isBrowserSupported,
  isSafariMobileBrowser,
  isAndroidMobileBrowser,
};

export default BrowserHelper;
