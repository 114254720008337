enum HardwareMaterialTypes {
  StandardChrome,
  StainlessSteel,
  AluminumChannelsBrackets,
  AluminumChannels,
  StainlessChannels,
  PhenolicStainlessSteel,
  PhenolicMatteBlack,
}

export default HardwareMaterialTypes;
