import i18n from '@/i18n';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'browserListSupported',
  data() {
    return {
      i18n,
    };
  },
});
