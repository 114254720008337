import FeedbackService from '@/endpoints/feedbackService';
import ModalEvents from '@/enums/modalEvents';
import EventBus from '@/eventBus';
import Toast from '@/helpers/toast';
import iconFeedback from '@/shared/icons/iconFeedback/iconFeedback.vue';
import iconSuccess from '@/shared/icons/iconSuccess/iconSuccess.vue';
import hadrianLoader from '@/shared/viewParts/hadrianLoader/hadrianLoader.vue';
import hadrianModal from '@/shared/viewParts/hadrianModal/hadrianModal.vue';
import limitedTextarea from '@/shared/viewParts/limitedTextarea/limitedTextarea.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'feedbackModal',
  components: {
    hadrianModal,
    limitedTextarea,
    iconFeedback,
    iconSuccess,
    hadrianLoader,
  },
  data() {
    return {
      subject: '',
      message: '',
      isMailSent: false,
      isLoading: false,
    };
  },
  created() {
    EventBus.$on(ModalEvents.Feedback, this.show);
  },
  beforeUnmount() {
    EventBus.$off(ModalEvents.Feedback, this.show);
  },
  computed: {
    canSendMail(): boolean {
      return !this.subject || !this.message;
    },
    confirmedbuttonText(): string {
      return this.isMailSent ? this.$t('feedbackModal.close').toString() : this.$t('feedbackModal.send').toString();
    },
  },
  methods: {
    show() {
      this.subject = '';
      this.message = '';
      this.isMailSent = false;
      this.isLoading = false;
      (this.$refs.feedbackModal as any).show();
    },
    async onSendMail() {
      if (!this.isMailSent) {
        this.isLoading = true;
        try {
          await FeedbackService.SendFeedback(this.subject, this.message);
          this.isMailSent = true;
        } catch {
          Toast.error({ body: this.$t('feedbackModal.errorMessage') });
          this.isMailSent = false;
        }

        this.isLoading = false;
      }
    },
  },
});
