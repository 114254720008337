<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none">
    <path
      fill="#478ABE"
      fill-rule="evenodd"
      d="M15 30a15.003 15.003 0 0 0 15-15A15.003 15.003 0 0 0 15 0 15.003 15.003 0 0 0 0 15a15.003 15.003 0 0 0 15 15Z"
      clip-rule="evenodd"
    />
    <path
      fill="#fff"
      fill-rule="evenodd"
      d="M16.732 8.188a1.875 1.875 0 1 0-3.464 1.436 1.875 1.875 0 0 0 3.464-1.436ZM15 12.656c-1.035 0-1.875.84-1.875 1.875v6.563a1.875 1.875 0 0 0 3.75 0V14.53c0-1.035-.84-1.875-1.875-1.875Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
