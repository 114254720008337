import { LockerRoomTypes } from '@/enums/lockerRoomTypes';
import { PackagingOptions } from '@/enums/packagingOptions';
import { LockerBaseType } from '@/models/lockers/lockerRoomBases';
import { LockerRoomTopsViewModel, LockerTopType } from '@/models/lockers/lockerRoomTops';
import LockerRoomType from '@/models/lockers/lockerRoomTypes';
import { LockersSeriesIds } from '@/models/lockers/lockersSeries';
import { LockerTrimAndFillersViewModel } from '@/models/lockers/lockerTrimAndFillers';
import Style from '@/models/lockers/style';
import Tier from '@/models/lockers/tier';
import Project from '@/models/project/project';
import { adaCompliantLockerTiers, StyleViewModel } from '@/models/project/projectApiInterfaces';
import Room from '@/models/room/room';
import {
  LockerBankQuantities,
  LockerRoomAssistantModel,
  LockerRoomViewModel,
  Range1to6,
} from '@/models/room/roomApiInterfaces';
import { vxManager } from '@/store/store';
import { sumBy, uniqBy } from 'lodash';
import { BanksCalculator } from './banksCalculator';
import StringHelper from './stringHelper';
import { LockerRoomBasesViewModel } from '@/models/lockers/lockerRoomBases';
import { LockersTypesIds } from '@/models/lockers/type';
import i18n from '@/i18n';
import PackagingOption from '@/models/lockers/packagingOption';

export enum LockersStyleUpdatePromptMessage {
  tiers = 'tiersWarning',
  packagingOption = 'packagingOptionWarning',
  locks = 'locksWarning',
  height = 'heightWarning',
  dfaDo1 = 'dfaDoWarning1',
  dfaDo2 = 'dfaDoWarning2',
  dfaDo3 = 'dfaDoWarning3',
  dfaDo4 = 'dfaDoWarning4',
  dfaDo5 = 'dfaDoWarning5',
}

export interface LockersStyleUpdatePromptData {
  displayPrompt: boolean;
  messages?: LockersStyleUpdatePromptMessage[];
  onConfirm?: () => void;
}

interface StyleUse {
  style?: Style;
  roomTypes: number[];
}
type StyleUseRecord = Record<string, StyleUse>;

export default class LockersHelper {
  public static isSlopeTops(viewModel: LockerRoomViewModel): boolean {
    if (viewModel.topsValues.topType !== LockerTopType.Slope) {
      return false;
    }

    return (
      !!viewModel.topsValues.slopeTop36 ||
      !!viewModel.topsValues.slopeTop48 ||
      !!viewModel.topsValues.slopeTop72 ||
      !!viewModel.topsValues.spliceAssemblies ||
      !!viewModel.topsValues.cornerSlopeTop36 ||
      !!viewModel.topsValues.rightHandGussets ||
      !!viewModel.topsValues.leftHandGussets ||
      !!viewModel.topsValues.supportClips ||
      !!viewModel.topsValues.heavyDutySlopeTops
    );
  }

  public static projectHasLocksWithKeys(project: Project | undefined) {
    if (!project) {
      return false;
    }

    const projectLocks = project.lockersStyles.flatMap(style => style.lock).filter(lock => !!lock);
    const projectLocksWithKey = projectLocks.filter(lock => !!lock?.masterKey || !!lock?.userKey);
    return !!projectLocksWithKey.length;
  }

  public static isFlatTops(viewModel: LockerRoomViewModel): boolean {
    if (viewModel.topsValues.topType !== LockerTopType.Flat) {
      return false;
    }

    return !!viewModel.topsValues.flatTopFillers;
  }

  public static isZBase(viewModel: LockerRoomViewModel): boolean {
    if (viewModel.baseValues.baseType !== LockerBaseType.ZBase) {
      return false;
    }

    return Object.values(viewModel.baseValues.zBase).some(Boolean);
  }

  public static isBoxBase(viewModel: LockerRoomViewModel): boolean {
    if (viewModel.baseValues.baseType !== LockerBaseType.BoxBase) {
      return false;
    }

    return Object.values(viewModel.baseValues.boxBase).some(Boolean);
  }

  public static computeTotalLockersCount(lockerRooms: Room[], styleIdFilter?: number): number {
    return sumBy(lockerRooms, room => this.computeRoomLockersCount(room, styleIdFilter));
  }

  public static computeRoomLockersCount(room: Room, styleIdFilter?: number): number {
    return sumBy(room.lockerRoomConfigurations, config =>
      !styleIdFilter || config.styleId === styleIdFilter ? config.totalLockerQuantity : 0
    );
  }

  public static computeRoomBootracksCount(room: Room): number {
    return sumBy(room.lockerRoomConfigurations, config => config.bootRacksUnitCount);
  }

  public static tierIsAdaCompliant(tier: Tier | null): boolean {
    return tier != null && adaCompliantLockerTiers.includes(tier.id);
  }

  public static getUpdatePromptData(
    oldLockerStyle: Style,
    newLockerStyle: StyleViewModel
  ): LockersStyleUpdatePromptData {
    if (!oldLockerStyle || !newLockerStyle) {
      return { displayPrompt: false };
    }

    const messages: LockersStyleUpdatePromptMessage[] = [];

    if (
      oldLockerStyle.tier != null &&
      !LockersHelper.tierIsAdaCompliant(oldLockerStyle.tier) &&
      LockersHelper.tierIsAdaCompliant(newLockerStyle.tier)
    ) {
      messages.push(LockersStyleUpdatePromptMessage.tiers);
    }

    if (
      newLockerStyle.packagingOption != null &&
      oldLockerStyle.packagingOption != null &&
      oldLockerStyle.packagingOption.id !== newLockerStyle.packagingOption.id
    ) {
      messages.push(LockersStyleUpdatePromptMessage.packagingOption);
    }

    if (!!oldLockerStyle.lock !== !!newLockerStyle.locksProvidedByHadrian) {
      messages.push(LockersStyleUpdatePromptMessage.locks);
    }

    if (
      oldLockerStyle.height != null &&
      newLockerStyle.height != null &&
      oldLockerStyle.height !== newLockerStyle.height &&
      newLockerStyle.height < 60
    ) {
      messages.push(LockersStyleUpdatePromptMessage.height);
    }

    if (
      oldLockerStyle.packagingOption?.id === PackagingOptions.DoorsOnly ||
      newLockerStyle.packagingOption?.id !== PackagingOptions.DoorsOnly
    ) {
      messages.push(LockersStyleUpdatePromptMessage.dfaDo1);
      messages.push(LockersStyleUpdatePromptMessage.dfaDo2);
      messages.push(LockersStyleUpdatePromptMessage.dfaDo3);
      messages.push(LockersStyleUpdatePromptMessage.dfaDo4);
      messages.push(LockersStyleUpdatePromptMessage.dfaDo5);
    }

    const didSeriesChange = oldLockerStyle.lockersSeries?.id !== newLockerStyle.series?.id;
    const didTypeChange = oldLockerStyle.type?.id !== newLockerStyle.type?.id;
    const didWidthChange = oldLockerStyle.width !== newLockerStyle.width;
    const didHeightChange = oldLockerStyle.height !== newLockerStyle.height;

    const displayPrompt = messages.length > 0 || didSeriesChange || didTypeChange || didWidthChange || didHeightChange;

    return { displayPrompt, messages };
  }

  public static reevaluateBankingPreferenceAfterStyleChange(
    style: Style,
    viewModel: LockerRoomViewModel
  ): { value: LockerBankQuantities | null; resetSides: boolean } {
    let banksNeedToBeRecalculated = false;
    Object.entries(viewModel.bankQuantities).forEach(([bq]) => {
      if (!banksNeedToBeRecalculated) {
        const currentBankOption = parseInt(bq, 10) as Range1to6;
        banksNeedToBeRecalculated = !style.compatibleBankSizes?.includes(currentBankOption);
      }
    });

    if (banksNeedToBeRecalculated) {
      const defaultBankSize =
        viewModel.bankPreferenceId && style.compatibleBankSizes?.includes(viewModel.bankPreferenceId)
          ? viewModel.bankPreferenceId
          : style.compatibleBankSizes?.[style.compatibleBankSizes?.length - 1];

      return {
        value: BanksCalculator.defaultBankQuantities({
          totalNumberOfLockers: viewModel.totalNumberOfLockers(),
          defaultBankSize,
        }),
        resetSides: true,
      };
    }

    return { value: viewModel.bankQuantities, resetSides: false };
  }

  /**
   * Adjusts a Locker's configuration to apply the passed in style
   *
   * @param style - Style which will be applied to the Locker configuration
   * @param viewModel - The current state of the Locker room.
   * @param forTests - Variable to allow us to test the helper function
   */
  public static reevaluateLockerConfigurationAfterStyleChange(
    style: Style,
    viewModel: LockerRoomViewModel,
    forTests = false
  ) {
    const viewModelStyle = viewModel.style;
    const isBootRacks = viewModelStyle?.lockersSeries?.id === LockersSeriesIds.BootRacks;
    if (!viewModelStyle || style.id === viewModelStyle.id) {
      return;
    }

    if (!isBootRacks && style.tier != null) {
      viewModel.partialUpdate(this.adjustLockersForTiers(style.tier, viewModel));
    }

    viewModel.partialUpdate(this.adjustLocksAndShelvesForTiers(style, viewModel));
    viewModel.partialUpdate(this.adjustLockersForSeries(style, viewModel));
    viewModel.partialUpdate(this.adjustLockersForTypes(style, viewModel));
    // Since the adjustRecessTrimsForHeight method relies on the store and data coming from the backend
    // it can not currently be tested.
    // https://cortexmedia.atlassian.net/browse/HAD2-3286
    if (!isBootRacks && !forTests) {
      viewModel.trimAndFillers.partialUpdate(this.adjustRecessTrimsForHeight(style.height));
    }
  }

  public static isStyleViewModelValid(viewModel: StyleViewModel): boolean {
    if (!viewModel.series || !viewModel.name || !viewModel.type) {
      return false;
    }

    const isBootRacks = viewModel.series.id === LockersSeriesIds.BootRacks;
    if (isBootRacks) {
      if (
        !viewModel.bootRacksWidth ||
        !viewModel.bootRacksShelvesQuantity ||
        !viewModel.bootRacksFrameFinish ||
        !viewModel.bootRacksShelvesFinish
      ) {
        return false;
      }
    } else {
      if (
        !viewModel.fastener ||
        !viewModel.tier ||
        !viewModel.packagingOption ||
        !viewModel.width ||
        (!viewModel.depth && viewModel.depth !== 0) ||
        !viewModel.height ||
        !viewModel.lockPreparation ||
        !viewModel.plateMaterial ||
        !viewModel.doorFinish ||
        !viewModel.frameFinish
      ) {
        return false;
      }
    }

    return true;
  }

  /**
   * Returns a list of styles from a project that can still be used to create a
   * configuration in the given room.
   *
   * @param room - Room to get styles for.
   * @param projectStyles - Locker Styles in current project.
   * @param allRoomTypes - Static room types data from the database.
   *                       Will generally come from =>
   *                       vxManager.lockersModule.getLockerRoomTypes;
   *
   * @returns {Style[]} - List of styles that are applicable to the room.
   */
  public static getApplicableStylesForLockerRoom(
    room: Room | undefined,
    projectStyles: Style[] | undefined,
    allRoomTypes: LockerRoomType[] | undefined
  ): Style[] {
    if (!room?.lockerRoomConfigurations || !projectStyles || !allRoomTypes) {
      return projectStyles || [];
    }

    const usedRoomTypesPerStyle = room.lockerRoomConfigurations.reduce((acc, config) => {
      const current = acc[config.styleId] || {};
      if (!current.style) {
        current.style = projectStyles.find(x => x.id === config.styleId);
      }

      current.roomTypes = current.roomTypes || [];
      current.roomTypes.push(config.roomType.id);

      acc[config.styleId] = current;
      return acc;
    }, {} as StyleUseRecord);

    const roomTypesForBootRacks = allRoomTypes.filter(x => x.id === LockerRoomTypes.Lockers);
    const roomTypesForDoorOnly = allRoomTypes.filter(x =>
      [LockerRoomTypes.DoorsOnly, LockerRoomTypes.DoorsAndFrames].includes(x.id)
    );

    return projectStyles.filter(style => {
      let applicableRoomTypes = allRoomTypes;
      if (style.lockersSeries.id === LockersSeriesIds.BootRacks) {
        applicableRoomTypes = roomTypesForBootRacks;
      }
      if (style.packagingOption?.id === PackagingOptions.DoorsOnly) {
        applicableRoomTypes = roomTypesForDoorOnly;
      }

      const styleUseRecord = usedRoomTypesPerStyle[style.id] || [];
      return !applicableRoomTypes.every(roomType => styleUseRecord.roomTypes?.find(id => id === roomType.id));
    });
  }

  /**
   * Returns the list of unique locker styles used at least once in a collection of rooms.
   *
   * @param rooms - Array of rooms to extract the styles from.
   * @returns {Style[]} - Array of locker styles without duplicates.
   */
  public static lockerStylesFromRooms(rooms: Room[]): Style[] {
    const lockerRoomConfigs = rooms.flatMap(room => room.lockerRoomConfigurations || []);
    const lockerStyles = lockerRoomConfigs.map(config => config.style).filter(Boolean);
    return uniqBy(lockerStyles as Style[], style => style.id);
  }

  private static adjustRecessTrimsForHeight(height: number): Partial<LockerTrimAndFillersViewModel> {
    const changes: Partial<LockerTrimAndFillersViewModel> = {};
    if (!vxManager.lockersModule.doesHeightAllowRecessTrims(height)) {
      changes.recessSideTrim = changes.recessSplicePlate = changes.recessTopTrim60 = 0;
    }

    return changes;
  }

  private static adjustLockersForTiers(newTier: Tier, viewModel: LockerRoomViewModel): Partial<LockerRoomViewModel> {
    const changes: Partial<LockerRoomViewModel> = {};
    if (newTier.id === viewModel.style?.tier?.id) {
      return {};
    }

    if (this.tierIsAdaCompliant(newTier) && !!viewModel.adaLockerNumericalQuantity) {
      changes.standardLockerNumericalQuantity =
        (viewModel.standardLockerNumericalQuantity || 0) + (viewModel.adaLockerNumericalQuantity || 0);

      changes.standardLockerQuantity = StringHelper.positiveIntegerFormatter(
        changes.standardLockerNumericalQuantity.toString()
      );

      changes.adaLockerNumericalQuantity = 0;
      changes.adaLockerQuantity = '0';
    }

    return changes;
  }

  private static adjustLockersForSeries(style: Style, viewModel: LockerRoomViewModel): Partial<LockerRoomViewModel> {
    const changes: Partial<LockerRoomViewModel> = {};
    if (style.lockersSeries.id === viewModel.style?.lockersSeries.id) {
      return {};
    }

    if (style.lockersSeries.id === LockersSeriesIds.BootRacks) {
      changes.adaLockerNumericalQuantity = 0;
      changes.adaLockerQuantity = '0';
      changes.standardLockerNumericalQuantity = 0;
      changes.standardLockerQuantity = '0';
      changes.bankPreferenceId = null;
      changes.extraShelvesQuantity = '0';
      changes.extraShelvesQuantityFromAda = '0';
      changes.numberExtraSides = '0';
      changes.numberExtraSidesNumeric = 0;
      changes.bankQuantities = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 };
      // Note: Can't call the reset object methods due to changes being a Partial
      changes.rdaValues = new LockerRoomAssistantModel();
      changes.enabledRdaValues = new LockerRoomAssistantModel();
      // Reset topsValues
      changes.topsValues = new LockerRoomTopsViewModel();
      // changes.topsValues
      // Reset trim and fillers
      changes.trimAndFillers = new LockerTrimAndFillersViewModel();
      // Reset base values
      changes.baseValues = new LockerRoomBasesViewModel();
      // Empty extra parts
      changes.extraParts = [];
    } else {
      changes.bootRacksAddersNumericalQuantity = 0;
      changes.bootRacksAddersQuantity = '0';
      changes.bootRacksStartersNumericalQuantity = 0;
      changes.bootRacksStartersQuantity = '0';
      changes.bootRacksNumericalQuantity = 0;
      changes.bootRacksQuantity = '0';
    }

    return changes;
  }

  private static adjustLockersForTypes(style: Style, viewModel: LockerRoomViewModel): Partial<LockerRoomViewModel> {
    const changes: Partial<LockerRoomViewModel> = {};
    const isBootracks = style.lockersSeries.id === LockersSeriesIds.BootRacks;
    if (!isBootracks || style.type.id === viewModel.style?.type.id) {
      return {};
    }

    if (style.type.id === LockersTypesIds.BootracksWallMounted) {
      changes.bootRacksQuantity = '0';
      changes.bootRacksNumericalQuantity = 0;
    } else {
      changes.bootRacksAddersQuantity = '0';
      changes.bootRacksAddersNumericalQuantity = 0;
      changes.bootRacksStartersQuantity = '0';
      changes.bootRacksStartersNumericalQuantity = 0;
    }

    return changes;
  }

  private static adjustLocksAndShelvesForTiers(
    newStyle: Style,
    viewModel: LockerRoomViewModel
  ): Partial<LockerRoomViewModel> {
    const oldTier = viewModel.style?.tier;
    const newTier = newStyle?.tier;

    if (!viewModel.style || !oldTier || !newTier) {
      return {};
    }

    const changes: Partial<LockerRoomViewModel> = {};
    const oldStyleProvidedLocks = !!viewModel.style.lock || !!viewModel.style.adaLock;
    const newStyleProvidesLocks = !!newStyle.lock || !!newStyle.adaLock;

    if (oldTier.id !== newTier.id || (!oldStyleProvidedLocks && newStyleProvidesLocks)) {
      changes.standardLocksQuantity = StringHelper.positiveIntegerFormatter(
        ((viewModel.standardLockerNumericalQuantity || 0) * newTier.id).toString()
      );

      if (!!newStyle.adaLock && this.tierIsAdaCompliant(newTier)) {
        changes.standardLocksQuantity = StringHelper.positiveIntegerFormatter(
          (parseInt(changes.standardLocksQuantity || '0', 10) - viewModel.computedAdaLocksQuantity).toString()
        );
      }
    }

    if (oldTier.id !== newTier.id) {
      if (this.tierIsAdaCompliant(newStyle.tier)) {
        changes.extraShelvesQuantity = '0';
      }
    }

    if (!newStyle.adaLock) {
      changes.adaLocksQuantity = null;
    }

    if (!newStyle.lock) {
      changes.standardLocksQuantity = null;
    }

    return changes;
  }

  public static getPackagingOptionLabel(packagingOption?: PackagingOption): string {
    switch (packagingOption?.id) {
      case PackagingOptions.KnockedDown:
        return i18n.global.t('newQuoteModal.firstScreen.rooms.lockerPackagingOption.knockedDown');
      case PackagingOptions.Assembled:
        return i18n.global.t('newQuoteModal.firstScreen.rooms.lockerPackagingOption.assembled');
      case PackagingOptions.DoorsOnly:
        return i18n.global.t('newQuoteModal.firstScreen.rooms.lockerPackagingOption.doorsOnly');
      default:
        return '';
    }
  }

  /**
   * Adds parentheses around the label if it's not empty.
   */
  public static formatPackagingOptionLabel(packagingOptionLabel: string): string {
    return packagingOptionLabel && `(${packagingOptionLabel})`;
  }
}
