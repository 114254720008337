import PermissionTypes from '@/enums/permissionTypes';
import PermissionsHelper from '@/helpers/permissionsHelper';
import Project from '@/models/project/project';
import { ProjectProductTypes } from '@/models/project/projectApiInterfaces';
import customTabs from '@/shared/viewParts/customTabs/customTabs.vue';
import { ICustomTabItem } from '@/shared/viewParts/customTabs/customTabs';
import { vxManager } from '@/store/store';
import { defineComponent } from 'vue';

export enum ProjectDetailsTabsName {
  Settings = 'settings',
  Rooms = 'rooms',
  Parts = 'parts',
  BOM = 'bom',
  Notes = 'notes',
  Quotes = 'quotes',
  Orders = 'orders',
  Markup = 'markup',
  Discounts = 'discounts',
}

export default defineComponent({
  name: 'projectDetailsTabs',
  components: {
    customTabs,
  },
  props: {
    projectId: {
      type: String,
      required: true,
    },
    selectedTab: {
      type: String,
    },
  },
  computed: {
    project(): Project | undefined {
      return vxManager.projectsModule.projectById(this.projectId);
    },
    canCreateOrder(): boolean {
      if (!this.project || !this.project.distributor) {
        return false;
      }
      return PermissionsHelper.analyzePermissions(PermissionTypes.CreateOrder, this.project.distributor.id);
    },
    canSeeDiscountsOverride(): boolean {
      const permissions = vxManager.userModule.getUserPermissions;
      return !!permissions && permissions[PermissionTypes.Discounts];
    },
    tabs(): ICustomTabItem[] {
      const results: ProjectDetailsTabsName[] = [];

      if (this.project?.productType === ProjectProductTypes.Dryers) {
        results.push(
          ProjectDetailsTabsName.Settings,
          ProjectDetailsTabsName.Parts,
          ProjectDetailsTabsName.BOM,
          ProjectDetailsTabsName.Quotes
        );
      } else {
        results.push(
          ProjectDetailsTabsName.Settings,
          ProjectDetailsTabsName.Rooms,
          ProjectDetailsTabsName.Parts,
          ProjectDetailsTabsName.BOM,
          ProjectDetailsTabsName.Notes,
          ProjectDetailsTabsName.Quotes
        );
      }

      if (this.canCreateOrder) {
        results.push(ProjectDetailsTabsName.Orders);
      }
      if (this.canSeeDiscountsOverride && !vxManager.appStateModule.getIsCounterSalesOn) {
        results.push(ProjectDetailsTabsName.Discounts);
      }
      if (this.project?.distributor?.organizationBranding?.markup != null || this.project?.containsMarkedUpQuotes) {
        results.push(ProjectDetailsTabsName.Markup);
      }

      return results.map(this.makeICustomTabItem);
    },
  },
  methods: {
    changeTab(newTab: string) {
      if (this.selectedTab === newTab) {
        return;
      }
      this.$router.push(`/projects/${this.projectId}/tab/${newTab}`);
    },
    makeICustomTabItem(tab: ProjectDetailsTabsName): ICustomTabItem {
      let productTypeLabel;
      switch (this.project?.productType) {
        case ProjectProductTypes.Partition:
          productTypeLabel = 'partition';
          break;
        case ProjectProductTypes.Lockers:
          productTypeLabel = 'lockers';
          break;
        case ProjectProductTypes.Dryers:
          productTypeLabel = 'dryers';
          break;
      }
      return {
        label: this.$t(`projects.tabs.${productTypeLabel}.${tab}`).toString(),
        value: tab,
      };
    },
  },
});
