import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cae22c02"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "help-box" }
const _hoisted_2 = { class: "help-box__title" }
const _hoisted_3 = { class: "help-box__entry" }
const _hoisted_4 = { href: "mailto:mail@hadrian-inc.com" }
const _hoisted_5 = { class: "help-box__entry help-box__entry--multiline" }
const _hoisted_6 = { class: "help-box__entry-content" }
const _hoisted_7 = { class: "help-box__phone-number" }
const _hoisted_8 = { class: "help-box__subtitle" }
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "help-box__phone-number" }
const _hoisted_11 = { class: "help-box__subtitle" }
const _hoisted_12 = ["href"]
const _hoisted_13 = { class: "help-box__entry" }
const _hoisted_14 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_iconMail = _resolveComponent("iconMail")!
  const _component_iconContact = _resolveComponent("iconContact")!
  const _component_iconWebsite = _resolveComponent("iconWebsite")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h6", _hoisted_2, _toDisplayString(_ctx.$t('mainMenu.help.title')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_iconMail),
      _createElementVNode("a", _hoisted_4, _toDisplayString(_ctx.$t('mainMenu.menu.hadrianMail')), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_iconContact),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('mainMenu.help.callUsa')), 1),
          _createElementVNode("a", {
            href: 'tel:' + _ctx.$t('mainMenu.menu.usaNumber')
          }, _toDisplayString(_ctx.$t('mainMenu.menu.usaNumber')), 9, _hoisted_9)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('mainMenu.help.callCanada')), 1),
          _createElementVNode("a", {
            href: 'tel:' + _ctx.$t('mainMenu.menu.canadaNumber')
          }, _toDisplayString(_ctx.$t('mainMenu.menu.canadaNumber')), 9, _hoisted_12)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createVNode(_component_iconWebsite),
      _createElementVNode("a", {
        target: "_blank",
        href: '//' + _ctx.$t('mainMenu.menu.hadrianWebSite')
      }, _toDisplayString(_ctx.$t('mainMenu.menu.hadrianWebSite')), 9, _hoisted_14)
    ])
  ]))
}