import inputGroup from '@/shared/viewParts/inputGroup/inputGroup.vue';
import sectionHeader from '@/shared/viewParts/sectionHeader/sectionHeader.vue';
import 'ts-replace-all';
import { configureCompat, createApp } from 'vue';
import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import gridContainer from '@/shared/viewParts/gridContainer/gridContainer.vue';
import hadrianLabel from '@/shared/viewParts/hadrianLabel/hadrianLabel.vue';
import paddedContainer from '@/shared/viewParts/paddedContainer/paddedContainer.vue';
import App from './app.vue';
import { formatDate } from './helpers/dateHelper';
import NumberFormatter from './helpers/numberFormatter';
import router from './router';
import hOption from './shared/viewParts/ui/h-options/h-option/h-option.vue';
import hOptions from './shared/viewParts/ui/h-options/h-options.vue';
import hPagination from './shared/viewParts/ui/h-pagination/h-pagination.vue';
import hTimepicker from './shared/viewParts/ui/h-timepicker/h-timepicker.vue';
import hSpinner from './shared/viewParts/ui/h-spinner/h-spinner.vue';
import hTable from './shared/viewParts/ui/h-table/h-table.vue';
import hToastCloseButton from './shared/viewParts/ui/h-toast/closeButton/closeButton.vue';
import { store, vxManager } from './store/store';
import i18n from './i18n';
import hShowmore from './shared/viewParts/ui/h-showmore/h-showmore.vue';
import newRelicService from './services/newRelic';

configureCompat({
  MODE: 2,
});

const pagesWithTitles = new Set([
  'projectDetails',
  'projectDetailsTab',
  'quoteCreation',
  'Order project',
  'Create room',
  'Edit room',
  'Edit a locker room configuration',
  'Add style to  room',
  'Create locker room',
  'Create locker style',
  'Create locker style step',
  'Edit locker style',
  'Edit locker style step',
  'PDF loader',
]);

router.beforeEach((toRoute, _fromRoute, next) => {
  if (!toRoute.name || !pagesWithTitles.has(toRoute.name.toString())) {
    // Cannot user translation helper here, it breaks the PDF page
    window.document.title = 'PROJECT CENTER NEXT';
  }

  next();
});

// Avoid non-finished calls keeping app loading state active when page is refreshed
document.addEventListener('DOMContentLoaded', vxManager.appStateModule.resetLoading);
function beforeUnmount() {
  document.removeEventListener('DOMContentLoaded', vxManager.appStateModule.resetLoading);
}

const app = createApp(App);
app.use(store);
app.use(i18n);
app.use(router);
app.use(beforeUnmount);

app.config.errorHandler = err => {
  if ((window as any).NREUM) {
    // send error details to new relic if available
    (window as any).NREUM.noticeError(err);
  }

  // don't swallow the error so Vue can display meaningful logs in the console
  throw err;
};

app.component('sectionHeader', sectionHeader);
app.component('inputGroup', inputGroup);
app.component('gridContainer', gridContainer);
app.component('paddedContainer', paddedContainer);
app.component('hadrianLabel', hadrianLabel);
app.component('h-table', hTable);
app.component('h-pagination', hPagination);
app.component('h-timepicker', hTimepicker);
app.component('h-showmore', hShowmore);
app.component('h-spinner', hSpinner);
app.component('h-options', hOptions);
app.component('h-option', hOption);

/**
 * DIRECTIVES
 */

/** Usage:
 *
 * To add a "on click outside" event to an element:
 *   <div v-click-outside="doSomething()" />
 */
app.directive('click-outside', {
  mounted(el, binding, _vnode) {
    el.clickOutsideEvent = function (event: MouseEvent) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

/* Usage:
 *
 * To have an element that automatically focuses when inserted into the DOM:
 *
 *   <input v-autofocus />
 *
 * To have an element that conditionally focuses upon insertion, based on the
 *   value of a boolean `foo`:
 *
 *  <input v-autofocus="foo" />
 */
app.directive('autofocus', {
  mounted: (el: HTMLElement, binding) => {
    if (!('value' in binding) || binding.value) {
      el.focus();
    }
  },
});

/**
 * FILTERS
 */
app.config.globalProperties.$filters = {
  formatDate,
  formatCurrency(value: number | string): string {
    if (typeof value !== 'number') {
      return value;
    }

    if (value <= 0) {
      return '-';
    }

    return '$' + NumberFormatter.getNumberAsCurrency(value);
  },
};

app.config.globalProperties.$newRelic = newRelicService;

app.use(Toast, { position: POSITION.BOTTOM_RIGHT, closeOnClick: false, icon: false, closeButton: hToastCloseButton });

app.mixin({
  computed: {
    isCounterSalesOn(): boolean {
      return vxManager.appStateModule.getIsCounterSalesOn;
    },
  },
});

app.mount('#app');
