import DistributorsService from '@/endpoints/distributorsService';
import UserService from '@/endpoints/userService';
import PermissionTypes from '@/enums/permissionTypes';
import { SecurityGroupIds } from '@/enums/securityGroups';
import UserContentTypes from '@/enums/userContentTypes';
import ArrayHelper from '@/helpers/arrayHelper';
import PermissionsHelper, { HadrianPermissions } from '@/helpers/permissionsHelper';
import StoreHelper from '@/helpers/storeHelper';
import urlBuilder from '@/helpers/urlBuilder';
import UserLogoutParams from '@/models/authentication/userLogoutParams';
import Group from '@/models/permission/group';
import Organization from '@/models/permission/organization';
import { UserData } from '@/models/signIn/signInResponse';
import { CreateUserRequest } from '@/models/user/createUserRequest';
import User from '@/models/user/user';
import { UserPatchOperation } from '@/models/user/userPatchOperation';
import { UserListResponse } from '@/models/userList/userList';
import $router from '@/router';
import RouterPaths from '@/router/paths';
import { UserContentViews } from '@/interfaces/userContentViews';
import { action, mutation, Module, VuexModule } from 'vuex-class-component';
import { vxManager } from '../store';
import newRelicService from '@/services/newRelic';

const TOKEN = 'token';
const REFRESH_TOKEN = 'refreshToken';

export interface UsersGetParams {
  Page?: number;
  Search?: string;
  Sort?: string;
  SortBy?: string;
  OrganizationId?: string;
  OnlyHadrianUser?: boolean;
  ActiveUsers?: boolean;
}
@Module()
export class UserModule extends VuexModule {
  private redirectOnLogin: string | undefined = undefined;
  private keepMeLoggedIn = false;
  private storageContainsToken: boolean = !!sessionStorage.getItem(TOKEN) || !!localStorage.getItem(TOKEN);
  private userInfo: User | undefined = undefined;
  private userList: UserListResponse | null = null;
  private userContentViews: UserContentViews[] = [];
  private users: User[] = [];
  private userPermissions: HadrianPermissions | undefined;
  private userParams: UsersGetParams = {} as UsersGetParams;
  private refreshTokenPromise: Promise<boolean> | null;

  @action({ mode: 'mutate' })
  public async clearState(): Promise<void> {
    this.clearUsersData();
  }

  @action({ mode: 'mutate' })
  public async login(userData?: UserData): Promise<boolean> {
    if (await this.buildPermissionScheme(userData)) {
      if (!vxManager.appStateModule.getIsHydrated) {
        StoreHelper.hydrateStore(userData);
      }
      return true;
    }
    return false;
  }

  @action({ mode: 'mutate' })
  public async buildPermissionScheme(userData?: UserData): Promise<boolean> {
    let user: User;
    if (userData) {
      user = userData.user;
      this.setUser(user);
      vxManager.userSettingsModule.setupUserSettings(userData);
    } else {
      user = await this.fetchUser();
    }

    if (!user) {
      return false;
    }
    const status = PermissionsHelper.mapPermissions(user.permissions ? user.permissions : []);
    this.setUserPermissions(status);
    return true;
  }

  @action({ mode: 'mutate' })
  public async logout(params?: UserLogoutParams): Promise<void> {
    this.deleteSessionInfo();
    await StoreHelper.clearUserDataFromStore();

    // No scenario where a logout shouldn't display the login page, so enforce it
    const loginParams = params?.redirectPath ? { redirect: params.redirectPath } : {};
    const loginURL = urlBuilder(RouterPaths.login, loginParams);

    // If already on login page, "replace" just empties the querystring
    const addLoginToHistory = params?.addLoginToHistory ?? true;
    addLoginToHistory ? $router.push(loginURL) : $router.replace(loginURL);
  }

  @action({ mode: 'mutate' })
  public changeKeepMeLoggedIn(value: boolean): Promise<void> {
    return new Promise(resolve => {
      this.setKeepMeLoggedIn(value);
      resolve();
    });
  }

  @action({ mode: 'mutate' })
  public changeRedirectOnLogin(value: string | undefined): void {
    this.setRedirectOnLogin(value);
  }

  @action({ mode: 'mutate' })
  public async fetchUser() {
    const userInfo = await UserService.getUserInfo();
    this.setUser(userInfo);
    return userInfo;
  }

  @action({ mode: 'mutate' })
  public async fetchUserById(userId: string) {
    const userInfo = await UserService.getUserInfoById(userId);
    this.setUserInList(userInfo);
  }

  @action({ mode: 'mutate' })
  public async patchUser(operations: UserPatchOperation): Promise<void> {
    const response = await UserService.patchUser(operations);
    this.setUser(response);
    this.setUserInList(response);
  }

  @action({ mode: 'mutate' })
  public async fetchUserContentViews(): Promise<void> {
    const response = await UserService.fetchUserContentViews(this.getUserInfo?.id as string);
    this.setUserContentViews(response);
  }

  @action({ mode: 'mutate' })
  public async udpateAndFetchUserContentViews(contentType: UserContentTypes): Promise<void> {
    if (!this.getUserInfo) {
      return;
    }

    const res = await UserService.updateAndFetchUserContentViews(this.getUserInfo.id, contentType);
    this.setUserContentViews(res);
  }

  @action({ mode: 'mutate' })
  public async markUserContentViewAsUnread(contentType: UserContentTypes): Promise<void> {
    this.setUserContentViews(
      this.userContentViews.map(v => (v.contentType === contentType ? { ...v, isUpToDate: false } : v))
    );
  }

  @action({ mode: 'mutate' })
  public async patchAnotherUser(operations: UserPatchOperation): Promise<void> {
    const response = await UserService.patchUser(operations);
    this.setUserInList(response);
  }

  @action({ mode: 'mutate' })
  public async fetchUserList(queryParameters: string) {
    const userList = await UserService.getUserList(queryParameters);
    this.setUserList(userList);
  }

  @action({ mode: 'mutate' })
  public async createUser(request: CreateUserRequest): Promise<string> {
    const userResponse = await UserService.createUser(request);
    this.setUserInList(userResponse);
    return userResponse.id;
  }

  @action({ mode: 'mutate' })
  public async sendInvite(userId: string) {
    const userInfo = await UserService.inviteUser(userId);
    this.setUserInList(userInfo);
  }

  @action({ mode: 'mutate' })
  public async updateUserOrganizations({ user, newOrgs }: { user: User; newOrgs: Organization[] }): Promise<User> {
    const updatedUser = await DistributorsService.assignOrganizationsToUser(user, newOrgs);
    this.setUserInList(updatedUser);
    return updatedUser;
  }

  @action({ mode: 'mutate' })
  public async revokeUserAccessToOrganization({ user, org }: { user: User; org: Organization }) {
    this.setUserInList(await DistributorsService.revokeUserOrganizationAccess(user, org));
  }

  @mutation
  public setUserParams(params: UsersGetParams) {
    this.userParams = params;
  }

  @mutation
  public setRefreshTokenPromise(promise: Promise<boolean> | null) {
    this.refreshTokenPromise = promise;
  }

  @mutation
  public setUserContentViews(userContentViews: UserContentViews[]) {
    this.userContentViews = userContentViews;
  }

  get isGodEmperor(): boolean {
    return this.getUserInfo?.group.id === SecurityGroupIds.GodEmperor;
  }

  get getUserContentViews(): UserContentViews[] {
    return this.userContentViews;
  }

  get getUserContentViewByType() {
    return (contentType: UserContentTypes) => {
      return this.getUserContentViews.find(v => v.contentType === contentType);
    };
  }

  get getRefreshTokenPromise(): Promise<boolean> | null {
    return this.refreshTokenPromise;
  }

  get getUserParams(): UsersGetParams {
    return this.userParams;
  }

  get getUserPermissions(): HadrianPermissions | undefined {
    return this.userPermissions;
  }

  get userPermissionsFinishedLoading(): boolean {
    return !!this.userPermissions;
  }

  get hasPendingActivityNotifications(): boolean {
    return !!this.userContentViews && this.userContentViews.some(v => v.isUpToDate === false);
  }

  get hasPermission() {
    return (permission: PermissionTypes): boolean => {
      return !!this.userPermissions && !!this.userPermissions[permission];
    };
  }

  get getUserInfo() {
    return this.userInfo;
  }

  get getUserInfoById() {
    return (id: string) => {
      return this.users.find(user => user.id === id);
    };
  }

  get getUserList() {
    return this.userList;
  }

  get getIsLoggedIn() {
    return !!this.userInfo;
  }

  get getKeepMeLoggedIn() {
    return this.keepMeLoggedIn;
  }

  get getRedirectOnLogin() {
    return this.redirectOnLogin;
  }

  get getStorageContainsToken() {
    return this.storageContainsToken;
  }

  get userCanAccessProjects() {
    return !!this.getUserPermissions && this.getUserPermissions[PermissionTypes.AccessProjects];
  }

  get userCanCreateProjects() {
    return !!this.getUserPermissions && this.getUserPermissions[PermissionTypes.CanCreateProjects];
  }

  get userCanAccessOrganizationsSection() {
    return !!this.getUserPermissions && this.getUserPermissions[PermissionTypes.CanAccessOrganizationsSection];
  }

  get userHasAccessToExpediteRequests(): boolean {
    return (
      !!this.getUserPermissions &&
      (!!this.getUserPermissions[PermissionTypes.CanRequestAnOrderExpediteRequest] ||
        !!this.getUserPermissions[PermissionTypes.CanReviewAnOrderExpediteRequestPlanner])
    );
  }

  get userCanReviewAnOrderExpediteRequestPlanning(): boolean {
    return !!this.getUserPermissions && this.getUserPermissions[PermissionTypes.CanReviewAnOrderExpediteRequestPlanner];
  }

  get userCanRequestAnOrderExpediteRequest(): boolean {
    return !!this.getUserPermissions && this.getUserPermissions[PermissionTypes.CanRequestAnOrderExpediteRequest];
  }

  get userCanCreateUser() {
    if (!this.userInfo) {
      return false;
    }
    return this.userInfo!.group.securityGroupsAvailableForCreation.length > 0 || false;
  }

  get userCanCreatePartitions() {
    return (
      process.env.VUE_APP_SHOW_PARTITIONS === 'true' &&
      !!this.getUserPermissions &&
      this.getUserPermissions[PermissionTypes.CanCreatePartitions]
    );
  }

  get userCanEditPrintedInstructionsPreference() {
    return !!this.getUserPermissions && this.getUserPermissions[PermissionTypes.CanEditPrintedInstructionsPreference];
  }

  get userCanCreateLockers() {
    return (
      process.env.VUE_APP_SHOW_LOCKERS === 'true' &&
      !!this.getUserPermissions &&
      this.getUserPermissions[PermissionTypes.CanCreateLockers]
    );
  }

  get userCanCreateDryers() {
    return (
      process.env.VUE_APP_SHOW_DRYERS === 'true' &&
      !!this.getUserPermissions &&
      this.getUserPermissions[PermissionTypes.CanCreateDryers]
    );
  }

  get userCanOnlyAccessNoneOrOneType() {
    return (
      [this.userCanCreatePartitions, this.userCanCreateLockers, this.userCanCreateDryers].filter(x => x).length <= 1
    );
  }

  get userCanAccessUsersList() {
    return (
      process.env.VUE_APP_SHOW_OTHER_USERS_SECTION === 'true' &&
      !!this.getUserPermissions &&
      this.getUserPermissions[PermissionTypes.AccessUsersList]
    );
  }

  get userCanEditAnotherUser() {
    if (!this.userPermissions || !this.userPermissions[PermissionTypes.EditAnotherUser]) {
      return false;
    }
    return true;
  }

  get userCanManageAllRoles() {
    if (!this.userPermissions || !this.userPermissions[PermissionTypes.ManageAllRoles]) {
      return false;
    }
    return true;
  }

  get userCanOverrideMaxDiscountRate() {
    if (!this.userPermissions || !this.userPermissions[PermissionTypes.CanOverrideMaxDiscountRate]) {
      return false;
    }
    return true;
  }

  get userCanSeeFailedOrders() {
    return !!this.getUserPermissions && this.getUserPermissions[PermissionTypes.CanSeeFailedToUploadStatus];
  }

  get assignableRolesForCurrentUser(): Group[] {
    if (!this.userInfo) {
      return [];
    }
    const allGroups = vxManager.permissionsModule.getPermissionGroups;
    return allGroups.filter(group => this.userInfo!.group.securityGroupsAvailableForCreation.includes(group.id));
  }

  get rolesThatCanBeAssignedToUser() {
    return (user: User): Group[] => {
      return this.assignableRolesForCurrentUser.filter(role => !!user.assignableGroupIds.find(id => id === role.id));
    };
  }

  @mutation private deleteSessionInfo() {
    const itemsToRemove = [TOKEN, REFRESH_TOKEN];
    newRelicService.resetUserId();
    itemsToRemove.forEach(el => {
      sessionStorage.removeItem(el);
      localStorage.removeItem(el);
    });
    sessionStorage.clear();
    this.userInfo = undefined;
    this.storageContainsToken = false;
    localStorage.setItem('hadrian-keepmeloggedin', String(false));
    this.keepMeLoggedIn = false;
    this.userPermissions = undefined;
  }

  @mutation private setKeepMeLoggedIn(value: boolean) {
    localStorage.setItem('hadrian-keepmeloggedin', String(value));
    this.keepMeLoggedIn = value;
  }

  @mutation private setUser(user: User) {
    this.userInfo = user;
    newRelicService.setUserId(user);
    this.storageContainsToken = true;
  }

  @mutation private setUserInList(user: User) {
    ArrayHelper.addOrReplace(this.users, [user]);
  }

  @mutation private setUserPermissions(status: HadrianPermissions) {
    this.userPermissions = status;
  }

  @mutation private setUserList(users: UserListResponse) {
    this.userList = users;
  }

  @mutation private clearUsersData() {
    this.userInfo = undefined;
    this.userList = null;
    this.redirectOnLogin = undefined;
    this.users = [];
  }

  @mutation private setRedirectOnLogin(value: string | undefined) {
    localStorage.setItem('hadrian-redirectonlogin', String(value));
    this.redirectOnLogin = value;
  }
}
