<template>
  <svg
    version="1.1"
    class="hadrian-icons users"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 422 406.6"
    style="enable-background: new 0 0 422 406.6"
    xml:space="preserve"
    fill="currentColor"
  >
    <path
      d="M255.9,94.4c5.6-19.1,23.3-33.1,44.1-33.1c25.4,0,46,20.6,46,46l0,37.3c0,25.4-20.6,46-46,46s-46-20.6-46-46V143l0,0
    c0-1,0.1-26.9,0.1-26.9S253.9,100,255.9,94.4z M284.9,222.6c0-0.4,0.1-0.7,0.1-1.1c0-0.1,0-0.2,0-0.3v-12.4
    c4.9,1.1,9.9,1.8,15.1,1.8c5.2,0,10.3-0.6,15.2-1.8v12.7c0,0.2,0,0.4,0,0.5c0,0,0.2,8.1,16.9,11.4l31.8,7.6
    c22.4,5.4,38.1,25.2,38.1,48.3v57.2h-20v-44.4c0-5.5-4.5-10-10-10s-10,4.5-10,10v44.4h-30.2v-5.1c0-21.9-7.5-42.2-20.4-58.3
    c0,0-9.6-13.5-33.8-26.3c-5.4-2.5-11.1-4.5-17.1-5.9l-31.6-7.6c2.4-1,4.8-1.8,7.4-2.4l31.6-7.6C267.8,233.5,284.9,230.5,284.9,222.6
    z M265,200.5v13.1l-33.5,8c-7.3,1.8-14.2,4.7-20.6,8.6v-14.6h0c14.2-8.5,25.9-20.8,33.5-35.6C249.7,188.3,256.7,195.3,265,200.5z
    M234.1,142.3c-1.2,36.4-31.2,65.7-67.9,65.7c-37.5,0-68-30.5-68-68v-23.1V88c0-37.5,30.5-68,68-68c37.5,0,68,30.5,68,68
    L234.1,142.3z M141,244.9 M191,245 M141,244.9v-20.6c8,2.4,16.4,3.7,25.1,3.7c8.6,0,17-1.3,24.8-3.6V245c0,0,0,10,15.8,13.8l49,11.7
    c33,7.9,56.1,37.1,56.1,71v45.1h0H277v-44c0-5.5-4.5-10-10-10s-10,4.5-10,10v44H121c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0-0.6,0H75.1v-44
    c0-5.5-4.5-10-10-10s-10,4.5-10,10v44H20v-45.1c0-33.9,23-63.1,56-71l49.2-11.8C141,255,141,244.9,141,244.9z M0,346.6v20v30
    c0,5.5,4.5,10,10,10h109.7c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6,0h200.9c5.5,0,10-4.5,10-10v-30H412c5.5,0,10-4.5,10-10v-67.2
    c0-32.3-22-60.2-53.4-67.7l-33.4-8v-13.2c18.5-11.7,30.8-32.3,30.8-55.8v-37.3c0-36.4-29.6-66-66-66c-19.6,0-37.3,8.6-49.4,22.3
    C240.1,26.9,206.2,0,166.1,0h0C126,0,90.7,28.4,81.5,63.8c-1.8,6.8-2.8,12.9-2.8,18.6c0,22.7,0.4,35.5,0.4,35.5s0,14.1,0,25.8
    c0,15.4,2,22.6,8.6,36.2c6.6,13.5,19.1,27.1,33.2,35.5v14.7c0,0,0.5,8.9-17.9,13.2c-15.9,3.7-31.7,7.6-31.7,7.6
    c-25.5,7.7-35,13.9-50.8,32.1 M20.5,283.1C7.6,299.1,0,319.5,0,341.4v5.1"
    />
  </svg>
</template>
