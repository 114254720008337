import { RoomDesignerService } from '@/endpoints/roomDesignerService';
import DoorTypes from '@/enums/doorTypes';
import MaterialCategories from '@/enums/materialCategories';
import PartitionTypes from '@/enums/partitionTypes';
import SeriesTypes from '@/enums/seriesTypes';
import ArrayHelper from '@/helpers/arrayHelper';
import {
  INLINE_ALCOVE_OFFSET_FROM_ADJECENT_UNIT,
  MIN_PILASTER_WIDTH_CEILING_HUNG,
  MIN_WALL_PILASTER_WIDTH_PHENOLIC,
} from '@/helpers/drawing/DrawingConstants';
import { DrawingTypes } from '@/helpers/drawing/factories/DrawingTypes';
import { RoomAnalysisHelper } from '@/helpers/roomDesigner/roomAnalysisHelper';
import HttpResponse from '@/interfaces/HttpResponse';
import { DrawingPropertiesStaticData } from '@/models/drawing/drawingProperties';
import RoomSetting from '@/models/room/roomSetting';
import RdAlcoveOption from '@/models/roomDesigner/rdAlcoveOption';
import RdAlcoveRule from '@/models/roomDesigner/rdAlcoveRule';
import RdAlcoveType from '@/models/roomDesigner/rdAlcoveType';
import RdBracketType, { RdBracketTypeTypes } from '@/models/roomDesigner/rdBracketType';
import RdDepthRestriction from '@/models/roomDesigner/rdDepthRestriction';
import RdDrawingNote, { RdDrawingNoteRequest } from '@/models/roomDesigner/rdDrawingNote';
import RdExtraWideExtensionOption from '@/models/roomDesigner/rdExtraWideExtensionOption';
import RdExtraWidePilasterPositions from '@/models/roomDesigner/rdExtraWidePilasterPositions';
import RdHingeType from '@/models/roomDesigner/rdHingeType';
import RdMountOption from '@/models/roomDesigner/rdMountOption';
import RdPanelPilaster from '@/models/roomDesigner/rdPanelPilaster';
import RdPatchOperations from '@/models/roomDesigner/rdPatchOperations';
import RdPatchResponseData from '@/models/roomDesigner/rdPatchResponseData';
import RdPilaster from '@/models/roomDesigner/rdPilaster';
import RdPilasterOptions from '@/models/roomDesigner/rdPilasterOptions';
import RdRoomData from '@/models/roomDesigner/rdRoomData';
import RdRoomNote from '@/models/roomDesigner/rdRoomNote';
import RdRoomUnit from '@/models/roomDesigner/rdRoomUnit';
import RdUnitExtensionOption from '@/models/roomDesigner/rdUnitExtensionOption';

import { RoomSettingsHelper } from '@/helpers/roomDesigner/roomSettingsHelper';
import { Module, VuexModule, action, mutation } from 'vuex-class-component';
import { vxManager } from '../store';

interface AvailablePilasterWidths {
  widths: number[];
  showInlineWarning: boolean;
}

@Module()
export class RoomDesignerModule extends VuexModule {
  get roomById() {
    return (id: string) => {
      return this.rooms.find(room => room.id === id);
    };
  }

  get roomIsLoading() {
    return this.roomLoadingCount > 0 || vxManager.appStateModule.loading;
  }

  get drawingType() {
    return this.currentDrawingType;
  }

  get getHingeTypes(): RdHingeType[] {
    return this.doorHinges;
  }

  get getDoorSizes() {
    return this.doorSizes;
  }

  get getPlasticDoorSizes() {
    return this.plasticDoorSizes;
  }

  get getPilasterWidths() {
    return (roomSetting: RoomSetting | undefined, minWidth = 0) => {
      if (roomSetting?.material?.category === MaterialCategories.Metal) {
        if (roomSetting.series?.type === SeriesTypes.Standard && roomSetting.partition?.type === PartitionTypes.Hr) {
          return this.metalStdHrWallPilasterWidths.filter(x => x >= minWidth);
        }
        return this.pilasterWidths.filter(x => x >= minWidth);
      }

      if (roomSetting?.material?.category === MaterialCategories.Plastic) {
        if (roomSetting?.partition?.type === PartitionTypes.Hr) {
          return this.plasticPilasterHrWidths.filter(x => x >= minWidth);
        }
        return this.plasticPilasterChFcWidths.filter(x => x >= minWidth);
      }

      if (roomSetting?.material?.category === MaterialCategories.Phenolic) {
        return this.phenolicPilasterWidths.filter(x => x >= minWidth);
      }

      return [];
    };
  }

  get getTPilasterWidths() {
    return this.tPilasterWidths;
  }

  get getPanelRearBracketTypes() {
    return (isIntermediate: boolean, mandatoryChannels: boolean, materialCategory: MaterialCategories) => {
      const brackets = isIntermediate ? this.intermediatePanelRearBracketTypes : this.cornerPanelRearBracketTypes;
      if (mandatoryChannels) {
        return brackets.filter(
          x => x.displayLabel === RdBracketTypeTypes.Channels && x.materialCategory === materialCategory
        );
      }
      return brackets.filter(x => x.materialCategory === materialCategory);
    };
  }

  get getPanelFrontBracketTypes() {
    return (mandatoryChannels: boolean, materialCategory: MaterialCategories) => {
      const brackets = this.panelFrontBracketTypes;
      if (mandatoryChannels) {
        return brackets.filter(
          x => x.displayLabel === RdBracketTypeTypes.Channels && x.materialCategory === materialCategory
        );
      }
      return brackets.filter(x => x.materialCategory === materialCategory);
    };
  }

  get getExtraWideExtensionOptions() {
    return this.extraWideExtensionOptions;
  }

  get getUnitExtensionOptions() {
    return this.unitExtensionOptions;
  }

  get getExtraWidePilasterPositions() {
    return this.extraWidePilasterPositions;
  }

  get getPanelGapOptions() {
    return this.panelGapRangeOptions;
  }

  get getPilasterGapOptions() {
    return this.pilasterGapRangeOptions;
  }

  get getPlasticAluminumGapRangeOptions() {
    return this.plasticAluminumGapRangeOptions;
  }

  get getPlasticStainlessGapRange() {
    return this.plasticStainlessGapRangeOptions;
  }

  get getAlcoveChannelGapOptions() {
    return this.alcoveChannelGapRangeOptions;
  }

  get getWallMounteAlcoveChannelGapOptions() {
    return this.wallMounteAlcoveChannelGapRangeOptions;
  }

  get getWallMounteAlcoveSSChannelGapOptions() {
    return this.wallMounteAlcoveSSChannelGapRangeOptions;
  }

  get getWallMountedPilasterAlcoveChannelGapRange() {
    return this.wallMountedPilasterAlcoveChannelGapRange;
  }

  get getPhenolicBracketGapRangeOptions() {
    return this.phenolicBracketGapRange;
  }

  get getPhenolicChannelGapRangeOptions() {
    return this.phenolicChannelGapRange;
  }

  get getUnitDepthRestrictions() {
    return this.unitDepthRestrictions;
  }

  get getPilasterWallGaps() {
    return this.pilasterWallGaps;
  }

  get getPostWallGaps() {
    return this.postWallGaps;
  }

  get getPilasterSizes() {
    return this.pilasterSizes;
  }

  get getWallPilasterBracketTypes() {
    return (mandatoryChannels: boolean, materialCategory: number, pilasterSize: number): RdBracketType[] => {
      let brackets = this.wallPilasterBracketTypes;

      if (mandatoryChannels) {
        brackets = brackets.filter(x => x.displayLabel === RdBracketTypeTypes.Channels);
      } else if (materialCategory === MaterialCategories.Plastic && pilasterSize <= 3) {
        brackets = brackets.filter(x => x.displayLabel !== RdBracketTypeTypes.Channels);
      }

      return brackets.filter(x => x.materialCategory === materialCategory);
    };
  }

  get getTdPilasterBracketTypes() {
    return (materialCategory: number): RdBracketType[] => {
      const brackets = this.tdPilasterBracketTypes;
      return brackets.filter(x => x.materialCategory === materialCategory);
    };
  }

  get getPostMountOptions() {
    return this.postMountOptions;
  }

  get getWallMountOptions() {
    return this.wallMountOptions;
  }

  get getAlcoveRules() {
    return this.alcoveRules;
  }

  get getAlcoveTypes() {
    return this.alcoveTypes;
  }

  get getAlcoveOptions() {
    return this.alcoveOptions;
  }

  get getPilasterOptions() {
    return this.pilasterOptions;
  }

  get getTemporaryNote() {
    return this.temporaryNote;
  }

  public static minPilasterPanelWidth(
    isLeft: boolean,
    isAlcovePanelPilaster: boolean,
    isCeilingHung: boolean,
    isFloorMounted: boolean,
    isHeadrailBracedPedestalMounted: boolean,
    currentPilaster: RdPanelPilaster | undefined,
    modifiedUnit: RdRoomUnit | undefined,
    roomData: RdRoomData,
    isPhenolic: boolean
  ): number {
    const unit = modifiedUnit;
    if (!unit) {
      return 0;
    }

    const adjacentUnit = isLeft
      ? RoomAnalysisHelper.getPreviousUnit(unit, roomData)
      : RoomAnalysisHelper.getNextUnit(unit, roomData);

    const panel = isLeft ? unit.leftPanel : unit.panel;

    if (isPhenolic && !(isAlcovePanelPilaster && panel && adjacentUnit)) {
      if (isAlcovePanelPilaster && isHeadrailBracedPedestalMounted) {
        return 5;
      }
      return 3;
    }

    let minWidth = 0;
    if (
      (isCeilingHung || isFloorMounted || isHeadrailBracedPedestalMounted) &&
      currentPilaster?.isHingedOnAWall === false
    ) {
      minWidth = Math.max(minWidth, MIN_PILASTER_WIDTH_CEILING_HUNG);
    }

    if (isAlcovePanelPilaster && panel && adjacentUnit) {
      const value =
        adjacentUnit.depth +
        INLINE_ALCOVE_OFFSET_FROM_ADJECENT_UNIT -
        (panel.obstructionDepth || 0) -
        panel.depth -
        panel.wallGap -
        panel.pilasterGap;
      minWidth = Math.max(minWidth, value);
    }
    return minWidth;
  }

  public static getAvailablePanelPilasterWidths(
    pilaster: RdPilaster | undefined,
    restricted3Inches: boolean,
    restricted4Inches: boolean,
    isExtraWidePilaster: boolean,
    isAlcovePanelPilaster: boolean,
    minWidth: number,
    isSingleAlcove: boolean,
    isInlinePilaster: boolean,
    selectedUnitId: string,
    roomSettings: RoomSetting | undefined,
    roomData: RdRoomData
  ): AvailablePilasterWidths {
    const reply = { widths: [] as number[], showInlineWarning: false };
    const minWidthRestriction = this.getMinimumPilasterWidthRestriction(
      pilaster,
      restricted3Inches,
      restricted4Inches,
      isExtraWidePilaster,
      isAlcovePanelPilaster,
      isSingleAlcove,
      selectedUnitId,
      roomSettings,
      roomData,
      reply
    );

    const pilasterWidths = vxManager.roomDesignerModule
      .getPilasterWidths(roomSettings, minWidthRestriction)
      .filter(option => option >= minWidth);
    const tPilasterWidths = vxManager.roomDesignerModule.getTPilasterWidths;

    if (isExtraWidePilaster || isSingleAlcove) {
      reply.widths = !isInlinePilaster ? tPilasterWidths : pilasterWidths;
    } else {
      reply.widths = pilasterWidths;
    }
    return reply;
  }

  private static getMinimumPilasterWidthRestriction(
    pilaster: RdPilaster | undefined,
    restricted3Inches: boolean,
    restricted4Inches: boolean,
    isExtraWidePilaster: boolean,
    isAlcovePanelPilaster: boolean,
    isSingleAlcove: boolean,
    selectedUnitId: string,
    roomSettings: RoomSetting | undefined,
    roomData: RdRoomData,
    reply: AvailablePilasterWidths
  ): number {
    if (roomSettings?.material.category === MaterialCategories.Phenolic) {
      return this.getMinimumPhenolicPilasterWidthRestriction(
        pilaster,
        isExtraWidePilaster,
        roomData,
        selectedUnitId,
        isAlcovePanelPilaster,
        roomSettings?.partition.type === PartitionTypes.Hrpm,
        isSingleAlcove
      );
    }

    if (roomSettings?.material.category === MaterialCategories.Metal) {
      return this.getMinimumMetalPilasterWidthRestriction(
        restricted3Inches,
        restricted4Inches,
        selectedUnitId,
        roomSettings,
        roomData
      );
    }

    if (roomSettings?.material.category === MaterialCategories.Plastic) {
      return this.getMinimumPlasticPilasterWidthRestriction(
        isExtraWidePilaster,
        isAlcovePanelPilaster,
        selectedUnitId,
        roomData,
        reply
      );
    }

    return 5;
  }

  private static getMinimumPhenolicPilasterWidthRestriction(
    pilaster: RdPilaster | undefined,
    isExtraWidePilaster: boolean,
    roomData: RdRoomData,
    selectedUnitId: string,
    isAlcovePanelPilaster: boolean,
    isHeadrailBracedPedestalMounted: boolean,
    isSingleAlcove: boolean
  ): number {
    const fullUnit = roomData.units.filter(x => x.id === selectedUnitId)[0];
    const isUnitExtended = !!fullUnit.extendedDepthExtensions;
    const isUnitExtraWide = !!fullUnit.extraWideExtensions;

    if (
      (isUnitExtended && pilaster?.isLeftConnectedToDoor) ||
      isExtraWidePilaster ||
      (pilaster?.isLeftConnectedToDoor && pilaster?.isRightConnectedToDoor) ||
      (isUnitExtraWide && pilaster?.legend === 'G') ||
      ((isAlcovePanelPilaster || (!!fullUnit.alcoveParts?.offUnitPilaster && !isSingleAlcove)) &&
        isHeadrailBracedPedestalMounted)
    ) {
      return 5;
    }
    return 3;
  }

  private static getMinimumMetalPilasterWidthRestriction(
    restricted3Inches: boolean,
    restricted4Inches: boolean,
    selectedUnitId: string,
    roomSettings: RoomSetting | undefined,
    roomData: RdRoomData
  ): number {
    if (!!roomData && roomSettings?.partition.type === PartitionTypes.Hrpm) {
      const fullUnit = roomData.units.filter(x => x.id === selectedUnitId)[0];
      if (fullUnit?.panel?.panelPilaster?.realHingeCount === 2) {
        return 6;
      }
    }

    return restricted3Inches ? (restricted4Inches ? 5 : 4) : 3;
  }

  private static getMinimumPlasticPilasterWidthRestriction(
    isExtraWidePilaster: boolean,
    isAlcovePanelPilaster: boolean,
    selectedUnitId: string,
    roomData: RdRoomData,
    reply: AvailablePilasterWidths
  ): number {
    if (!!roomData && isExtraWidePilaster) {
      const fullUnit = roomData.units.filter(x => x.id === selectedUnitId)[0];
      if (!!fullUnit && fullUnit.door.doorType === DoorTypes.HasDoor) {
        if (fullUnit.extraWideExtensions?.isRight !== fullUnit.door.isLeftHinge) {
          reply.showInlineWarning = true;
          return 8;
        }
      }
    } else if (!!roomData && isAlcovePanelPilaster) {
      const fullUnit = roomData.units.filter(x => x.id === selectedUnitId)[0];
      if (!!fullUnit && fullUnit.door.doorType === DoorTypes.HasDoor) {
        if (fullUnit.isNextToRightWall === fullUnit.door.isLeftHinge) {
          reply.showInlineWarning = true;
          return 8;
        }
      }
    }

    return 5;
  }

  public static getAvalableWallPilasterWidths(
    isRestricted: boolean,
    isHingedOnAWall: boolean,
    roomSettings: RoomSetting,
    isAlcovePanelPilaster: boolean
  ) {
    const isPhenolic = RoomSettingsHelper.isRoomPhenolic(roomSettings);
    let minWidthRestriction;
    if (isRestricted) {
      minWidthRestriction = 5;
    } else {
      if (isPhenolic && roomSettings.partition?.type === PartitionTypes.Hrpm && isAlcovePanelPilaster) {
        // Phenolic HRPM Alcove Panel Pilasters can't be below 5"
        minWidthRestriction = 5;
      } else {
        if (isHingedOnAWall) {
          minWidthRestriction = 3;
        } else {
          if (isPhenolic) {
            minWidthRestriction = MIN_WALL_PILASTER_WIDTH_PHENOLIC;
          } else {
            minWidthRestriction = 2;
          }
        }
      }
    }

    const pilasterSizes = vxManager.roomDesignerModule.getPilasterWidths(roomSettings, minWidthRestriction);
    return pilasterSizes;
  }

  private static compareBracketTypes(typeA: RdBracketType, typeB: RdBracketType) {
    return typeA.displayOrder - typeB.displayOrder;
  }

  private static compareDoorHinges(typeA: RdHingeType, typeB: RdHingeType) {
    if (typeA.isInSwing !== typeB.isInSwing) {
      return typeA.isInSwing ? -1 : 1;
    }
    if (typeA.isLeftHinge !== typeB.isLeftHinge) {
      return typeA.isLeftHinge ? -1 : 1;
    }
    return 0;
  }

  private rooms: RdRoomData[] = [];
  private roomLoadingCount = 0;
  private doorHinges: RdHingeType[] = [];
  private doorSizes: number[] = [];
  private plasticDoorSizes: number[] = [];
  private postWallGaps: number[] = [];
  private pilasterWallGaps: number[] = [];
  private pilasterSizes: number[] = [];
  private wallPilasterBracketTypes: RdBracketType[] = [];
  private postMountOptions: RdMountOption[] = [];
  private wallMountOptions: RdMountOption[] = [];
  private pilasterWidths: number[] = [];
  private plasticPilasterHrWidths: number[] = [];
  private plasticPilasterChFcWidths: number[] = [];
  private phenolicPilasterWidths: number[] = [];
  private tPilasterWidths: number[] = [];
  private metalStdHrWallPilasterWidths: number[] = [];
  private panelFrontBracketTypes: RdBracketType[] = [];
  private intermediatePanelRearBracketTypes: RdBracketType[] = [];
  private cornerPanelRearBracketTypes: RdBracketType[] = [];
  private extraWideExtensionOptions: RdExtraWideExtensionOption[] = [];
  private unitExtensionOptions: RdUnitExtensionOption[] = [];
  private extraWidePilasterPositions: RdExtraWidePilasterPositions[] = [];
  private panelGapRangeOptions: number[] = [];
  private pilasterGapRangeOptions: number[] = [];
  private plasticAluminumGapRangeOptions: number[] = [];
  private plasticStainlessGapRangeOptions: number[] = [];
  private alcoveChannelGapRangeOptions: number[] = [];
  private unitDepthRestrictions: RdDepthRestriction[] = [];
  private wallMounteAlcoveChannelGapRangeOptions: number[] = [];
  private wallMounteAlcoveSSChannelGapRangeOptions: number[] = [];
  private wallMountedPilasterAlcoveChannelGapRange: number[] = [];
  private alcoveRules: RdAlcoveRule[] = [];
  private alcoveTypes: RdAlcoveType[] = [];
  private alcoveOptions: RdAlcoveOption[] = [];
  private temporaryNote?: Partial<RdRoomNote>;
  private currentDrawingType: DrawingTypes;
  private pilasterOptions: RdPilasterOptions[] = [];
  private phenolicBracketGapRange: number[] = [];
  private phenolicChannelGapRange: number[] = [];
  private tdPilasterBracketTypes: RdBracketType[] = [];

  @action({ mode: 'mutate' })
  public async withRoomLoading<T>(func: () => Promise<T>): Promise<T> {
    this.addRoomLoading();
    try {
      return await func();
    } finally {
      this.removeRoomLoading();
    }
  }

  @action({ mode: 'mutate' })
  public async clearState() {
    this.clearRoomDesignerData();
  }

  @action({ mode: 'mutate' })
  public changeDrawingType(drawingType: DrawingTypes) {
    this.setDrawingType(drawingType);
  }

  @action({ mode: 'mutate' })
  public async patchUnit(patchOperations: RdPatchOperations): Promise<HttpResponse<RdPatchResponseData>> {
    return await this.withRoomLoading(async () => {
      const response = await RoomDesignerService.patchUnit(patchOperations);
      const room = response.data.entity;
      // The room id is not in the response, but we need it for the store
      (room as any).id = patchOperations.roomId;
      for (let i = 0; i < room.notes.length; i++) {
        room.notes[i] = { ...room.notes[i], roomId: room.id };
      }
      this.addRoom(room);
      return response;
    });
  }

  @action({ mode: 'mutate' })
  public async patchAllUnits(patchOperations: RdPatchOperations): Promise<HttpResponse<RdPatchResponseData>> {
    return await this.withRoomLoading(async () => {
      const response = await RoomDesignerService.patchAllUnits(patchOperations);
      const room = response.data.entity;
      // The room id is not in the response, but we need it for the store
      (room as any).id = patchOperations.roomId;
      this.addRoom(room);
      return response;
    });
  }

  @action({ mode: 'mutate' })
  public async pushNewNote(note: Partial<RdRoomNote>) {
    return await this.withRoomLoading(async () => {
      const response = await RoomDesignerService.postNote(note);
      this.deleteTemporaryNote();
      if (note.roomId) {
        const room = this.roomById(note.roomId);
        if (room) {
          this.addRoom({ ...room, notes: response.data.entities });
        }
      }
    });
  }

  @action({ mode: 'mutate' })
  public async deleteNote(note: RdRoomNote) {
    return await this.withRoomLoading(async () => {
      const response = await RoomDesignerService.deleteNote(note);
      if (note.roomId) {
        const room = this.roomById(note.roomId);
        if (room) {
          this.addRoom({ ...room, notes: response.data.entities });
        }
      }
    });
  }

  @action({ mode: 'mutate' })
  public async putNote(note: RdRoomNote) {
    return await this.withRoomLoading(async () => {
      const response = await RoomDesignerService.putNote(note);
      if (note.roomId) {
        const room = this.roomById(note.roomId);
        if (room) {
          this.addRoom({ ...room, notes: response.data.entities });
        }
      }
    });
  }

  @action({ mode: 'mutate' })
  public async createRoomDrawingNotes(request: RdDrawingNoteRequest) {
    return await this.withRoomLoading(async () => {
      if (!request.roomId) {
        return;
      }
      const note = await RoomDesignerService.postDrawingNotes(request);
      const room = this.rooms.find(x => x.id === request.roomId);
      if (room !== undefined) {
        const newNotes: RdDrawingNote[] = [...room.drawingNotes];
        ArrayHelper.addOrReplace(newNotes, note);
        const roomUpdate = {
          ...room,
          drawingNotes: newNotes,
        };
        this.updateRoom(roomUpdate);
      }
    });
  }

  @action({ mode: 'mutate' })
  public async editRoomDrawingNotes(request: RdDrawingNoteRequest) {
    return await this.withRoomLoading(async () => {
      try {
        const room = this.rooms.find(x => x.id === request.roomId);
        if (room == undefined) {
          return;
        }

        ArrayHelper.addOrReplace(room.drawingNotes, [request as RdDrawingNote]);
        const note = await RoomDesignerService.putDrawingNotes(request);
        const newNotes = room.drawingNotes.map(x => (x.id === request.id ? note : x));
        const roomUpdate = {
          ...room,
          drawingNotes: newNotes,
        };
        this.updateRoom(roomUpdate);
      } catch (ex: any) {
        if (ex?.response?.status === 404) {
          this.removeNote({ room: request.roomId, note: request.id! });
        }

        throw ex;
      }
    });
  }

  @action({ mode: 'mutate' })
  public async deleteRoomDrawingNotes(note: RdDrawingNote) {
    return await this.withRoomLoading(async () => {
      try {
        await RoomDesignerService.deleteDrawingNotes(note);
        this.removeNote({ room: note.roomId, note: note.id });
      } catch (ex: any) {
        if (ex?.response?.status === 404) {
          this.removeNote({ room: note.roomId, note: note.id });
        }
        throw ex;
      }
    });
  }

  @action({ mode: 'mutate' })
  public async fetchRoom(roomId: string): Promise<void> {
    return await this.withRoomLoading(async () => {
      const data = await RoomDesignerService.getRoom(roomId);
      this.addRoom(data);
    });
  }

  @action({ mode: 'mutate' })
  public async fetchRoomNoAuth(roomId: string): Promise<void> {
    return await this.withRoomLoading(async () => {
      const data = await RoomDesignerService.getRoomNoAuth(roomId);
      this.addRoom(data);
    });
  }

  @action({ mode: 'mutate' })
  public hydrateRoomDesign(data: DrawingPropertiesStaticData) {
    this.setDrawingProperties(data);
  }

  @mutation
  public setTemporaryNote(note: Partial<RdRoomNote>) {
    this.temporaryNote = { ...note };
  }

  @mutation
  public setDrawingType(drawingType: DrawingTypes) {
    this.currentDrawingType = drawingType;
  }

  @mutation
  public deleteTemporaryNote() {
    this.temporaryNote = undefined;
  }

  @mutation
  public resetRoomLoading() {
    this.roomLoadingCount = 0;
  }

  @mutation
  private addRoomLoading() {
    ++this.roomLoadingCount;
  }

  @mutation
  private removeRoomLoading() {
    --this.roomLoadingCount;
    if (this.roomLoadingCount < 0) {
      this.roomLoadingCount = 0;
      throw new Error('Loading has been dismissed more often than it was triggered. Fix this!');
    }
  }

  @mutation
  private addRoom(value: RdRoomData) {
    ArrayHelper.addOrReplace(this.rooms, [value]);
  }

  @mutation
  private setDrawingProperties(drawingProperties: DrawingPropertiesStaticData) {
    this.doorSizes = drawingProperties.doorSizes;
    this.plasticDoorSizes = drawingProperties.plasticDoorSizes;
    this.doorHinges = drawingProperties.doorHinges.sort(RoomDesignerModule.compareDoorHinges);
    this.panelGapRangeOptions = drawingProperties.panelGapRange;
    this.pilasterGapRangeOptions = drawingProperties.pilasterGapRange;
    this.plasticAluminumGapRangeOptions = drawingProperties.plasticAluminumGapRange;
    this.plasticStainlessGapRangeOptions = drawingProperties.plasticStainlessGapRange;
    this.pilasterWallGaps = drawingProperties.pilasterGapRange;
    this.wallMountedPilasterAlcoveChannelGapRange = drawingProperties.wallMountedPilasterAlcoveChannelGapRange;
    this.wallMounteAlcoveChannelGapRangeOptions = drawingProperties.wallMountedAlcoveChannelGapRange;
    this.wallMounteAlcoveSSChannelGapRangeOptions = drawingProperties.wallMountedAlcoveSSChannelGapRange;
    this.alcoveChannelGapRangeOptions = drawingProperties.alcoveChannelGapRange;
    this.postWallGaps = drawingProperties.postGapRange;
    this.tPilasterWidths = drawingProperties.tPilasterSizes;
    this.wallPilasterBracketTypes = drawingProperties.wallPilasterBracketTypes;
    this.alcoveTypes = drawingProperties.alcoveTypes.sort(lh => (lh.isNextToFrontWall ? -1 : 1));
    this.alcoveOptions = drawingProperties.splitAlcoveOptions;
    this.alcoveRules = drawingProperties.alcovePanelRules;
    this.extraWideExtensionOptions = drawingProperties.extraWideExtensionOptions;
    this.extraWidePilasterPositions = drawingProperties.extraWidePilasterPositions;
    this.unitExtensionOptions = drawingProperties.extensionOptions;
    this.unitDepthRestrictions = drawingProperties.unitDepthRestrictions;
    this.pilasterOptions = drawingProperties.pilasterOptions;
    this.panelFrontBracketTypes = drawingProperties.panelFrontBracketTypes.sort(RoomDesignerModule.compareBracketTypes);
    this.intermediatePanelRearBracketTypes = drawingProperties.intermediatePanelRearBracketTypes.sort(
      RoomDesignerModule.compareBracketTypes
    );
    this.cornerPanelRearBracketTypes = drawingProperties.cornerPanelRearBracketTypes.sort(
      RoomDesignerModule.compareBracketTypes
    );
    this.postMountOptions = drawingProperties.squarePostOptionTypes;
    this.wallMountOptions = drawingProperties.wallMountOptionTypes;
    this.pilasterWidths = drawingProperties.pilasterWidths;
    this.plasticPilasterHrWidths = drawingProperties.plasticPilasterHrWidths;
    this.plasticPilasterChFcWidths = drawingProperties.plasticPilasterChFcWidths;
    this.phenolicPilasterWidths = drawingProperties.phenolicPilasterWidths;
    this.metalStdHrWallPilasterWidths = drawingProperties.metalStdHrWallPilasterWidths;
    this.pilasterSizes = drawingProperties.pilasterWidths;
    this.phenolicBracketGapRange = drawingProperties.phenolicBracketGapRange;
    this.phenolicChannelGapRange = drawingProperties.phenolicChannelGapRange;
    this.tdPilasterBracketTypes = drawingProperties.tdPilasterBracketTypes;
  }

  @mutation
  private clearRoomDesignerData() {
    this.rooms = [];
    this.temporaryNote = undefined;
    this.roomLoadingCount = 0;
  }

  @action({ mode: 'mutate' })
  private removeNote(ids: { room: string; note: string }) {
    const room = this.rooms.find(x => x.id === ids.room);
    if (room !== undefined) {
      const newNotes = room.drawingNotes.filter(x => x.id !== ids.note);
      const roomUpdate = {
        ...room,
        drawingNotes: newNotes,
      };
      this.updateRoom(roomUpdate);
    }
  }

  @mutation
  private updateRoom(room: RdRoomData) {
    ArrayHelper.addOrReplace(this.rooms, [room], 'id');
  }
}
