import { vxManager } from '@/store/store';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'environmentFooter',
  computed: {
    environmentClass(): string {
      return `environment-footer--${vxManager.appStateModule.backendEnvironment}`;
    },
    showFooter(): boolean {
      return vxManager.appStateModule.showEnvironmentFooter;
    },
    userInfo(): string {
      return [vxManager.userModule.getUserInfo?.name, vxManager.userModule.getUserInfo?.group.name]
        .filter(Boolean)
        .join(' - ');
    },
  },
});
