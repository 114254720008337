<template>
  <svg class="checkmark" viewBox="0 0 10.6 7.9">
    <defs />
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M4.8 7.6c-.5.4-1.3.4-1.7 0L.4 4.9c-.5-.5-.5-1.3 0-1.7.5-.5 1.3-.5 1.7 0l1.6 1.6c.1.1.3.1.4 0L8.5.4c.5-.5 1.3-.5 1.7 0 .2.2.4.5.4.9s-.1.6-.4.9L4.8 7.6z"
      class="checkmark__path"
      clip-rule="evenod"
    />
  </svg>
</template>
