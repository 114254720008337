import MaterialIds from '@/enums/materialIds';
import ModalEvents from '@/enums/modalEvents';
import EventBus from '@/eventBus';
import Distributor from '@/models/distributor/distributor';
import { SuggestedPartsModalData } from '@/models/part/suggestedParts';
import { ProjectProductTypes } from '@/models/project/projectApiInterfaces';
import { CancelConfirmModalData } from '@/shared/viewParts/cancelConfirmModal/cancelConfirmModal';
import { CommonErrorModalData } from '@/shared/viewParts/commonErrorModal/commonErrorModal';
import { NotesCreationModalData } from '@/shared/viewParts/notesCreationModal/notesCreationModal';

export function showCancelConfirmModal(data: CancelConfirmModalData) {
  EventBus.$emit(ModalEvents.CancelConfirm, data);
}

export function showCommonErrorModal(data: CommonErrorModalData) {
  EventBus.$emit(ModalEvents.CommonErrorModal, data);
}

export function showCreateNoteModal(data: NotesCreationModalData) {
  EventBus.$emit(ModalEvents.Notes, data);
}

export function showDuplicateAndAssignModal(
  projectId: string,
  projectName: string,
  productType: ProjectProductTypes,
  materialId: MaterialIds | null,
  hasSpecialColor: boolean,
  distributor: Distributor
) {
  EventBus.$emit(
    ModalEvents.ProjectDuplicate,
    projectId,
    projectName,
    productType,
    materialId,
    hasSpecialColor,
    distributor
  );
}

export function showSuggestedPartsModal(data: SuggestedPartsModalData) {
  EventBus.$emit(ModalEvents.SuggestedPartsModal, data);
}
