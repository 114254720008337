import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_checkmark = _resolveComponent("checkmark")!

  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["hadrian-checkbox", {
      'hadrian-checkbox--inverted': _ctx.inverted,
      'hadrian-checkbox--no-margins': _ctx.noMargins,
      'hadrian-checkbox--disabled': _ctx.disabled,
      'hadrian-checkbox--read-only': _ctx.readOnly,
    }])
  }, [
    _createElementVNode("input", {
      type: "checkbox",
      value: _ctx.selected,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggle())),
      class: "hadrian-checkbox__input",
      disabled: _ctx.disabled || _ctx.readOnly
    }, null, 40, _hoisted_1),
    _createElementVNode("div", {
      class: _normalizeClass(["hadrian-checkbox__checkmark", { 'hadrian-checkbox__checkmark--selected': _ctx.selected }])
    }, [
      _createVNode(_component_checkmark)
    ], 2),
    (!!_ctx.label)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["hadrian-checkbox__label", _ctx.labelClass])
        }, _toDisplayString(_ctx.label), 3))
      : _createCommentVNode("", true)
  ], 2))
}