<script src="./dateRangeButton.ts"></script>
<style lang="scss" src="./dateRangeButton.scss"></style>

<template>
  <div class="date-range-button">
    <button class="date-range-button__button" @click="useComponentEvents ? toggleModal() : parentClick()" type="button">
      <calendar
        class="date-range-button__icon"
        :class="{ 'date-range-button__icon--active': showPicker || startDate || endDate }"
      />
      <span
        class="date-range-button__selected-dates"
        :class="{ 'date-range-button__selected-dates--active': showPicker || startDate || endDate }"
      >
        {{ currentLabel }}
      </span>
    </button>

    <button
      v-if="startDate || endDate"
      class="date-range-button__clear-button"
      v-on:click="useComponentEvents ? clearDates() : parentClearDates()"
      type="button"
      arial-label="Clear date range"
    >
      <cancel />
    </button>
  </div>
</template>
