import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "hadrian-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("header", {
      class: _normalizeClass(["hadrian-page__header", _ctx.headerClass])
    }, [
      _renderSlot(_ctx.$slots, "header")
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["hadrian-page__body", {
        [_ctx.bodyClass]: !!_ctx.bodyClass,
        withMaxWidth: !_ctx.fullPageWidth,
        'hadrian-page__body--environment-padding': _ctx.addEnvironmentPadding,
      }])
    }, [
      _renderSlot(_ctx.$slots, "body")
    ], 2)
  ]))
}