import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "input-group__container" }
const _hoisted_2 = { class: "input-group__input-container" }
const _hoisted_3 = {
  key: 0,
  class: "input-group__icon"
}
const _hoisted_4 = {
  key: 1,
  class: "input-group__max-characters"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_hadrianLabel = _resolveComponent("hadrianLabel")!
  const _component_cancel = _resolveComponent("cancel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createBlock(_component_hadrianLabel, {
          key: 0,
          text: _ctx.label,
          mandatory: _ctx.mandatory
        }, null, 8, ["text", "mandatory"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.hasIcon)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "icon")
          ]))
        : _createCommentVNode("", true),
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.numLines > 1 ? 'textarea' : 'input'), {
        ref: "input",
        rows: _ctx.numLines,
        type: _ctx.type,
        value: _ctx.internalValue,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onInput($event.target.value))),
        disabled: _ctx.disabled,
        placeholder: _ctx.placeholder,
        name: _ctx.name,
        id: _ctx.id,
        onBlur: _ctx.onBlur,
        onFocus: _ctx.onFocus,
        onKeyup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('keyup', $event))),
        class: _normalizeClass(["input-group__input", _ctx.inputClasses]),
        autocomplete: _ctx.autocomplete
      }, null, 40, ["rows", "type", "value", "disabled", "placeholder", "name", "id", "onBlur", "onFocus", "class", "autocomplete"])),
      (_ctx.clearable && _ctx.internalValue)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "input-group__delete-icon",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.clearFilter())),
            type: "button"
          }, [
            _createVNode(_component_cancel)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.maxLength && _ctx.showMaxLengthLabel && !_ctx.validationRuleLabel)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('inputSection.maxCharacters', [_ctx.maxLength])), 1))
      : _createCommentVNode("", true),
    (!!_ctx.validationRuleLabel)
      ? (_openBlock(), _createElementBlock("span", {
          key: 2,
          class: _normalizeClass(["input-group__validation-rules-label", _ctx.computedValidationRuleLabelClasses])
        }, _toDisplayString(_ctx.validationRuleLabel), 3))
      : _createCommentVNode("", true)
  ]))
}