import LockerRoomConfiguration from '../lockers/lockerRoomConfiguration';

export enum LockerTopType {
  Unknown,
  Flat,
  Slope,
}

export interface LockerRoomTops {
  topType: LockerTopType;
  topsFollowRda: boolean;

  flatTopFillers: number;

  slopeTop36: number;
  slopeTop48: number;
  slopeTop72: number;
  spliceAssemblies: number;
  cornerSlopeTop36: number;
  rightHandGussets: number;
  leftHandGussets: number;
  supportClips: number;
  heavyDutySlopeTops: boolean;
}

export class LockerRoomTopsViewModel {
  static fromData(config: LockerRoomConfiguration): LockerRoomTopsViewModel {
    const viewModel = new this();
    viewModel._topType = config.tops.topType;
    viewModel._flatTopFillers = config.tops.flatTopFillers;
    viewModel._flatTopsAreDirty = !config.tops.topsFollowRda && config.tops.topType === LockerTopType.Flat;
    viewModel._slopeTopsAreDirty = !config.tops.topsFollowRda && config.tops.topType === LockerTopType.Slope;
    viewModel._slopeTop36 = config.tops.slopeTop36;
    viewModel._slopeTop48 = config.tops.slopeTop48;
    viewModel._slopeTop72 = config.tops.slopeTop72;
    viewModel._spliceAssemblies = config.tops.spliceAssemblies;
    viewModel._cornerSlopeTop36 = config.tops.cornerSlopeTop36;
    viewModel._rightHandGussets = config.tops.rightHandGussets;
    viewModel._leftHandGussets = config.tops.leftHandGussets;
    viewModel._supportClips = config.tops.supportClips;
    viewModel._heavyDutySlopeTops = config.tops.heavyDutySlopeTops;
    return viewModel;
  }

  static defaultTopsValues(): LockerRoomTops {
    const viewModel = new this();
    return {
      topType: viewModel.topType,
      topsFollowRda: false,
      flatTopFillers: viewModel.flatTopFillers,
      slopeTop36: viewModel.slopeTop36,
      slopeTop48: viewModel.slopeTop48,
      slopeTop72: viewModel.slopeTop72,
      spliceAssemblies: viewModel.spliceAssemblies,
      cornerSlopeTop36: viewModel.cornerSlopeTop36,
      rightHandGussets: viewModel.rightHandGussets,
      leftHandGussets: viewModel.leftHandGussets,
      supportClips: viewModel.supportClips,
      heavyDutySlopeTops: viewModel.heavyDutySlopeTops,
    };
  }

  private _topType: LockerTopType;
  private _slopeTopsAreDirty: boolean;
  private _flatTopsAreDirty: boolean;

  private _flatTopFillers: number;

  private _slopeTop36: number;
  private _slopeTop48: number;
  private _slopeTop72: number;
  private _spliceAssemblies: number;
  private _cornerSlopeTop36: number;
  private _rightHandGussets: number;
  private _leftHandGussets: number;
  private _supportClips: number;
  private _heavyDutySlopeTops: boolean;

  constructor(source?: LockerRoomTopsViewModel) {
    this._slopeTop36 = 0;
    this._slopeTop48 = 0;
    this._slopeTop72 = 0;
    this._spliceAssemblies = 0;
    this._cornerSlopeTop36 = 0;
    this._rightHandGussets = 0;
    this._leftHandGussets = 0;
    this._supportClips = 0;

    this._topType = source?.topType ?? LockerTopType.Unknown;
    this._slopeTopsAreDirty = source?.slopeTopsAreDirty ?? false;
    this._flatTopsAreDirty = source?.flatTopsAreDirty ?? false;
    this._flatTopFillers = source?.flatTopFillers ?? 0;
    this._heavyDutySlopeTops = source?.heavyDutySlopeTops ?? false;
  }

  public toRequest(): LockerRoomTops {
    let topsFollowRda = false;
    if (this.topType === LockerTopType.Flat) {
      topsFollowRda = !this.flatTopsAreDirty;
    }
    if (this.topType === LockerTopType.Slope) {
      topsFollowRda = !this.slopeTopsAreDirty;
    }
    return {
      slopeTop36: this.slopeTop36,
      slopeTop48: this.slopeTop48,
      slopeTop72: this.slopeTop72,
      spliceAssemblies: this.spliceAssemblies,
      cornerSlopeTop36: this.cornerSlopeTop36,
      rightHandGussets: this.rightHandGussets,
      leftHandGussets: this.leftHandGussets,
      supportClips: this.supportClips,
      topType: this.topType,
      flatTopFillers: this.flatTopFillers,
      topsFollowRda,
      heavyDutySlopeTops: this.heavyDutySlopeTops,
    };
  }

  public get topType(): LockerTopType {
    return this._topType;
  }
  public set topType(value: LockerTopType) {
    this._topType = value;
  }

  public get slopeTopsAreDirty(): boolean {
    return this._slopeTopsAreDirty;
  }
  public set slopeTopsAreDirty(value: boolean) {
    this._slopeTopsAreDirty = value;
  }

  public get flatTopsAreDirty(): boolean {
    return this._flatTopsAreDirty;
  }
  public set flatTopsAreDirty(value: boolean) {
    this._flatTopsAreDirty = value;
  }

  public get flatTopFillers(): number {
    return this._flatTopFillers;
  }
  public set flatTopFillers(value: number) {
    this._flatTopFillers = value;
  }

  public get heavyDutySlopeTops(): boolean {
    return this._heavyDutySlopeTops;
  }
  public set heavyDutySlopeTops(value: boolean) {
    this._heavyDutySlopeTops = value;
  }

  public get slopeTop36(): number {
    return this._slopeTop36;
  }
  public set slopeTop36(value: number) {
    this._slopeTop36 = value;
    this.addSupportClips();
  }

  public get slopeTop48(): number {
    return this._slopeTop48;
  }
  public set slopeTop48(value: number) {
    this._slopeTop48 = value;
    this.addSupportClips();
  }

  public get slopeTop72(): number {
    return this._slopeTop72;
  }
  public set slopeTop72(value: number) {
    this._slopeTop72 = value;
    this.addSupportClips();
  }

  public get spliceAssemblies(): number {
    return this._spliceAssemblies;
  }
  public set spliceAssemblies(value: number) {
    this._spliceAssemblies = value;
  }

  public get cornerSlopeTop36(): number {
    return this._cornerSlopeTop36;
  }
  public set cornerSlopeTop36(value: number) {
    this._cornerSlopeTop36 = value;
    this.addSupportClips();
  }

  public get rightHandGussets(): number {
    return this._rightHandGussets;
  }
  public set rightHandGussets(value: number) {
    this._rightHandGussets = value;
  }

  public get leftHandGussets(): number {
    return this._leftHandGussets;
  }
  public set leftHandGussets(value: number) {
    this._leftHandGussets = value;
  }

  public get supportClips(): number {
    return this._supportClips;
  }
  public set supportClips(value: number) {
    this._supportClips = value;
  }
  private addSupportClips() {
    this._supportClips =
      this._slopeTop36 * 2 + this._slopeTop48 * 3 + this._slopeTop72 * 3 + this._cornerSlopeTop36 * 2;
  }
}
