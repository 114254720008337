import OrdersService, { OrderLeadTimeRequest, OrderShippingInfoResponse } from '@/endpoints/orderService';
import { requestToQueryString } from '@/helpers/WebServiceHelper';
import ArrayHelper from '@/helpers/arrayHelper';
import * as QuotesAndOrdersHelper from '@/helpers/quoteAndOrderHelper';
import ShippingAddress from '@/models/addresses/shippingAddress';
import DryersShippingRatesResponse from '@/models/dryers/dryersShippingRates';
import CreationViewModel from '@/models/orders/CreationViewModel';
import { LargeShipmentRequest } from '@/models/orders/CreationViewModel';
import Order from '@/models/orders/Order';
import OrderCreationRequest, {
  OrdersListResponse,
  OrderAddressChangeRequest,
  OrderFreightProviderRequest,
  OrderJDERequest,
  OrderPatchOperation,
  OrderPutShippingMethodOptionsRequest,
  ProjectOrdersListResponse,
} from '@/models/orders/OrderAPIInterfaces';
import OrderListItemReply from '@/models/orders/OrderListItemReply';
import { ProjectProductTypes } from '@/models/project/projectApiInterfaces';
import OrderBom from '@/models/shipping/OrderBom';
import FreightProvider from '@/models/shipping/freightProvider';
import ShippingMethod, { sortedShippingMethodTypes } from '@/models/shipping/shippingMethod';
import ShippingMethodOptions from '@/models/shipping/shippingMethodOption';
import { action, mutation, Module, VuexModule } from 'vuex-class-component';
import ShippingOptions from '@/models/shipping/shippingOptions';

interface AddOrdersToProject {
  readonly projectId: string;
  readonly orders: Order[];
}

interface AddOrdersItemToProject {
  readonly projectId: string;
  readonly orders: ProjectOrdersListResponse;
}

export interface OrderItemsRequest {
  InitialProjectId?: string;
  Page: number;
  PerPage: number;
  OnlyShowRecent: boolean;
  DistributorId?: string;
  SalesAgencyId?: string;
  ProductTypes?: ProjectProductTypes;
  OrderStatus?: string;
  Search?: string;
  shipStartDate?: string;
  shipEndDate?: string;
  creationStartDate?: string;
  creationEndDate?: string;
  MinPrice?: number;
  MaxPrice?: number;
  sort: string;
  sortBy: string;
}

@Module()
export class OrdersModule extends VuexModule {
  private ordersByProject: { [projectId: string]: Order[] } = {};
  private orders: OrderListItemReply[] = [];
  private ordersItemsByProject: { [projectId: string]: ProjectOrdersListResponse } = {};
  private shippingMethods: ShippingMethod[] = [];
  private orderParams: OrderItemsRequest = {} as OrderItemsRequest;

  @action({ mode: 'mutate' })
  public async clearState() {
    this.clearOrdersData();
  }

  @action({ mode: 'mutate' })
  public async confirmOrder(orderId: string): Promise<string> {
    const orderNumber = OrdersService.confirmOrder(orderId);
    return orderNumber;
  }

  @action({ mode: 'mutate' })
  public async resendOrder(orderRequest: OrderJDERequest): Promise<void> {
    await OrdersService.resendOrder(orderRequest.orderId);
  }

  @action({ mode: 'mutate' })
  public async pollForOrderReport(orderRequest: OrderJDERequest): Promise<void> {
    OrdersService.pollForOrderReport(orderRequest);
  }

  @action({ mode: 'mutate' })
  public async fetchOrders(request: OrderItemsRequest): Promise<OrdersListResponse> {
    this.setOrderParams(request);
    const response = await OrdersService.getOrdersForProject(requestToQueryString(this.orderParams));
    ArrayHelper.addOrReplace(this.orders, response.entities);
    return response;
  }

  @action({ mode: 'mutate' })
  public async fetchOrderCount(request: OrderItemsRequest): Promise<number> {
    this.setOrderParams(request);
    const response = await OrdersService.getOrderCountForProject(requestToQueryString(this.orderParams));
    return response;
  }

  @action({ mode: 'mutate' })
  public async fetchOrderById(orderId: string): Promise<Order> {
    return await OrdersService.getOrderById(orderId);
  }

  @action({ mode: 'mutate' })
  public async deleteOrder(orderId: string): Promise<void> {
    await OrdersService.deleteOrder(orderId);
  }

  @action({ mode: 'mutate' })
  public async fetchShippignRatesForOrder(orderId: string): Promise<DryersShippingRatesResponse> {
    return await OrdersService.getDryerShippinRatesForOrder(orderId);
  }

  @action({ mode: 'mutate' })
  public async fetchOrdersForProject(request: OrderItemsRequest): Promise<ProjectOrdersListResponse> {
    await OrdersService.validateOrdersForProject(request.InitialProjectId ?? '');
    const response = await OrdersService.getOrdersForProject(requestToQueryString(request));

    if (request.InitialProjectId) {
      this.addOrdersItem({ projectId: request.InitialProjectId, orders: response });
    }
    return response;
  }

  @action({ mode: 'mutate' })
  public async createOrder(viewModel: CreationViewModel): Promise<Order> {
    const request: OrderCreationRequest = {
      additionalNotes: viewModel.additionalNotes,
      projectId: viewModel.projectId,
      projectPartsAreAdded: viewModel.projectPartsAreAdded,
      projectPartsAreMasterKeys: viewModel.projectPartsAreMasterKeys,
      selectedRoomsId: viewModel.selectedRoomsId,
      attention: viewModel.attention,
      specialCharges: QuotesAndOrdersHelper.unwrapSpecialChargesFromViewModel(viewModel),
      specialChargesDescription: viewModel.specialChargesDescription?.trim(),
      numberPlateSequence: viewModel.numberPlateSequence,
      lockEndUserInfo: viewModel.lockEndUserInfo,
      extraKeys: viewModel.extraKeys,
    };
    const response = await OrdersService.createOrder(request);
    this.addOrders({ projectId: request.projectId, orders: [response.data.entity] });
    return response.data.entity;
  }

  @action({ mode: 'mutate' })
  public async patchOrder(operation: OrderPatchOperation): Promise<Order> {
    if (operation.operations.length < 1) {
      throw new Error('No operations');
    }
    const response = await OrdersService.patchOrder(operation);

    this.addOrders({ projectId: operation.projectId, orders: [response.data.entity] });
    return response.data.entity;
  }

  @action({ mode: 'mutate' })
  public async updateLeadTimeAndGetOrderShippingInfo(
    request: OrderLeadTimeRequest
  ): Promise<OrderShippingInfoResponse> {
    const response = await OrdersService.updateLeadTimeAndGetShippingInfo(request);

    this.addOrders({ projectId: request.projectId, orders: [response.order] });

    response.shippingMethods.sort((lh: ShippingMethod, rh: ShippingMethod) => {
      const lhSortedIndex = sortedShippingMethodTypes.indexOf(lh.label);
      const rhSortedIndex = sortedShippingMethodTypes.indexOf(rh.label);
      return lhSortedIndex - rhSortedIndex;
    });
    this.setOrderShippingMethods(response.shippingMethods);

    return response;
  }

  @action({ mode: 'mutate' })
  public async putOrderShippingMethodOptions(
    request: OrderPutShippingMethodOptionsRequest
  ): Promise<ShippingMethodOptions> {
    const response = await OrdersService.putOrderShippingMethodOptions(request);
    this.addOrders({ projectId: request.projectId, orders: [response.entity] });
    return response.entity.shippingMethodOptions;
  }

  @action({ mode: 'mutate' })
  public async getBomForOrder(orderId: string): Promise<OrderBom> {
    return await OrdersService.getBomForOrder(orderId);
  }

  @action({ mode: 'mutate' })
  public async changeOrderAddress(request: OrderAddressChangeRequest): Promise<ShippingAddress> {
    const response = await OrdersService.changeOrderAddress(request);
    this.setShippingOptions({
      projectId: request.projectId,
      orderId: request.orderId,
      shippingOptions: response.entity,
    });
    return response.entity.selectedAddressToShipTo;
  }

  @action({ mode: 'mutate' })
  public async fetchFreightProvidersForOrder(
    orderFreightProviderRequest: OrderFreightProviderRequest
  ): Promise<FreightProvider[]> {
    if (!orderFreightProviderRequest.freightProvidersToUpdate) {
      return (await OrdersService.getFreightProvidersForOrder(orderFreightProviderRequest)).filter(
        x => x.carrierGuarantee !== 'G5PM'
      );
    } else {
      return (await OrdersService.updateFreightProvidersForOrder(orderFreightProviderRequest)).filter(
        x => x.carrierGuarantee !== 'G5PM'
      );
    }
  }

  @action({ mode: 'mutate' })
  public async fetchLargeShipmentRequestsForOrders(orderId: string): Promise<LargeShipmentRequest[]> {
    return await OrdersService.getLargeShipmentsForOrders(orderId);
  }

  @mutation
  public setOrderParams(params: OrderItemsRequest) {
    this.orderParams = params;
  }

  get getOrderParams() {
    return this.orderParams;
  }

  get getOrders() {
    return this.orders;
  }

  get OrdersForProject() {
    return (projectId: string) => {
      return this.ordersByProject[projectId] ? this.ordersByProject[projectId] : [];
    };
  }

  get getOrderForProjectById() {
    return (projectId: string, orderId: string) => {
      const ordersList = this.ordersByProject[projectId] ? this.ordersByProject[projectId] : [];
      return ordersList.find(order => order.id === orderId);
    };
  }

  get getOrdersListForProject() {
    return (projectId: string): ProjectOrdersListResponse | undefined => {
      return this.ordersItemsByProject[projectId];
    };
  }

  get getOrderListItemForProjectById() {
    return (projectId: string, orderId: string) => {
      const ordersList = this.ordersItemsByProject[projectId] ? this.ordersItemsByProject[projectId].entities : [];
      return ordersList.find(order => order.id === orderId);
    };
  }

  get getOrderShippingMethods(): ShippingMethod[] {
    return this.shippingMethods;
  }

  @mutation private addOrders(request: AddOrdersToProject) {
    if (this.ordersByProject[request.projectId] === undefined) {
      this.ordersByProject[request.projectId] = [];
    }
    ArrayHelper.addOrReplace(this.ordersByProject[request.projectId], request.orders);
  }

  @mutation private addOrdersItem(request: AddOrdersItemToProject) {
    if (this.ordersItemsByProject[request.projectId] === undefined) {
      this.ordersItemsByProject[request.projectId] = request.orders;
      return;
    }
    const { entities, pendingEntities, ...rest } = request.orders;
    ArrayHelper.addOrReplace(this.ordersItemsByProject[request.projectId].entities, request.orders.entities);
    const allEntities = [...this.ordersItemsByProject[request.projectId].entities];
    const allPendingEntities = [...this.ordersItemsByProject[request.projectId].pendingEntities];
    this.ordersItemsByProject[request.projectId] = {
      entities: allEntities,
      pendingEntities: allPendingEntities,
      ...rest,
    };
  }

  @mutation private setShippingOptions({
    projectId,
    orderId,
    shippingOptions,
  }: {
    projectId: string;
    orderId: string;
    shippingOptions: ShippingOptions;
  }) {
    const order = this.ordersByProject[projectId].find(o => o.id === orderId);
    if (!order) {
      return;
    }

    order.shippingOptions = shippingOptions;
  }

  @mutation private setOrderShippingMethods(shippingMethods: ShippingMethod[]) {
    this.shippingMethods = shippingMethods;
  }

  @mutation private clearOrdersData() {
    this.ordersByProject = {};
    this.ordersItemsByProject = {};
    this.shippingMethods = [];
  }
}
