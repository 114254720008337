<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="57" height="52" viewBox="0 0 57 52">
    <g fill="none" fill-rule="evenodd">
      <g>
        <path
          fill="#FFF"
          d="M21 25L32 25 39 33 41 43 37 49 8 49 4 47 2 39 4 31 8 27 13 25z"
          transform="translate(14)"
        />
        <path
          fill="currentColor"
          d="M9.174 51.192C4.136 51.192 0 46.979 0 41.848v-2.83c0-7.65 5.137-14.392 12.325-16.42-2.5-2.435-3.998-5.906-3.998-9.548C8.327 5.8 14.022 0 21.14 0l.167.002.168-.002c7.014 0 12.647 5.633 12.809 12.736l.003.314c0 3.642-1.498 7.113-3.998 9.549 7.188 2.027 12.325 8.768 12.325 16.42v2.829c0 5.13-4.136 9.344-9.174 9.344H9.174zm24.09-4.016c2.77 0 5.055-2.325 5.055-5.149v-2.83c0-6.233-4.48-11.527-10.299-12.683-.834-.156-1.686-.235-2.544-.235h-8.338c-.198 0-.395.003-.59.008-6.74.237-12.252 5.995-12.252 12.91v2.83c0 2.824 2.284 5.149 5.054 5.149z"
          transform="translate(14)"
        />
        <path
          fill="#FFF"
          d="M30.811 13.23c0-5.303-4.136-9.517-9.343-9.517s-9.344 4.214-9.344 9.517c0 5.303 4.137 9.516 9.343 9.516 5.207.008 9.344-4.206 9.344-9.516z"
          transform="translate(14)"
        />
      </g>
      <path
        fill="currentColor"
        fill-opacity=".36"
        d="M9.174 51.192C4.136 51.192 0 46.979 0 41.848v-2.83c0-7.65 5.137-14.392 12.325-16.42-2.5-2.435-3.998-5.906-3.998-9.548C8.327 5.8 14.022 0 21.14 0l.167.002.168-.002c7.014 0 12.647 5.633 12.809 12.736l.003.314c0 3.642-1.498 7.113-3.998 9.549 7.188 2.027 12.325 8.768 12.325 16.42v2.829c0 5.13-4.136 9.344-9.174 9.344H9.174z"
      />
    </g>
  </svg>
</template>
