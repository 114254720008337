<template>
  <svg
    version="1.1"
    class="hadrian-icons orders"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="106.602 263.077 298.34 317.874"
    style="enable-background: new 106.602 263.077 298.34 317.874"
    xml:space="preserve"
    fill="currentColor"
  >
    <path
      d="M400.665,332.193l-141.604-68.36c-2.09-1.008-4.488-1.008-6.58,0L110.88,332.192c-2.6,1.254-4.278,3.93-4.278,6.815v166.016
      c0,2.885,1.679,5.561,4.277,6.816l141.604,68.359c1.02,0.492,2.157,0.752,3.289,0.752s2.27-0.26,3.291-0.752l141.601-68.359
      c2.6-1.254,4.278-3.93,4.278-6.816V339.008C404.941,336.123,403.263,333.449,400.665,332.193z M263.34,412.118l19.66-9.602
      c3.758-1.815,5.339-6.349,3.523-10.106c-0.878-1.82-2.413-3.189-4.321-3.855c-1.908-0.666-3.964-0.548-5.784,0.331l-20.646,10.078
      l-11.361-5.485c-1.821-0.879-3.873-0.998-5.785-0.332c-1.908,0.666-3.443,2.035-4.323,3.856c-1.813,3.758-0.232,8.292,3.526,10.106
      l10.375,5.008v149.207l-126.464-61.051V351.066l87.806,42.529c1.036,0.5,2.143,0.754,3.288,0.754c2.889,0,5.565-1.68,6.818-4.278
      c0.88-1.821,0.997-3.875,0.331-5.784c-0.666-1.909-2.035-3.444-3.855-4.323l-84.548-40.956l38.481-18.578l134.295,64.833v52.987
      c0,2.62,1.325,5.017,3.546,6.411c2.165,1.358,5.011,1.516,7.313,0.405l34.179-16.5c2.599-1.254,4.278-3.93,4.278-6.816V368.51
      l36.133-17.443v149.207L263.34,561.325V412.118z M348.981,353.965l-124.193-59.955l30.983-14.958l124.193,59.955L348.981,353.965z
      M319.492,426.191V385.01l19.043-9.193v41.181L319.492,426.191z M187.703,312.14l19.87-9.631l123.999,59.861l-19.91,9.612
      L187.703,312.14z"
    />
  </svg>
</template>
