import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { setupCache } from 'axios-cache-adapter';
import jwt_decode from 'jwt-decode';

import AuthenticationService from '@/endpoints/authenticationService';
import { MiniProfilerHelper } from '@/helpers/miniProfilerHelper';
import Toast from '@/helpers/toast';
import paths from '@/router/paths';
import { showCommonErrorModal } from '@/services/modals';
import iconErrorCommon from '@/shared/icons/iconErrorCommon/iconErrorCommon.vue';
import { vxManager } from '@/store/store';
import i18n from '@/i18n';

const DEFAULT_DISABLED_CACHE_CONFIG = {
  maxAge: 0,
  exclude: {
    query: false,
  },
};

const DEFAULT_CACHE_CONFIG = {
  ...DEFAULT_DISABLED_CACHE_CONFIG,
  maxAge: 15 * 60 * 1000,
};

const cache = setupCache(DEFAULT_DISABLED_CACHE_CONFIG);

const AxiosService = axios.create({
  baseURL: `${process.env.VUE_APP_SERVER_URL}/`,
  adapter: cache.adapter,
  maxContentLength: 30000000,
});

interface GetRequestConfig extends AxiosRequestConfig {
  cached?: boolean;
}

class HttpService {
  public static async get<T = any>(url: string, config?: GetRequestConfig): Promise<AxiosResponse<T>> {
    if (config?.cached) {
      config.cache = DEFAULT_CACHE_CONFIG;
    }
    return await AxiosService.get(url, config);
  }

  public static async getWithLoading<T = any>(url: string, config?: GetRequestConfig): Promise<AxiosResponse<T>> {
    vxManager.appStateModule.setLoading(true);
    try {
      return await HttpService.get(url, config);
    } finally {
      vxManager.appStateModule.setLoading(false);
    }
  }

  public static async post(url: string, data?: any, config?: AxiosRequestConfig) {
    return await AxiosService.post(url, data, config);
  }

  public static async postWithLoading(url: string, data?: any, config?: AxiosRequestConfig) {
    vxManager.appStateModule.setLoading(true);
    return await AxiosService.post(url, data, config).finally(() => vxManager.appStateModule.setLoading(false));
  }

  public static async delete(url: string, config?: AxiosRequestConfig) {
    return await AxiosService.delete(url, config);
  }

  public static async deleteWithLoading(url: string, config?: AxiosRequestConfig) {
    vxManager.appStateModule.setLoading(true);
    return await AxiosService.delete(url, config).finally(() => vxManager.appStateModule.setLoading(false));
  }

  public static async patch(url: string, data?: any, config?: AxiosRequestConfig) {
    config = HttpService.fixPatchConfig(config);

    return await AxiosService.post(url, data, config);
  }

  public static async patchWithLoading(url: string, data?: any, config?: AxiosRequestConfig) {
    vxManager.appStateModule.setLoading(true);

    config = HttpService.fixPatchConfig(config);

    return await AxiosService.post(url, data, config).finally(() => vxManager.appStateModule.setLoading(false));
  }

  public static async put(url: string, data?: any, config?: AxiosRequestConfig) {
    return await AxiosService.put(url, data, config);
  }

  public static async putWithLoading(url: string, data?: any, config?: AxiosRequestConfig) {
    vxManager.appStateModule.setLoading(true);
    return await AxiosService.put(url, data, config).finally(() => vxManager.appStateModule.setLoading(false));
  }

  private static fixPatchConfig(config: AxiosRequestConfig | undefined) {
    if (!config) {
      config = {};
    }
    if (!config.headers) {
      config.headers = {};
    }

    config.headers = { ...config.headers, 'X-HTTP-Method-Override': 'PATCH' };
    return config;
  }
}

function getStoredToken(persistentLogin: boolean): string | null {
  return persistentLogin ? localStorage.getItem('token') : sessionStorage.getItem('token');
}

function getStoredRefreshToken(persistentLogin: boolean): string | null {
  return persistentLogin ? localStorage.getItem('refreshToken') : sessionStorage.getItem('refreshToken');
}

AxiosService.interceptors.request.use(
  async (config: AxiosRequestConfig<any>) => {
    const { getIsLoggedIn, getKeepMeLoggedIn } = vxManager.userModule;

    const token = getStoredToken(getKeepMeLoggedIn);

    if (!token) {
      // Every logged-in user has a token. If they don't, assume they logged out
      // (like from another tab) and reflect this in the current tab. Otherwise,
      // 401 API responses render PCN mostly unusable (BUG 14106).
      if (getIsLoggedIn) {
        vxManager.userModule.logout({ redirectPath: window.location.pathname });
      }
      return config;
    }

    const decodedToken: any = jwt_decode(token);
    const currentTime = Date.now() / 1000;

    if (decodedToken.exp < currentTime) {
      // Assume refreshExpiredToken() runs once (when this 'if' first becomes true).
      // RefreshTokenPromise acts as a cache while refreshExpiredToken() is
      // awaited to avoid multiple calls (BUG 12521).
      const promise = vxManager.userModule.getRefreshTokenPromise || refreshExpiredToken();
      vxManager.userModule.setRefreshTokenPromise(promise);

      const success = await promise;
      // Clear previous cache now that refreshExpiredToken() is settled
      vxManager.userModule.setRefreshTokenPromise(null);

      if (!success) {
        // Try to cancel the intercepted request(s)
        return {
          ...config,
          cancelToken: new axios.CancelToken(cancel => cancel('Error encountered while refreshing access token')),
        };
      }
    }

    // Get token again in case refreshExpiredToken() obtained a new one
    const validToken = getStoredToken(getKeepMeLoggedIn);

    config.headers = config.headers || {};
    config.headers.Authorization = `Bearer ${validToken}`;

    return config;
  },
  error => {
    vxManager.userModule.logout();
    return Promise.reject(error);
  }
);

const isProfilingEnabled =
  process.env.VUE_APP_PROFILING_ENABLED_FOR_GOD_EMPERORS === 'true' || process.env.VUE_APP_PROFILING_ENABLED === 'true';

if (isProfilingEnabled) {
  AxiosService.interceptors.request.use(x => {
    const request: any = x;
    request.meta = request.meta || {};
    request.meta.requestStartedAt = new Date().getTime();
    return request;
  });

  AxiosService.interceptors.response.use(
    //
    async (response: AxiosResponse) => {
      MiniProfilerHelper.saveRequest(response);
      return response;
    }
  );
}

AxiosService.interceptors.response.use(
  async (response: AxiosResponse<any>) => {
    if (response.headers.enabled !== undefined && response.headers.enabled === 'false') {
      vxManager.userModule.logout();
    }
    return response;
  },
  error => {
    if (error?.response?.status === 500) {
      if (!vxManager.appStateModule.getServerErrorAlreadyShown) {
        vxManager.appStateModule.setServerErrorAlreadyShown(true);
        showCommonErrorModal({
          showLogo: false,
          title: i18n.global.t('problemOccured.title'),
          icon: iconErrorCommon,
          actionLabel: i18n.global.t('generic.refresh'),
          onActionClick: () => window.location.reload(),
          useAlphaBackDrop: true,
        });
      } else {
        vxManager.appStateModule.setServerErrorAlreadyShown(false);
        window.location.href = paths.home;
      }
    } else if (error?.response?.headers?.enabled === 'false') {
      vxManager.userModule.logout();
    }
    return Promise.reject(error);
  }
);

async function refreshExpiredToken(): Promise<boolean> {
  const { getKeepMeLoggedIn } = vxManager.userModule;

  const refreshToken = getStoredRefreshToken(getKeepMeLoggedIn);

  if (!refreshToken) {
    // Then() (vs. await) unblocks an awaiting caller faster by synchronously
    // resolving the returned promise (false)
    handleRefreshError().then(() => toastRefreshError());
    return false;
  }

  try {
    const data = await AuthenticationService.refreshToken(refreshToken);
    if ('token' in data) {
      if (getKeepMeLoggedIn) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('refreshToken', data.refreshToken);
      } else {
        sessionStorage.setItem('token', data.token);
        sessionStorage.setItem('refreshToken', data.refreshToken);
      }
      return true;
    } else {
      // Then() (vs. await) unblocks an awaiting caller faster
      handleRefreshError().then(() => {
        // A service failure is unexpected and should be toasted while an expired
        // refresh token (although unfortunate) isn't and shouldn't
        if (data?.label != 'Refresh Token has expired') toastRefreshError();
      });
      return false;
    }
  } catch (e) {
    // Then() (vs. await) unblocks an awaiting caller faster
    handleRefreshError().then(() => toastRefreshError());
    return false;
  }
}

async function handleRefreshError(): Promise<void> {
  await vxManager.userModule.logout();
}

// If called after handleRefreshError(), await it to ensure the login page is
// displayed. Otherwise, the toast may appear on the wrong page.
function toastRefreshError(): void {
  const errorString = i18n.global.t('errors.refreshTokenError');
  Toast.error({ body: errorString });
}

export { HttpService as default, getStoredToken };
