import { defineComponent } from 'vue';

export default defineComponent({
  name: 'gridContainer',
  props: {
    numColumns: {
      type: Number,
      default: 2,
    },
    columnGapStyle: {
      //                       20px       40px
      type: String as () => 'standard' | 'large',
      default: 'standard',
    },
    rowGapStyle: {
      //                       20px       40px
      type: String as () => 'standard' | 'large',
      default: 'standard',
    },
  },
});
