import Toast from '@/helpers/toast';
import router from '@/router';
import iconChevronRight from '@/shared/icons/chevronRight/chevronRight.vue';
import { Component } from 'vue';

import { defineComponent } from 'vue';
import { RouteLocationRaw } from 'vue-router';

export default defineComponent({
  name: 'h-toast',
  props: {
    title: {
      type: String,
    },
    link: {
      type: [String, Object as () => RouteLocationRaw],
    },
    linkText: {
      type: String,
    },
    body: {
      type: String,
    },
    icon: {
      type: [Object as () => Component, Function as () => any],
    },
    iconPosition: {
      type: String as () => 'left' | 'right',
    },
  },
  components: {
    iconChevronRight,
  },
  computed: {
    displayBodyInHeader(): boolean {
      return !this.title && !!this.icon && !!this.body;
    },
  },
  methods: {
    onClickLink(event: MouseEvent) {
      if (!this.link) {
        return;
      }

      Toast.dismissParentToast(event);
      router.push(this.link);
    },
  },
});
