import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_iconToastClose = _resolveComponent("iconToastClose")!

  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: "h-toast-close-button btn btn-icon",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
    "aria-label": "Dismiss"
  }, [
    _createVNode(_component_iconToastClose)
  ]))
}