import UserSettingsService from '@/endpoints/userSettingsService';
import MeasurementTypes from '@/enums/measurementTypes';
import { WorkingDirection } from '@/enums/workingDirection';
import { convertMetricMeasurementOn } from '@/helpers/measurementTypeHelper';
import PatchOperation from '@/models/patchOperation/patchOperation';
import RdDepthInfo from '@/models/roomDesigner/rdDepthInfo';
import RdUserSettingsDefaultDoorWidth from '@/models/roomDesigner/rdUserSettingsDefaultDoorWidth';
import { UserData } from '@/models/signIn/signInResponse';
import MeasurementOption from '@/models/userSettings/measurementOption';
import Timezone from '@/models/userSettings/timezonesSettings';
import ToolbarSettings, { BowlCenterType } from '@/models/userSettings/toolbarSettings';
import UserSettings from '@/models/userSettings/userSettings';
import UserSettingsStaticData from '@/models/userSettings/userSettingsStaticData';

import { action, mutation, Module, VuexModule } from 'vuex-class-component';
import { vxManager } from '../store';

@Module()
export class UserSettingsModule extends VuexModule {
  private toolbarSettings: ToolbarSettings | undefined = undefined;
  private bowlCenterDisplayOptions: BowlCenterType[] = [];
  private measurementsOptions: MeasurementOption[] = [];
  private allowedBaseDepths: RdDepthInfo[] = [];
  private userSettingsDefaultDoorWidths: RdUserSettingsDefaultDoorWidth[] = [];
  private timezones: Timezone[] = [];
  private userSettings: UserSettings | undefined = undefined;
  private workingDirection: number = WorkingDirection.FromLeft;

  @action({ mode: 'mutate' })
  public async clearState() {
    this.clearUserSettingsData();
  }

  @action({ mode: 'mutate' })
  public async fetchToolbarSettings(): Promise<void> {
    const response = await UserSettingsService.getToolbarSettings();
    this.setToolbarSettings(response);
  }

  @action({ mode: 'mutate' })
  public async hydrateUserSettings(data: UserSettingsStaticData) {
    this.setBowlCenterDisplayOptions(data.bowlCenterDisplayOptions);
    this.setMeasurementOptions(data.measurementOptions);
    this.setAllowedBaseDepthOptions(data.allowedDepths.allowedBaseDepths);
    this.setUserSettingsDefaultDoorWidths(data.userSettingsDefaultDoorWidths);
  }

  @action({ mode: 'mutate' })
  public async fetchTimezones(): Promise<void> {
    const response = await UserSettingsService.getTimezones();
    this.setTimezones(response);
  }

  get userTimezoneId(): string {
    if (this.userSettings && this.userSettings.timeZoneId) {
      return this.userSettings.timeZoneId;
    } else {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
  }

  @action({ mode: 'mutate' })
  public async fetchUserSettings(): Promise<void> {
    const response = await UserSettingsService.getUserSettings();
    this.setUserSettings(response);
  }

  @action({ mode: 'mutate' })
  public setupUserSettings(userData: UserData) {
    this.setUserSettings(userData.userSettings);
    this.setToolbarSettings(userData.toolbarSettings);
    vxManager.permissionsModule.setupPermissionGroups(userData.securityGroups);
  }

  @action({ mode: 'mutate' })
  public async patchToolbarSettings(operations: PatchOperation[]): Promise<void> {
    return await vxManager.roomDesignerModule.withRoomLoading(async () => {
      const response = await UserSettingsService.patchToolbarSettings(operations);
      this.setToolbarSettings(response);
    });
  }

  @action({ mode: 'mutate' })
  public async patchUserSettings(operations: PatchOperation[]): Promise<void> {
    return await vxManager.roomDesignerModule.withRoomLoading(async () => {
      const response = await UserSettingsService.patchUserSettings(operations);
      this.setUserSettings(response);
    });
  }

  @action({ mode: 'mutate' })
  public async patchWorkingDirection(operations: number): Promise<void> {
    this.setWorkingDirection(operations);
  }

  get getToolbarSettings() {
    return this.toolbarSettings;
  }

  get getBowlCenterDisplayOptions() {
    return this.bowlCenterDisplayOptions;
  }

  get getMeasurementsOptions() {
    return this.measurementsOptions;
  }

  get getAllowedBaseDepthsOptions() {
    return this.allowedBaseDepths;
  }

  get getUserSettingsDefaultDoorWidths() {
    return this.userSettingsDefaultDoorWidths;
  }

  get getTimezones() {
    return this.timezones;
  }

  get getUserSettings() {
    return this.userSettings;
  }

  get getWorkingDirection() {
    return this.workingDirection;
  }

  get getSelectedMeasurementType(): MeasurementTypes {
    if (this.userSettings) {
      return convertMetricMeasurementOn(this.userSettings.metricMeasurementOn);
    } else {
      return MeasurementTypes.Imperial;
    }
  }

  @mutation private setToolbarSettings(settings: ToolbarSettings) {
    this.toolbarSettings = settings;
  }

  @mutation private setBowlCenterDisplayOptions(options: BowlCenterType[]) {
    this.bowlCenterDisplayOptions = options;
  }

  @mutation private setMeasurementOptions(options: MeasurementOption[]) {
    this.measurementsOptions = options;
  }

  @mutation private setAllowedBaseDepthOptions(options: RdDepthInfo[]) {
    this.allowedBaseDepths = options;
  }

  @mutation private setUserSettingsDefaultDoorWidths(options: RdUserSettingsDefaultDoorWidth[]) {
    this.userSettingsDefaultDoorWidths = options;
  }

  @mutation private setTimezones(options: Timezone[]) {
    this.timezones = options;
  }

  @mutation private setUserSettings(options: UserSettings) {
    this.userSettings = options;
  }

  @mutation private setWorkingDirection(options: number) {
    this.workingDirection = options;
  }

  @mutation private clearUserSettingsData() {
    this.toolbarSettings = undefined;
    this.userSettings = undefined;
  }
}
