<script src="./customTabs.ts"></script>
<style lang="scss" scoped src="./customTabs.scss"></style>

<template>
  <div class="custom-tabs__wrapper">
    <button
      type="button"
      v-for="tab in tabs"
      :class="{ active: selectedTab === tab.value }"
      v-on:click="changeTab(tab)"
      :key="tab.value"
      class="custom-tabs__tab-item button-reset"
    >
      {{ tab.label }}
    </button>
  </div>
</template>
