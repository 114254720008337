import HttpService from '@/endpoints/HttpService';
import { CreateUserRequest } from '@/models/user/createUserRequest';
import User from '@/models/user/user';
import { UserPatchOperation } from '@/models/user/userPatchOperation';
import { UserListResponse } from '@/models/userList/userList';
import UserContentTypes from '@/enums/userContentTypes';
import { UserContentViews } from '@/interfaces/userContentViews';

const usersUrl = `${process.env.VUE_APP_API_PREFIX}Users`;

export default class UserService {
  public static async getUserInfo(): Promise<User> {
    const response = await HttpService.get(usersUrl);
    return response.data;
  }

  public static async getUserInfoById(userId: string): Promise<User> {
    const response = await HttpService.get(usersUrl + '/' + userId);
    return response.data.entity;
  }

  public static async getUserList(queryParameters: string): Promise<UserListResponse> {
    const response = await HttpService.get(usersUrl + `/all${queryParameters}`);
    return response.data;
  }

  public static async patchUser(operations: UserPatchOperation): Promise<User> {
    const response = await HttpService.patch(
      process.env.VUE_APP_API_PREFIX + `Users/${operations.userId}`,
      operations.operations
    );
    return response.data.entity;
  }

  public static async createUser(request: CreateUserRequest): Promise<User> {
    const response = await HttpService.postWithLoading(usersUrl + `/${encodeURIComponent(request.username)}`, request);
    return response.data;
  }
  public static async inviteUser(userId: string): Promise<User> {
    const response = await HttpService.post(process.env.VUE_APP_API_PREFIX + `Users/${userId}/invitation`);
    return response.data.entity;
  }

  public static async getUserIsEnabled(): Promise<boolean> {
    const res = await HttpService.get(usersUrl + `/isEnabled`);
    return res.data;
  }

  public static async resetUserPassword(userId: string): Promise<void> {
    await HttpService.post(`${usersUrl}/${userId}/password/reset`);
  }

  public static async disableUser(userId: string): Promise<User> {
    const res = await HttpService.post(`${usersUrl}/${userId}/disable`);
    return res.data.entity;
  }

  public static async restoreUser(userId: string): Promise<User> {
    const res = await HttpService.post(`${usersUrl}/${userId}/restore`);
    return res.data.entity;
  }

  public static async updateAndFetchUserContentViews(
    userId: string,
    contentType: UserContentTypes
  ): Promise<UserContentViews[]> {
    const res = await HttpService.post(`${usersUrl}/${userId}/userContentViews?contentType=${contentType}`);
    return res.data;
  }

  public static async fetchUserContentViews(userId: string): Promise<UserContentViews[]> {
    const res = await HttpService.get(`${usersUrl}/${userId}/userContentViews`);
    return res.data;
  }
}
