<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
    <path
      fill="currentColor"
      fill-rule="nonzero"
      d="M6.927 0C3.107 0 0 3.106 0 6.927c0 3.82 3.106 6.927 6.927 6.927 3.82 0 6.927-3.106 6.927-6.927C13.854 3.107 10.748 0 6.927 0z"
    />
    <path
      fill="#FFF"
      d="M7.044 10.894c-.467 0-.831-.38-.831-.832 0-.466.38-.83.83-.83.467 0 .832.379.832.83 0 .467-.38.832-.831.832zM9.057 6.65c-.321.438-.657.671-.905.846-.276.19-.393.277-.422.73-.015.203-.19.349-.38.349h-.598c-.219 0-.408-.19-.393-.423.087-1.05.495-1.429 1.006-1.779.204-.146.393-.277.583-.54.19-.262.219-.626.058-.932-.102-.219-.394-.569-1.065-.569-.875 0-1.137.555-1.224.977-.03.19-.19.32-.38.32h-.612c-.234 0-.423-.203-.38-.437.161-1.151.992-2.245 2.596-2.245 1.006 0 1.867.495 2.29 1.327.409.786.336 1.69-.174 2.376z"
    />
  </svg>
</template>
