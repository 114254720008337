import { DecimalInchPrecisions } from '@/constants/decimalInchPrecisions';

export class RoundingHelper {
  public static roundToNumberOfDecimals(value: number, numberOfDecimals: number): number {
    const scalar = Math.pow(10, numberOfDecimals);
    return Math.round(value * scalar) / scalar;
  }

  public static truncateToNumberOfDecimals(value: number, numberOfDecimals: number): number {
    const intLength = Math.floor(value).toString().length;
    return parseFloat(value.toString().substr(0, intLength + numberOfDecimals + 1));
  }

  public static truncateStringToNumberOfDecimals(input: string, numberOfDecimals: number): [string, number] {
    const inputParts = input.split('.');

    if (inputParts.length > 2) {
      return [input, NaN];
    }

    if (inputParts.length > 1) {
      inputParts[1] = inputParts[1].slice(0, numberOfDecimals);
    }

    let rejoined = inputParts[0];
    if (numberOfDecimals > 0) {
      rejoined = inputParts.slice(0, 2).join('.');
    }

    return [rejoined, parseFloat(rejoined)];
  }

  public static GetRoundedInchDecimalValue(decimalValue: number, precision: DecimalInchPrecisions): number {
    return this.roundTo(decimalValue, precision);
  }

  public static roundTo(value: number, multiple: number): number {
    return multiple * Math.round(value / multiple);
  }

  public static roundToDenominator(rawValue: number, roundingDenominator: number) {
    return Math.round(rawValue * roundingDenominator) / roundingDenominator;
  }

  public static getCommonDivisor(a: number, b: number): number {
    let c: number;
    a = Math.abs(a);
    b = Math.abs(b);

    while (b) {
      c = a % b;
      a = b;
      b = c;
    }
    return a;
  }

  public static discountToMultiplier(discount: number): number {
    return RoundingHelper.roundToNumberOfDecimals((100 - discount) / 100, 4);
  }

  public static multiplierToDiscount(multiplier: number): number {
    return RoundingHelper.roundToNumberOfDecimals(100 - 100 * multiplier, 2);
  }
}
