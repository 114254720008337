import i18n from '@/i18n';

const MIN_PASSWORD_LENGTH = 8;

const SPECIAL_CHARACTERS = '^$*.[]{}()?-"!@#%&/\\,><\':;|_~`'.split('');

enum PasswordValidationError {
  TooShort = 'tooShort',
  NoSpecialCharacters = 'noSpecialCharacters',
  NoUppercaseLetters = 'noUppercaseLetters',
  NoLowercaseLetters = 'noLowercaseLetters',
  NoNumbers = 'noNumbers',
  NoLeadingOrTrailingWhitespace = 'noLeadingOrTrailingWhitespace',
}

export class PasswordValidationResult {
  private _errors: PasswordValidationError[];

  constructor(errors: PasswordValidationError[]) {
    this._errors = errors;
  }

  get isValid(): boolean {
    return this._errors.length < 1;
  }

  get errors(): PasswordValidationError[] {
    return this._errors;
  }

  get printableErrors(): string[] {
    return this.errors.map(PasswordHelper.errorToString);
  }
}

const PasswordHelper = {
  validatePassword(password: string): PasswordValidationResult {
    const errors = [] as PasswordValidationError[];

    if (password.length < MIN_PASSWORD_LENGTH) {
      errors.push(PasswordValidationError.TooShort);
    }

    if (!RegExp('[a-z]').test(password)) {
      errors.push(PasswordValidationError.NoLowercaseLetters);
    }

    if (!RegExp('[A-Z]').test(password)) {
      errors.push(PasswordValidationError.NoUppercaseLetters);
    }

    if (!RegExp('\\d').test(password)) {
      errors.push(PasswordValidationError.NoNumbers);
    }

    if (!SPECIAL_CHARACTERS.some(char => password.includes(char))) {
      errors.push(PasswordValidationError.NoSpecialCharacters);
    }

    if (password.trim() !== password) {
      errors.push(PasswordValidationError.NoLeadingOrTrailingWhitespace);
    }

    return new PasswordValidationResult(errors);
  },
  errorToString(error: PasswordValidationError): string {
    return i18n.global.t(`passwordValidation.${error.toString()}`).toString();
  },
};

export default PasswordHelper;
