<template>
  <svg class="calendar" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 16 16">
    <defs>
      <filter id="fgywhn50pa">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.407843 0 0 0 0 0.458824 0 0 0 0 0.533333 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fill-rule="evenodd">
      <path
        fill="currentColor"
        fill-rule="nonzero"
        d="M7.273 13.09c.401 0 .727-.325.727-.726 0-.402-.326-.728-.727-.728-.402 0-.728.326-.728.728 0 .401.326.727.728.727zm3.636 0c.402 0 .727-.325.727-.726 0-.402-.325-.728-.727-.728-.402 0-.727.326-.727.728 0 .401.325.727.727.727zm0-2.908c.402 0 .727-.326.727-.727 0-.402-.325-.728-.727-.728-.402 0-.727.326-.727.728 0 .401.325.727.727.727zm-3.636 0c.401 0 .727-.326.727-.727 0-.402-.326-.728-.727-.728-.402 0-.728.326-.728.728 0 .401.326.727.728.727zm5.09-8.727h-.727V.727c0-.401-.325-.727-.727-.727-.402 0-.727.326-.727.727v.728H4.364V.727C4.364.326 4.038 0 3.636 0c-.401 0-.727.326-.727.727v.728h-.727C.977 1.455 0 2.43 0 3.636v10.182C0 15.023.977 16 2.182 16h10.182c1.205 0 2.181-.977 2.181-2.182V3.636c0-1.205-.976-2.181-2.181-2.181zm.728 12.363c0 .402-.326.727-.727.727H2.182c-.402 0-.727-.325-.727-.727V7.273H13.09v6.545zm0-8H1.455V3.636c0-.401.325-.727.727-.727h.727v.727c0 .402.326.728.727.728.402 0 .728-.326.728-.728V2.91h5.818v.727c0 .402.325.728.727.728.402 0 .727-.326.727-.728V2.91h.728c.401 0 .727.326.727.727v2.182zm-9.455 4.364c.402 0 .728-.326.728-.727 0-.402-.326-.728-.728-.728-.401 0-.727.326-.727.728 0 .401.326.727.727.727zm0 2.909c.402 0 .728-.326.728-.727 0-.402-.326-.728-.728-.728-.401 0-.727.326-.727.728 0 .401.326.727.727.727z"
        transform="translate(.725)"
      />
    </g>
  </svg>
</template>
