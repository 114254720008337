import { LockersBaseEntityWithImage } from './lockersBaseEntity';

export enum BodyFinishSelectionType {
  HardcodedLightGray = 1,
  MatchFrameColor,
  NoFinish,
}

export default class Type extends LockersBaseEntityWithImage {
  readonly description: string;
  readonly isDefault: boolean;
  readonly isSolidSidesEnabled: boolean;
  readonly bodyFinishSelectionType: BodyFinishSelectionType;
}

export enum LockersTypesIds {
  EmperorStandard = 1,
  EmperorHeavyDuty,
  EmperorGladiator,
  GladiatorStandard,
  GladiatorEmperor,
  DividedLocker,
  OpenFrontLocker,
  ReplacementFront,
  BootracksWallMounted,
  BootracksStationary,
  BootracksMobile,
}
