import hadrianLoader from '@/shared/viewParts/hadrianLoader/hadrianLoader.vue';
import hadrianTitle from '@/shared/viewParts/hadrianTitle/hadrianTitle.vue';
import searchInput from '@/shared/viewParts/searchInput/searchInput.vue';
import DistributorsService from '@/endpoints/distributorsService';
import hPagination from '@/shared/viewParts/ui/h-pagination/h-pagination.vue';
import { OrganizationFlag } from '@/enums/organizationFlag';
import hadrianPage from '../hadrianPage/hadrianPage.vue';
import paths from '@/router/paths';
import pill from '@/shared/viewParts/pill/pill.vue';
import Organization from '@/models/permission/organization';
import { PillThemes } from '@/shared/viewParts/pill/pill';

interface OrganizationRow {
  product: string;
  organization: string;
  type: string;
  id: number;
  organizationId: string;
}

import { defineComponent } from 'vue';
import { vxManager } from '@/store/store';

export default defineComponent({
  name: 'organizations',
  components: {
    hadrianPage,
    hadrianLoader,
    hadrianTitle,
    searchInput,
    hPagination,
    pill,
  },
  data() {
    return {
      organizations: [] as Organization[],
      organizationsShown: [] as Organization[],
      loading: true,
      perPage: 20,
      currentPage: 1,
      search: '',
      PillThemes,
    };
  },
  beforeCreate() {
    if (process.env.VUE_APP_ORGANIZATIONS_SECTION_ENABLED === 'false') {
      this.$router.push({ name: 'projectsList' });
    }
  },
  async created() {
    const organizations = await DistributorsService.getAllOrganizations();
    this.organizations = organizations ?? [];
    this.organizationsShown = organizations ?? [];
    this.loading = false;
  },
  methods: {
    async onSearch() {
      if (this.organizations.length) {
        this.organizationsShown = this.organizations.filter(organization => {
          const searchLowerCase = this.search.toLowerCase();
          return (
            organization.name.toLowerCase().includes(searchLowerCase) ||
            organization.jdeCustomerId.toString().includes(searchLowerCase)
          );
        });
      }
    },
    isWD(row: OrganizationRow): boolean {
      return row['product'] === OrganizationFlag.WorldDryer;
    },
    organizationLink(row: OrganizationRow) {
      let link = `${paths.organizations}/${row.organizationId}`;

      if (vxManager.userModule.userCanEditPrintedInstructionsPreference) {
        link += '/generalPreferences';
      }

      return link;
    },
  },
  computed: {
    getColumns(): object[] {
      return [
        { key: 'product', width: 2, align: 'center' },
        { key: 'organization', width: 5, class: 'organizations-list__body--organization' },
        { key: 'type', width: 3 },
      ];
    },
    getRows(): OrganizationRow[] {
      return this.organizationsShown.map((organization: Organization, i) => {
        const jdeIdPlusOrganizationName = organization.jdeCustomerId.toString() + ' - ' + organization.name;
        return {
          product: organization.flag,
          organization: jdeIdPlusOrganizationName,
          type: organization.type,
          id: i,
          organizationId: organization.id,
        };
      });
    },
    getShownRows(): object[] {
      const startIndex = (this.currentPage - 1) * this.perPage;
      return this.getRows.slice(startIndex, startIndex + this.perPage);
    },
    userHasOrganizations(): boolean {
      return !!this.organizations?.length;
    },
    areShownOrganizations(): boolean {
      return !!this.organizationsShown?.length;
    },
  },
  watch: {
    search() {
      this.onSearch();
      this.currentPage = 1;
    },
  },
});
