import { action, mutation, Module, VuexModule } from 'vuex-class-component';
import DryersService from '@/endpoints/dryersService';
import CoverMaterials from '@/models/dryers/coverMaterials';
import DryerModels from '@/models/dryers/dryerModels';
import DryersData from '@/models/dryers/dryersData';
import Mountings from '@/models/dryers/mountings';
import Operations from '@/models/dryers/operations';
import Voltages from '@/models/dryers/voltages';

@Module()
export class DryersModule extends VuexModule {
  get allModels(): DryerModels[] {
    return this.dryersStaticData?.models ?? [];
  }
  get allCoverMaterials(): CoverMaterials[] {
    return this.dryersStaticData?.coverMaterials ?? [];
  }
  get allVoltages(): Voltages[] {
    return this.dryersStaticData?.voltages ?? [];
  }
  get allMountings(): Mountings[] {
    return this.dryersStaticData?.mountings ?? [];
  }
  get allOperations(): Operations[] {
    return this.dryersStaticData?.operations ?? [];
  }

  private dryersStaticData: DryersData;

  @action({ mode: 'mutate' })
  public async fetchStaticDryersData(projectId: string) {
    const response = await DryersService.getStaticDryersData(projectId);
    this.setStaticDryersData(response);
  }

  @mutation private setStaticDryersData(data: DryersData) {
    this.dryersStaticData = data;
  }
}
