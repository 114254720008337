import rdUnitType from '@/enums/rdUnitType';
import RdWallPilaster from '@/models/roomDesigner/rdWallPilaster';
import HadrianBase from '../hadrianBase';
import RdAlcoveParts from './rdAlcoveParts';
import RdAllowedDepths from './rdAllowedDepths';
import RdDoor from './rdDoor';
import RdExtendedDepthExtensions from './rdExtendedDepthExtensions';
import RdExtraWideExtensions from './rdExtraWideExtensions';
import RdWallPanel from './rdWallPanel';

export default class RdRoomUnit extends HadrianBase {
  readonly alcoveParts?: RdAlcoveParts;
  baseDepth: number;
  readonly baseDepthRule: number;
  readonly baseWidthRule: number;
  baseWidth: number;
  bowlCenterX: number;
  depth: number;
  readonly door: RdDoor;
  readonly extendedDepthExtensions?: RdExtendedDepthExtensions;
  readonly extraWideExtensions?: RdExtraWideExtensions;
  readonly index: number;
  readonly isNextToLeftWall: boolean;
  readonly isNextToRightWall: boolean;
  readonly leftPanel?: RdWallPanel;
  readonly panel?: RdWallPanel;
  readonly rightWallPilaster?: RdWallPilaster;
  readonly unitType: rdUnitType;
  readonly displayedUnitType: rdUnitType;
  readonly wallPilaster?: RdWallPilaster;
  width: number;
  readonly availableWidths: number[];
  readonly allowedDepths: RdAllowedDepths;
  readonly hasCrossBracing: boolean;
  readonly hasTdPilaster: boolean;
  readonly maxCrossBracingHeadrailLength: number;
  readonly affOptionRespectingAdaStandardsEnabledForAdaAndAmbUnits: boolean;
}

export const StandardUnitMinimumDistance = 12;
export const StandardUnitMinimumWidth = 24;
export const SmallUnitMinimumDistance = 6;
