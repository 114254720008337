import { defineComponent } from 'vue';
import { RouteLocationRaw } from 'vue-router';

export default defineComponent({
  name: 'hadrianRouterLink',
  props: {
    mainLabel: {
      type: String,
      required: true,
    },
    // A different label can be shown if the link is not clickable
    alternateLabel: {
      type: String,
    },
    url: {
      type: [String, Object as () => RouteLocationRaw],
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
    },
    customClass: {
      type: String,
    },
  },
  computed: {
    isClickable(): boolean {
      if (!this.url) {
        return false;
      }
      return this.clickable;
    },
    toUrl(): string | RouteLocationRaw {
      return this.url || '';
    },
    getAlternateLabel(): string {
      if (!this.alternateLabel) {
        return this.mainLabel;
      }
      return this.alternateLabel;
    },
  },
});
