import * as QuoteAndOrderHelper from '@/helpers/quoteAndOrderHelper';

import LockPreparationType from '@/models/lockers/lockPreparationType';
import CreationViewModel from '@/models/orders/CreationViewModel';
import ExtraKey from '@/models/orders/ExtraKey';
import LockEndUserInfo from '@/models/orders/LockEndUserInfo';
import Project from '@/models/project/project';
import { QuotePatchRequest, QuotePatchRequestArray } from '@/models/quotes/QuoteAPIInterfaces';
import Quote, { QuotesListItem } from '@/models/quotes/quote';
import Room from '@/models/room/room';
import { ProjectProductTypes } from '@/models/project/projectApiInterfaces';
import LockersHelper from './lockersHelper';
import QuoteableEntityRoom from '@/models/quoteableEntity/quoteableEntityRoom';

export enum FreightInformationOptionsPath {
  LiftGateTruckIsSelected = '/FreightInformationOptions/LiftGateTruckIsSelected',
  ResidentialAreaDeliveryIsSelected = '/FreightInformationOptions/ResidentialAreaDeliveryIsSelected',
  ScheduledAppointmentsIsSelected = '/FreightInformationOptions/ScheduledAppointmentsIsSelected',
  ReDeliveryCoverageIsSelected = '/FreightInformationOptions/ReDeliveryCoverageIsSelected',
  LimitedAccessIsSelected = '/FreightInformationOptions/LimitedAccessIsSelected',
}

/**
 * Builds a PATCH request with type safety assuring value is going to be
 * a value coming from the user's input.
 * The K here is a string which NEEDS to be included in QuoteCreationViewModel's keys.
 * This has 2 benefits:
 * - path is always going to be a valid property name
 * - we get type safety by being able to do viewModel[path]
 */
export function quotePatchRequestFromViewModel<K extends keyof CreationViewModel>(
  viewModel: CreationViewModel,
  path: K
): QuotePatchRequest<K> {
  return {
    path,
    quoteId: viewModel.id,
    projectId: viewModel.projectId,
    value: viewModel[path],
    op: 'replace',
  };
}

/// Same as above method but for nested objects properties in Quotes.
/// The difference being the path is different than the property's key.
export function quoteNestedPatchRequestFromViewModel<K extends keyof CreationViewModel>(
  viewModel: CreationViewModel,
  property: K,
  path: string
): QuotePatchRequest<K> {
  return {
    path,
    quoteId: viewModel.id,
    projectId: viewModel.projectId,
    value: viewModel[property],
    op: 'replace',
  };
}

/// The backend creates new rooms entities for each quotes we create.
/// If we need to change the rooms selection, we need the IDs from these
/// new entities.  This maps the IDs of the new entities to our Room object
/// so we can build the PATCH request later.
export function mapQuotedRoomsToRooms(rooms: Room[], quotedRooms: QuoteableEntityRoom[]) {
  rooms.forEach(room => {
    const associatedQuotedRoom = quotedRooms.find(quotedRoom => quotedRoom.originalAssociatedRoomId === room.id);
    if (associatedQuotedRoom) {
      room.quoteDuplicatedRoomId = associatedQuotedRoom.id;
      room.quoteAssociatedRoomId = associatedQuotedRoom.associatedRoomId;
    }
  });
}

/// Builds and returns valid patch operations corresponding to the user's
/// chnges in the quote process' first screen.
export function getPatchOperationsStepOne(
  quote: Quote,
  viewModel: CreationViewModel,
  project: Project | undefined
): QuotePatchRequestArray {
  const result: QuotePatchRequestArray = [];

  if (quote.title !== viewModel.title) {
    result.push(quotePatchRequestFromViewModel(viewModel, 'title'));
  }
  if (quote.additionalNotes !== viewModel.additionalNotes) {
    result.push(quotePatchRequestFromViewModel(viewModel, 'additionalNotes'));
  }
  if (quote.projectPartsAreAdded !== (viewModel.projectPartsAreMasterKeys ? false : viewModel.projectPartsAreAdded)) {
    result.push(quotePatchRequestFromViewModel(viewModel, 'projectPartsAreAdded'));
  }

  if (!!project && quote.partsNotes !== viewModel.partsNotes) {
    const bothPartsNotesAreEmpty = !viewModel.partsNotes && !quote.partsNotes;
    const partsNotesNeedPatch = !bothPartsNotesAreEmpty;
    if (partsNotesNeedPatch) {
      result.push(quotePatchRequestFromViewModel(viewModel, 'partsNotes'));
    }
  }

  const bothCustomersNotesAreEmpty = !quote.customersNotes && !viewModel.customersNotes;
  if (quote.customersNotes !== viewModel.customersNotes && !bothCustomersNotesAreEmpty) {
    result.push(quotePatchRequestFromViewModel(viewModel, 'customersNotes'));
  }

  const viewModelSpecialCharges = QuoteAndOrderHelper.unwrapSpecialChargesFromViewModel(viewModel);
  const bothSpecialChargesAreEmpty = !quote.specialCharges && !viewModel.specialCharges;
  if (quote.specialCharges !== viewModelSpecialCharges && !bothSpecialChargesAreEmpty) {
    result.push({
      path: 'specialCharges',
      quoteId: viewModel.id,
      projectId: viewModel.projectId,
      value: viewModelSpecialCharges,
      op: 'replace',
    });
  }

  if (quote.specialChargesDescription !== viewModel.specialChargesDescription) {
    result.push(quotePatchRequestFromViewModel(viewModel, 'specialChargesDescription'));
  }
  if (quote.numberPlateSequence !== viewModel.numberPlateSequence) {
    result.push(quotePatchRequestFromViewModel(viewModel, 'numberPlateSequence'));
  }
  if (!LockEndUserInfo.areEqual(quote.lockEndUserInfo, viewModel.lockEndUserInfo)) {
    result.push(quotePatchRequestFromViewModel(viewModel, 'lockEndUserInfo'));
  }

  const oldSelectedRoomIds = quote.selectedRooms.filter(room => room.isSelected).map(room => room.id);
  const newSelectedRoomIds = viewModel.selectedRooms.filter(room => room.isSelected).map(room => room.id);
  const oldAndNewSelectedRoomsHaveSameIds =
    oldSelectedRoomIds.length === newSelectedRoomIds.length &&
    oldSelectedRoomIds.every(id => newSelectedRoomIds.includes(id));

  if (!oldAndNewSelectedRoomsHaveSameIds) {
    result.push(quotePatchRequestFromViewModel(viewModel, 'selectedRooms'));
  }

  if (project?.productType === ProjectProductTypes.Lockers) {
    const projectHasLockWithKeys = LockersHelper.projectHasLocksWithKeys(project);
    if (projectHasLockWithKeys && !!viewModel.lockEndUserInfo) {
      result.push(quotePatchRequestFromViewModel(viewModel, 'extraKeys'));
    }
  }

  return result;
}

export function quoteItemsRequestToQueryString(request: any): string {
  let result = '';
  Object.keys(request).forEach(key => {
    if (request[key]) {
      result += `${key}=${request[key]}&`;
    }
  });
  return result;
}

export interface ExtraKeysByLockPreparationType {
  [lockPreparationTypeId: number]: {
    lockPreparationType: LockPreparationType;
    extraKeys: ExtraKey[];
  };
}

export function groupExtraKeysByLockPreparationType(
  extraKeys: ExtraKey[],
  lockPreparationTypes: LockPreparationType[]
): ExtraKeysByLockPreparationType {
  const extraKeysByLockPreparationType: ExtraKeysByLockPreparationType = {};

  extraKeys.forEach(extraKey => {
    const lockPreparationType = lockPreparationTypes.find(type => type.id === extraKey.lock?.lockPreparationTypesId);
    if (!lockPreparationType) {
      return;
    }

    extraKeysByLockPreparationType[lockPreparationType.id] = {
      lockPreparationType,
      extraKeys: [...(extraKeysByLockPreparationType[lockPreparationType.id]?.extraKeys || []), extraKey],
    };
  });

  return extraKeysByLockPreparationType;
}

export function hasMarkup(quote: QuotesListItem): boolean {
  return quote.organizationBranding?.markup != null;
}
