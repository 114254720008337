import { AssignableOrganizationType } from '@/enums/assignableOrganizationsType';
import MaterialCategories from '@/enums/materialCategories';
import PermissionTypes from '@/enums/permissionTypes';
import Material from '@/models/material/material';
import Organization from '@/models/permission/organization';
import Group from '@/models/permission/group';
import Permission from '@/models/permission/permission';
import User from '@/models/user/user';
import { vxManager } from '@/store/store';

export type HadrianPermissions = { [key in PermissionTypes]: boolean };

export default class PermissionsHelper {
  public static addToQueue(func: () => void) {
    this.queue.push(func);
  }

  // The permissions validations often depend on certain API calls to be resolved.
  // We delay executing validations with side effects until the required data is loaded in the store.
  public static runQueue() {
    do {
      const func = this.queue.shift();
      if (!func) {
        continue;
      }
      func();
    } while (this.queue.length);
  }

  public static mapPermissions(data: Permission[] = []): HadrianPermissions {
    return Object.fromEntries(
      Object.entries(PermissionTypes).map(([key, value]) => {
        if (isNaN(Number(value)) === false) {
          return [value as PermissionTypes, this.contains(data, value as PermissionTypes)]; // Todo refactor array to use enum names as keys ?
        }
        return [];
      })
    ) as HadrianPermissions;
  }

  public static analyzeUserPermissions(toCheck: PermissionTypes) {
    const userPermissions = vxManager.userModule.getUserPermissions;
    if (!userPermissions) {
      return false;
    }
    const result = userPermissions[toCheck];
    return result;
  }

  public static analyzePermissions(toCheck: PermissionTypes, organizationId: string) {
    const userPermissions = vxManager.userModule.getUserPermissions;
    if (!userPermissions) {
      return false;
    }
    let result = userPermissions[toCheck];
    const orgPermissions = vxManager.permissionsModule.getOrganizationPermissions(organizationId);
    if (orgPermissions) {
      result = orgPermissions[toCheck];
    }

    return result;
  }

  public static hasAccessToMaterial(material: Material, distributorId: string): boolean {
    if (!material.isEnabled) {
      return false;
    }
    if (material.category === MaterialCategories.Phenolic) {
      return PermissionsHelper.analyzePermissions(PermissionTypes.CanAccessPhenolic, distributorId);
    }
    return true;
  }

  private static queue: Array<() => void> = [];

  private static contains(data: Permission[], type: PermissionTypes): boolean {
    return data.some(p => p.permissionType === type);
  }
}

export const organizationsAssignableToGroup = (
  group: Group,
  distributors: Organization[],
  salesAgencies: Organization[]
): Organization[] => {
  switch (group.assignableOrganizationsType) {
    case AssignableOrganizationType.AllOrganizations:
      return salesAgencies.concat(distributors);
    case AssignableOrganizationType.OnlyDistributors:
      return distributors;
    case AssignableOrganizationType.OnlySalesAgencies:
      return salesAgencies;
    default:
      return [];
  }
};

export const organizationIsAssignableToUser = (
  user: User,
  org: Organization,
  distributors: Organization[],
  salesAgencies: Organization[]
): boolean => {
  switch (user.group.assignableOrganizationsType) {
    case AssignableOrganizationType.AllOrganizations:
      return true;
    case AssignableOrganizationType.OnlyDistributors:
      return !!distributors.find(dist => dist.id === org.id);
    case AssignableOrganizationType.OnlySalesAgencies:
      return !!salesAgencies.find(sa => sa.id === org.id);
    default:
      return false;
  }
};
