import HttpService from '@/endpoints/HttpService';
import HttpResponse from '@/interfaces/HttpResponse';
import { ProjectResponse } from '@/interfaces/responses/projectResponse';
import ShippingAddress from '@/models/addresses/shippingAddress';
import ShippingAddressCreateRequest from '@/models/addresses/shippingAddressCreateRequest';
import FiltersRequest from '@/models/part/filtersRequest';
import FiltersResponse from '@/models/part/filtersResponse';
import PatchOperation from '@/models/patchOperation/patchOperation';
import Discount from '@/models/project/discount';
import MarkupResponse from '@/models/project/markupResponse';
import Project from '@/models/project/project';
import {
  ChangePartsFinish,
  DuplicateNoteToRoomsRequest,
  ProjectsGetParams,
  ProjectsResponse,
  ProjectCreationRequest,
  SaveMarkupRequest,
} from '@/models/project/projectApiInterfaces';
import SpecialCharges from '@/models/project/specialCharges';
import Room from '@/models/room/room';
import { RoomPriceObject } from '@/models/room/roomApiInterfaces';
import User from '@/models/user/user';

export enum ProjectsPath {
  name = 'name',
  clientName = 'clientName',
  architectName = 'architectName',
  location = 'location',
  contractor = 'contractorName',
  distributor = 'organization',
  shipTo = 'shippingAddress',
  notes = 'notes',
  finish = '/Finish',
  material = '/Material',
  partition = '/Partition',
  discountOverride = 'discountOverride',
}

const projectsUrl = `${process.env.VUE_APP_API_PREFIX}projects`;
const noAuthUrl = `${process.env.VUE_APP_API_PREFIX}WizardNoAuthv1`;

export default class ProjectService {
  public static async getProjects(params: ProjectsGetParams): Promise<HttpResponse<ProjectsResponse>> {
    const response = await HttpService.get<ProjectsResponse>(projectsUrl, { params });
    return response;
  }

  public static async getProjectCount(params: ProjectsGetParams = {} as ProjectsGetParams): Promise<number> {
    const response = Object.keys(params).length
      ? await HttpService.get(`${projectsUrl}/count`, { params })
      : await HttpService.get(`${projectsUrl}/count`);
    return response.data;
  }

  public static async getProject(projectId: string): Promise<Project> {
    const response = await HttpService.getWithLoading<Project>(projectsUrl + `/${projectId}`);
    return response.data;
  }

  public static async getProjectDiscounts(projectId: string): Promise<Discount[]> {
    const response = await HttpService.getWithLoading<Discount[]>(projectsUrl + `/${projectId}/projectDiscounts`);
    return response.data;
  }

  public static async getProjectStripped(projectId: string): Promise<Project> {
    const response = await HttpService.getWithLoading<Project>(`${projectsUrl}/${projectId}/stripped`);
    return response.data;
  }

  public static async getProjectStrippedNoAuth(projectId: string): Promise<Project> {
    const response = await HttpService.getWithLoading<Project>(`${noAuthUrl}/${projectId}/stripped`);
    return response.data;
  }

  public static async getProjectHasSpecialColor(projectId: string): Promise<boolean> {
    const response = await HttpService.getWithLoading<boolean>(`${projectsUrl}/${projectId}/specialColored`);
    return response.data;
  }

  public static async updateRoomPrices(projectId: string): Promise<RoomPriceObject[]> {
    const response = await HttpService.getWithLoading(`${projectsUrl}/${projectId}/rooms/prices`);
    return response.data;
  }

  public static async getProjectCreator(projectId: string): Promise<User> {
    const response = await HttpService.getWithLoading<User>(projectsUrl + `/${projectId}/creator`);
    return response.data;
  }

  public static async createProject(project: ProjectCreationRequest): Promise<HttpResponse<ProjectResponse>> {
    const res = await HttpService.post(projectsUrl, project);
    return res;
  }

  /// If distributorId is defined, duplicate will be assigned to the provided distributor.
  public static async duplicateProject(projectId: string, distributorId?: string): Promise<ProjectResponse> {
    const res = await HttpService.postWithLoading(`${projectsUrl}/${projectId}`, { distributorId });
    return res.data;
  }

  public static async deleteProject(projectId: string) {
    const res = await HttpService.delete(`${projectsUrl}/${projectId}`);
    return res.data;
  }

  public static async deleteProjects(projects: string[]) {
    const res = await HttpService.delete(`${projectsUrl}`, { data: projects });
    return res.data;
  }

  public static async refreshProjectBom(projectId: string) {
    const res = await HttpService.post(`${projectsUrl}/${projectId}/bom/dirtyFlag`);
    return res.data;
  }

  public static async patchProject(projectId: string, operations: PatchOperation[]): Promise<Project> {
    const response = await HttpService.patch(`${projectsUrl}/${projectId}`, operations);
    return response.data.entity;
  }

  public static async patchProjectBowlCenterType(projectId: string, operations: PatchOperation[]): Promise<Project> {
    const response = await HttpService.patch(`${projectsUrl}/${projectId}/BowlCenterType`, operations);
    return response.data.entity;
  }

  public static async setProjectShippingAddress(
    projectId: string,
    address: ShippingAddressCreateRequest
  ): Promise<ShippingAddress> {
    const res = await HttpService.post(`${projectsUrl}/${projectId}/shippingAddress`, address);
    return res.data.entity;
  }

  public static async patchProjectRoomSettings(operation: PatchOperation[], projectId: string): Promise<void> {
    const response = await HttpService.patch(`${projectsUrl}/${projectId}/rooms`, operation);
    return response.data;
  }

  public static async getProjectSpecialCharges(projectId: string): Promise<SpecialCharges[]> {
    const response = await HttpService.getWithLoading<SpecialCharges[]>(projectsUrl + `/${projectId}/specialCharges`);
    return response.data;
  }

  public static async reorderRooms(projectId: string, roomOrder: Record<string, number>): Promise<Room[]> {
    const response = await HttpService.postWithLoading(`${projectsUrl}/${projectId}/reOrder/`, roomOrder);
    return response.data.entities;
  }

  public static async deleteRooms(projectId: string, roomIds: string[]): Promise<void> {
    await HttpService.deleteWithLoading(`${projectsUrl}/${projectId}/rooms/`, { data: roomIds });
  }

  public static async deleteAddress(projectId: string, addressId: string): Promise<void> {
    await HttpService.deleteWithLoading(`${projectsUrl}/${projectId}/address/${addressId}`);
  }

  public static async duplicateRooms(
    projectId: string,
    roomIds: string[],
    quantity: number,
    mirror: boolean
  ): Promise<Room[]> {
    const response = await HttpService.postWithLoading(`${projectsUrl}/${projectId}/rooms/`, {
      roomIds,
      quantity,
      mirror,
    });
    return response.data.entity;
  }

  public static async duplicateNoteToRooms(data: DuplicateNoteToRoomsRequest): Promise<void> {
    await HttpService.postWithLoading(`${projectsUrl}/duplicateNote/`, data);
  }

  public static async changeProjectPartsFinish(data: ChangePartsFinish) {
    const res = await HttpService.postWithLoading(`${projectsUrl}/${data.projectId}/partsFinish`, data);
    return res.data.entities;
  }

  public static async getDefaultsForFilters(params: FiltersRequest): Promise<FiltersResponse> {
    const response = await HttpService.post(`${process.env.VUE_APP_API_PREFIX}defaults/filters`, params);
    return response.data.entity;
  }

  public static async isProjectQuotable(projectId: string): Promise<boolean> {
    const response = await HttpService.getWithLoading<boolean>(`${projectsUrl}/${projectId}/isQuotable`);
    return response.data;
  }

  public static async isProjectInConflict(projectId: string): Promise<boolean> {
    const response = await HttpService.get(`${projectsUrl}/${projectId}/inConflict`);
    return response.data;
  }

  public static async getProjectMarkup(projectId: string): Promise<MarkupResponse> {
    const response = await HttpService.get(`${projectsUrl}/${projectId}/markup`);
    return response.data;
  }

  public static async saveProjectMarkup(request: SaveMarkupRequest): Promise<MarkupResponse> {
    const response = await HttpService.post(`${projectsUrl}/${request.projectId}/markup`, { markup: request.markup });
    return response.data;
  }

  public static async resetProjectMarkup(projectId: string): Promise<MarkupResponse> {
    const response = await HttpService.delete(`${projectsUrl}/${projectId}/markup`);
    return response.data;
  }
}
