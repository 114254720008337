import EventBus from '@/eventBus';
import Events from '@/events';
import NumberFormatter from '@/helpers/numberFormatter';
import Toast from '@/helpers/toast';
import { FilterType } from '@/models/filterBox/filterBox';
import cancel from '@/shared/icons/cancel/cancel.vue';
import price from '@/shared/icons/price/price.vue';
import { defineComponent } from 'vue';
import inputGroup from '../inputGroup/inputGroup.vue';

export default defineComponent({
  name: 'priceRangeSelector',
  components: {
    price,
    cancel,
    inputGroup,
  },
  props: {
    minPrice: {
      type: Number,
    },
    maxPrice: {
      type: Number,
    },
    onSelectMinPrice: {
      type: Function,
    },
    onSelectMaxPrice: {
      type: Function,
    },
  },
  data() {
    return {
      isToggled: false,
      tempMinPrice: this.minPrice ?? undefined,
      tempMaxPrice: this.maxPrice ?? undefined,
      minError: '',
      maxError: '',
    };
  },
  created() {
    EventBus.$on(Events.onDeleteFilter, this.clear);
  },
  beforeUnmount() {
    EventBus.$off(Events.onDeleteFilter, this.clear);
  },
  computed: {
    currentLabel(): string {
      if (this.isOpenAndNoRangeSelected) {
        return '';
      } else if (this.noRangeSelected) {
        return this.$t('labels.selectRange').toString();
      }
      return NumberFormatter.formatPriceRange(this.minPrice, this.maxPrice);
    },
    noRangeSelected(): boolean {
      return !this.minPrice && !this.maxPrice;
    },
    isOpenAndNoRangeSelected(): boolean {
      return this.noRangeSelected && this.isToggled;
    },
  },
  methods: {
    apply() {
      const min = this.tempMinPrice;
      const max = this.tempMaxPrice;
      if (this.validatePrice(min, max)) {
        this.onSelectMinPrice && this.onSelectMinPrice(min);
        this.onSelectMaxPrice && this.onSelectMaxPrice(max);
        this.toggleSelector();
      }
    },
    clear(filterType: FilterType) {
      if (filterType === FilterType.PriceRange) {
        this.tempMinPrice = undefined;
        this.tempMaxPrice = undefined;
        this.onSelectMinPrice && this.onSelectMinPrice();
        this.onSelectMaxPrice && this.onSelectMaxPrice();
      }
    },
    toggleSelector(): void {
      this.isToggled = !this.isToggled;
    },
    validatePrice(min: number | undefined, max: number | undefined): boolean {
      const maximumValue = 99999999;
      // cannot be negative
      if (min && min < 0) {
        Toast.error({ body: 'Cannot input negative number' });
        return false;
      }
      if (max && max < 0) {
        Toast.error({ body: 'Cannot input negative number' });
        return false;
      }
      // min cannot be over max
      if (!!min && !!max && min > max) {
        Toast.error({ body: 'Minimum cannot be greater than maximum' });
        return false;
      }
      // cannot be over 99M
      if ((!!min && min > maximumValue) || (!!max && max > maximumValue)) {
        Toast.error({ body: 'Min or max cannot be greater than 99,999,999' });
        return false;
      }
      return true;
    },
  },
});
