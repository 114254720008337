<template>
  <svg width="30" height="31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.476 5.024A14.818 14.818 0 1 0 4.524 25.976 14.818 14.818 0 1 0 25.476 5.024Z"
      fill="#D10505"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.496 10.002a1.482 1.482 0 0 0-2.104 0L15 13.41l-3.393-3.408a1.488 1.488 0 0 0-2.104 2.104L12.91 15.5l-3.408 3.394a1.482 1.482 0 0 0 0 2.104 1.481 1.481 0 0 0 2.104 0l3.393-3.408 3.393 3.408a1.481 1.481 0 0 0 2.104 0 1.482 1.482 0 0 0 0-2.104l-3.408-3.394 3.408-3.393a1.482 1.482 0 0 0 0-2.104Z"
      fill="#fff"
    />
  </svg>
</template>
