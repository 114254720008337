import UpdateService from '@/endpoints/updateService';
import { HadrianUpdatesData, HadrianUpdateType } from '@/shared/viewParts/hadrianUpdates/hadrianUpdates';

import { action, mutation, Module, VuexModule } from 'vuex-class-component';
export interface HadrianUpdatesDict {
  Type: HadrianUpdateType;
  Data: HadrianUpdatesData[];
}

@Module()
export class UpdatesModule extends VuexModule {
  private userUpdates: HadrianUpdatesDict[] = [];

  @action({ mode: 'mutate' })
  public async fetchUpdates(updateType: HadrianUpdateType) {
    const userInfo = await UpdateService.getUpdates(updateType);
    this.setUserUpdates({
      Type: updateType,
      Data: (userInfo as HadrianUpdatesData[]).map(x => {
        return {
          updateDate: new Date(x.updateDate),
          updateType: x.updateType,
          title: x.title,
          description: x.description,
        };
      }),
    });
  }

  @action({ mode: 'mutate' })
  public async saveUserDismiss(updateType: HadrianUpdateType) {
    UpdateService.saveUserDismiss(updateType);
  }

  @mutation private setUserUpdates(updates: HadrianUpdatesDict) {
    const currentUpdates = this.userUpdates.findIndex(x => x.Type === HadrianUpdateType.drawingUpdates);
    if (currentUpdates >= 0) {
      this.userUpdates[currentUpdates] = updates;
    } else {
      this.userUpdates.push(updates);
    }
  }

  get getUserUpdates() {
    return this.userUpdates;
  }
}
