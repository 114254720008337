import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "h-options__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_iconDots = _resolveComponent("iconDots")!

  return (_openBlock(), _createElementBlock("div", {
    class: "h-options",
    onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isOpen = false))
  }, [
    _createElementVNode("button", {
      type: "button",
      class: "h-options__button",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.isOpen = !_ctx.isOpen), ["stop"]))
    }, [
      _renderSlot(_ctx.$slots, "icon", {}, () => [
        _createVNode(_component_iconDots)
      ])
    ]),
    (_ctx.isOpen)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "h-options__content-wrapper",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isOpen = false))
        }, [
          _createElementVNode("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ]))
      : _createCommentVNode("", true)
  ], 32))
}