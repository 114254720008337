import HttpService from '@/endpoints/HttpService';
import { ProjectsGetParams } from '@/models/project/projectApiInterfaces';
import { AxiosResponse } from 'axios';

export default class GenerateCsvService {
  public static async getProjectPartsSummary(projectId: string) {
    const url = `${GenerateCsvService.getApiUrl()}projects/${projectId}/parts/csv`;
    const response = await HttpService.post(url);
    this.downloadFile(response);
  }

  public static async getProjectBomAsCsv(projectId: string, withPrices: boolean) {
    const url = `${GenerateCsvService.getApiUrl()}projects/${projectId}/bom/csv?showPrices=${withPrices}`;
    const response = await HttpService.post(url);
    this.downloadFile(response);
  }

  public static async getQuoteBomAsCsv(quoteId: string, withPrices: boolean) {
    const url = `${GenerateCsvService.getApiUrl()}quotes/${quoteId}/bom/csv?showPrices=${withPrices}`;
    const response = await HttpService.get(url);
    this.downloadFile(response);
  }

  public static async getOrderBomAsCsv(orderId: string, withPrices: boolean) {
    const url = `${GenerateCsvService.getApiUrl()}orders/${orderId}/bom/csv?showPrices=${withPrices}`;
    const response = await HttpService.get(url);
    this.downloadFile(response);
  }

  public static async getProjectListAsCsv(params: ProjectsGetParams) {
    const url = `${GenerateCsvService.getApiUrl()}projects/csv`;
    const response = await HttpService.get(url, { params });
    this.downloadFile(response);
  }

  public static async getOrderListAsCsv(params: ProjectsGetParams) {
    const url = `${GenerateCsvService.getApiUrl()}orders/csv`;
    const response = await HttpService.get(url, { params });
    this.downloadFile(response);
  }

  private static getApiUrl() {
    return `${process.env.VUE_APP_SERVER_URL}${process.env.VUE_APP_API_PREFIX}`;
  }

  private static downloadFile(response: AxiosResponse<any>) {
    const fileName = this.getFileNameFromResponse(response);
    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
    const fileLink = document.createElement('a');
    fileLink.href = fileURL;
    fileLink.setAttribute('download', fileName);
    document.body.appendChild(fileLink);
    fileLink.click();
  }

  private static getFileNameFromResponse(response: AxiosResponse<any>): string {
    const missing = 'Missing-file-name.csv';
    const header: string = response.headers['content-disposition'];
    const marker = "UTF-8''";
    const index = header?.indexOf(marker) || -1;
    if (index >= 0) {
      const fileName = header.substring(index + marker.length);
      return fileName ? decodeURIComponent(fileName) : missing;
    }
    return missing;
  }
}
