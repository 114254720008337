import { FractionsHelper } from '@/helpers/fractionsHelper';
import i18n from '@/i18n';

const AMOUNT_REGEX: (decimals: number) => RegExp = decimals => new RegExp(`^\\d+(\\.\\d{0,${decimals}}){0,1}$`, 'i');

export default class NumberFormatter {
  public static formatAmount(input: string, decimals = 2): string {
    let newString = input;
    if (!AMOUNT_REGEX(decimals).test(input)) {
      newString = '';
      for (const letter of input) {
        const temp = newString + letter;
        if (AMOUNT_REGEX(decimals).test(temp)) {
          newString += letter;
        }
      }
    }
    return NumberFormatter.addThousandsSeparator(newString);
  }

  public static getNumberWithOrdinal(n: number | null) {
    if (n == null) {
      return '';
    }
    const s = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  public static addTrailingZeroes(value: string, decimalCount: number): string {
    const decimalIndex = value.indexOf('.');
    if (decimalIndex === -1) {
      return value;
    }
    return value.padEnd(decimalIndex + decimalCount + 1, '0');
  }

  public static getNumberAsCurrency(value: number) {
    const val = (value / 1).toFixed(2);
    return NumberFormatter.addThousandsSeparator(val.toString());
  }

  public static formatNumberAsCurrency(value: number): string {
    const n = this.getNumberAsCurrency(value).replace('.00', '');
    return `$${n}`;
  }

  public static formatPriceRange(min: number | undefined, max: number | undefined): string {
    if (min == null && max == null) {
      return '';
    }

    const minPrice = min != null ? '$' + this.getNumberAsCurrency(min).replace('.00', '') : '';
    const maxPrice = max != null ? '$' + this.getNumberAsCurrency(max).replace('.00', '') : '';

    let prefix = '';
    if (!!min && !max) {
      prefix = i18n.global.t('generic.atLeast').toString();
    }

    if (!!max && !min) {
      prefix = i18n.global.t('generic.atMost').toString();
    }

    const priceLabel = [minPrice, maxPrice].filter(Boolean).join(' - ');
    return [prefix, priceLabel].filter(Boolean).join(' ');
  }

  public static addThousandsSeparator(value: string): string {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  public static getFormattedWeights(weight: number | undefined, weightKg: number | undefined): string {
    const formatedKg = (weightKg || 0).toLocaleString('en-US', { maximumFractionDigits: 2 });
    const formatedLbs = (weight || 0).toLocaleString('en-US', { maximumFractionDigits: 2 });
    return `${formatedKg} kg / ${formatedLbs} lbs`;
  }

  public static containsValidFraction(value: number) {
    const decimals = value % 1;
    const hasDecimal = decimals > 0.0001;
    const fraction = hasDecimal ? FractionsHelper.getFormattedInchesFractionFromDecimalValue(decimals) : '';
    return hasDecimal && fraction.length <= 6;
  }

  public static formatWithDecimals(value: number, decimalCount: number): string {
    if (decimalCount < 0) {
      return value.toString();
    }

    const normalizer = 10 ** decimalCount;
    return (Math.round(value * normalizer) / normalizer).toFixed(decimalCount);
  }

  public static clamp(value: number, min: number, max: number) {
    return Math.min(Math.max(value, min), max);
  }

  public static formatInteger(value: number): string {
    return value.toLocaleString('en-US');
  }

  public static strictParseInt(input: string): number {
    input = input.replace(/,/g, '');

    if (!/^-?\d+$/.test(input)) {
      return NaN;
    }

    return parseInt(input, 10);
  }

  public static strictParseFloat(input: string): number {
    // remove commas from the decimal portion of the string
    const numberParts = input.split('.');

    if (numberParts.length > 2) {
      return NaN;
    }

    numberParts[0] = numberParts[0].replaceAll(',', '');
    input = numberParts.join('.');

    // any number of digits, optionally followed by a . and at least one digit
    if (!/^-?\d*(\.\d+)?$/.test(input)) {
      return NaN;
    }

    return parseFloat(input);
  }

  public static strictToFixed(input: number, precision: number): string {
    return input % 1
      ? (input || 0).toLocaleString('en-US', { maximumFractionDigits: precision })
      : input.toLocaleString('en-US');
  }

  public static formatOrdinals(number: number): string {
    const pr = new Intl.PluralRules('en-US', { type: 'ordinal' });
    const suffixes = new Map([
      ['one', 'st'],
      ['two', 'nd'],
      ['few', 'rd'],
      ['other', 'th'],
    ]);

    const rule = pr.select(number);
    const suffix = suffixes.get(rule);
    return `${number}${suffix}`;
  }
}
