<template>
  <svg width="30" height="31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15.5" r="15" fill="#0A8A17" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.566 19.594a1.26 1.26 0 0 1-1.847 0l-2.863-3.017a1.431 1.431 0 0 1 0-1.947 1.26 1.26 0 0 1 1.847 0l1.706 1.798a.318.318 0 0 0 .466 0l4.618-4.867a1.26 1.26 0 0 1 1.847 0c.245.258.384.609.384.974s-.139.714-.384.973l-5.774 6.086Z"
      fill="#fff"
    />
  </svg>
</template>
