import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, Fragment as _Fragment, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "related-parts-modal" }
const _hoisted_2 = { class: "related-parts-modal__content" }
const _hoisted_3 = {
  key: 0,
  class: "related-parts-modal__title related-parts-modal__title--more-space"
}
const _hoisted_4 = {
  key: 1,
  class: "related-parts-modal__title"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 0,
  class: "related-parts-modal__related-item-title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_relatedPartsTable = _resolveComponent("relatedPartsTable")!
  const _component_hadrianModal = _resolveComponent("hadrianModal")!

  return (_openBlock(), _createBlock(_component_hadrianModal, {
    class: "related-parts-modal",
    ref: "suggestedPartsModal",
    leftButtonText: _ctx.$t('generic.skip'),
    rightButtonText: _ctx.$t('modal.actions.save'),
    onRightButtonClick: _ctx.rightClick,
    onLeftButtonClick: _ctx.leftClick,
    noCloseOnBackdrop: "",
    hideHeader: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.hasSuggestedPartsOnly)
            ? (_openBlock(), _createElementBlock("h5", _hoisted_3, _toDisplayString(_ctx.$t('modal.suggestedParts.relatedToThisItemTitle')), 1))
            : (_openBlock(), _createElementBlock("h5", _hoisted_4, _toDisplayString(_ctx.$t('modal.suggestedParts.relatedTitle')), 1)),
          (!_ctx.hasSuggestedPartsOnly)
            ? (_openBlock(), _createElementBlock("p", {
                key: 2,
                class: "related-parts-modal__text",
                innerHTML: 
            _ctx.$t(_ctx.removeOrAddKey, {
              partNumber: _ctx.currentPartNumber,
            })
          
              }, null, 8, _hoisted_5))
            : _createCommentVNode("", true),
          (!!_ctx.temporaryParts.autoAdded && _ctx.temporaryParts.autoAdded.length > 0)
            ? (_openBlock(), _createBlock(_component_relatedPartsTable, {
                key: 3,
                partsData: _ctx.partsData,
                temporaryParts: _ctx.temporaryParts,
                isRemovingPart: _ctx.isRemovingPart
              }, null, 8, ["partsData", "temporaryParts", "isRemovingPart"]))
            : _createCommentVNode("", true),
          (!_ctx.isRemovingPart && !!_ctx.temporaryParts.suggested && _ctx.temporaryParts.suggested.length > 0)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                (!_ctx.hasSuggestedPartsOnly)
                  ? (_openBlock(), _createElementBlock("h6", _hoisted_6, _toDisplayString(_ctx.$t('modal.suggestedParts.relatedToItem')), 1))
                  : _createCommentVNode("", true),
                _createVNode(_component_relatedPartsTable, {
                  partsData: _ctx.partsData,
                  temporaryParts: _ctx.temporaryParts,
                  isSuggestedTable: ""
                }, null, 8, ["partsData", "temporaryParts"])
              ], 64))
            : _createCommentVNode("", true)
        ])
      ])
    ], undefined, true),
    _: 1
  }, 8, ["leftButtonText", "rightButtonText", "onRightButtonClick", "onLeftButtonClick"]))
}