const en = {
  generic: {
    back: 'Back',
    cancel: 'Cancel',
    submit: 'Submit',
    delete: 'Delete',
    yesDelete: 'Yes, Delete',
    create: 'Create',
    close: 'Close',
    confirm: 'confirm',
    dontSkip: 'Do not skip',
    edit: 'Edit',
    ok: 'Ok',
    all: 'All',
    okay: 'Okay',
    quote: 'Quote',
    order: 'Order',
    no: 'No',
    notApplicable: 'N/A',
    previous: 'Previous',
    preview: 'Preview',
    next: 'Next',
    yes: 'Yes',
    skip: 'Skip',
    add: 'Add',
    update: 'Update',
    apply: 'Apply',
    warning: 'Warning',
    continue: 'Continue',
    resume: 'Resume',
    dateFilterBefore: 'or before',
    dateFilterLater: 'or later',
    atMost: 'At most',
    atLeast: 'At least',
    searchTooltip: 'Press the blue button to start the search',
    refresh: 'Refresh',
    generate: 'Generate',
    save: 'Save',
    on: 'ON',
    off: 'OFF',
    product: 'product',
    organization: 'organization',
    type: 'type',
    search: 'Search',
    colorTba: 'Color TBA',
    generalTermsAndConditions: 'General terms and conditions',
    activity: 'activity',
    showMore: 'Show More',
    new: 'New',
    error: 'An error has occured',
    declined: 'Declined',
  },
  datePicker: {
    from: 'From:',
    to: 'To:',
  },
  wysiwyg: {
    paragraph: 'paragraph',
    title: 'title',
  },
  activity: {
    sidemenu: {
      releaseNotes: 'Release Notes',
      articles: 'News & Updates',
      expediteRequests: 'Order Expedite Requests',
    },
    releaseNotesList: {
      noReleaseNotesPublished: 'No release notes have been published yet.',
      noReleaseNoteCorrespondingToSearch: 'No release notes correspond to your search.',
      errors: {
        fetchingReleaseNotes: 'An error occurred while trying to get release notes.',
        fetchingReleaseNote: 'An error occurred while trying to get release note.',
      },
      releaseNotesPrompt: 'See all previous release notes >',
    },
    articlesList: {
      noArticle: 'No articles have been published yet',
      noArticleCorrespondingToSearch: 'No articles correspond to your search.',
      errors: {
        fetchingArticles: 'An error occurred while trying to get articles.',
        fetchingArticle: 'An error occurred while trying to get article.',
      },
    },
    expediteRequests: {
      couldntAccomodateExpediteRequest: 'We could not accommodate the Order Expedite Request',
      noOrderExpediteRequests: 'No current order expedite requests',
      noOrderExpediteRequestsForFilter: 'No results correspond to your search',
      manage: 'Manage',
      details: 'Details',
      search: 'Search',
      errors: {
        fetchingOrderExpediteRequests: 'An error occurred while trying to get order expedite requests.',
      },
      filters: {
        status: {
          placeholder: 'All Statuses',
        },
        area: {
          placeholder: 'All Sales Area',
        },
      },
      table: {
        header: {
          createdAt: 'Requested ON',
          status: 'Status',
          jdeSalesOrderNumber: 'Sales Order #',
          order: 'Orders',
          project: 'Project',
        },
      },
      modal: {
        title: 'Order Expedite Request',
        close: 'Close',
        submit: 'Submit',
        accept: 'Accept',
        decline: 'Decline',
        edit: 'Edit Request',
        manageSuccess: {
          accept: 'This Order Expedite Request has been accepted.',
          decline: 'This Order Expedite Request has been declined.',
          edit: 'This Order Expedite Request has been edited.',
          declinedByPlanner: 'This Order Expedite Request has been declined.',
          process: {
            pcn: 'Your date change has been successfully processed in PCN.',
            jde: 'Your date change has been successfully processed in JDE.',
          },
        },
        manageFailed: {
          process: {
            pcn: 'Something went wrong while processing the date change in PCN. Please try again.',
            jde: 'Something went wrong while processing the date change in JDE. Please try again.',
          },
        },
        propositionResponse: {
          note: 'Note',
          propositionTitle: 'Planning Proposal',
          responseTitle: 'Response',
          accept: 'Accept',
          decline: 'Decline',
          expediteProposal: 'Expedite Proposal',
          submit: 'Submit',
          close: 'Close',
          acceptBanner:
            'Your response will be forwarded to the Planning Department. Please note that it may take some time for the date change to be reflected on Project Center Next.',
          declineBanner:
            'By declining the planning proposition, the original ship date will be preserved. For further assistance, feel free to call our Customer Service Department: 440-942-9118 (USA) or 905-333-0300 (Canada)',
          success: {
            accept: 'The Order Expedite Request has been accepted successfully.',
            decline: 'The Order Expedite Request has been declined successfully.',
          },
          error: {
            canceled: 'The Order Expedite Request has been canceled by user.',
            changedStatus: 'The Order Expedite Request has meanwhile changed status.',
          },
        },
        details: {
          userResponse: 'User Response',
          respondedOn: 'Responded On',
          expiration: 'Proposal Expiration',
          acceptedBy: 'Accepted by',
          acceptedOn: 'Accepted on',
          declinedBy: 'Declined by',
          declinedOn: 'Declined on',
          processedBy: 'Processed by',
          processedOn: 'Processed on',
          accept: 'Accepted',
          decline: 'Declined',
          requestedBy: 'Requested by',
          newShipDate: 'New Ship Date',
          note: 'Note',
          respondedBy: 'Responded by',
          proposal: 'Expedite Proposal',
          reviewedBy: 'Reviewed by',
          review: 'Review',
          decision: 'Decision',
          requestDate: 'Expedite Request Date',
          requestStatus: 'Request Status',
          reason: 'Reason',
          planningResponse: 'Planning Response',
          orderExpediteRequest: 'Order Expedite Request',
          activeInfosHeader: {
            requestStatus: 'Request Status',
          },
          planningInfosHeader: {
            orderExpediteRequest: 'Order Expedite Request',
            planningResponse: 'Planning Response',
          },
          action: {
            accept: 'Accepted',
            proposition: 'Proposal',
            process: 'Accepted',
            decline: 'Declined',
          },
        },
      },
      statusChange: {
        newRequest: {
          alreadyManaged: 'The Order Expedite Request has already been processed by someone else.',
          canceled: 'The Order Expedite Request has been canceled by user.',
        },
        generic: 'The Order Expedite Request has meanwhile changed status.',
      },
      actions: {
        cancel: {
          label: 'Cancel Request',
          success: 'The Order Expedite Request has been canceled successfully.',
          modal: {
            title: 'Are your sure you want to cancel this request?',
            body: 'This action is not reversible.',
            confirm: 'Proceed',
          },
          errors: {
            generic: 'An error occured while canceling the Order Expedite Request.',
            alreadyCanceled: 'The Order Expedite Request has been canceled already.',
            alreadyManaged: 'The Order Expedite Request has been managed already.',
          },
        },
      },
      process: {
        process: 'Process',
        warning: 'This information will be sent to JDE and the ship date will be updated.',
        force: {
          title:
            'Due to an error, we are unable to transmit the status update to JDE. Would you like to proceed manually?',
          body: 'Confirm that the necessary changes have been made manually in JDE, then proceed with submission. Upon submission, the status will change from "Accepted" to "Processed" and it will no longer be editable in Project Center Next.',
          button: 'Force status change',
        },
        failed: {
          canceled: 'The Order Expedite Request has meanwhile been canceled.',
          managed: 'The Order Expedite Request has already been processed by someone else.',
        },
      },
    },
    header: {
      expediteRequests: 'Expedite Requests',
      articles: 'News & Updates',
      releaseNotes: 'Release Notes',
    },
  },
  toasts: {
    info: 'Information',
    error: 'Error',
    warning: 'Warning',
    success: 'Success',
  },
  countries: {
    CA: 'Canada',
    US: 'United States',
  },
  errors: {
    noFreightError: 'No freight providers were found',
    freightServiceUnavailable:
      'Freight prices cannot be calculated at the moment. Please call your Customer Sales Representative to get a quote or try again later.',
    redirectToHomepage: `The requested page could not be loaded.\nThe page either does not exist or you do not have access to it.`,
    refreshTokenError: 'There was an issue with the authentication process.  Please log back in.',
    lockerStyleNotFound: 'The locker style you are trying to edit no longer exists.',
  },
  export: {
    bom: 'BOM as CSV file was successfully downloaded.',
    parts: 'Parts summary as CSV file was successfully downloaded.',
    projectList: 'Projects list as CSV file was successfully downloaded.',
    orderList: 'Orders list as CSV file was successfully downloaded.',
  },
  toastification: {
    serverError: 'There was a problem loading part of the page data',
  },
  pageTabTiles: {
    generic: 'PROJECT CENTER NEXT',
    pdf: 'LOADING PDF',
  },
  updateModal: {
    title: 'Project Center Next Update',
    main: 'Project Center Next has been updated.',
    sub: 'You will be automatically logged out. You must log in again to continue.',
  },
  browserNotSupportedModal: {
    title: 'Browser not supported',
    text: 'To use Project Center Next, <b>download or upgrade to the latest version*</b> of Chrome, Firefox or Safari:',
    subText: '*Some older versions of supported browsers may become incompatible with Project Center Next over time.',
  },
  problemOccured: {
    title: 'A problem has occurred.',
  },
  mainMenu: {
    menu: {
      articles: 'News & Updates',
      newsAndUpdates: 'News & Updates',
      releaseNotes: 'Release Notes',
      expediteRequests: 'Order Expedite Requests',
      orderExpediteRequests: 'Order Expedite Requests',
      activity: 'Activity',
      profile: 'Profile',
      projects: 'Projects',
      orders: 'Orders',
      organizations: 'Organizations',
      users: 'Users',
      news: 'News',
      contactUs: 'Contact Us',
      termsAndConditions: 'License Agreement',
      legalNotice: 'Legal Notice',
      privacyPolicy: 'Privacy Policy',
      logout: 'Logout',
      quickStartGuide: 'Quick Start Guide (PDF)',
      documentation: 'Documentation',
      documentationItems: {
        resourceCenter: 'Resource Center',
        licenseAgreement: 'License Agreement',
        privacyPolicy: 'Privacy Policy',
        resourceAdmin: 'Resource Admin',
      },
      feedback: 'Give your Feedback',
      hadrianMail: "mail{'@'}hadrian-inc.com",
      canadaNumber: '905-333-0300',
      usaNumber: '440-942-9118',
      hadrianWebSite: 'www.hadrian-inc.com',
      counterSales: 'Counter sales',
    },
    help: {
      title: 'Need Help?',
      email: 'Email',
      callUsa: 'USA',
      callCanada: 'Canada',
      moreInfo: 'For more information, visit:',
    },
  },
  feedbackModal: {
    title: 'Feedback',
    subtitle: 'Want to give us your feedback?',
    subtitleMessage: 'Tell us about your experience on Project Center Next by filling out the form below.',
    infoMessage:
      "<b>To report a bug,</b> please send an email to <a href='mailto:bugs{'@'}hadrian-inc.com'>bugs{'@'}hadrian-inc.com</a> with a description of the bug and, if you can, the steps that caused the problem you are reporting. If need be, please include a screenshot or a recording.",
    subject: 'Subject',
    subjectWarning: '(Orders, Quotes, Drawing, etc.)',
    subjectRules: 'Maximum of 60 characters.',
    message: 'Message',
    messageRules: 'Maximum of 2000 characters.',
    send: 'Send',
    close: 'Close',
    errorMessage: 'There was an error sending the mail.',
  },
  labels: {
    total: 'Total',
    close: 'Close',
    subtotal: 'Subtotal',
    quantity: 'Quantity',
    search: 'Search',
    searchPart: 'Search',
    filter_by: 'FILTER BY',
    allTypes: 'All filters',
    colors: 'COLORS',
    allMaterials: 'All materials',
    allSeries: 'All series',
    allColors: 'All colors',
    allPartCategories: 'All part categories',
    allPartitionTypes: 'All partition types',
    allHardwareMaterial: 'All hardware materials',
    allMountings: 'All mountings',
    allOperations: 'All operations',
    allVoltages: 'All voltages',
    reset: 'Reset to project settings',
    resetToProject: 'Reset to project settings',
    resetToRoom: 'Reset to room settings',
    clear: 'Clear filters',
    materialFilterLabel: 'Material',
    seriesFilterLabel: 'Series',
    partitionTypeFilterLabel: 'Partition Type',
    partCategoriesFilterLabel: 'Part Categories',
    finishFilterLabel: 'Finish',
    coverMaterialLabel: 'Material',
    mountingLabel: 'Mounting / ADA',
    operationLabel: 'Operation',
    voltageLabel: 'Voltage',
    hardwareMaterialFilterLabel: 'Hardware Material',
    measureFieldPlaceholder: 'Enter value',
    comingSoon: 'Coming soon',
    rangeNotice: 'Enter a dimension between {min} and {max}',
    dimensionInfo: 'Dimension range is based on your unit width',
    adjustPilasters: 'Adjust pilasters and or doors to decrease or increase range or change working direction',
    greatherThanNotice: 'Value must be at least {min}.',
    smallerThanNotice: 'Value must be at most {max}.',
    unavailable: 'COMING SOON',
    selectionUnavailable: 'SELECTION UNAVAILABLE',
    materialComingSoon: 'MATERIAL SWITCH <br/> COMING SOON',
    mandatoryOption: 'This option is mandatory with your current selection.',
    conditionalMandatory: 'This option is mandatory when Continuous Channels are selected.',
    projectType: 'Project Type',
    productType: 'Product Type',
    salesAgencies: 'Sales Agencies',
    distributors: 'Distributors',
    organizations: 'Organizations',
    orderStatus: 'Order Status',
    allOrderStatus: 'All Order Status',
    date: 'Date Range',
    creationDate: 'Creation Date',
    shipDate: 'Ship Date',
    price: 'Price Range',
    optional: 'Optional',
    minimum: 'Minimum',
    maximum: 'Maximum',
    filters: 'Filters',
    selectRange: 'Select a range',
    notAvailableForQuickShip: '(Not available for quick ship)',
    pendingShippingQuote: 'Pending Shipping Quotes',
  },
  pdf: {
    generationError: 'PDF generation failed.',
  },
  login: {
    email: 'Email',
    disclaimer: 'Enter the email address you use to sign in to Project Center Next.',
    signin: 'SIGN IN',
    keep: 'Keep me signed in',
    forgotPassword: 'Forgot your password?',
    passwordChangeRedirect: 'You will be redirected to the change password form',
    password: 'Password',
    emailLabel: 'Sign In',
    emailPlaceholder: 'Enter your email address',
    continue: 'CONTINUE',
    office365: 'Sign in with office 365',
    title: 'Sign in to Hadrian',
    newUser: 'New User',
    year: 'Since 1983',
    slogan: "North America's leading toilet partition and locker manufacturer",
    copyright: 'Hadrian © {year}',
    or: 'or',
    backToLogin: 'BACK TO LOGIN SCREEN',
    pendingHadrianLogin: 'Please wait...',
    footer: 'North America’s leading toilet partition and locker manufacturer, since 1983',
    errors: {
      notFound: "This user doesn't exist.",
      incorrectPassword: 'The username or password is incorrect.',
      unknown: 'An error occurred while trying to login. Please try again.',
      office365: 'An error occurred while trying to login with Office 365.',
      disabled: 'This account has been disabled.',
      failedTitle: 'Login Failed',
      failedMessage: 'Username and/or password is incorrect.',
    },
    validation: {
      email: 'Please enter a valid email address.',
      password: 'Your password must be at least 8 characters in length.',
    },
    links: {
      termsConditions: 'License Agreement',
      legalNotice: 'Legal Notice',
      privacyPolicy: 'Privacy Policy',
    },
  },
  forgotPassword: {
    title: 'Forgot your password?',
    subTitle: 'Enter your email address and we’ll send you a link to reset it.',
    submit: 'SEND',
    cancel: 'BACK',
    error: 'Please enter a valid email address.',
    success: `If the provided email address matches an active account, you'll receive an email with a validation code shortly.`,
  },
  confirmForgotPassword: {
    title: 'Confirm Password Change',
    validationCode: {
      label: 'Validation Code',
      placeholder: 'Validation Code',
    },
    success: 'Your password has been changed successfully. You can now use it to sign in.',
  },
  unauthenticatedPages: {
    licenseAgreement: {
      licenseAgreement: 'License Agreement',
      backBtn: 'Back to Sign  in page',
    },
    privacyPolicy: {
      privacyPolicy: 'Privacy Policy',
    },
  },
  users: {
    creation: {
      title: 'Create new user',
      breadcrumb: 'New',
      informations: 'User information',
      organizations: 'Organizations',
      allOrganizations: 'The selected role grants access to all organizations.',
      errors: {
        prefix: 'You must ',
        and: ' and ',
        mandatoryFields: 'fill all mandatory fields (Name and email)',
        role: 'select a role',
        hadrianrole: 'select a valid Hadrian role',
        hadrianemail: 'enter a valid Hadrian email address',
        organizations: 'assign at least one organization',
      },
      success: 'User successfully created',
    },
    actions: {
      editView: 'Edit/View',
      restore: 'Restore',
      disable: {
        disable: 'Disable',
        disableUser: 'Disable user',
        success: 'User successfully disabled',
        failure: 'User could not be disabled',
      },
    },
  },
  parts: {
    added: 'Parts',
    deleteInProgress: 'Part deletion in progress',
    deleteSuccess: 'The part was successfully deleted',
    partsWarning: 'Please enter a quantity between 1 and 99,999',
    deleteError: 'There was an error deleting the part',
    updateSuccess: 'The part was successfully updated',
    updateError: 'There was an error while updating the part',
    updateSuccessMany: 'The parts were successfully updated',
    updateErrorMany: 'There was an error while updating the parts',
    updateWarning: 'You cannot have more than 999 units of the same loose part',
    addSuccess: 'The part was successfully added',
    addError: 'There was an error adding the part',
    partsEmpty: 'No parts have been added to this project yet',
    changePartsFinish: 'Change Parts Finish',
    addParts: 'Add parts',
    allFilter: 'All',
    filters: 'Filters',
    searchResult: 'Search Result',
    unavailable: 'Unavailable',
    importantProductionNotes: {
      delete: 'Delete Important Production Note',
      projectParts: 'Project parts',
      edit: 'Edit Important production note',
      add: 'Add an important production note',
      maxChar: 'Maximum of 255 characters.',
      nb: '<b>N.B:</b> Orders with "important production notes" are not available for quickship.',
      title: 'Important Production Note',
      modal: {
        bulletPoint1: 'This note can be edited in the quote and order process.',
        bulletPoint2:
          'After ordering the project parts, the related production note below will be cleared. You will then be able to refer to it on the order pdf.',
        note: 'Note',
      },
      toasts: {
        saveSuccess: 'Note successfully saved',
        saveFailed: 'There was an error saving the note',
        editSuccess: 'Note successfully edited',
        editFailed: 'There was an error editing the note',
        deleteSuccess: 'Note successfully deleted',
        deleteFailed: 'There was an error deleting the note',
      },
    },
    partsModal: {
      title: 'Parts Catalog',
    },
    legacyParts: {
      partialSearchTitle: 'Some parts containing "{searchPart}" are not available.',
      partialSearchSubTitle: 'The part you are looking for may have been replaced.',
      partialSearchButton: 'See replacement list',
      searchTitle: '"{searchPart}" is not available.',
      searchSubTitle: 'The following part is replacing it: | The following parts are replacing it:',
      searchSubTitleNote: '*It might not follow your current filters | *They might not follow your current filters',
    },
    legacyPartsModal: {
      title: 'Results for replaced parts containing "{searchPart}"',
      subtitle: 'Select a part  in the table below to reveal which part is replacing it.',
      partNumber: 'PART NUMBER',
      partModel: 'MODEL',
      searchSubTitle: 'The following part is replacing it: | The following parts are replacing it:',
      searchSubTitleNote: '*It might not follow your current filters | *They might not follow your current filters',
    },
    partsFinishModal: {
      materialTitle: 'Select a Material',
      finishTitle: 'Select a Finish',
      confirmationTitle: 'Apply change',
      confirmationSubtitle: 'Do you want to apply this finish to all projects parts?',
      confirmationText:
        "All projects parts of the same material will updated to the selected finish. <br/>This change will not be applied to the project's rooms",
      confirmationWarning: 'Please review your parts after applying changes',
      next: 'Next',
      applyChange: 'Apply Change',
      cancel: 'Cancel',
      back: 'Back',
    },
    dryersModal: {
      title: 'Products',
      rightButton: 'Select',
    },
  },
  discounts: {
    title: 'Discounts',
    discountMultiplier: 'Enter discount multiplier',
    apply: 'APPLY',
    ammount: 'Amount',
    expiry: 'Discounts expiration: {date}',
  },
  submittalPackage: {
    sheetLabel: 'COVER SHEET INFO',
    createdBy: 'Created By',
    contactName: 'Contact Name',
    contactPhone: 'Contact Phone',
    contactEmail: 'Contact Email',
    referenceNumber: 'Reference #',
    selectAll: 'Select all',
    deselectAll: 'Deselect all',
    notes: 'Notes...',
    coverNotes: 'Submittal Cover Notes',
    generate: 'Generate',
    coverSheet: 'Cover sheet',
    technicalSheet: 'Technical sheet',
    optionDetails: 'Option details',
    colorCard: 'Color card',
    styleSummary: 'Style summary',
    drawings: 'Drawings',
    drawingOrBOM: 'Drawing / Bom',
    backingDrawing: 'Bolt/Backing Drawing',
    materialSpec: 'Material specification',
    elevationDrawing: 'Elevation drawing',
    warrantyInfo: 'Warranty information',
    maintenanceProcedure: 'Maintenance procedure',
    leedInfo: 'LEED information',
  },
  setUpAccount: {
    title: 'Welcome!',
    text: "First things first, you need to create a strong password for your account. You'll be able to review your account settings & infos on the next page.",
    submit: 'Continue',
    password: 'Password',
    confirmPassword: 'Confirm Password',
  },
  changePassword: {
    title: 'Change Password',
    confirmPassword: 'Confirm New Password',
    oldPassword: 'Old Password',
    newPassword: 'New Password',
    submit: 'Change Password',
    passwordRules:
      'Your password must have at least 8 characters, including a lowercase character, an uppercase character, a number and a special character.',
    errors: {
      missingField: 'You must fill all fields.',
      numeric: 'Your password must have a numerical character.',
      uppercase: 'Your password must have an uppercase character.',
      special: 'Your password must have a special character.',
      length: 'Your password must have at least 8 characters.',
      default: 'An error occured while trying to change password.',
      match: "Your new password and it's confirmation do not match.",
      incorrectUser: 'Incorrect user or password.',
      alreadyDone: 'Your password has already been changed. Please use it to login.',
      invalidValidationCode: 'Your validation code is invalid. Please ask for a new one.',
      code: 'The validation code must not be empty',
    },
  },
  drawing: {
    unitLabel: 'Unit {number}',
    unitTypes: {
      Standard: 'Standard',
      Ambulatory: 'AMB',
      Ada: 'ADA/BF',
      AlcoveStandard: 'Alcove Standard',
      AlcoveAda: 'Alcove ADA/BF',
    },
  },
  drawingToolbar: {
    showBoltDimensions: 'Show Bolt Dimensions',
    bowlCenters: 'Bowl Centers',
    measurements: 'Measurements',
    showBothMeasurements: 'Show Both Measurements',
    printNotes: 'Print Drawing With Annotations',
    withinUnit: 'Within unit',
    betweenBowls: 'Between Bowls',
    imperial: 'Imperial',
    metric: 'Metric',
    drawingLayout: 'Drawing Layout',
    edit: 'EDIT',
    close: 'CLOSE',
    open: 'TOOLBAR',
    editUnitsPopupTitle: 'Edit units',
    editUnitsPopupMessage:
      "Modifying the units settings will reset any changes you've made to the partitions.\n Do you want to proceed?",
    editLayoutPopupTitle: 'Edit layout',
    editLayoutPopupMessage:
      "Modifying the layout settings will reset any changes you've made to the partitions.\n Do you want to proceed?",
    editPopupLeftButtonText: 'Cancel',
    editPopupRightButtonText: 'Yes',
    displayBetweenBowls: 'Display Between Bowls',
    displayWithinUnit: 'Display Within Unit',
    bowlCenterMeasurements: 'Bowl Centers Measurements',
    workingMeasurements: 'Working Measurements',
  },
  drawingSidebar: {
    notesDetail: {
      maxCharacters: 'Maximum of 250 characters.',
      cancel: 'Cancel',
      save: 'Save',
      annotations: 'Annotations',
      desktopInstructions: 'Right click on the drawing to create a note or an annotation.',
      touchscreenInstructions: 'Adding annotation is only available on desktop.',
      newSaveSuccess: 'Annotation successfully added',
      newSaveError: 'Error creating annotation',
      updateSuccess: 'Annotation successfully updated',
      updateError: 'Error updating annotation',
      mandatoryBubbleText: 'An annotation cannot be empty.',
    },
    bomDetail: {
      addNewParts: '+ Add new parts',
      search: 'Search',
      quantity: 'QTY',
      originalQty: 'Original Qty',
      actualQty: 'Actual Qty',
      partDescription: 'Description',
      partNumber: 'Part Number',
      weight: 'Unit Weight',
      listPrice: 'List Price',
      deprecatedWarningTooltip: 'This part has become obsolete since your last drawing modifications',
      showBomEdits: 'Show BOM edits only',
      showAllParts: 'Show all parts',
      clearAllChanges: 'Clear all changes',
    },
  },
  stylesAndRooms: {
    styles: {
      title: 'Styles',
      createButtonHeader: 'Add',
      createButton: 'Create a style',
      emptyNotice: 'No style has been created<br>in this project yet.',
      bootRacks: {
        shelvesQuantityLabel: 'Shelves Qty',
        shelvesQuantityValue: 'No shelves | {count} shelf | {count} shelves',
        widthLabel: 'Boot Rack Width',
        widthValue: '{width}”',
      },
      card: {
        width: 'Width',
        measurements: 'Measurements: ',
        door: 'Door',
        frame: 'Frame',
        shelves: 'Shelves',
        nShelves: '{count} shelf | {count} shelves',
        bootrackHeight: 'Boot Rack Height',
        body: 'Body',
        packagingOption: 'Packaging:',
        tier: 'Tiers:',
        fastener: 'Fasteners:',
        lockPreparationType: 'Lock type:',
        plateMaterial: 'Number plates:',
        hookType: 'Hooks:',
        none: 'None',
        na: 'N/A',
        unitCountLabel: 'No active units | active unit | active units',
        actions: {
          edit: 'Edit Style',
          delete: 'Delete Style',
          yesDelete: 'Yes, Delete',
          deleteSub:
            'If you do, it will be removed from all rooms, and all rooms containing no other styles will be deleted.',
          deleteError: 'There was an issue deleting the style',
          deleteSuccess: 'Style Deleted with success',
          deleteConfirmation: 'Do you really want to delete this style?',
        },
      },
    },
    rooms: {
      title: 'Rooms',
      createButton: 'Create a room',
      emptyStyleListNotice: 'You need at least one style<br>to create a room.',
      emptyRoomListNotice: 'No room has been created<br>in this project yet.',
      ordered: 'Ordered rooms',
      nonOrdered: 'Unordered rooms',
      noteModalTitle: 'Room Note',
      deleteRoomStyleConfirmation: 'Do you really want to delete this room style?',
      roomStyleDeleted: 'The room style has been deleted successfully',
      search: {
        placeholder: 'Search',
        tooltip: 'Search by room name or style name',
      },
    },
  },
  hardwareLegend: {
    legend: 'LEGEND',
    labels: {
      hBracket: {
        display: 'H Bracket',
        abbreviation: 'A',
      },
      screenHBracket: {
        display: 'Screen H Bracket',
        abbreviation: 'AS',
      },
      uClip: {
        display: 'U Clip',
        abbreviation: 'C',
      },
      screenChannel: {
        display: 'Screen Channel',
        abbreviation: 'DS',
      },
      paintedPost: {
        display: 'Painted Post',
        abbreviation: 'EP',
      },
      flatStop: {
        display: 'Flat Stop',
        abbreviation: 'FS',
      },
      grabBarReinforcing: {
        display: 'Grab Bar Reinforcing',
        abbreviation: 'GB',
      },
      alcoveChannel: {
        display: 'Alcove Channel',
        abbreviation: 'AC',
      },
      fBracket: {
        display: 'F Bracket',
        abbreviation: 'B',
      },
      deepChannel: {
        display: '3" Deep Channel',
        abbreviation: 'D',
      },
      unpaintedPost: {
        display: 'Unpainted Post',
        abbreviation: 'E',
      },
      pilasterFBracket: {
        display: 'Pilaster F Bracket',
        abbreviation: 'F',
      },
      pilaster218Channel: {
        display: 'Pilaster 2 1/8" Channel',
        abbreviation: 'G',
      },
      postBracket: {
        display: 'Post Bracket',
        abbreviation: 'H',
      },
      pedestal: {
        display: 'Panel/Pilaster pedestal',
        abbreviation: 'P',
      },
      fcPilaster: {
        display: 'Floor to Ceiling Pilaster',
        abbreviation: 'FCP',
      },
      wallMountPilaster: {
        display: 'Wall Mount Pilaster',
        abbreviation: 'WM',
      },
      wrapHinge: {
        display: 'Wrap Hinge',
        abbreviation: 'WH',
      },
      plasticFChannels: {
        display: 'F Channel',
        abbreviation: 'FC',
      },
      plasticHChannels: {
        display: 'H Channel',
        abbreviation: 'HC',
      },
      plasticUChannel: {
        display: 'U Channel',
        abbreviation: 'UC',
      },
      plasticUUndercut: {
        display: 'U Channel AMB 32"',
        abbreviation: 'UU',
      },
      transomPanel: {
        display: 'Transom Panel',
        abbreviation: 'TR',
      },
      wallPost: {
        display: 'Wall Post',
        abbreviation: 'WP',
      },
      twoEarBracket: {
        display: '2 Ear Bracket',
        abbreviation: 'PA',
      },
      lBracket: {
        display: 'L Bracket',
        abbreviation: 'PB',
      },
      uBracket: {
        display: 'U Bracket',
        abbreviation: 'PC',
      },
      lChannel: {
        display: 'L Channel',
        abbreviation: 'PD',
      },
      uChannel: {
        display: 'U Channel',
        abbreviation: 'PE',
      },
      wallBracketInside: {
        display: 'Wall Bracket Inside',
        abbreviation: 'PF',
      },
      highDensityUniversalGrabBarReinforcing: {
        display: 'High Density Universal Grab Bar Reinforcing',
        abbreviation: 'GB',
      },
      panelPilasterPedestal: {
        display: 'Panel/Pilaster Pedestal',
        abbreviation: 'PP',
      },
    },
    unitsInfoSection: {
      unitLabel: 'Unit {unitNumbers}',
      doorHeight: 'Panel/Door height = {formattedHeight}',
      doorAff: 'Panel/Door AFF = {formattedAff}',
    },
  },
  roomDesigner: {
    screensDisclaimer: 'Location of urinal screen(s) may not\nbe correctly reflected in this drawing',
    postCutOnSite: '120" Screen Post Cut on Site',
    screenHeight: '{label} ({height}" high)',
    noHinge: '   No\nHinge',
    addNote: 'Add a note',
    addAnnotation: 'Add an annotation',
    noDrawing: 'There is no drawing for this room.',
    properties: 'Properties',
    partType: 'Part type',
    notes: {
      transparency: 'Transparency',
      duplicateNote: 'Duplicate note to this room',
      duplicateNoteToRooms: 'Duplicate note to other room(s)',
      editNote: 'Edit note',
      deleteNote: 'Delete note',
      deletedByOther: 'This note has been deleted by another user',
      lockerRoomNoteCreatedByOther: 'A note has already been added by another user',
      create: {
        success: 'Note successfully added',
        error: 'An error occured during the note creation',
      },
      update: {
        success: 'Note successfully updated',
        error: 'An error occured during the note update',
      },
      delete: {
        success: 'Note successfully deleted',
        error: 'An error occured while deleting the note',
        modal: {
          title: 'Delete note',
          body: 'Are you sure you want to delete this note?',
        },
      },
      duplicate: {
        success: 'Note successfully duplicated',
        error: 'An error occured while duplicating the note',
        modal: {
          title: 'Duplicate',
          confirm: 'Duplicate',
          cancel: 'Cancel',
          close: 'Close',
          instruction: 'Duplicate your note to one or multiple rooms',
          instruction_detail:
            'Select the room(s) for which you want to copy the selected note. It will be duplicated on each selected drawing.',
          select_all: 'Select all rooms',
          floor: 'Floor ',
          noRooms: 'There are no other active rooms in this project.',
        },
      },
      modal: {
        title: 'Note Properties',
        leftButton: 'cancel',
        rightButton: 'save',
        closeButtonText: 'Close',
        noteLabel: 'Note',
        notePlaceholder: 'Type your note here...',
        isProductionTitle: 'Status: Important production note',
        isProductionBody: 'To set this note as a general/installer note for Hadrian, uncheck the box.',
        isGeneralTitle: 'Status: General / installer note',
        isGeneralBody: 'To set this note as an important production note for Hadrian, check the box.',
        isProductionNB: '<b>N.B.:</b> Orders with "important production notes" are not available for quickship.',
        options: 'Options',
        showPointer: 'Show note pointer',
        isTransparent: 'Transparent note',
        notice: {
          addNote:
            '<span>If required, you can add a note for this room below.</span><br><span>•&ensp;Please be advised that only one note can be added per room, even if it contains multiple room configurations. <br>•&ensp;You can edit this note afterwards directly from the room card.</span>',
          updateNote:
            '<span>You must click the Edit button in order to update an existing note.</span><br><span>•&ensp;A note already exist for this room.<br>•&ensp;Only one note can be added even if it contains multiple room configurations.</span>',
          generalNote:
            '•&ensp;Only one note can be added per room, even if it contains multiple room configurations.<br>\n' +
            '•&ensp;Deleted/edited notes cannot be recovered.<br>\n' +
            '•&ensp;Make sure to not delete by mistake any useful notes that are already in the box below.',
        },
      },
    },
  },
  rooms: {
    duplicateSuccessful: 'The room has been duplicated successfully',
    duplicateError: 'There was an issue duplicating the room',
    creationFailedTooMany: 'You cannot create more than 100 rooms.',
    tooManyRoomsTooltip: 'You cannot create more than 100 rooms in a project.',
    deleteSuccessful: 'The room has been deleted successfully',
    deleteError: 'There was an issue deleting the room',
    duplicateConfirmation: 'Are you sure you want to duplicate this room?',
    deleteConfirmation: 'Are you sure you want to delete this room?',
    lastUpdated: 'Last Updated',
    createRoom: 'Create a new room',
    none: 'None',
    upchargeNotice: '*** Color TBA (upcharge for 800 series)',
    customColorUpchargeNotice: '*** Custom color (upcharge for 800 series)',
    latch: 'Latch',
    doorHinges: 'Door Hinges',
    noSightLines: 'No-Sightline Solution',
    options: 'Options',
    roomProperties: 'Room Properties',
    gender: 'Gender',
    floor: 'Floor',
    genderAndFloor: 'Gender & Floor',
    genderCard: {
      mixed: 'Neutral',
      man: 'Men',
      woman: 'Women',
    },
    toiletTypes: {
      men: 'Men',
      women: 'Women',
      neutral: 'Neutral',
    },
    roomTypes: {
      woman: "Women's Room",
      man: "Men's Room",
      Neutral: 'Neutral Room',
    },
    roomUpdated: {
      success: 'Room successfully updated',
      error: 'There was an issue updating the room',
    },
    tooManyError: {
      title: 'Duplicate Rooms',
      body: 'Your project cannot exceed 100 rooms.',
      bodySub:
        'Duplicating a total of {duplicates} rooms will put you {overflow} rooms over the maximum allowed. Please review the amount of rooms you would like to duplicate. ',
    },
    drawingUpdates: {
      title: 'New feature available! | New features available!',
      subtitle:
        'Here’s a the latest feature we’ve added to the drawing: | Here’s a list of the latest features we’ve added to the drawing:',
      dismiss: 'DISMISS',
      readMore: 'Read more',
    },
    roomCreation: {
      newRoom: 'New Room',
      editRoom: 'Edit Room',
      progressBar: {
        mandatoryBubbleText:
          'You must fill all <span class="mandatory">*</span><b>mandatory</b> fields to proceed to the next step.',
        previous: 'Previous',
        next: 'NEXT',
        create: 'CREATE',
        save: 'SAVE',
        cancel: 'Cancel',
      },
      steps: {
        roomNameAndType: {
          title: 'Room Name & Type',
          roomName: {
            label: 'Room Name',
            placeholder: 'Room Name',
          },
          roomType: {
            label: 'Room Type',
          },
        },
        layout: {
          title: 'Layout',
        },
        unitQuantities: {
          title: 'Unit Quantities',
          units: 'units',
        },
      },
      cancel: {
        yes: 'Yes',
        no: 'No',
        header: '<b>Are you sure you wish to continue ?</b>',
        main: 'You are about to cancel the creation of this room. If you do so, all progress will be lost.',
        bottomText: 'Are you sure you wish to continue?',
      },
      success: 'A new room has been created',
      error: 'There was an issue creating the room',
      noLayoutError: 'You must choose a layout to create the room',
    },
    lockerRoomCreation: {
      headerTitleNew: 'New room',
      headerTitleEdit: 'Edit current room',
      headerTitleAdd: 'Add a style to current room',
      cancelSubtitle: 'Are you sure you wish to continue?',
      cancelMain: 'You are about to cancel the creation of this room.<br>If you do so, all progress will be lost.',
      skipTitle: 'Are you sure you wish to skip the assistant?',
      skipMain:
        "<p class='properties-modal-content__subtitle'>Are you sure you wish to skip the assistant?</p> If you don't complete the assistant, you will have to manually fill out most of the following steps.",
      disableAssistant:
        "<p class='properties-modal-content__subtitle'>Are you sure you wish to disable the assistant?</p> When disabling this feature, you can choose to keep all the values filled by the Room Design Assistant or clear them.",
      select: 'Select',
      defaultLockerRoomName: 'New room',
      clearValues: 'Clear values',
      keepValues: 'Keep values',
      errorCreateRoom: 'An error occurred while trying to create the room.',
      errorUpdateRoom: 'An error occurred while trying to update the room.',
      errorAddStyle: 'An error occurred while trying to add the style to the room.',
      reset: 'Reset',
      rdaResetLabel: 'Reset to Room Design Assistant default values',
      rdaResetLabelSingular: 'Reset to Room Design Assistant default value',
      progressBar: {
        nameAndStyle: 'New Room',
        nameAndStyleSubtitle: 'No lockers | {quantity} locker | {quantity} lockers',
        nameAndStyleSubtitleDoors: 'No door only | {quantity} door only | {quantity} door only',
        nameAndStyleSubtitleBootRacks: 'No Boot Racks | {quantity} Boot Rack | {quantity} Boot Racks',
        nameAndStyleSubtitleDoorsAndFrames:
          'No door & frame assembly | {quantity} door & frame assembly | {quantity} door & frame assemblies',
        roomAssistant: 'Assistant',
        trimsAndFillers: 'Trims/Fillers',
        tops: 'Tops',
        bases: 'Bases',
        extraParts: 'Extra Parts',
        summary: 'Summary',
        disabled: 'Disabled',
        enabled: 'Enabled',
        skipped: 'Skipped',
        completed: 'Completed',
      },
      nameStyleQuantitiesStep: {
        roomName: 'Room Name',
        style: 'Style',
        selectStyle: 'Select a style',
        measurements: '<b>Measurements:</b> {width}" x {depth}" x {height}"',
        measurementsWithoutDepth: '<b>Measurements:</b> {width}" x {height}"',
        frame: 'frame',
        door: 'door',
        finishes: 'Finishes:',
        kit: 'Kit:',
        fasteners: 'Fasteners:',
        numberPlates: 'Number plates:',
        tiers: 'Tiers:',
        lockType: 'Lock type:',
        hooks: 'Hooks:',
        hooksNone: 'None',
        typeAndQuantity: 'Type & Quantity',
        type: 'Type',
        typeLabel: 'Type',
        selectType: 'Select a type',
        lockers: 'Lockers',
        quantity: 'Quantity',
        lockersQuantity: 'Number of standard lockers',
        doorsQuantity: 'Locker door quantity',
        doorAndFramesQuantity: 'Number of door and frame assemblies',
        adaLockersQuantity: 'Number of ADA lockers',
        extraShelvesQuantity: 'Number of ADA shelves',
        locks: 'Locks',
        standardLocksQuantity: 'Standard Locks Qty',
        adaLocksQuantity: 'ADA Locks Qty',
        adaShelvesTooltipText:
          'Hadrian lockers can be configured to conform to ADA requirements for accessibility. The pre-drilled hole locations in Hadrian lockers allow shelves to be installed at heights that fall within ADA guidelines (the addition of locker bases will affect the height of shelves). Additionally, Hadrian’s standard friction catch and positive latch options allow doors to be operated with one hand and without the need for grasping, pinching or twisting of the wrist.',
        extraKeyNotice: 'You will be able to add extra user keys and master keys when quoting/ordering.',
        checkboxStandardLocker: "I don't need Standard Lockers.",
        shelves: 'Shelves',
        shelvesWQuantity: '{count} Shelf | {count} Shelves',
        width: 'Width',
        bootRacksFrame: 'Frame',
        bootRacksHeight: 'Boot Rack Height',
        wide: 'Wide',
        unitQuantities: 'Unit Quantities',
        bootRacksLockerQuantityLabel: 'Number of boot racks',
        bootRacksWallMountedQuantityStepInfo:
          'Every row of boot racks requires one starter and any additional boot racks would be an adder.',
        bootRacksHowManyStarters: 'How many starters do you need?',
        bootRacksHowManyAdders: 'How many adders do you need?',
      },
      banks: {
        pageTitle: 'Banks & Extra Sides',
        pageTitleNoBanks: 'Extra Sides',
        bankPreferenceHeader: 'Banks Preference',
        bankPreferenceLabel: 'Select a Preference',
        bankChoiceLabel: '{count} locker banks',
        reset: 'Reset',
        lockersTotals: 'Locker total: {totalBanksQuantity}/{totalNumberOfLockers}',
        extraSidesHeader: 'Extra Sides',
        extraSidesLabel: 'Number of extra sides',
        extraSidesTooltip: 'A locker side is provided with each locker and each bank.',
        extraSidesNotice: 'You may not have enough extra sides. Your configuration requires at least {0}.',
      },
      roomDesignAssistant: {
        start: 'start',
        keep: 'keep',
        keepLabel: 'Keep the Room Design Assistant values as they are and proceed to the next step',
        edit: 'edit',
        editLabel: 'Edit the Room Design Assistant values',
        disable: 'disable',
        disableLabel: 'Disable the Room Design Assistant',
        stepOneTitle: 'Room Design Assistant',
        optionalStep: 'Optional step',
        stepOneBody:
          'You can answer a few simple questions to help you determine how many extra parts you will need based on the layout of the room. Answering these questions will help prefill most of the the following steps. <b>This step is optional.</b>',
        exposedSidesTotal: 'Exposed sides total: {leftRightTotalExposedSides}/{totalExposedSides}',
        wallQuestions: {
          betweenWalls: 'How many between wall conditions do you have?',
          corners: 'How many corners do you have?',
          exposedTotals: 'How many exposed sides do you have (total)?',
        },
        topBaseQuestions: {
          slopeTops: 'Do you have slope tops?',
          bases: 'Do you have bases?',
        },
        exposedQuestions: {
          leftExposed: 'How many exposed left sides do you have?',
          rightExposed: 'How many exposed right sides do you have?',
          backToBack: 'How many locker units are back to back?',
        },
      },
      extraParts: {
        extraParts: 'Extra Parts',
        optional: 'Optional step',
        add: 'Add',
        bench: 'Bench',
        benches: 'Benches',
        adaBench: 'ADA Bench Option',
        style: 'Style',
        benchSeatLength: 'Bench Seat Length',
        pedestalQty: 'Pedestal Quantity',
        benchQty: 'Bench Quantity',
        pedestals: '{quantity} pedestals',
        perBench: '{quantity} per bench',
        ada: ' ADA',
        part: 'Part',
        quantity: 'Quantity',
        units: 'No units | {count} unit | {count} units',
        tooltips: {
          addNewBench:
            'To add another style, please select a style and a length for the last bench style you’ve added.',
          standardBench:
            'Locker bench seats are hardwood laminate with a clear lacquer finish and the bench corners and edges are rounded. The aluminum bench pedestals are available with either stationary or free-standing mounting hardware and are powder coated in any of Hadrian’s designer colors.',
          stdBenchDimensions: '</br><b>Bench set dimensions:</b></br>1 1/4" (32 mm) thick, 9 1/2" (241 mm) wide',
          adaBench:
            'To meet ADA guidelines, Hadrian offers a wider bench complete with four pedestals for additional support. ADA benches include additional pedestals as shown and are only available with stationary mounting hardware.',
          adaBenchDimensions: '</br><b>Bench set dimensions:</b></br>1 1/4" (32 mm) thick, 24" (610 mm) wide',
          add: 'To add another style, please select a length for the last bench style you’ve added.',
        },
      },
      tops: {
        title: 'Tops',
        optional: 'Optional step',
        notAvailable: 'None',
        topStyle: 'Top Style',
        select: 'Select a top style',
        skipStep: 'Skip this step',
        provideQuantity: 'Provide a quantity',
        provideQuantityForEach: 'Provide a quantity for each',
        units: '{count} unit | {count} units',
        flat: {
          name: 'Flat Top Fillers',
          shortName: 'Flat Tops',
          description:
            'Where lockers meet in a corner or when using expansion trim and slope top is not used, Hadrian’s 24” (610 mm) x 24” (610 mm) flat top filler can be cut to suit and installed to cover the gaps and prevent litter from being thrown between the lockers.',
          flatTopFillers: {
            label: '24" x 24" Flat top filler',
            tooltip: `Hadrian's 24" (610mm) x 24" (610mm) flat top filler can be cut to size and installed to cover the gap that forms when lockers are assembled in a corner, where a U filler and/or expansion fillers are use.`,
          },
        },
        slope: {
          name: 'Slope Tops',
          heavyName: 'Heavy Duty Slope Tops',
          description:
            'In addition to presenting a clean, finished appearance, the angle of the sloping top prevents the accumulation of dust and discourages users from stacking unsightly clutter above the locker. Heavy-duty 16-gauge slope tops are available (standard slope tops are 20-gauge).',
          totalLength: 'Locker Total Length',
          covered: 'Covered:',
          required: 'Required: {requiredLabel}',
          corners: 'Corners',
          apparentSides: 'Apparent Sides',
          apparentSidesLabel: '{left} left, {right} right',
          lengthNotCoveredStatus: 'You may not have enough tops.',
          lengthCoveredStatus: 'Your tops selection covers all your lockers.',
          heavyDuty: 'Heavy Duty',
          tooltipText: '16ga Slope Tops',
          slopeTop36: {
            label: '36" Slope Tops',
            tooltip: `36" slope tops are made from 20ga powder coated steel and will be painted to match your locker frame color. Slope tops are cut infield if the required size is smaller than 36". See installation manual for more details.`,
          },
          slopeTop48: {
            label: '48" Slope Tops',
            tooltip: `48" slope tops are made from 20ga powder coated steel and will be painted to match your locker frame color. Slope tops are cut infield if the required size is smaller than 48". See installation manual for more details.`,
          },
          slopeTop72: {
            label: '72" Slope Tops',
            tooltip: `72" slope tops are made from 20ga powder coated steel and will be painted to match your locker frame color. Slope tops are cut infield if the required size is smaller than 72". See installation manual for more details.`,
          },
          spliceAssembly: {
            label: 'Splice Assemblies',
            tooltip: `Splice assemblies are for joining two slope top lengths together. See installation manual for more details.`,
          },
          corner36Tops: {
            label: '36" Corner Slope Tops',
            tooltip: `36" corner slope tops have a 45 degree mitered edge on the right hand side for easy installation. See installation manual for more details.`,
          },
          rightHandGussets: {
            label: 'Right Hand Gussets',
            tooltip: `Right-hand gussets are used to close the right-hand end of an exposed slope top side. See installation manual for more details.`,
          },
          leftHandGussets: {
            label: 'Left Hand Gussets',
            tooltip: `Left-hand gussets are used to close the left-hand end of an exposed slope top side. See installation manual for more details.`,
          },
          supportClips: {
            label: 'Support Clips',
            tooltip: `Slope top support clips are mounted to the top of the locker to support the back of the slope top. 2 clips are provided for each 36" slope top and 3 clips are provided for each 48" and 72" slope top. See installation manual for more details.`,
          },
        },
      },
      bases: {
        title: 'Bases',
        optional: 'Optional step',
        baseStyle: 'Base Style',
        skipStep: 'Skip this step',
        select: 'Select a base style',
        provideQuantity: 'Provide a quantity for each part',
        totalLength: 'Locker Total Length',
        added: 'Added:',
        suggested: 'Suggested:',
        covered: 'Covered:',
        required: 'Required: {requiredLabel}',
        corners: 'Corners',
        sides: 'Sides',
        apparentSides: 'Apparent Sides',
        apparentSidesLabel: '{left} left, {right} right',
        lengthNotCoveredStatus: 'You are not covering the total locker length.',
        lengthCoveredStatus: 'You are covering the total locker length.',
        notAvailable: 'None',
        boxBase: {
          name: 'Box base',
          description:
            'The versatility of Box Bases make them ideal for any size installation. Bases are recessed 1.75” (44mm) from the locker front to allow for toe space and protection against damage from cleaning. To help withstand damp floor conditions, the base is made from galvanneal steel.',
          units: '{count} unit | {count} units',
          frontBack: {
            label: 'Front Back',
            labelPlural: 'Fronts/Backs',
            tooltip:
              'Base fronts and backs are installed on the front and back of each locker. 1 front and 1 back is required per locker. See installation manual for more details.',
          },
          corner4x10: {
            label: 'Base Corner (4" x 10")',
            tooltip:
              '4" x 10" Base Corners are used to cover corner gaps and or gaps from expansion trim. See installation manual for more details.',
          },
          corner4x4: {
            label: 'Base Corner (4" x 4")',
            tooltip:
              '4" x 4" Base Corners are used to cover corner gaps and or gaps from the wall to locker with an exposed edge. See installation manual for more details.',
          },
          backToBack32: {
            label: 'Back to Back Base Trim (32")',
            tooltip:
              '32" Back-to-Back Base Trim is used to connect back-to-back lockers and finish the exposed end. It is cut to size onsite. See installation manual for more details.',
          },
          sides: {
            label: 'Side',
            tooltip:
              'Base sides are installed on the side of each locker. The quantity of base sides is determined by the locker banking chosen for installation. Locker sides and base sides should be the same quantity. See installation manual for more details.',
          },
        },
        zBase: {
          name: 'Z base',
          description: `Hadrian's Continuous Z-Base is a heavy-duty locker support option. The durable 14-gauge base is anchored directly to the floor and elevates the lockers to 4” (102”) off the ground.<br/><br/>`,
          units: '{count} unit | {count} units',
          base60: {
            label: '60" Bases',
            tooltip:
              'Continuous Z-bases are purchased in 60” lengths and are to be cut to size infield. See installation manual for more details.',
          },
          ends: {
            label: 'Z Base Ends',
            tooltip:
              'Z Base Ends are used to finish an exposed locker end. They are available in 12", 15", 18", 21" and 24" lengths to match our locker depths. See installation manual for more details',
          },
          splicePlates: {
            label: 'Z Base Splice Plate',
            tooltip:
              'Z Base Splice Plates are used to join two Z Base lengths together.  See installation manual for more details. ',
          },
          zBaseCorners: {
            label: '4" x 4" Base Corner',
            tooltip:
              '4" x 4" Base Corners are used to cover corner gaps and/or gaps from the wall to the locker with an exposed edge. See installation manual for more details. ',
          },
          concreteScrews: {
            label: '1/4" x 1 3/4" Hex Concrete Screw',
            tooltip:
              '1/4" x 1 3/4" Hex Concrete Screws are to fasten Z Bases to the floor. See installation manual for more details. ',
          },
          expansionTrims: {
            label: '12" Expansion Trim (back to back support)',
            tooltip:
              '12" Expansion Trim is used to connect and support back to back lockers. See installation manual for more details.',
          },
        },
      },
      trimAndFillers: {
        pageTitle: 'Trims & Fillers',
        recessTitle: 'Trims & Fillers option',
        recessCheckboxLabel: 'Recess Top & Recess Side Trim (pair)',
        importantNotesTitle: 'Important Notes',
        importantNotesContent:
          'All trim will be painted the same colour as the locker frames.<br><br>If you need to order Plenum panels, Please contact Hadrian.',
        higherPartSizeTitle: 'Higher Part Size',
        higherPartSizeContent:
          'The specific part size is not available. To fit the locker style, the closest higher part size will be added. These parts will have to be adjusted on site.',
        width: 'Width',
        quantity: 'Quantity',
        height: 'Height',
        heightQuantity: '{0} in',
        part: 'Part',
        addAnotherSize: '+ Add another size',
        starterTrimLabel: 'Starter Trims',
        starterTrimTooltip:
          'For installations where a gap exists at the end of lockers in a corner, expansion trim can be used to enclose the gap. Powder coated to match the frame color, the expansion trim and <b>starter trim</b> are attached to the locker side and wall and slide together to present a clean, uniform appearance.',
        expansionTrimLabel: 'Expansion Trims',
        expansionTrimTooltip:
          'For installations where a gap exists at the end of lockers in a corner, expansion trim can be used to enclose the gap. Powder coated to match the frame color, the <b>expansion trim</b> and starter trim are attached to the locker side and wall and slide together to present a clean, uniform appearance.',
        uFillerLabel: 'U-Filler Panels',
        uFillerTooltip:
          'Hadrian’s U-shaped filler panels can be installed to cover gaps that exist between two banks of lockers or to cover pipes or other unsightly building elements. The fasteners for the filler panel are installed from inside the lockers, maintaining a clean exterior with no exposed fasteners.',
        dressEndLabel: 'Dress Ends',
        dressEndTooltip:
          'Hadrian’s dress end panels can be added to locker sides in order to eliminate exposed fasteners and present a clean, uniform appearance. Dress ends can be painted to match the locker frame color and are easily installed with a locking strip.',
        addDressEnds: '+ Add dress ends',
        recessLabel: 'Recess Top & Recess Side Trim (pair)',
        recessTooltip:
          'For recessed applications, this 3” (76 mm) high trim, for side and top gaps installs easily. The flat surface of the trim blends with the locker frames for a continuous appearance.',
        recessTopTrim60: 'Recess Top Trim 60"',
        recessSplicePlate: 'Recess Splice Plate',
        recessSideTrim: 'Recess Side Trim (pair)',
        unitLabelWithWidth: '{count} unit ({width} in) | {count} units ({width} in)',
        unitLabel: '{count} unit | {count} units',
      },
      summary: {
        roomInformations: {
          title: 'Room Information',
          styleName: 'Style name',
          totalStdLockersLabel: 'Total Standard Lockers',
          totalStdLockersValue: '{quantity} standard locker | {quantity} standard lockers',
          totalDoorOnlyLabel: 'Total Door Only',
          totalDoorOnlyValue: '{quantity} door only',
          totalDoorAndFrameLabel: 'Total Door & Frame Assemblies',
          totalDoorAndFrameValue: '{quantity} door and frame assemblies',
          totalStdLocksLabel: 'Total Standard Locks',
          totalStdLocksValue: '{count} standard locks',
          totalExtraSidesLabel: 'Total Extra Sides',
          totalExtraSidesValue: '{count} extra sides',
          totalAdaLockersLabel: 'Total ADA Lockers',
          totalAdaLockersValue: '{count} ADA lockers',
          totalAdaLocksLabel: 'Total ADA Locks',
          totalAdaLocksValue: '{count} ADA locks',
          totalAdaShelvesLabel: 'Total ADA Shelves',
          totalAdaShelvesValue: '{count} ADA shelves',
        },
        banks: {
          title: 'Banks',
          preference: 'Preference',
        },
      },
    },
    screens: {
      quantity: 'Quantity',
      screens: 'Screens',
      type: 'Type',
      support: 'Screen Support',
      noScreenSupport: 'No screen support available',
      size: 'Size',
      hardware: 'Hardware',
      noScreensAddedTitle: 'Screens',
      noScreensAdded: 'No screens have been added yet.',
      addScreensButton: 'ADD SCREENS',
      addScreenTitle: 'Add Screen',
      add: 'ADD',
      cancel: 'CANCEL',
      pilaster: 'Floor mounted support',
      post: 'Floor to ceiling post',
    },
    hardwareView: {
      hardwarePackage: 'Hardware Package',
      doorHinges: 'Door Hinges',
      noSightlineSolution: 'No Sightline Solution',
      options: 'Options',
      noOptions: 'No options selected',
    },
    edit: {
      roomName: 'Room Name',
      floor: 'Floor',
      types: 'Room',
      confirmApply: 'Apply Changes',
      material: {
        title: 'Material',
        body: 'Are you sure you want to change the material for this room ?',
        bodySub:
          'No parts in your room will be changed. Filters in the room’s parts catalog will be reset to match the new settings selected.',
      },
      series: {
        title: 'Series',
        body: 'Are you sure you want to change the series for this room ?',
        bodySub:
          'No parts in your room will be changed. Filters in the room’s parts catalog will be reset to match the new settings selected.',
      },
      partition: {
        title: 'Partition',
        body: 'Are you sure you want to change the partition for this room ?',
        bodySub:
          'No parts in your room will be changed. Filters in the room’s parts catalog will be reset to match the new settings selected.',
      },
      hardware: {
        title: 'Hardware',
        body: 'Are you sure you want to change the hardware for this room ?',
        bodySub:
          'No parts in your room will be changed. Filters in the room’s parts catalog will be reset to match the new settings selected.',
      },
    },
    list: {
      searchPlaceholder: 'Search',
      searchTooltip: "Search by room's name",
      emptyFilter: 'All',
      create: 'CREATE A ROOM',
      noFilteredRooms: 'No rooms correspond to your search',
      noRooms: 'No rooms have been created in this project yet',
      drawer: {
        selectedRoomCount: '{count} room selected | {count} rooms selected',
        delete: 'Delete',
        deleteModal: {
          title: 'Delete Room | Delete Rooms',
          subtitle: 'Do you really want to delete {count} room? | Do you really want to delete {count} rooms?',
          text: 'This action is not reversible. | If you do, all active selected rooms will be deleted. This action is not reversible.',
          confirm: 'Yes, delete',
          success: 'The room was successfully deleted. | The rooms were successfully deleted.',
          error: 'An error occured while deleting the room. | An error occured while deleting the rooms.',
        },
        duplicate: {
          duplicate: 'Duplicate',
          duplicateMirror: 'Duplicate & Mirror',
          quantity: 'How many duplicates?',
          cancel: 'Cancel',
          apply: 'Apply',
          success: 'The room was successfully duplicated. | The rooms were successfully duplicated.',
          error: 'An error occured while duplicated the room. | An error occured while duplicated the rooms.',
        },
      },
      actions: {
        roomPackage: 'Download room(s) package',
      },
      card: {
        total: 'TOTAL',
        lastUpdated: 'Last updated:',
        soldOutNotice: 'This room contains unavailable / sold out parts.',
        orderedOn: 'Ordered on',
        poNumber: '<b>PO#</b>',
        units: 'unit | units',
        screensCount: 'screen | screens',
        floor: 'Floor',
        actions: {
          edit: 'Edit/View',
          duplicate: 'Duplicate',
          duplicateMirror: 'Duplicate & Mirror',
          delete: 'Delete',
          roomOrder: 'Edit Room Sequence',
        },
      },
      lockerCard: {
        bootracksCount: '{count} boot rack | {count} boot racks',
        lockerCount: '{count} locker | {count} lockers',
        doorCount: '{count} door only | {count} door only',
        doorAndFrameCount: '{count} door and frame assembly | {count} door and frame assemblies',
        addConfiguration: 'add',
        bankPreference: 'banks of {count} | banks of {count}',
        noTopsOrBases: 'No tops or bases',
        flatTops: 'Flat tops',
        slopeTops: 'Slope tops',
        zBases: 'Z Bases',
        boxBases: 'Box bases',
        editNote: 'Edit Note',
        viewNote: 'View Note',
        deleteNote: 'Delete Note',
        edit: 'Edit',
        delete: 'Delete',
        duplicateRoom: 'Duplicate Room',
        deleteRoom: 'Delete Room',
        roomOrder: 'Edit Room Sequence',
        heavyDutySlopeTops: 'Heavy Duty Slope Tops',
        lastUpdated: '<b class="locker-room-card__last-updated-label">Last updated:</b> {date}',
        cantAddTooltip:
          'All available styles/types are already added to this room. To adjust the amount of lockers in your room, edit one of the room styles below.',
        addNote: 'Add a note',
        readMore: 'Read more',
      },
    },
    create: {
      layoutTypes: {
        cornerRight: 'Corner - Right',
        cornerLeft: 'Corner - Left',
        alcoveLeft: 'Alcove - Left',
        alcoveRight: 'Alcove - Right',
      },
      units: 'Unit Quantities & Type',
      unitsLabel: 'Unit Quantity',
      roomConfigurationSectionTitle: 'Room Configuration',
      unitRequirements: 'Unit Requirements',
      adaAndAmbUnitsConfigurationSectionTitle: 'ADA/BF & AMBulatory unit Configuration',
      affOptionForAdaAndAmbUnitsLabel: 'Mounting height above finished floor (ADA/BF & AMB units)',
      roomWidthSectionTitle: 'Room Width',
      unitTypesLabel: 'Select if ADA/BF or Ambulatory units are required.',
      unitDepthNoticeAll:
        'When selecting ADA/BF & AMB, all unit depths (except alcoves) will be adjusted to 61 1/4” (18669 mm).',
      unitDepthNoticeAmb:
        'When selecting AMB, all unit depths (except alcoves) will be adjusted to 61 1/4” (18669 mm).',
      informationLabel: 'You can change the unit type by clicking on the unit.',
      noSpecialRequirementsLabel: 'No ADA/BF or Ambulatory unit required.',
      placeAdaLabel:
        'To set the disposition of your <font class="semi-bold-blue">ADA/BF</font> unit(s), please select or unselect the unit(s) below.',
      placeAmbLabel:
        'To set the disposition of your <font class="semi-bold-blue">Ambulatory</font> unit(s), please select or unselect the unit(s) below.',
      clickAgainLabel:
        'To modify the disposition of your units, click on the <font class="semi-bold-blue">ADA/BF</font> or the <font class="semi-bold-blue">Ambulatory</font> icon.',
      clickAgainAdaOnly: 'To modify your selection, click on the <font class="semi-bold-blue">ADA/BF</font> icon.',
      clickAgainAmbOnly: 'To modify your selection, click on the <font class="semi-bold-blue">Ambulatory</font> icon.',
      adaRestricted: 'The <font class="semi-bold-blue">ADA/BF</font> unit position cannot be changed for this layout.',
      clickAgainAdaRestricted:
        'The <font class="semi-bold-blue">ADA/BF</font> unit position cannot be changed for this layout.</br>To modify your the <font class="semi-bold-blue">Ambulatory</font> position, click on the <font class="semi-bold-blue">Ambulatory</font> icon.',
      typicalWidthLabel: 'Typical width for this configuration:',
      approximateRoomWidthLabel: 'Room approximate width',
      approximateRoomWidthPlaceholder: 'Optional',
      approximateWidthTooltip:
        'This tool is used for quoting purposes only. Pilasters may need to be adjusted to suit layout design.',
      optional: '(optional)',
      basePath: 'Projects',
      layoutType: 'Layout type',
      title: 'Edit Room',
      next: 'Next',
      cancel: 'Cancel',
      layouts: 'Layouts',
      done: 'Done',
      breadcrumb1: 'Cortex Studio Project',
      editSuccess: 'Your room was successfully updated',
      editError: 'There was a problem updating the room',
      currentWidth: 'Overall room width: ',
      targetWidth: 'Desired room width:',
      widthMatch: 'Overall and desired room width are equal',
      widthTooSmall: 'Overall width is {difference} greater than desired width',
      widthTooLarge: 'Overall width is {difference} smaller than desired width',
    },
    designer: {
      alcoveProperties: {
        alcoveType: 'Alcove Type',
        alcoveOptions: 'Alcove Options',
        extraWide: 'Extra wide unit',
        extraWideSize: 'Select a panel size',
        unitWidth: 'Unit Width',
        unitWidthDisabled: 'This feature is not available.  Changes are to be made manually in the drawing.',
        unitDepth: 'Unit Depth',
        patchError: 'There was a problem updating the unit.',
        patchSuccess: 'The unit was updated successfully!',
        alcoveWidthInvalidFromParts:
          'For automatic partition selection, enter a dimension between {min} and {max}. Otherwise, adjust the drawing manually to get a value outside the width of the alcove range.',
        alcoveWidthOutsideRangeFromInput:
          'Incorrect value. Please select a value between {min} and {max} or make changes manually in the drawing.',
      },
      addonLabels: {
        headrail60: '60" Headrail',
        headrail74: '74" Headrail',
        headrail96: '96" Headrail',
        headrail120: '120" Headrail',
        grabReinforcing: 'High Density Universal Grab Reinforcing',
        stabilizerBar80: '80" Stabilizer Bar',
        bowlCenterWarning: 'Check bowl dimensions. Less than 12" is not recommended',
      },
      bowlCenterModal: {
        title: 'Bowl Center Properties',
        bowlCenter: 'Bowl Center Dimension',
        rangeNotice: 'The minimum value is: {min}, the maximum is {max}',
      },
      commonProperties: {
        yes: 'Yes',
        no: 'No',
        obstruction: 'Add an Obstruction',
        obstructionDepth: 'Depth of the obstruction',
        postDisclamer: 'Post hardware is available in chrome with stirrup brackets only.',
        defaultWidthDisclaimer: 'The width of the obstruction is <b>6 inches</b> by default  and can’t be changed.',
        depthDisclaimer:
          'The depth of the obstruction must be <b>{clearance} inches</b> lower than the depth of the unit.',
        cutoutOption: 'Cutout Option',
        cutoutOptions: {
          no: 'No cutout',
          single: 'Single',
          double: 'Double',
        },
      },
      cutoutsProperties: {
        warning: 'A drawing of the cutout(s) must be provided when ordering.',
        cutout: 'Cutout(s)',
      },
      doorProperties: {
        title: 'Door Properties',
        swing: 'Door Swing',
        size: 'Door Width',
        enclosureWidth: 'Enclosure Width',
        applyWidthAllStandard: 'Apply width to all standard doors',
        applySwingAllStandard: 'Apply swing to all standard doors',
        applyAll: 'Apply to all doors',
        applyCustomWidth: 'Apply custom door opening width',
        customWidthlabel: 'Enter a door opening width within the range of {min}-{max}.',
        type: 'TYPE: ',
        typeDoor: 'Door',
        typeNone: 'None',
        typeCurtain: 'Curtain',
        twoDoorsWarning: 'Two doors can not hinge on one pilaster.',
      },
      wallPilaster: {
        title: 'Pilaster / Post Properties',
        noPilaster: 'No Pilaster',
        setPilaster: 'Select Pilaster or Post',
        pilasterOptions: 'Pilaster Options',
        pilaster: 'Pilaster',
        post: 'Post',
        pilasterWidth: 'Pilaster Width',
        pilasterWallGap: 'Pilaster Wall Gap',
        pilasterWallHardware: 'Pilaster Wall Hardware',
        squarePost: 'Square Post Options',
        squarePostGap: 'Square Post Wall Gap',
        painted: 'Painted',
        noOptions: 'No options available for the current configuration.',
      },
      pilasterProperties: {
        title: 'Pilaster Properties',
        width: 'Pilaster Width',
        offset: 'Select an Offset',
        type: 'Pilaster Options',
        selectType: 'Select pilaster position',
        oneInch: '1',
        twoInches: '2',
        alcovePilaster: 'Alcove Pilaster',
        pilasterPosition: 'Pilaster Position',
        inlinePilasterWarning: 'Pilaster sizes are limited when a door is hinging on an inline pilaster.',
        integral3InchesNote: ' (Wrap Hinge - Integral Unavailable)',
      },
      panelProperties: {
        title: 'Panel Properties',
        applyAllRear: 'Apply rear hardware to all panels',
        applyAllFront: 'Apply front hardware to all panels',
        grabBar: 'Add High Density Universal Grab Bar Panel',
        rearHardware: 'Rear Hardware',
        frontHardware: 'Front Hardware',
        panelWallGap: 'Wall Gap',
        panelPilasterGap: 'Pilaster Gap',
      },
      unitProperties: {
        title: 'Unit Properties',
        applyAll: 'Apply depth to all units',
        customDepth: 'Apply other depth',
        unitWidth: 'Unit Width',
        unitDepth: 'Unit Depth',
        extraWideSection: 'Extra Wide Unit',
        extraDeepSection: 'Extra Deep Unit',
        doorPosition: 'Select door position',
        unitWidthDisclaimer: 'Enter a unit width between {min}" - {max}"',
        standardUnitDepthDisclaimer: 'Select a standard unit depth',
        customUnitDepthDisclaimer: 'Enter a unit depth between {min}" - {max}"',
        customUnitDepthChannelsDisclaimer: 'Continuous channels may be used to obtain depth (with an added cost).',
        customUnitDepthDisabledDisclaimer:
          'Unit depth range will be limited if the standard depth is not applied to all units.',
        extraWide: 'With alcove channel extension',
        selectWidth: 'Select a panel size',
        extraDeep: 'With pilaster or panel extension',
        extraDeepWarning:
          'The unit depth range is limited by the Extra Deep option. You can change its standard depth through the adjacent unit.',
        selectDepth: 'Enter a pilaster or panel size between {min}" - {max}"',
        moreOptions: 'More options',
        crossBracing: 'Cross-Bracing',
        transomNotice: '(Panel with Transom)',
        unitType: {
          title: 'Unit Type',
          description: 'Please select the desired type for this unit.',
        },
        affOptionRespectingAdaStandards: {
          title: '{unitType} units',
          description: 'Mounting height above finished floor',
        },
      },
      extraWidePanelProperties: {
        title: 'Panel Properties',
        panelSize: 'Panel Size',
        wallGapHardware: 'Wall gap hardware',
        extensionTPilasterHardware: 'T Pilaster Hardware',
        wallGap: 'Wall gap',
        pilasterGap: 'Pilaster Gap',
        leftGap: 'Left Gap',
        rightGap: 'Right Gap',
        obstruction: 'Add an Obstruction',
        no: 'No',
        yes: 'Yes',
      },
      depthExtensionPanelProperties: {
        title: 'Extension Properties',
        panelSize: 'Select a Panel or Pilaster Size',
        frontGap: 'Front Gap',
        rearGap: 'Rear Gap',
      },
      workingDirection: {
        label: 'WORKING DIRECTION',
        leftToRight: 'Left to Right',
        rightToLeft: 'Right to Left',
      },
      orderedError: {
        title: 'Whoops!',
        text: "The room you are trying to edit has already been ordered. We have updated the room's listing to reflect its current status.",
        action: 'OK',
      },
    },
  },
  modal: {
    duplicateProject: {
      title: 'Duplicate & Assign',
      label: 'Choose your Organization',
    },
    actions: {
      save: 'save',
      cancel: 'cancel',
    },
    disableUser: {
      modalTitle: 'Disable User',
      accept: 'Disable',
      cancel: 'Cancel',
      warning: 'Are you sure you want to disable this user?',
      description: 'The user will be moved to the disabled users list.',
    },
    finishes: {
      starDescription:
        'Headrail braced toilet partitions available ' + 'for Premium Quick Ship in USA; subject to upcharge.',
      canadaDescription: 'Headrail braced toilet partitions available for Quick Ship in Canada.',
      usaDescriptionPartOne: 'Headrail braced, ceiling hung',
      usaDescriptionPartTwo:
        '(for 9 ft ceilings and lower) and floor mounted toilet partitions available for Quick Ship in USA.',
    },
    hardwarePackage: {
      modalTitle: 'Hardware Package',
      hardwareMaterialLabel: 'Hardware Material',
      hardwareMaterialPlasticLabel: 'Hardware Connections',
      doorHingesLabel: 'Door Hinges',
      doorLatchesLabel: 'Door Latches',
      noSightlineSolutionLabel: 'No-Sightline Solution',
      optionsLabel: 'Options',
    },
    genderAndFloor: {
      modalTitle: 'Gender & Floor',
      save: 'Save',
    },
    licenseAgreement: {
      modalTitle: 'License Agreement',
      cancel: 'Cancel',
      accept: 'I accept',
    },
    roomPackage: {
      withoutBom: 'Drawing only',
      withBom: 'BOM with Drawings',
      withoutPrices: 'Prices not included',
      withPrices: 'Prices included',
      header:
        'Select the rooms you want to export. You can choose to include or not the room prices and drawings to the PDF file export.',
      export: 'Generate',
      tableHeader: 'Rooms',
      title: 'Room package',
      roomsSelected: 'room(s) selected',
      pdfGenerationError: 'PDF generation failed.',
    },
    partitionTypeWarning: {
      rightButtonText: 'Apply change',
      mainText: 'Are you sure you want to change the partition type of this {0}?',
      project: 'project',
      room: 'room',
      subtext:
        'Any Bill of material edits made to the room will be <b>removed</b> and some parts may not be available for the selected partition type. Some parameters may also be reset to create a valid room.',
    },
    materialsWarning: {
      rightButtonText: 'Apply change',
      mainText: 'Are you sure you want to change the Material of this {0}?',
      project: 'project',
      room: 'room',
      subtext:
        'Any Bill of material edits made to the room will be <b>removed</b> and some parts may not be available for the selected material. Some parameters may also be reset to create a valid room.',
    },
    seriesWarning: {
      rightButtonText: 'Apply change',
      mainText: 'Are you sure you want to change the Series for this {0}?',
      project: 'project',
      room: 'room',
      subtext:
        'Any Bill of material edits made to the room will be <b>removed</b> and some parts may not be available for the selected series. Some parameters may also be reset to create a valid room.',
      addChannelSubtext: 'Your No-Sightline Solution will automatically be <b>switched to Continuous Channels</b>',
      keepChannelSubtext: 'Your No-Sightline Solution will automatically <b>keep the Continuous Channels</b>',
    },
    editSettings: {
      applyAll: 'Do you want to apply your project settings to all the rooms in the project?',
      warning: 'Please review your rooms after applying changes',
      context: {
        project: 'project',
        room: 'room',
      },
      Material: {
        banner: 'Switching between Phenolic and other materials is currently not available',
        title: 'Material',
        questionSingle: 'Are you sure you want to change the Material of this {context}?',
        questionMultiple: 'Are you sure you want to change the Material of all rooms?',
        partitionChangeSingle:
          'Any Bill of material edits made to the room will be <b>removed</b> and some parts may not be available for the material. Some parameters may also be reset to create a valid room.',
        partsOnlyChangeSingle:
          'No parts in your room will be changed. Filters in the room’s parts catalog will be reset to match the new settings selected.',
        partitionChangeMultiple:
          'Any Bill of material edits made to the Partitions and/or Screens rooms will be <b>removed</b> and some parts may not be available for the material. Some parameters may also be reset to create a valid room.',
        partsOnlyChangeMultiple:
          'No parts in your Parts Only rooms will be changed. Filters in the room’s parts catalog will be reset to match the new settings selected.',
      },
      Series: {
        title: 'Series',
        questionSingle: 'Are you sure you want to change the Series of this {context}?',
        questionMultiple: 'Are you sure you want to change the Series of all rooms?',
        partitionChangeSingle:
          'Any Bill of material edits made to the room will be <b>removed</b> and some parts may not be available for the series. Some parameters may also be reset to create a valid room.',
        partsOnlyChangeSingle:
          'No parts in your room will be changed. Filters in the room’s parts catalog will be reset to match the new settings selected.',
        partitionChangeMultiple:
          'Any Bill of material edits made to the Partitions and/or Screens rooms will be <b>removed</b> and some parts may not be available for the series. Some parameters may also be reset to create a valid room.',
        partsOnlyChangeMultiple:
          'No parts in your Parts Only rooms will be changed. Filters in the room’s parts catalog will be reset to match the new settings selected.',
      },
      Partition: {
        title: 'Partition Type',
        questionSingle: 'Are you sure you want to change the Partition Type of this {context}?',
        questionMultiple: 'Are you sure you want to change the Partition Type of all rooms?',
        partitionChangeSingle:
          'Any Bill of material edits made to the room will be <b>removed</b> and some parts may not be available for the partition type. Some parameters may also be reset to create a valid room.',
        partsOnlyChangeSingle:
          'No parts in your room will be changed. Filters in the room’s parts catalog will be reset to match the new settings selected.',
        partitionChangeMultiple:
          'Any Bill of material edits made to the Partitions and/or Screens rooms will be <b>removed</b> and some parts may not be available for the partition type. Some parameters may also be reset to create a valid room.',
        partsOnlyChangeMultiple:
          'No parts in your Parts Only rooms will be changed. Filters in the room’s parts catalog will be reset to match the new settings selected.',
      },
      Hardware: {
        title: 'Hardware',
        questionSingle: 'Are you sure you want to change the Hardware of this {context}?',
        questionMultiple: 'Are you sure you want to change the Hardware of all rooms?',
        partitionChangeSingle:
          'Bill of material edits made to the room <b>may be removed</b> when material, series and/or partition type is updated as well. Some parts may not be available for the selected hardware.',
        partsOnlyChangeSingle:
          'No parts in your room will be changed. Filters in the room’s parts catalog will be reset to match the new settings selected.',
        partitionChangeMultiple:
          'Bill of material edits made to the rooms <b>may be removed</b> when material, series and/or partition type is updated as well; some parts may not be available for the selected hardware.',
        partsOnlyChangeMultiple:
          'No parts in your Parts Only rooms will be changed. Filters in the room’s parts catalog will be reset to match the new settings selected.',
      },
      Finish: {
        title: 'Finish',
        questionSingle: 'Are you sure you want to change the Finish of this {context}?',
        questionMultiple: 'Are you sure you want to change the Finish of all rooms?',
        partitionChangeMultiple:
          'Bill of material edits made to the rooms <b>may be removed</b> when material, series and/or partition type is updated as well. Some parts may not be available for the selected finish.',
        partsOnlyChangeMultiple: 'Parts of the same Material in your Parts Only rooms will be changed.',
      },
      Gender: {
        title: 'Gender and Floor',
      },
    },
    confirmationOverlayMainText: 'Do you want to apply your project settings to all the rooms in the project?',
    confirmationOverlaySubText:
      'Switching to or from Solid Plastic is not yet supported. Powder Coated rooms and Stainless Steel rooms that are already created will not be updated with Solid Plastic settings. Solid Plastic rooms that are already created will not be updated with Powder Coated or Stainless Steel settings.',
    suggestedParts: {
      relatedTitle: 'Related Parts',
      relatedToThisItemTitle: 'Products related to this item',
      addingPartText: 'Adding the part <b>#{partNumber}</b> requires to add the following components listed below:',
      removingPartText:
        'Removing the part <b>#{partNumber}</b> requires to remove the following components listed below:',
      tableHeaders: {
        parts: 'Parts',
        listPrice: 'List Price',
        originalQty: 'Original Qty',
        qtyAdded: 'Qty Added',
        qtyRemoved: 'Qty Removed',
        suggestedQty: 'Suggested Qty',
      },
      originalQtyToolTip: 'Quantity currently in the parts list',
      qtyAddedToolTip: 'Quantity that will be added to the parts list',
      qtyRemovedToolTip: 'Quantity that will be removed from the parts list',
      relatedToItem: 'Products related to this item',
      partNumber: 'PART# ',
      ratioText: '{count} unit for each #{partNumber} | {count} units for each #{partNumber}',
    },
    listExportModal: {
      projectEntity: 'project',
      orderEntity: 'order',
      exportProjectsText: 'Project List - CSV',
      exportOrdersText: 'Order List - CSV',
      projectListTitle: 'Export Projects List to CSV file',
      orderListTitle: 'Export Orders List to CSV file',
      exportListText: 'Select the required filter parameters and click the generate button to download the CSV file.',
      resetFilters: 'Reset filters',
      footerEntityListCount:
        '<span>{count} {entity}</span> <span>will be included in your CSV file.</span> | <span>{count} {entity}s</span> <span>will be included in your CSV file.</span>',
      footerProjectErrorMessage: 'The CSV file cannot exceed 10,000 projects. Please refine your filter parameters.',
      fetchListCountError: 'There was an error filtering the list',
      exportCsvFailedError: 'An error occured during CSV generation.',
    },
  },
  projects: {
    title: 'Projects',
    newProject: 'New project',
    filter: 'FILTER BY',
    partitions: 'Partitions',
    allTypes: 'All types',
    lockers: 'Lockers',
    dryers: 'World Dryer',
    searchPlaceholder: 'Project Name, PO#, ...',
    applyToAllRoomsConfirmation: 'Do you want to apply the changes to all rooms within the project?',
    searchTooltip: 'Searchable columns are: <i>Project name & ID, PO # and Sales order #</i>',
    resetFilter: 'Reset Filter',
    apply: 'Apply',
    cancel: 'Cancel',
    permissionsFailure: 'Failed to retrieve organization permissions, please try again later.',
    allOrganizations: 'All Organizations',
    onlyDistributors: 'Distributors',
    onlySalesAgencies: 'All Sales Agencies',
    notAProject: 'The requested ID does not correspond to any project.',
    noProjectsFind: 'No projects correspond to your search',
    noProjectsCreated: 'No projects have been created yet',
    invalidProjectName: 'This project name is not valid.',
    datesWarning: 'Please select a start date',
    table: {
      column: {
        projectName: 'Project Name',
        distributorName: 'Distributor Name',
        clientName: 'Client Name',
        lastUpdatedDate: 'Last Updated',
        price: 'Price',
      },
    },
    tabs: {
      partition: {
        settings: 'Settings & Info',
        rooms: 'Rooms',
        parts: 'Parts',
        bom: 'Bill of Materials',
        notes: 'Notes',
        quotes: 'Quotes',
        orders: 'Orders',
        markup: 'Markup',
        discounts: 'Discounts',
      },
      lockers: {
        settings: 'Info',
        rooms: 'Styles & Rooms',
        parts: 'Parts',
        bom: 'Bill of Materials',
        notes: 'Notes',
        quotes: 'Quotes',
        orders: 'Orders',
        markup: 'Markup',
        discounts: 'Discounts',
        solidSides: 'Solid sides',
      },
      dryers: {
        settings: 'Info',
        parts: 'Parts',
        bom: 'Bill of Materials',
        quotes: 'Quotes',
        orders: 'Orders',
        markup: 'Markup',
        discounts: 'Discounts',
      },
      undefined: {
        settings: 'Loading',
        rooms: '-',
        parts: '-',
        bom: '-',
        notes: '-',
        quotes: '-',
        orders: '-',
        markup: '-',
        discounts: 'Tabs',
      },
    },
    creation: {
      creationError: 'There was an issue creating the project. \n Please try again in a few minutes.',
      information: {
        title: 'Project Information',
        organizationLabel: 'Organization',
        organizationPlaceholder: 'Select an organization',
        projectTypeLabel: 'Project Type',
        projectNameLabel: 'Project Name',
        projectNamePlaceholder: 'New Project',
        clientNameLabel: 'Client Name',
        clientNamePlaceholder: 'Client name',
        partsOnlyLabel: 'Order spare parts only',
        projectType: {
          Undecided: 'Select a type',
          Lockers: 'Lockers',
          Partitions: 'Partitions',
          Dryers: 'World Dryer',
        },
      },
      material: {
        title: 'Material',
      },
      series: {
        title: 'Series',
      },
      partition: {
        title: 'Partition type',
        affLabel: 'Mounting height above finished floor',
        standardAffLabel: 'Standard',
        adaAmbAffLabel: 'ADA/BF & AMB',
        PhenolicF2CAffNoticeStandardUnit: 'For Phenolic Floor to Ceiling, the Standard AFF will be {aff}',
        PhenolicF2CAffNoticeAdaUnit: ' and the ADA/BF & AMB will be {aff}',
        floorToCeiling: {
          label: 'Finished floor to ceiling height:',
          unknown: 'Unknown',
          dimensionLabel: 'Between {min} and {max}',
          minDistanceFormulaPreamble: 'The min range value will change according to this equation:',
          minDistanceFormula: 'AFF + Door and panel height + {minDistance} = Min. ceiling height',
          minDistanceNotice:
            'For ceiling heights lower than {min}, change the AFF and/or the panel and door height values.',
        },
        doorHeight: {
          label: 'Panel height & door height:',
          detailLabel: 'Panel height is equal to door height',
        },
      },
      hardware: {
        title: 'Hardware package',
        material: {
          title: 'Hardware Material',
          plastic_title: 'Hardware Connections',
        },
        doorHinge: {
          title: 'Door Hinges',
        },
        latches: {
          title: 'Latch',
        },
        noSightLine: {
          title: 'No-Sightline Solutions',
        },
        options: {
          title: 'Options',
        },
      },
      finishes: {
        legend: 'Legend',
        notes: 'Notes',
        currentChoice: 'Current choice',
        cardTitle: 'Colors / Finish',
        filterTitle: 'Types',
        specialUpcharge: 'Subject to upcharge',
        starDescription:
          'Headrail braced toilet partitions available for Premium Quick Ship in USA; subject to upcharge.',
        canadaDescription: 'Headrail braced toilet partitions available for Quick Ship in Canada.',
        psDescription: 'Project Specific colors are unique colors that are offered to your business.',
        usaDescription:
          'Headrail braced (including Elite 6" and 9" AFF), ceiling hung (for 9 ft ceilings and lower) and floor mounted toilet partitions available for Quick Ship in USA.',
        colorsDisclaimer1:
          '<b>For custom colors,</b> a minimum of 20 units for toilet partitions and 100 units for lockers is required per color. Custom colors are subject to upcharge.',
        colorsDisclaimer2:
          "<i>Colors shown are approximations only. For accurate color matches, refer to Hadrian's printed color card. Hadrian reserves the right to change colors and/or finishes without prior notice.</i>",
        lockersDisclaimer:
          '<b>For custom colors,</b> a minimum of 20 units for toilet partitions and 100 units for lockers is required per color. Custom colors are subject to upcharge.',
        plasticSpecs: {
          title: 'Certified class B rated HDPE:',
          firstBulletPoint: '<b>Flame Spread Index:</b> 26-75',
          secondBulletPoint: '<b>Smoke Developed Index:</b> 0 - 450',
        },
        belowCardTooltip:
          '<b>Punched out logo</b> <br/> Every door will be punched out with a "{punchedOutText}" logo.',
      },
      cancel: {
        no: 'no',
        yes: 'YES',
        topText: '<b>Do you really want to leave ?</b>',
        topTextCreateStyle:
          'You are about to cancel the creation of this style. If you do so, all progress will be lost.',
        topTextUpdateStyle:
          'You are about to cancel the update of this style. If you do so, all progress will be lost.',
        bottomText: 'You are about to cancel the creation of this project. If you do so, all progress will be lost.',
      },
    },
    lockersCreation: {
      updatePrompt: {
        title: 'Save My Changes',
        subTitle: 'Warning',
        intro: 'Parts in existing rooms will be updated to match the current changes.',
        outro: 'Please review all rooms associated to the edited Style before ordering.',
        dfaDoWarning1:
          'Banks will be reset to default values if <b>Package Selection</b> or <b>Width changes</b> result in invalid configurations.',
        dfaDoWarning2:
          'If your new tier selection is ADA compliant, shelves from your room configuration with this Style will be removed.',
        dfaDoWarning3:
          'Locks will be removed if they are not provided by Hadrian and they will not be automatically added to existing rooms.',
        dfaDoWarning4:
          'Recess trim will be removed from your rooms if the locker height changes to an invalid trim height.',
        dfaDoWarning5:
          'Room configurations with Door and Frame Assemblies or Door Only will be deleted if the edited Styledoes not allow them anymore.',
        tiersWarning:
          'Your new tiers selection is ADA compliant. Shelves from your room configurations with this Style might be removed.',
        packagingOptionWarning:
          'Banks will be reset to default values if Package Selection or Width changes result in invalid configurations.',
        locksWarning:
          'Locks will be removed if they are not provided by Hadrian but they will not be automatically added to existing rooms',
        heightWarning:
          'Recess trims will be removed from your rooms if Height changes result in invalid configurations.',
      },
      series: {
        styleTitle: 'Style Name',
        styleInputLabel: 'Style Name',
        styleNameMaxLength: 'Maximum of 40 characters.',
        styleNameNonUnique: 'The style name must be unique in your project.',
        styleNameWhiteSpace: 'The style name cannot contain only whitespace.',
        title: 'Series',
        contactHadrian: 'Please contact Hadrian for quoting and ordering.',
        notice: {
          title: 'Welcome!',
          subtitle: 'Let’s begin by creating a style for your project.',
          textTitle:
            'Once your first style is created, you will be redirected to the project page. From there you can:',
          mainText:
            '• Create other styles for your project; </br> • Start creating rooms suitable to your first created style.',
          checkboxLabel: 'Never show this message again when creating a new project',
        },
      },
      finish: {
        door: 'door',
        frameAndTrim: 'frame & trim',
        frameAndTrimTitle: 'Frame & Trim Color',
        frame: 'Frame',
        shelves: 'Shelves',
        pleaseSelectFinish: 'Please select the <b>{type}</b> finish.',
        doorLabel: 'Door',
        frameAndTrimLabel: 'Frame & Trim',
        bodyLabel: 'Body',
      },
      options: {
        pageTitle: 'Options',
        packageSelectionTitle: 'Kit',
        packageSelectionLabel: 'Package Selection',
        fastenersTitle: 'Fasteners',
        fastenersLabel: 'Fastener Types',
        tiersTitle: 'Tiers',
        tiersItemLabel: '{count} Tier | {count} Tiers',
        tierNotAvailable: 'This option is not available with your current series/type selection.',
        measurementsTitle: 'Measurements',
        measurementsPlaceholder: 'Select',
        noDepthPackageSelection:
          'Package selection is not available when depth is set to “None”. To select a kit, change your depth selection.',
        noDepthTooltip:
          'To order <b>door & frame assemblies</b> or <b>door only</b>, select “None” in the dropdown below.',
        noDepth: 'None',
        noDepthWarning:
          'Some styling options will not be available because you are creating a style without depth. No depth selection is used to let you order door and frame assemblies or door only.',
        disabledMeasurements: 'To choose your measurements, please select the number of tiers.',
        obligatoryWidthTooltip: 'To choose a depth, first select your width.',
        obligatoryWidthDepthTooltip: 'To choose a height, first select your width and depth.',
        widthLabel: 'Width',
        depthLabel: 'Depth',
        heightLabel: 'Height',
        notice: {
          title: 'Assembled Kit Notice',
          subtitle: 'Please consult the information below.',
          textTitle: 'All prices are LIST PER LOCKER',
          headerText:
            '<div>• Assembled in single units or in banks of two (please specify)</div>' +
            '<div>• Assembled lockers are <b>FCA Calgary</b></div>',
          checkboxLabel: 'Never show this message again when creating a new locker project',
          tableFirstRow:
            '<td>' +
            '</td>' +
            '<td>' +
            '    Single tier' +
            '</td>' +
            '<td>' +
            '    Two tiers' +
            '</td>' +
            '<td>' +
            '    Three tiers' +
            '</td>' +
            '<td>' +
            '    Four tiers' +
            '</td>' +
            '<td>' +
            '    Five tiers' +
            '</td>' +
            '<td>' +
            '    Six tiers' +
            '</td>',
          tableSecondRow:
            '<td>' +
            'Emperor Series' +
            '</td>' +
            '<td>' +
            '    $ 67.00' +
            '</td>' +
            '<td>' +
            '    $ 70.00' +
            '</td>' +
            '<td>' +
            '    $ 73.00' +
            '</td>' +
            '<td>' +
            '    $ 77.00' +
            '</td>' +
            '<td>' +
            '    $ 81.00' +
            '</td>' +
            '<td>' +
            '    $ 84.00' +
            '</td>',
          tableThirdRow:
            '<td>' +
            '    Gladiator Series' +
            '</td>' +
            '<td>' +
            '    $ 82.00' +
            '</td>' +
            '<td>' +
            '    $ 84.00' +
            '</td>' +
            '<td>' +
            '    $ 88.00' +
            '</td>' +
            '<td>' +
            '    $ 92.00' +
            '</td>' +
            '<td>' +
            '    N/A' +
            '</td>' +
            '<td>' +
            '    $ 99.00' +
            '</td>',
          termsTitle: 'Terms & Conditions',
          termsText:
            '<div>• All prices are LIST per locker.</div>' +
            '<div>• All prices are for assembly only and are in addition to the price of the lockers themselves.</div>' +
            '<div>• Prices are subject to change without notice.</div>' +
            '<div>• All lockers will be riveted.</div>' +
            '<div>• All lockers will be stretch wrapped and palletized for shipping.</div>' +
            '<div>• Approximately one extra week lead time will be required.</div>' +
            '<div>• No minimum or maximum quantities (call for lead time on assembly of 50 or more lockers).</div>' +
            '<div>• Call for pricing on assembly of custom sizes.</div>' +
            '<div>• Freight charges (NMFC code 080460-01) and risk of shipping damage are increased with assembled lockers.</div>' +
            '<div>• Accessories such as slope tops, dress ends, z-bases, fillers, locks, number plates, etc. will ship loose to be installed at the job site.</div>' +
            '<div>• Box Base assembly is included in price if that base option is selected.</div>',
        },
      },
      prep: {
        title: 'Lock Preparation',
        typeLabel: 'Lock Preparation',
        latchOptionsHeader: 'Latch Option',
        positiveLatchLabel: 'Positive Latch',
        providedByHadrian: 'Yes',
        providedByOthers: 'No, they are provided by others',
        hadrianStandardLocks: 'Are the locks to be supplied by Hadrian?',
        standardLockers: 'Standard Lockers',
        standardLocks: 'Standard Locks',
        standardLocksLabel: 'Select a Lock Option',
        hadrianAdaLocks: 'Are the locks to be supplied by Hadrian?',
        adaLocks: 'ADA Locks',
        adaLockers: 'ADA Lockers',
        adaLocksLabel: 'Select an ADA Lock Option',
        digitalTitle: 'Hadrian does not provide digital locks.',
        digitalSubtitle: 'Digital locks must be purchased by others.',
        digitalApproved:
          'The locks below are <em class="lockers-prep__digital-approved-emphasis">tested and approved</em> for use with Hadrian lockers:',
        digitalOption1: 'Digilock Range Keypad NLRK-AD02-619-01P1',
        digitalOption2: 'Digilock Range RFID NLRR-AD02-619-01P1',
        digitalOption3: 'Digilock Range Keypad/RFID combo NLRC-AD02-619-01P1',
        digitalOption4: 'Digilock Aspire keypad D6AKX-0XS0-XXX-X1P1',
        digitalOption5: 'Digilock Aspire RFID D6ARX-0XS0-XXX-X1P1',
        digitalOption6: 'Digilock Axis Keypad NLSK2-AXS0-XXX-01P1',
        digitalOption7: 'Digilock Axis RFID NLTR2-AXS0-XXX-01P1',
        digitalWarning:
          'Digital locks not on the list will have to be approved by Hadrian. Customers are responsible for purchasing locks.',
      },
      hooks: {
        title: 'Hooks & Number Plates Preparation',
        noDepth: 'Hooks are not available when depth is set to “None”.',
        hooksSectionHeader: 'Hooks',
        hooksLabel: 'Hook Type',
        hooksDisabled:
          'Tiers 4, 5 and 6 do not allow hooks. If you need hooks, change your tiers selection by going back to the options step.',
        platesSectionHeader: 'Number Plates',
        platesLabel: 'Plate Material',
        tooltipTextMain:
          'Hadrian’s standard number plate is made of a durable black plastic. Aluminum number plates are available as an optional upcharge item (not available for locker express program orders).',
        tooltipItem1: 'Number plates 1-999 are standard.',
        tooltipItem2: '4 characters or letters are subject to an upcharge.',
        notice: {
          title: 'Double Hook Notice',
          subtitle: 'Please consult the information below.',
          textTitle: 'Important!',
          checkboxLabel: 'Never show this message again when creating a new locker project',
          mainText1: 'Double hooks are drilled in field.',
          mainText2: 'Please contact customer service if you require pre-punched shelves. Special charges may apply.',
        },
      },
    },
    create: {
      title: 'New Project',
      start: 'Start',
      startProject: 'Start project',
      basePath: 'Projects',
      breadcrumb1: 'New Project',
      clientName: 'Client name',
      projectName: 'Project name',
      distributorName: 'Distributor name',
      hardwarePackage: 'Hardware Package',
      finishes: 'Finishes / Colors',
      materials: 'Materials',
      partitions: 'Partitions',
      createFailed:
        'All the basic settings have to be selected before creating a project. ' +
        'It will be possible to make changes once the project is created.',
      progressBar: {
        mandatoryBubbleText:
          'You must fill all <span class="mandatory">*</span><b>mandatory</b> fields to proceed to the next step.',
        newProject: 'New Project',
        previous: 'Previous',
        next: 'NEXT',
        create: 'CREATE',
        update: 'UPDATE',
        cancel: 'Cancel',
        partitions: {
          information: 'New Project',
          material: 'Materials',
          series: 'Series',
          type: 'Type',
          hardware: 'Hardware',
          finish: 'Colors',
        },
        lockers: {
          information: 'Information',
          nameandseries: 'Series',
          type: 'Type',
          firstfinish: 'Finish',
          secondfinish: 'Finish',
          options: 'Options',
          preparation: 'Preparation',
        },
      },
      dryers: {
        notice: {
          title: 'Welcome!',
          subtitle:
            "Let's start your new World Dryer project by selecting a product category. The selection you will make will define how the catalog is filtered.",
          checkboxLabel: 'Never show again this modal when creating a new Dryers project',
          mostPopular: 'Most Popular Product',
          moreProducts: 'More Products',
          featuredProduct: 'Featured Product',
        },
      },
    },
    update: {
      success: 'Project successfully updated',
      error: 'There was an error updating this project',
    },
    bom: {
      title: 'Bill of Materials',
      partTitle: 'Parts',
      pdf: 'PDF (without prices)',
      csvWithout: 'Group Per Room - CSV',
      csvWith: 'Group Per Room (with prices) - CSV',
      pdfWithout: 'Group Per Room - PDF',
      pdfWith: 'Group Per Room (with prices) - PDF',
      csvSettingsWithout: 'Group Per Room Settings - CSV',
      pdfSettingsWithout: 'Group Per Room Settings - PDF',
      partSummary: 'Summary of parts - CSV',
      viewPerRoom: 'VIEW PER ROOM',
      bomOptions: 'BOM OPTIONS',
      filterBy: 'FILTER BY FINISH',
      sortBy: 'SORT BY',
      finishFilterPlaceholder: 'All available finishes',
      all: 'All',
      ordered: 'Ordered',
      nonOrdered: 'Non ordered',
      orderedParts: {
        title: 'Parts - {orderNumber}',
        poNumber: 'PO# {poNumber}',
        orderDate: 'ordered on {orderDate}',
      },
      sort: {
        sequenceNumber: 'Sequence Number',
        creationDate: 'Creation Date',
        nameAscending: 'Room name - ascending (A-Z)',
        nameDescending: 'Room name - descending (Z-A)',
      },
      total: {
        standardLockers: 'Standard lockers:',
        adaLockers: 'ADA lockers:',
        adaUnits: 'ADA Units',
        totalOfUnits: 'Total Units:',
        benches: 'Benches:',
        standardUnits: 'Standard Units:',
        bfUnits: 'ADA/BF Units:',
        alcoveUnits: 'Alcove Units:',
        screens: 'Screens:',
        totalPartitions: 'Total Partitions:',
        totalOfLockers: 'Total Lockers:',
        materialWeight: 'Material Weight:',
        totalWeight: 'Total Weight:',
        skidWeight: 'Estimated SKID Weight:',
        numberSkids: 'Number Skids:',
        density: 'Density:',
        freightClass: 'Freight Class:',
        freightCharge: 'Estimated Freight:',
        handDryers: 'Dryers',
        babyChangingStations: 'Baby Changing Stations:',
        accessories: 'Parts and Accessories',
        totalItems: 'Total Items',
        dryers: 'Dryers:',
        dryerAccessories: 'Parts & Accessories:',
        dryersTotal: 'Total Items:',
        bootRacksTotal: 'Boot Racks:',
        unknown: 'Unknown',
        totalPrice: 'TOTAL PRICE:',
      },
    },
    details: {
      addAdress: 'Add a new address',
      imageDisclaimer:
        "<i>Colors shown are approximations only. For accurate color matches, refer to Hadrian's printed color card. Hadrian reserves the right to change colors and/or finishes without prior notice.</i>",
      markup: {
        title: 'Markup',
        subtitle:
          "The active project’s markup will be saved when a quote is created. It will be applied whenever a customer's quote PDF is generated for that quote. Changing the project’s markup will have no impact on older quotes.",
        warning:
          "The Markup has been deleted for your organization. You must add a Markup in the Organization section to be able to generate Customer's Quotes again. If a new markup is defined at the organization level, the project-level markup will be restored.",
        defaultMarkup: 'Default Markup',
        projectMarkup: 'Project Markup %',
        projectPrice: 'Total Unordered Price (Markup included)',
        saveSuccess: 'The project markup has been saved successfully.',
        resetSuccess: 'The project markup has been reset to default successfully.',
        resetToDefault: 'Reset to default markup',
        errors: {
          noChange: 'The markup has not been changed.',
          notInRange: 'The markup must be between {min}% and {max}%.',
          priceNotInRange: 'The total price must be between ${min} and ${max}.',
          saveFailed: 'There was an error saving the project markup.',
          resetFaialed: 'There was an error resetting the markup to default.',
        },
        resetModal: {
          title: 'Do you really want to reset the project markup?',
          body: 'If you do, the project markup and the Total Unordered Price will be reset to suit the default Markup. All quotes created with a previous default or edited markup will not be affected by the change.',
          confirm: 'Reset markup',
        },
      },
      info: {
        title: 'Project Information',
        poNumber: 'PO #',
        salesAgent: 'Sales Agency',
        salesOrder: 'Sales Order #',
        shipTo: 'Ship To',
        shipToPlaceholder: 'Select an address',
        creationDate: 'Creation Date',
        lastEdit: 'Last Edit',
        distributedBy: 'Distributed by',
        createdBy: 'Created By',
        client: 'Client',
        leadTime: 'Lead Time',
        leadTimeLabel: 'Unavailable | {count} business day | {count} business days',
        shipping: 'shipping not included',
        totalPrice: 'TOTAL PRICE',
        totalOrdered: 'TOTAL ORDERED',
        totalUnordered: 'TOTAL UNORDERED',
        submittalPackage: 'Submittal Package',
        roomPackage: 'Room Package',
        units: 'Units',
        architect: 'Architect',
        contractor: 'Contractor',
        location: 'Project Location',
        totalUnits: 'Total Units',
        discount: 'Discount',
        markup: 'Markup',
        phone: 'Phone',
        projectRef: 'Project Ref. #',
        linkLabelToOriginalProject: 'This project has been created based on this quote:',
      },
      settings: {
        ok: 'OK',
        save: 'save',
        delete: 'delete',
        cancel: 'cancel',
        title: 'Settings',
        options: 'Options - Door Hinges: ',
        noSightLines: 'No-Sightline Solution: ',
        hardwareMaterial: 'Hardware Material',
        doorHinges: 'Door Hinges',
        noSightLinesLabel: 'No-Sightline Solution:',
        noSightLinesInfo: 'You can choose more than one No-Sightline Solution.',
        option: 'Option',
        material: 'Material',
        seriesLabel: 'Series',
        partitionTypes: 'Partition Type',
        seriesTypes: 'Series',
        hardwarePackage: 'Hardware Package',
        doorHingesLabel: 'Door Hinges:',
        doorLatchLabel: 'Door Latch:',
        hardwareOptionsLabel: 'Options:',
        colorsFinish: 'Colors / Finish',
        finish: 'Finish',
        none: 'None',

        mountingAFFLabel: 'Mounting height above finished floor: {0}',
        finishedCeilingHeight: 'Finished floor to ceiling height: {0}',
        panelAndDoorHeight: 'Panel height & door height: {0}',
      },
      summary: {
        createButton: 'Go to Styles & Rooms Tab',
        totalLockersCountLabel: 'Total',
        totalLockersCount: '{count} locker | {count} lockers',
        totalBootRacksCount: '{count} boot rack | {count} boot racks',
        totalActiveCount: '{count} active',
        totalOrderedCount: '{count} ordered',
      },
    },
    parts: {
      tableHeaders: {
        originalQty: 'Original QTY',
        actualQty: 'Actual QTY',
        quantity: 'Quantity',
        partNumber: 'Part number',
        description: 'Description',
        unitWeight: 'Unit weight',
        unitPrice: 'Unit price',
        listPrice: 'List price',
        netPrice: 'Net price',
        subtotal: 'subtotal',
        roomTotal: 'Room Total',
        tooltip: {
          originalQty: 'The original quantity is the quantity of parts generated in the room layout.',
          actualQty:
            'The actual quantity is the total room quantity, which shows when you subtract or add parts to the room.',
        },
      },
      cardBack: {
        description: 'A free bottle of touch-up paint for the selected color was added as an extra part.',
        free: 'Free',
        touchUp: 'Touch-up paint bottle',
        warning: 'Only one bottle is needed for multiple parts of the same finish.',
        notNeeded: 'Not needed',
        ok: 'OK',
      },
      cardMeasurements: {
        mountHeight: 'Mounting height above finished floor: ',
        doorHeight: 'Panel height & door height: ',
        doorHeightHelper: 'Panel height is equal to door height',
        doorWidth: 'Door Opening Width',
        floorToCeiling: 'Finished floor to ceiling height: ',
        heightRange: 'Between {min}" - {max}"',
        minDistanceFormulaPreamble: 'Range value will change according to this equation:',
        minDistanceFormula: 'AFF + Door and panel height + {minDistance} = Min. ceiling height',
        dimensionLabel: 'Between {min} and {max}',
        minDistanceNotice:
          'For ceiling heights lower than {min}, change the AFF and/or the panel and door height values.',
        doorWidthTooltip:
          'The range available is always narrower than your selected door\'s width. If you need a width outside of this range, pick another door (the door must be at least 1/8" wider than your desired width).',
        cancel: 'Cancel',
        save: 'Save',
      },
      clearAll: {
        subtitle: 'Do you really want to clear all changes?',
        text: 'Generated parts quantities will be reset and added parts will be removed from the parts list.',
        rightButtonText: 'Clear all changes',
      },
      materialFilter: 'Material',
      partTypeFilter: 'Part type',
      partTypePlaceholder: 'All part types',
      emptyListWarning: 'No parts correspond to your search.',
      emptyListWarningDryers: 'The applied filters may prevent the system showing up the part you are looking for.',
      emptyListWarningSuggestion: 'To see all the catalog parts available, try ',
      emptyListWarningReset: 'resetting all filters',
      emptyListWarningOr: ' or ',
      emptyListWarningClear: 'clearing the search.',
      emptyAddedPartsWarning: 'No parts have been added for the moment.',
      noParts: 'No parts have been added yet',
      noUpcomingParts: 'No parts correspond to your search',
      noPartsDryers: 'Your search doesn’t match to any parts in our system.',
      noPartsDryersSubtitle:
        'Please review the wording/part number used in the search bar. If the spelling seems <br/> to be correct, please contact your Sales Representant for further assistance.',
      recentlyAdded: 'Added',
      partNumber: 'Part#',
      selectMeasurements: 'Select measurements',
      partColor: 'Part color',
      quantity: 'Quantity',
      add: 'ADD',
      removeAll: 'REMOVE ALL',
      measurements: 'Measurements',
      aff: 'AFF: ',
      ceilingHeight: 'Ceiling Height: ',
      doorWidth: 'Door Opening Width: ',
      doorHeight: 'Door Height: ',
      select: 'SELECT',
      and: 'and',
      galleryViewTooltip: 'Parts catalog<br />(gallery view)',
      listViewTooltip: 'Parts catalog<br />(list view)',
      bomViewRoomTooltip: 'Parts list<br />(room)',
      bomViewProjectTooltip: 'Parts list<br />(project)',
      part: 'part | parts',
      moreInfo: 'more info',
    },
    duplicateConfirmation: 'Are you sure you want to duplicate this project?',
    deletionConfirmation: 'Are you sure you want to delete this project?',
    deletionError: 'There was an error while deleting the project.',
    duplicateSuccessful: 'The project has been duplicated successfully',
    duplicateError: 'There was an error while duplicating the project.',
    deletionSuccessful: 'The project has been deleted successfully',
    deletionMultipleConfirmation: 'Are you sure you want to delete the selected projects?',
    deletionMultipleSuccessful: 'The projects have been deleted successfully',
    refreshBomSuccessful: 'The Project Bom has been refreshed successfully',
    refreshBomError: 'There was an error while refreshing the Bom of the project.',
    notes: {
      title: 'Notes*',
      placeholder: 'Start typing...',
      footer: 'These notes are for your own internal use only.',
      saveFeedback: "The project's notes have been saved correctly.",
    },
    discounts: {
      view: 'View:',
      activeDiscount: 'Active discount',
      inactiveDiscounts: 'Inactive discounts',
      edit: 'Edit',
      disable: 'Disable',
      title: 'Discount',
      expirationDate: 'Expiration date',
      noActive: 'No active discount',
      create: 'Create a discount',
      createSuccess: 'Your discount has been successfully saved.',
      createError: 'An error occurred while trying to create the discount.',
      updateSuccess: 'Your discount has been successfully updated.',
      updateError: 'An error occurred while trying to update the discount.',
      disableSuccess: 'The discount was successfully disabled.',
      disableError: 'An error occurred while trying to disable the discount.',
      concurrentModificationError: 'The discount you attempted to modify has been disabled by another user.',
      concurrentCreationError: 'A discount has been created by another user.',
      maxRateOverrideError: 'The necessary permission to override the max discount rate were not found',
      permissionError: 'The necessary permission to edit the discount rate were not found',
      disableDiscountModal: {
        modalTitle: 'Disable discount',
        mainText: 'Do you really want to disable this discount?',
        subText: 'The discount will be transferred to the discounts history list.',
        rightButtonText: 'Yes, disable',
      },
      details: {
        expirationDate: 'Expiration date:',
        expirationDatePlaceholder: 'Click here to select a date',
        cancel: 'cancel',
        save: 'submit',
        editSave: 'save',
        materialType: 'Material Type',
        quantity: 'Quantity',
        discount: 'Discount',
        multiplier: 'Multiplier',
        amount: '$ Amount',
        amountTooltip: "The applied discount is a percentage of the material's total cost.",
        commissionRate: 'Commission Rate',
        commissionRateCropped: 'Com. Rate',
        commissionRateShortened: '(CR: {rate}%)',
        suggestedRate: 'Sugg. Rate:',
        maxRate: 'Max. Rate:',
        suggestedMultiplier: 'Sugg. Value:',
        minMultiplier: 'Min. Value:',
        invalidValue: 'Invalid value',
        missingValue: '- - -',
        edit: 'edit',
        resetToDefault: 'Reset',
        maxRateOverridden: 'Max. rate overridden',
        minRateOverridden: 'Min. rate overridden',
        overriddenDiscount: 'Overridden discount',
        defaultDiscount: 'Default material discount',
        defaultCommissionRate: 'Default',
        maxCommissionRate: 'Max. Rate',
        materialNotAvailable: 'This material is not yet available.',
        totalDiscount: 'Total discount',
        totalValue: 'Total price after discount',
        createdBy: 'Created By',
        updatedBy: 'Updated By',
        reasonLabel: 'Reason for the discount:',
        previousReasonLabel: 'Previous reason:',
        reasonNote: 'Maximum of 250 characters.',
        errors: {
          prefix: 'To proceed, you must',
          and: ' and ',
          invalidMaterial: 'fix any errors in your discount overrides',
          noMaterial: `override at least one material's discount`,
          noReason: 'enter a reason',
          noExpiration: 'select an expiration date',
          invalidExpiration: ' expiration is invalid',
          differentReason: 'enter a different reason than the previous discount',
        },
      },
      history: {
        title: 'Discount history',
        dateTime: 'Created/Edited at',
        createdBy: 'Creator/Editor',
        powderCoated: 'Powder Coated',
        stainless: 'Stainless Steel',
        plastic: 'Solid Plastic',
        expiration: 'Expires',
        expandDetails: 'Expand Details',
        reason: 'Reason:',
        default: 'Default',
      },
      inactive: {
        status: 'discount status',
        dateTime: 'Created/Edited at',
        expiration: 'Expiration',
        creatorEditor: 'Creator/Editor',
        materials: 'Materials',
        reason: 'Reason',
        history: 'History',
        edited: 'Edited',
        created: 'Created',
        disabled: 'Disabled',
        expired: 'Expired',
        noDiscounts: 'No inactive discounts.',
      },
    },
  },
  organizations: {
    markedUp: 'Marked up',
    noOrganizationsFind: 'No organizations correspond to your search',
    noOrganizations: 'No organizations',
    organizations: 'Organizations',
    organizationUploadImageCard: {
      noticeTitle: 'Custom Branding Feature Notice',
      noticeBody:
        'The branding feature allows you to upload your brand (logo) and web address to Project Center Next. While you can currently interact with the branding section, please note that it will only be used for future releases.',
      body: {
        title: 'Custom branding image',
        preview: 'Preview',
        deleteBranding: 'Delete',
        text: "The logo will be displayed in the header of all generated Customer's Quote PDF documents.",
        uploadButton: 'Upload image...',
        supportedFormats: '<b>Supported formats:</b> jpg, jpeg, png, webp, bmp.',
        maxFileSize: 'Maximum file size: 5 MB',
        defaultBranding: 'Default branding',
        seeGuidelines: 'See the branding guidelines',
        ratioWarning:
          'The uploaded image does not have an optimal aspect ratio. See the branding guidelines for the best image settings to use.',
        extensionError: 'The selected file is not supported. The supported formats are: jpg, jpeg, png, webp, bmp.',
        weightError: 'The uploaded file exceeds the maximum file size. The maximum allowed is 5 MB.',
        footer: {
          website: 'Website Address',
          maxChar: 'Maximum of 100 characters.',
        },
      },
      header: {
        branding: 'Branding',
        generalPreferences: 'General Preferences',
      },
    },
    organizationMarkupCard: {
      header: {
        markup: 'Markup',
      },
      body: {
        disclaimer:
          "When a markup is set for the Organization, it will be applied on all Customer Quotes by default when they are generated. The default markup can be adjusted or removed in a specific project later on. If no markup is set for an Organization, none can be set in the project's Markup tab. Please note that if the custom branding image is deleted, the markup will be deleted as well.",
        defaultMarkupPercentage: 'Default Markup %',
        notice:
          'Editing the default markup of the Organization will have no impact on projects where the default suggested markup was edited by a user. All quotes created with a previous default or edited markup will not be affected by the change.',
      },
      errors: {
        notInRange: 'The markup must be between {min}% and {max}%.',
      },
    },
    generalPreferencesCard: {
      title: 'General Preferences',
      text: 'Preferences listed below apply to both Partitions and Lockers projects.',
      toggleText: 'Printed Installation Instructions',
    },
    organizationCustomTermsAndConditionsCard: {
      header: {
        customTermsAndConditions: 'General Terms & Conditions',
      },
      body: {
        mainText:
          "These terms and conditions will appear in the customer's quote PDF to replace Hadrian's terms and conditions. If you do not fill this section, there will be no terms and conditions in the Customer's Quote PDF.",
      },
    },
    history: {
      organization: {
        organizationLevel: {
          markup: {
            Added: 'Markup added ({newValue}%)',
            Deleted: 'Markup deleted',
            Edited: 'Markup edited ({oldValue}% to {newValue}%)',
          },
          customBranding: {
            Added: 'Custom Branding added',
            Deleted: 'Custom Branding deleted',
            Edited: 'Custom Branding edited',
          },
          termsAndConditions: {
            Added: 'Terms and Conditions added',
            Deleted: 'Terms and Conditions deleted',
            Edited: 'Terms and Conditions edited',
          },
        },
      },
      project: {
        organizationLevel: {
          markup: {
            Added: 'Default Markup added at Organization level ({newValue}%)',
            Deleted: 'Default Markup deleted at Organization level',
            Edited: 'Default Markup edited at Organization level ({oldValue}% to {newValue}%)',
          },
          customBranding: {
            Deleted: 'Custom Branding feature has been deleted at Organization level',
          },
        },
        projectLevel: {
          markup: {
            Added: 'Project Markup added ({newValue}%)',
            Deleted: 'Project Markup reset to default ({oldValue}% to {organizationMarkup}%)',
            Edited: 'Project Markup edited ({oldValue}% to {newValue}%)',
          },
        },
      },
    },
    organizationHistoryCard: {
      showMoreHistory: 'Show more history',
      header: {
        history: 'History',
      },
    },
    disableTermsAndConditionsModal: {
      text: 'If you deleted them, no Terms & Conditions will be included in the Customer’s quote PDF.',
      title: 'Do you really want to delete the General Terms & Conditions ?',
    },
    disableMarkupModal: {
      text: 'If you delete the Markup, project-level Markup will also be disabled once you save the organization information.',
      title: 'Do you really want to delete the Markup ?',
    },
    cancelModal: {
      text: 'This action is not reversible.',
      title: 'Do you really want to quit without saving your progress?',
    },
    deleteModal: {
      text: 'This action is not reversible. Once deleted, the branding will be replaced by the default Hadrian branding. Any Custom General terms & conditions and custom markup will also be deleted.',
      title: 'Do you really want to delete your custom branding ?',
    },
    guidelinesModal: {
      title: 'Guidelines',
      mainText:
        'We strongly recommend to use an image that is wider than it is tall (landscape). The aspect ratio of the uploaded image will be preserved, but it will be resized to fit on the PDF files (the aspect ratio is a proportional relationship between the width and height of an image). Try generating a test sheet to see how your image looks like on a page after uploading your image.',
      firstSubTitle: 'You should:',
      firstSubTitleFirstBulletPoint:
        'Use an image that is wider than taller. Aim for an aspect ratio of <b>4:1</b> for optimal results (ex: 240x80 pixels). The maximum ratio that can be used is <b>5:1</b>.',
      firstSubTitleSecondBulletPoint: 'Use an image with a resolution between 96 and 300 dpi (dot per inch).',
      secondSubTitle: 'Try to avoid:',
      secondSubTitleFirstBulletPoint:
        'Using a vertical oriented image (ex: aspect ratio of <b>1:3</b> - 80x240 pixels). An image that is taller than wider will end up very small.',
      secondSubTitleSecondBulletPoint:
        'Using a screenshot or an image with a resolution of less than 96 dpi (dots per inch). If you do so, the image may look blurred on screen or pixelated when printing a document.',
    },
    toasts: {
      savedSuccess: 'Your organization information has been saved successfully.',
      savedError: 'There was an error saving your organization information.',
      deletedSuccess: 'Your organization information has been deleted successfully.',
      deletedError: 'There was an error deleting your organization information.',
      previewError: 'There was an error generating the preview.',
    },
  },
  orders: {
    quadiraExternalLinkPrompt: 'View Invoice',
    salesOrderStatus: 'Pending',
    next: 'Next',
    previous: 'Previous',
    cancel: 'Cancel',
    order: 'Order',
    confirm: 'Confirm',
    projectChangedTitle: 'Something went wrong',
    projectChangedWarning:
      'The content of the project has changed since this Order was started. Please start again to make a valid selection.',
    orderReview: 'Order Review',
    orderConfirmedTitle: 'Your order has been placed.',
    orderEndButtonLabel: 'Go to Orders Tab',
    orderConfirmedReference: 'Your order reference number is: ',
    orderConfirmedThanks: 'Thank you!',
    ordersPOWarning: 'Orders cannot be shipped to a P.O. Box.',
    customColorSmall: 'Custom color',
    emptyRoom: 'Empty room',
    roomToOrder: 'Rooms to order',
    requiredData: 'Requested Date',
    noteConcerning: 'NOTE CONCERNING THE ORDER',
    shipping: 'SHIPPING',
    specialCharges: 'SPECIAL CHARGES',
    roomIsSoldOut: 'This room contains unavailable / sold out part(s).',
    disableRoomMessage: 'Rooms containing TBA colors cannot be ordered',
    disableEmptyRoomMessage: 'Empty rooms cannot be ordered',
    tbaColorUpcharge: 'Color TBA (upcharge for 800 series)',
    CeilingUnknownDisabled: 'Rooms with unknown ceiling height cannot be ordered',
    CeilingUnknownUpcharge: 'Upcharge may apply to larger ceiling height',
    disableRoomCheckboxMessage: 'Rooms with TBA colors can not be selected.',
    noPartsAdded: 'No parts have been added yet.',
    noRoomsAdded: 'No rooms added',
    noOrders: 'NO ORDERS',
    noUpcomingOrders: 'NO ORDERS CORRESPOND TO YOUR SEARCH',
    noUpcomingQuotes: 'NO QUOTES',
    allDistributors: 'All Distributors',
    recentOrders: 'Recent Orders',
    resend: 'Resend',
    allSalesAgencies: 'All Sales Agencies',
    infoLabelRoomsOptional: 'When parts are added, selecting one or more rooms below is optional.',
    infoLabelNoParts: 'No parts have been added to this project',
    infoLabelNoPartsNoRooms:
      'No <b>parts or rooms</b> have been added to this project yet. You must add parts or rooms to the project if you want to proceed to the next step.',
    infoLabelWithPartsRoomsMandatory:
      'When no parts are added, you must add rooms if you want to proceed to the next step.',
    infoLabelNoPartSelectedNoRoom: 'You must include parts when no rooms in the project',
    mandatoryBubbleText:
      'You must fill all <span class="mandatory">*</span><b>mandatory fields</b> and verify any fields marked in red to proceed to the next step.',
    noRooms: 'NO ROOMS',
    infoLabelRoomsMandatory: 'Select room(s) to {0} for this project',
    tbaPartsWarning: 'TBA COLORS PARTS CANNOT BE ORDERED.',
    tbaPartMessage:
      '<b>TBA color parts cannot be ordered.</b> To include all parts to this order, you must assign a color/finish for all TBA parts displayed in the <a href="{link}">parts list</a>.',
    goToProjectMessage:
      'To create a room or add parts to your project, <a id="order-link-to-project-details" href="/projects/{0}/tab/rooms"> click here</a>.',
    tbaBubbleMessage: 'Rooms with TBA Colors cannot be selected.',
    numRoomsSelected: 'rooms selected',
    showMoreRooms: 'Show more rooms',
    jdeOrderConfirm: 'Your order {0} was received successfully.',
    jdeOrderFailure: 'Order could not be completed for now.',
    goToOrderButton: 'See Order’s tab',
    copiedNumber: 'Freight number copied',
    mixedMaterialWarning:
      'This order is a mixed materials order, which means  it has been divided into multiple Sales Orders. They all share the same Order Reference Number. The price displayed here is the total price of all these Sales Orders combined.',
    orderConfirmModal: {
      title: 'Are you sure you want to leave your order?',
      body: 'Your progress will be lost.',
    },
    orderWarningModal: {
      cannotOrder: 'One room cannot be ordered. | Some rooms cannot be ordered.',
      TBARoomCount: '{count} room is TBA | {count} rooms are TBA',
      emptyRoomCount: '{count} room is empty | {count} rooms are empty',
      unknownCeilingRoomCount: '{count} room has unknown ceiling height | {count} rooms have unknown ceiling height',
      continueText:
        'You may still proceed to the next step, but that room will not be added to the order. | You may still proceed to the next step, but these rooms will not be added to the order.',
      cancelButton: 'Go to settings',
    },
    steps: {
      dryer: {
        first: 'Parts to order',
        second: 'Shipping Info',
        third: 'Lead Time',
        fourth: 'Shipping method',
        fifth: 'Order review',
        sixth: 'Confirmation',
      },
      first: 'Parts & Rooms to Order',
      second: 'Shipping Info',
      third: 'Lead Time',
      fourth: 'Shipping method',
      fifth: 'Order review',
      sixth: 'Confirmation',
    },
    ordersTab: {
      noStyles: 'No styles (parts only)',
      resendConfirmation: {
        title: 'Check JDE before resending the failed order',
        body: 'Please check JDE for the failed sales order before resending. If the order exists in JDE, you must contact the IT department with the order number so that it can be manually updated in the database.',
        cancel: 'Cancel',
        confirm: 'Resend',
      },
      soldOutNotice: {
        title:
          'Some parts included in your original order have become unavailable since the shipping quote was requested or provided',
        text: 'Please start a new order and remove unavailable rooms and/or parts from your selection.<br><br>The shipping quote will still be available if the shipping address does not change and if the content (weight, footage, etc) remains similar.',
        delete: 'Delete',
        startNew: 'Start a new order',
      },
      projectUpdatedNotice: {
        title: 'Your project may have been altered since<br>the shipping quote was provided.',
        text: 'Do you want to proceed with your original order?<br>The rooms or parts in your current project will be reset to match the original order<br>if they were altered.<br><br>If you want to order the updated rooms or parts, please start a new order. The shipping quote will still be available if the shipping address does not change and if the content (weight, footage, etc) remains similar.',
        continue: 'Continue with original order',
        startNew: 'Start a new order',
      },
      orders: 'Order',
      productType: 'Type',
      orderRef: 'Order Ref. #',
      orderPO: 'PO #',
      salesOrder: 'Sales Order #',
      shipDate: 'Ship Date',
      orderStatus: 'Order Status',
      shipTo: 'Ship To',
      carrier: 'Carrier',
      price: 'Price',
      invoice: 'Invoice #',
      expand: 'Expand all',
      collapse: 'Collapse all',
      specialShippingMethodRequestModal: {
        title: 'Your special shipping quote request has been sent.',
        text: 'We will notify you by email when the quote is ready (within&nbsp;1&nbsp;business&nbsp;day).',
        action: 'Close',
        error: {
          default: 'Your special shipping quote could not be completed at this time. Please try again.',
          soldOut:
            'Your special shipping quote could not be completed at this time. Some items in the quote are unavailable/sold out.',
        },
      },
      pendingOrderName: 'Pending Order',
      projectSettings: 'Project Settings',
      styles: 'Styles',
      status: 'Status',
      manage: 'Manage',
      complete: 'Complete',
      delete: 'Delete',
      orderAgain: 'Order again',
      deleteOrderError: 'There was an error deleting the pending order.',
      completeOrderError: 'There was an error gathering information on the pending order.',
      pendingOrderExpiredWarningMessage:
        'Your special shipping quote has expired. Please request a new one for your pending order.',
      pendingOrderInvalidWarningMessage: 'Your special shipping quote has become invalid. Please request a new one.',
      pendingOrders: {
        column: {
          settings: 'Settings',
          manage: '',
          name: 'Pending Order',
          projectSettings: 'Project Settings',
          projectStyles: 'Styles',
          status: 'Status',
          ref: 'Order Ref. #',
        },
      },
      ordersTable: {
        column: {
          projectName: 'Order',
          orderPO: 'PO #',
          shipDate: 'Ship Date',
          orderStatus: 'Order Status',
          carrier: 'Carrier',
          price: 'Price',
        },
      },
    },
    page: {
      searchPlaceholder: 'PO#, SO#, Order Ref#, ... ',
      searchTooltip: 'Searchable columns are: <i>Project name, Order #, PO # and Sales order #</i>',
    },
    review: {
      total: 'Grand Total',
      title: 'Order Review',
      totalPrice: 'Total price:',
    },
    productionNotes: {
      title: 'Important Production Notes',
      noProductionNotes: 'There are no important production notes for this order.',
    },
    extraKeyPrompt: {
      title: 'Extra Keys',
      mainText: 'Do you need extra keys?',
      subText:
        'Your order contains locks provided by Hadrian, but you have not added any extra keys. Are you sure you wish to continue?',
      cancelButtonText: 'Add Keys',
      confirmButtonText: 'Continue',
    },
    status: {
      ordered: 'order received',
      shipped: 'shipped',
      production: 'in production',
      planning: 'in planning',
      canceled: 'canceled',
      completed: 'completed',
      pending: 'pending',
      failed: 'failed to upload',
      ready: 'ready for shipping',
      pendingRequest: 'Pending freight',
      orderReady: 'Ready',
      expired: 'Expired',
      invalid: 'Invalid',
    },
    statusLegendDescription: {
      statusLegend: 'Status legend',
      ordered: 'Order has been placed.',
      planning: 'Order is being reviewed.',
      production: 'Order is being manufactured (revisions cannot be made).',
      ready: 'Ready for collection.',
      shipped: 'Order is shipped (tracking info is available in the orders tab).',
      canceled: 'Order has been canceled and will not be manufactured.',
      completed: 'completed',
      pending: 'pending',
      pendingRequest: 'We will notify you by email when the quote is ready (within&nbsp;1&nbsp;business&nbsp;days).',
      orderReady: 'TODO',
      expired: 'TODO',
      invalid: 'TODO',
    },
  },
  account: {
    title: 'My Account',
    settings: {
      title: 'Settings',
      measurementMetric: 'Metric',
      measurementImperial: 'Imperial',
      measures: 'Measures',
      timezone: 'Working Timezone',
      timezoneSelectTitle: 'Select Time Zone',
      save: 'Save',
      savingError: 'Error while saving',
      savingSuccess: 'Saving succeeded',
    },
    profile: {
      title: 'Profile',
      personalInfo: {
        title: 'Personal information',
      },
      unitPreferences: {
        title: 'Unit preferences',
      },
      generalPreferences: {
        printedInstructionPreferences: 'Printed instructions preferences',
        printedInstallationInstructionsTooltip:
          '<p>When selected, all orders will be shipped with printed paper installation instructions.</p><p>If not selected, you will be provided with a QR code that will take you to an online version of the installation instructions.</p>',
      },
      partitionPreferences: {
        adaBf: 'ADA/BF',
        adaBfExtraDeepUnits: 'ADA/BF Extra Deep Units',
        adaBfUnitDoorPosition: 'ADA/BF Unit Door Position',
        adaBfUnitDoorPositionOptionA: 'Option A (wall side)',
        adaBfUnitDoorPositionOptionB: 'Option B (panel side)',
        adaBfUnits: 'ADA/BF Units',
        applyOtherDepth: 'Apply other depth',
        continuousChannelsBannerText: 'Continuous channels may be used to obtain depth (with an added cost).',
        crossBracingLabel: 'Automatic Cross-Bracing for Alcoves and Extra Wide Units',
        crossBracingTooltip:
          'When selected, all alcove and extra wide units will automatically receive headrail cross bracing.',
        defaultAffForAdaAndAmbUnitsOption:
          '{formattedMeasure} AFF for ADA/BF and Ambulatory units pre-selected by default',
        defaultAffForAdaAndAmbUnitsOptionDisplay:
          '{formattedMeasure} AFF for ADA/BF and Ambulatory pre-selected by default',
        defaultAffForAdaAndAmbUnitsTitle: 'ADA/BF & AMB Unit default',
        defaultAffForAdaAndAmbUnitsTooltip:
          'This preference is applicable to Elite, Elite Plus, and Elite Max series only.  If you activate this option, the Mounting height above finish floor for ADA/AMB units will be pre-selected by default when you create a new project.',
        defaultMeasurementSystem: 'Default Measurement System',
        depthConflictText: "{depthTypeLabel} unit's preference has been reset to avoid incompatible depths.",
        doorAndPilasterDefault: 'Doors & pilasters default',
        highDensityLabel: 'Universal High Density Panels (Grab Bar Reinforced)',
        highDensityToggleLabel: 'High-Density Universal Grab Bar Panels',
        highDensityToggleTooltip:
          'When selected, all ADA and Ambulatory panels will automatically include high-density universal grab bar panels.',
        moreOptions: 'More options',
        overallUnitDepthDefault: 'Overall Unit Depth Default',
        overallUnitDepthDefaultFromWall: 'Overall Unit Depth Default (From Wall to Exterior Front)',
        overallUnitDepthDefaultText:
          'If your preferred depth cannot be applied in a Plastic room, Next will use the closest standard depth instead.',
        specifyDepthNotice: 'Specify in the blue box a depth between {min} and {max}.',
        specifyExtraDeepDepthNotice: 'Specify a depth between {min} and {max}.',
        standardUnits: 'Standard Units',
        std: 'Standard',
        stdDoorWidthOptionLabel: '{doorWidth} door / {panelPilasterWidth} pilaster',
        stdUnits: 'STD Units',
        title: 'Partition Preferences',
      },
      myInformations: 'My Information',
      settings: 'Settings',
      role: 'Role',
      roleAndPermissions: 'Role & Permissions',
      email: 'Email',
      phone: 'Phone Number',
      resetPassword: {
        dropdownLabel: 'Reset password',
        success: "The user's password has been successfully reset.",
        error: "An error occurred while trying to reset the user's password",
        modalTitle: 'Reset password',
        mainModalText: 'Are you sure you want to reset the password?',
        modalSubtext: 'Reset password instructions will be sent to the user’s email address.',
        modalConfirm: 'RESET PASSWORD',
      },
      seeMyOrganizations: 'See my organizations',
      edit: 'Edit',
      cancel: 'Cancel',
      save: 'Save',
      timezone: 'Timezone',
      nameLabel: 'First and Last Name',
      emailAddressLabel: 'Email address',
      jobTitleLabel: 'Job Title',
      addYourPhone: '+ Add your number',
      addJobTitle: '+ Add job title',
      updateInfos: {
        success: 'Your information has been successfully updated',
        error: 'There was an error updating your information',
      },
      updateSettings: {
        success: 'Your settings have been successfully updated',
        error: 'There was an error updating your settings',
      },
      createdBy: 'Created By:',
      lastEdit: 'Last Edit:',
      on: 'on',
      permissions: 'Permissions',
      jobTitle: 'Job title',
      lastActive: 'Last active',
      organizations: 'Organizations',
      userDetails: 'User Details',
      organizationName: 'Organization Name',
      type: 'Type',
      salesAgency: 'Sales Agency',
      distributor: 'Distributor',
      JDEId: 'JDE ID Number',
      add: 'Add',
      assign: 'Assign',
      unknown: 'Unknown',
      revokeAccess: 'Revoke Access',
      allOrganizationsAccess: 'This user has access to all project center organizations.',
      noOrganizations: "The user doesn't belong to any organization yet.",
      notApplicable: 'N/A',
      sendInvitation: 'Send Invitation',
      sendInvitationAgain: 'Send Again',
      organizationsCard: {
        editTitle: 'Assign to an organization',
        changingRoleNotice: `Changing the role of a user will remove them from their current organizations.`,
        organizations: 'Organizations',
        salesAgencyNote:
          '<i>Note that all distributors under a sales agency will be automatically added to the organizations list.</i>',
        selectedOrganizations: 'Selected Organizations',
      },
      lastOrganizationRevokalModal: {
        modalTitle: 'Last organization removal',
        mainText: "You are about to remove this user's last organization.",
        hadrianUserText:
          'If you do so, they will not be able to access most of the sections of the site until you reassign them an organization. Are you sure you wish to continue?',
        nonHadrianUserText:
          "If you do so, they will not be able to access most of the sections of the site and you will not be able to view or edit this user's profile. Do you wish to continue?",
        rightButtonText: 'proceed',
      },
      invitation: {
        disabled: {
          title: 'Project center invitation link',
          description: 'To send an invitation link to this user, you’ll first need to assign him to an organization.',
        },
        enabled: {
          title: 'No invitation link have been sent to this user yet.',
          description:
            'You can send an invitation link at any time.  The invitation will be send to the email address showed in the user details box below.',
        },
        sent: {
          title: 'An invitation has been sent on {0}',
          description:
            'Ask user to look in their spam email box if they can’t find the invitation. You can also resend the invitation if needed.',
        },
        success: 'Invitation successfully sent',
        error: 'There was an error sending the invitation',
      },
    },
  },
  autoComplete: {
    organizationPlaceholder: 'Organization name',
  },
  notesModal: {
    title: 'Notes',
  },
  table: {
    expandAll: 'Expand all',
    collapseAll: 'Collapse all',
    columns: {
      projectName: 'Project name',
      poNumber: 'PO#',
      distributorName: 'Distributor Name',
      clientName: 'Client name',
      lastUpdated: 'Last updated',
      price: 'Price CDN$',
    },
    actions: {
      edit: 'Edit/View',
      watch: 'Watch project',
      duplicate: 'Duplicate',
      duplicateAssign: 'Duplicate & Assign',
      delete: 'Delete',
      refreshBom: 'Refresh Bom',
    },
  },
  projectDetails: {
    quote: 'Quote',
    order: 'ORDER',
    ordered: 'ROOM ORDERED',
    orderedOn: 'Ordered on',
    poNumber: 'PO#',
    orderConfirmModal:
      "Do you confirm that the <b>Measurements</b> of the rooms about to be ordered are correct? </br>If you click Yes, you won't be able to change them after completing the order.",

    drawingPreferences: {
      title: 'Drawing Preferences',
      printedInstructions: 'Printed Instructions',
      showBoltDimensions: 'Show Bolt Dimensions',
      displayBowlCenterWithinUnits: 'Display Bowl Center Within Units',
      displayBowlCentersBetweenBowls: 'Display Bowl Centers Between Bowls',
    },
    emptyProjectModal: {
      quote: 'Quote',
      order: 'Order',
      partsRooms: {
        subtitle: 'No parts or rooms have been added to this project.',
        quote: {
          text: 'To create a quote, you must add parts or rooms to the project.',
        },
        order: {
          text: 'To create an order, you must add parts or rooms to the project.',
        },
        leftActionButton: 'Create a room',
      },
      styleParts: {
        subtitle: 'No parts have been added to this project.',
        quote: {
          text: 'To create a quote, you must create a style or add parts to the project.',
        },
        order: {
          text: 'To create an order, you must create a style or add parts to the project.',
        },
        leftActionButton: 'Create a style',
      },
      parts: {
        subtitle: 'No parts have been added to this project.',
        quote: {
          text: 'To create a quote, you must add parts to the project.',
        },
        order: {
          text: 'To create an order, you must add parts to the project.',
        },
      },
      leftActionButton: 'Create a room',
      rightActionButton: 'Add parts',
      leftButtonText: 'Close',
    },
    submittalPackage: 'Submittal Package',
    submittalPackageTooltip: 'To generate a submittal package,<br/>first add parts to your project.',
  },
  toast: {
    error: {
      title: 'An error occured',
    },
    success: {
      title: 'YAY',
    },
  },
  buttons: {
    back: 'Back',
    edit: 'Edit',
    cancel: 'Cancel',
    previous: 'Previous',
    save: 'Save',
  },
  newAddress: {
    title: 'Shipping info',
    selectOption: 'Select an option',
    firstName: {
      label: 'First name',
      placeholder: 'First name',
    },
    contactName: {
      label: 'Contact Name',
    },
    lastName: {
      label: 'Last name',
      placeholder: 'Last name',
    },
    companyName: {
      label: 'Company name',
      placeholder: 'Company name',
    },
    address: {
      label: 'Address line 1',
      placeholder: 'Enter full address',
    },
    addressTwo: {
      label: 'Address line 2',
    },
    phoneNumber: {
      label: 'Phone Number',
    },
    email: {
      label: 'Email',
    },
    unit: {
      label: 'Unit number (optional)',
      placeholder: 'Unit number',
    },
    city: {
      label: 'City',
      placeholder: 'City/town/village',
    },
    country: {
      label: 'Country',
      placeholder: 'Country',
    },
    region: {
      labelUsa: 'State',
      labelCan: 'Province',
      placeholderUsa: 'State',
      placeholderCan: 'Province',
    },
    postalCode: {
      labelUsa: 'ZIP Code',
      labelCan: 'Postal Code',
      placeholderUsa: 'ZIP Code',
      placeholderCan: 'Postal Code',
    },
  },
  shippingMethodForm: {
    specialShippingQuote: {
      placeholder: 'Select a previous shipping quote (optional)',
      placeholderNoMatch: 'No match found',
      option: 'None',
      label: 'Shipping Quotes History',
      notice: 'Options may not be selectable.',
      largeShipmentRequestLabel: '{price} - {refNumber} - Exp: {expiryDate}{invalidMessage}',
      createdByLabel: ' {expiryDate} (Created by {createdBy} on {createdAt})',
      invalidMessage: {
        expired: ' (Expired)',
        weight: ' (Weight mismatch)',
        origin: ' (Origin mismatch)',
        destination: ' (Destination mismatch)',
        footage: ' (Footage mismatch)',
        assembly: ' (Assembly mismatch)',
      },
    },
    amount: {
      label: 'Freight Amount',
      placeholder: 'Amount',
      rules: 'Amount entered must be below $1,000,000',
    },
    carrier: {
      label: 'Carrier',
      placeholder: 'Carrier name',
    },
    estimatedTransitTime: {
      label: 'Estimated transit time',
      placeholder: 'Transit time',
      checkboxLabel: 'Unknown',
    },
    phoneNumber: {
      label: 'Phone Number',
      placeholder: 'Enter number',
    },
    extension: {
      label: 'EXT.',
      placeholder: '123',
    },
    accountNumber: {
      label: 'Account #',
      placeholder: 'Account number',
    },
    caseManagementNumber: {
      label: 'Case Management #',
      placeholder: '#000000',
    },
  },
  customColorNotice: {
    quote: `Quoting with a smooth custom color. For pricing, use the custom charges guide or contact Hadrian. Custom colors require a sample to be provided to Hadrian for color matching.
    Custom color match must be approved prior to material being ordered. For textured or metallic custom colors please contact Hadrian for pricing and availability.`,
    order: `Ordering with a smooth custom color. For pricing, use the custom charges guide or contact Hadrian. Custom colors require a sample to be provided to Hadrian for color matching.
    Custom color match must be approved prior to material being ordered. For textured or metallic custom colors please contact Hadrian for pricing and availability.`,
  },
  newQuoteModal: {
    soldOutNotice: {
      banner: {
        quote:
          '<b>Your quote includes unavailable / sold out part(s).</b><br />Please remove the part(s) from your selection if you want to proceed to the next step.',
        order:
          '<b>Your order includes unavailable / sold out part(s).</b><br />Please remove the part(s) from your selection if you want to proceed to the next step.',
      },
      partNotice: {
        quote:
          '<b>Your parts list includes unavailable / sold out parts.</b> You must remove these parts if you want to add them to your quote.',
        order:
          '<b>Your parts list includes unavailable / sold out parts.</b> You must remove these parts if you want to add them to your order.',
        link: 'Go to parts list',
      },
    },
    cancelModalTitle: 'Are you sure you want to leave your quote?',
    cancelModalBody: 'Your progress will be lost.',
    creationError: 'There was an error processing the request.',
    patchError: 'There was an error updating the quote.',
    patchErrorSoldOut: 'Your parts list includes unavailable / sold out parts.',
    patchErrorLargeShipment: 'Your request could not be completed at this time. Please try again.',
    patchErrorOrder: 'There was an error updating the order.',
    projectChangedTitle: 'Something went wrong',
    projectChangedWarning:
      'The content of the project has changed since this Quote was started. Please start again to make a valid selection.',
    mixedMaterialTitle: 'This project contains mixed materials.',
    mixedMaterialText:
      'Depending on the destination, the order may ship out of more than one location. Please confirm shipping method and price before ordering.',
    dryerWeightNoticeTitle: 'Parts/Accessories selection exceeds 40lbs',
    dryerWeightNoticeText:
      'Your quote or your order cannot include shipping handled by World Dryer. Please call World Dryer Customer Service for freight quote assistance or select another shipping method.',
    next: 'Next',
    done: 'Get a quote',
    cancel: 'Cancel',
    previous: 'Previous',
    title: 'Quote',
    steps: {
      dryer: {
        first: 'Parts to quote',
        second: 'Shipping info',
        third: 'Lead Time',
        fourth: 'Shipping method',
      },
      first: 'Parts & rooms to quote',
      second: 'Shipping info',
      third: 'Lead Time',
      fourth: 'Shipping method',
    },
    firstScreen: {
      numberPlates: 'Number Plates',
      lockerDoorQuantity: {
        label: 'Locker Door Quantity',
        value: 'No doors | {count} door | {count} doors',
      },
      numberSequences: {
        title: 'Number Sequences',
        placeholder: 'i.e. 1-30, 31-60, 100-999',
        tooltip: {
          text: 'Standard Number plates have a maximum of 5 characters. The sequence can be a mix of numbers, letters or both.',
          secondaryText: 'Enter desired number sequences following a semicolon.',
          exampleLabel: 'Example:',
          exampleValue: '1-30; 30-60; 61-90; 90-100',
        },
      },
      informations: {
        header: 'Information',
        attention: 'Attention',
        title: 'Title of the quote',
        notes: {
          label: 'Additional notes',
          placeholder: {
            customersNotes: "This note will only appears on Customer's quote PDF.",
            additionalNotes: 'This note will only appears on Hadrian Quote PDF.',
          },
          subLabel:
            'You can add two different notes for the Quote by Room/Quote by Rooms Settings PDF (Hadrian) and the Customer’s Quote PDF (custom branding). Select the type of note you wish to add and add your additional notes to the textbox below.',
        },
        maxChar: 'Maximum of 90 characters.',
        additionalNotes: 'Hadrian Note',
        customersNotes: 'Customer’s quote note',
      },
      discounts: {
        header: {
          discounts: 'Discounts',
          discountsAndMarkup: 'Discounts & Markup',
        },
        active: 'Active',
        default: 'Default',
      },
      markup: {
        title: 'Project Markup',
        mainText:
          'Once generated, the total price with markup will be displayed on the Customer’s quote PDF. The markup will be applied on material price and special charges, but not on freight.',
      },
      parts: {
        dryer: {
          header: 'Parts to {isOrderOrQuote}',
          noticeQuote: 'All active parts will be included in the quote.',
          noticeOrder:
            'Some items could be out of stock by the time your order is received. Please check your Sales Acknowledgement to confirm the final lead time.',
          quantity: 'Total Item Quantity',
          weight: 'Total Item Weight',
          price: 'Total Item Price',
          subtotal: 'Subtotal',
          column: {
            partNumber: 'Part Number',
            label: 'Description',
            weight: 'Unit Weight',
            price: 'Net Price',
            quantity: 'Added Quantity',
            delete: '',
          },
        },
        header: 'Parts & Rooms to {isOrderOrQuote}',
        orderHeader: 'Parts',
        allPartsMessage: 'Would you like to include all parts to your Quote?',
        allPartsMessageOrder: 'Would you like to add all parts to your order?',
        addRoomsWarningErrorMessage: 'You must add rooms if you do not include all parts.',
        addRoomsWarningNoticeMessage: 'Adding rooms is optional when all parts are included.',
        yes: 'Yes',
        no: 'No',
        partsHeader: 'Parts',
      },
      specialCharge: {
        header: 'Special Charges',
        amount: 'Amount',
        description: 'Description',
        defaultDescription: 'Special Charge',
        maxChar: 'Maximum of 60 characters',
        specialChargesHistory: 'Special Charges History',
        selectPrevious: 'Select a previous special charge (optional)',
        moreInfo: 'For more info on pricing, ',
        clickHere: 'click here.',
      },
      rooms: {
        rooms: 'Rooms',
        quote: 'quote',
        header: 'Rooms to {0}',
        title: 'Which rooms do you want to be {0} in this project?',
        titleOrder: 'Which rooms would you like to order in this project',
        titleNoRooms: 'There are no room for this project',
        roomName: 'Room name',
        roomSettings: 'Room settings',
        leadTime: 'Lead time',
        floor: 'Floor',
        units: 'Units',
        materialPrice: 'Material price',
        viewing: 'Viewing {0} of {1}',
        styles: 'Styles',
        lockerPackagingOption: {
          knockedDown: 'Knocked Down',
          assembled: 'Assembled',
          doorsOnly: 'Doors Only',
        },
      },
      locksAndKeys: {
        title: 'Locks and Keys',
        endUser: {
          title: 'End User Information',
          subtitle: 'Ordering locks from Hadrian requires you to provide end user information for MasterLock.',
          companyName: 'Company Name',
          contactName: 'Contact Name',
          address: 'Company Full Address',
          phoneNumber: 'Phone Number',
          keyNumber: 'Matching Key Number',
          keyNumberPlaceholder: 'Enter key number',
          existingKeyingSystem: 'This is an existing keying system',
        },
        extraKeys: {
          title: 'Extra Keys',
          subtitle: 'Enter the extra key quantity required for each type lock model below.',
          lock: {
            quantity: 'Lock qty: ',
            userKeys: 'User keys: ',
            userKeyQuantityIncluded: '{count} included',
            masterKeys: 'Master keys: ',
            extraUserKeys: 'Extra User Keys (Per Lock)',
            extraMasterKeys: 'Master Key Qty',
          },
        },
      },
    },
    secondScreen: {
      title: {
        addressBook: 'Address Book',
        landing: 'Shipping Address',
        form: 'New Shipping Address',
        formEdit: 'Edit Shipping Address',
        zip: 'Quote with just a ZIP Code',
      },
      subtitle: {
        addressBook: 'Select where you want the order to be shipped.',
        landing: 'Your order will be sent to the address displayed below.',
        form: 'Fill out the form below.',
        zip: 'Please note that using only a ZIP Code may result in additional delivery charges at time of ordering. It is recommended that a full address is utilized to provide the most accurate pricing.',
      },
      landing: {
        addressBook: 'Address Book',
        quoteWithZipCode: 'Quote with a ZIP Code only',
        unavailableMap: {
          title: 'Map unavailable',
          subtitle: 'Shipping address cannot be displayed.',
        },
      },
      addressBook: {
        createNewAddress: 'Create new address',
        searchPlaceholder: 'Search...',
        cancelButton: 'Cancel',
        selectButton: 'Select',
        noResults: 'No results',
      },
      addressForm: {
        cancelButton: 'Cancel',
        addButton: 'Add',
      },
      zip: {
        backButton: 'Back',
        nextButton: 'Next',
        isInvalid: 'The ZIP Code you have entered is not valid.',
        poBox:
          'The ZIP Code you have entered corresponds to a P.O. Box ({location}). It cannot be used to create a quote.',
        provinceInvalid:
          'The ZIP code you have entered is associated to a State that is not available for shipping. Please enter a valid US port city ZIP code to continue.',
      },
      handling: {
        title: 'Handling Method',
        shipping: 'Shipping',
        pickup: 'Pick up',
      },
      defaultAddress: {
        shipping: 'Shipping Address',
        pickup: 'Pick up information',
        phone: 'Phone: {number}',
        email: 'Email: {email}',
      },
      differentAddress: {
        label: 'I want to ship to a different address',
        existing: 'Select an existing address',
        chooseOrAdd: 'Choose an existing address, or {0}',
        addAddress: 'add a new address',
        new: 'Please provide the shipping information below.',
        viewing: 'Viewing {0} of {1}',
        zipCodeQuote: 'I want to quote with just a ZIP Code',
        zipCode: 'ZIP Code',
        enterZipCode: 'Enter ZIP Code',
        notValid: 'The ZIP Code is not valid.',
        validate: 'VALIDATE',
        unavailable: 'There has been a problem with your request, please try again later',
      },
      addressSearchPlaceholder: 'Name, phone, etc...',
      tag: 'Tag',
      appears: 'Appears on packaging',
      poLabel: 'PO#',
      invalidAddress: 'The selected address is invalid',
    },
    thirdScreen: {
      title: 'Lead Time',
      pickup: '{prefix}Pick up {suffix}',
      shipping: '{prefix}Shipping {suffix}',
      timezoneNotice: 'Shown dates are only valid if you order before {time}.',
      timezone: 'Timezone: {timezone}.',
      importantNotice: 'Important Notice',
      nextDayFee:
        "• Hadrian's <b>Premium Quick Ship - Next Business Day</b> shipping option is subject to a $450 list up-charge.",
      quickShipWarning:
        '•  All <b>Quick Ship orders</b> are immediately sent to production and are not reviewed for accuracy or modified by Hadrian. If you need to review this order with your Customer Service Representative, please do so <b>BEFORE</b> submitting it. Once submitted, Quick Ship orders cannot be modified.',
    },
    fourthScreen: {
      freightInformation: {
        title: 'Freight Calculator',
        subtitle: 'FREIGHT CLASS',
        freightClass: 'FREIGHT CLASS',
        skids: 'SKIDS',
        weight: 'WEIGHT (LBS)',
        shipFrom: 'SHIP FROM',
        unknown: 'Unknown',
        column: {
          materialType: 'Material',
          nmfcClass: 'Freight Class',
          palletQuantity: 'Skids',
          weight: 'Material Weight (lbs)',
          originAddress: 'Ship from',
        },
      },
      specialRequirements: {
        title: 'SPECIAL REQUIREMENTS',
        disclaimer:
          'To obtain an accurate freight estimate, select all applicable special requirements below.  Special requirements will be billed as necessary at the time of shipping.',
        liftGateTruckIsSelected: 'Liftgate truck required (no dock access)',
        liftGateTruckIsSelectedToolTip:
          'Select if there is no dock or forklift at the destination. The carrier will use a platform at the rear of the truck for unloading.',
        reDeliveryCoverageIsSelected: 'Re-delivery coverage',
        limitedAccessIsSelected: 'Limited Access',
        limitedAccessIsSelectedTooltip:
          'Select if access to the delivery location is controlled, for example by a gate or a guard.',
        residentialAreaDeliveryIsSelected: 'Residential area delivery',
        residentialAreaDeliveryIsSelectedTooltip:
          'Select if destination is in a residential area. Residential streets are often not equipped to accommodate a semi-truck and can be difficult to maneuver requiring smaller vehicles.',
        scheduledAppointmentsIsSelected: 'Scheduled appointment required prior to delivery',
        scheduledAppointmentsIsSelectedTooltip:
          'Select if the carrier needs to contact the consignee to arrange a time frame for delivery.',
      },
      shippingMethod: {
        title: 'Shipping Method',
        businessDays: '- 1 business day | - {days} business days',
        form: {
          warning: {
            lockersInCanada: '',
            notInContiguousStates:
              'The address you have selected is associated to a State that requires a special shipping quote.',
            weightOrPrice:
              'LTL Freight is limited to a maximum of 5,500 Lbs or $15,000. You must request a special shipping quote.',
            elm: 'LTL Freight is not available because the shipment contains more than 2 skids (with Elite Max parts). You must request a special shipping quote.',
            assembledLocker:
              'LTL Freight is not available because the shipment contains more than 4 skids (with at least one assembled locker). You must request a special shipping quote.',
            knockedDownLocker:
              'LTL Freight is not available because the shipment contains more than 4 skids (knocked down locker parts). You must request a special shipping quote.',
          },
          note: 'Note',
          emailsLabel: 'CC on all notifications',
          emailsHelp: 'Secondary recipients will be notified by email when the special shipping quote is ready.',
          emailsCountLabel: 'Add a comma after each recipient.',
          emailsError:
            'The hightlighted email address cannot be validated. | The hightlighted email addresses cannot be validated',
          submit: 'Send Request',
          stackLockersOption: 'Can Lockers/Boot Racks be stacked for shipment?',
          stackLockersOptionTooltip:
            "Hadrian Locker/Boot Rack crates can be stacked 2 high for shipment. If site conditions do not allow for unloading of stacked crates, please select 'No'.",
        },
        partialOrder: {
          shippingQuoteHistory: 'Shipping Quote History',
        },
      },
      prePay: {
        freightProviders: 'Freight Providers',
        selectCarrier: 'Select a Freight Provider',
        footer:
          'To combine 2 shipments that come from the same plant, please contact your customer experience representative.',
        total: 'Total',
        errorMessages: {
          notAvailable: 'This shipping option is not available.',
          generic: 'Contact Customer Experience for an accurate freight quote.',
          tooHeavy: 'LTL Freight is limited to a maximum of 5,500 lbs.',
          twoSkids:
            'LTL Freight is not available because the shipment contains more than 2 skids (with Elite Max parts).',
          fourSkids:
            'LTL Freight is not available because the shipment contains more than 4 skids (with at least one assembled locker).',
          eightSkids:
            'LTL Freight is not available because the shipment contains more than 8 skids (knocked down locker parts).',
        },
      },
      fedex: {
        amountPlaceholder: 'Amount',
        amount: 'Freight Amount (optional)',
        important: 'Important',
        listDescription: 'The following restrictions apply to this shipping service:',
        listFirst: 'Packages cannot exceed 107’’ in length',
        listFirstPointFive: 'Packages greater than 96’’ in length may have additional charges',
        listSecond: 'Packages cannot exceed 36’’ in width',
        listThird: 'No piece can exceed 50 lbs',
        listFourth: 'Total shipment cannot exceed 150 lbs',
        disclaimer:
          'Please contact our Customer Service Representative for assistance or to obtain a quotation for this service. If the shipment does not meet the requirements outlined above, an alternative shipping method will be used and charged accordingly.',
        exceedFedEx: {
          title: 'Your selection is not eligible for FedEx Small Package.',
          mainText:
            'Your quote or your order exceeds the weight restrictions for FedEx Small Package shipping. A single piece cannot exceed 50 lbs and the total shipment cannot exceed 150 lbs.',
        },
      },
      specialShippingMethod: 'Special shipping method',
      dryer: {
        over200LbsBanner: {
          title: 'Your selection exceeds 200 lbs.',
          mainText:
            'Your quote or your order cannot include shipping handled by World Dryer. Please call World Dryer Customer Service for freight quote assistance or select another shipping method.',
        },
        notAvailable: 'World Dryer To Arrange is not available for this order.',
        shipNote: 'Materials are shipped from Phoenix, AZ.',
        shipCostNote: 'Estimated freight shipping cost.',
        freeShippingNote: 'Order $3,500 or more to be eligible for free shipping.',
        freeShippingNoteEnabled: 'This order is eligible for free shipping.',
        freeShippingDescription: 'Free Shipping on orders over $3,500',
      },
    },
  },
  addressCard: {
    submitButton: 'Add',
    saveButton: 'Save',
    validateButton: 'Validate',
    phone: 'Phone: {number}',
    email: 'Email: {mail}',
    cancel: 'Cancel',
    deleteModal: {
      subtitle: 'Do you really want to delete this address?',
      text: 'This action is not reversible.',
      confirm: 'Yes, delete',
      deleteSuccessful: 'The address has been deleted successfully',
      deleteError: 'There was an error deleting the address',
    },
    validation: {
      feedback: {
        incomplete: {
          us: {
            contactName: 'contact name',
            contactPhone: 'contact phone',
            companyName: 'company name',
            addressLine1: 'address',
            locality: 'city',
            province: 'state',
            zipCode: 'zip code',
          },
          ca: {
            contactName: 'contact name',
            contactPhone: 'contact phone',
            companyName: 'company name',
            addressLine1: 'address',
            locality: 'city',
            province: 'province',
            country: 'country',
            zipCode: 'postal code',
          },
        },
        missing: {
          us: {
            contactName: '[Contact Name]',
            contactPhone: '[Contact Phone]',
            companyName: '[Company Name]',
            addressLine1: '[Address Line 1]',
            locality: '[City]',
            province: '[State]',
            zipCode: '[Zip Code]',
          },
          ca: {
            contactName: '[Contact Name]',
            contactPhone: '[Contact Phone]',
            companyName: '[Company Name]',
            addressLine1: '[Address Line 1]',
            locality: '[City]',
            province: '[Province]',
            country: '[Country]',
            zipCode: '[Postal Code]',
          },
        },
        mismatches: {
          us: {
            locality: 'the city',
            zipCode: 'the zip code',
            province: 'the state',
          },
          ca: {
            locality: 'the city',
            zipCode: 'the postal code',
            province: 'the province',
          },
        },
        long: {
          incomplete:
            'The {missingFields} is missing. To select this address, you must add the missing information first. | The {missingFields} are missing. To select this address, you must add the missing information first.',
          notValidated: 'This address does not need validation.',
          valid: 'This address has been validated',
          validWithRecommendations:
            'This address was successfully validated, but {mismatches} does not match with the official database. | This address was successfully validated, but {mismatches} do not match with the official database.',
          notFound:
            'This address cannot be validated with the official database. Please review and confirm the information provided before proceeding to the next step.',
          poBox:
            'P.O. Boxes cannot be used as a shipping address. Please select another address in the Address Book or create a new one.',
          serviceError: 'The address cannot be validated at the moment.',
          missingSecondaryInformation:
            'This address cannot be validated with the official database. Additional secondary information may be missing.',
        },
        short: {
          incomplete:
            'The {missingFields} is missing. To select this address, you must add the missing information first. | The {missingFields} are missing. To select this address, you must add the missing information first.',
          notValidated: 'This address does not need validation.',
          valid: 'This address has been validated',
          validWithRecommendations:
            'This address was successfully validated, but {mismatches} does not match with the official database. | This address was successfully validated, but {mismatches} do not match with the official database.',
          notFound: 'This address cannot be validated with the official database.',
          poBox: 'P.O. Boxes cannot be used as a shipping address.',
          serviceError: 'The address cannot be validated at the moment.',
          missingSecondaryInformation:
            'This address cannot be validated with the official database. Additional secondary information may be missing.',
        },
      },
    },
  },
  addressForm: {
    fullName: 'Contact name',
    firstName: 'First name',
    lastName: 'Last name',
    phoneNumber: 'Phone number',
    addressLineOne: 'Address line 1',
    addressLineTwo: 'Address line 2',
    addressLineTwoPlaceholder: 'Unit #, suite, floor, building, gate or dock',
    city: 'City',
    country: 'Country',
    province: 'Province',
    state: 'State',
    postalCode: 'Postal Code',
    zipCode: 'ZIP Code',
    maximumCharacterToFormat: 'Maximum of {0} characters.',
    email: 'Email address',
    companyName: 'Company name',
    optional: '(optional)',
    validation: {
      feedback: {
        notFound: 'This address cannot be validated with the official database.',
        serviceError: 'The address cannot be validated at the moment.',
        valid: 'This address has been validated.',
        validWithRecommendations:
          'A valid address has been found in the database. Please verify the corrections made to the field(s) highlighted above.',
        poBox: 'P.O. Boxes cannot be used as shipping addresses.',
        missingSecondaryInformation:
          'This address cannot be validated with the official database. Additional information may be required in address line 2.',
        incomplete: 'Please fill all required fields.',
      },
    },
  },
  orderExpedite: {
    orderExpediteRequest: 'Order Expedite Request',
    ordersList: {
      oerCurrentlyOpen: 'An Order Expedite Request is currently open for this order.',
      oerBeingFinalized: 'An Order Expedite Request is still in the process of being finalized.',
      oerHasBeenUpdated: 'The ship date has been updated.',
    },
    submitModal: {
      header: 'Order Expedite Request',
      jdeSalesOrderNumber: 'Sales Order #',
      organization: 'Organization',
      orderInfo: 'Order Info',
      originalShipDate: 'Original Ship Date',
      product: 'Product',
      branchPlant: 'Branch Plant',
      unitCount: 'Unit Count',
      materials: 'Materials',
      finishes: 'Finishes',
      importantProductionNote: 'Important Production Note',
      note: 'Note',
      requestedDate: 'Requested Date',
      dateRange: 'Date range',
      successCreated: 'Your Order Expedite Request has been sent successfully.',
      successCreatedLink: 'View Request',
      multiplePartsLabel: 'This is a Multiple Parts Order',
      multiplePartsValue: 'The order ref. # {orderRef} is divided into {count} sales orders: {jdeSalesOrderNumbers}.',
      projectName: 'Project Name',
    },
    manageModal: {
      requestDeclined: 'Request Declined',
      declined: 'declined',
      expediteRequest: 'Expedite Request Date',
      planningResponse: 'Planning Response',
      planningProposition: 'Planning Proposition',
      response: 'Response',
      reason: 'Reason',
      reasonPlaceholder: 'Explain why you declined the request',
      note: 'Note',
      notePlaceholderPlanner: 'Explain why you declined the request',
      newShipDate: 'New Ship Date',
      expiryDate: 'Expiry Date',
      orderNumber: 'Sales order #',
      requestedBy: 'Requested by',
      originalShipDate: 'Original Ship Date',
      dateRequested: 'Date Requested',
      respondedBy: 'Responded by',
      expediteProposal: 'Expedite Proposal',
      proposalExpiration: 'Proposal Expiration',
      planningDecision: 'Planning Decision',
      banner: {
        planner: {
          accept: 'The user will be notified that the Order Expedite Request has been accepted as is.',
          decline: 'The user will be notified that the Order Expedite Request has been declined.',
          edit: 'The user will be notified that the Order Expedite Request has been accepted on a different date.',
        },
      },
    },
    jdeExternalLinkPrompt: 'View order in JDE',
    errors: {
      fetchingExpediteRequestOrder: 'There was an error getting your order information.',
      fetchingExpediteRequest: 'There was an error getting your Order Expedite Request.',
      creatingExpediteRequest: 'There was an error creating your Order Expedite Request.',
      creatingExpediteRequestConflict: 'An Order Expedite Request has already been created for this order.',
      canceled: 'The Order Expedite Request has been canceled by user.',
    },
  },
  invoicePdf: {
    loading: 'Loading PDF...',
    noResponse:
      'The invoice requested is currently unavailable. For assistance, please contact customer service. Additionally, please note that invoices can only be accessed if they were created after December 1st, 2023.',
    errorResponse:
      'The invoice requested is currently unavailable. For assistance, please contact customer service. Additionally, please note that invoices can only be accessed if they were created after December 1st, 2023.',
  },
  quotesList: {
    generatingPdf: 'PDF generation in progress',
    errorFetching: 'Error while fetching data',
    deletePendingQuoteError: 'There was an error deleting the pending quote',
    pendingQuoteInvalidWarningMessage: 'Your special shipping quote has become invalid. Please request a new one.',
    pendingQuoteDeleted: 'This quote cannot be accessed because it was deleted',
    actions: {
      customerQuotePdf: "Customer's Quote - PDF",
      orderPdf: 'Quote By Room - PDF',
      quotePdfBySettings: 'Quote By Room Settings - PDF',
      quotePdfNoRooms: 'PDF of the quote',
      pdfOfOrder: 'Order By Room - PDF',
      pdfOfOrderBySettings: 'Order By Room Settings - PDF',
      pdfOfOrderNoRooms: 'PDF of the order',
      bomWithPrices: 'Group Per Room (with prices) - CSV',
      bomWithoutPrices: 'Group Per Room - CSV',
      submittalPackagePdf: 'Submittal Package',
      packagingDocumentPdf: 'Packaging Documents',
      JdeSalesOrderXml: 'JDE SalesOrder XML',
      projectFromQuote: 'Duplicate Quote as a New Project',
      orderExpediteRequest: 'Order Expedite Request',
      activeOrderExpediteRequest: 'See Active Expedite Request',
    },
    manage: 'Manage',
    delete: 'Delete',
    quoteName: 'QUOTE',
    pendingQuoteName: 'Pending quote',
    pendingQuoteStatus: 'Pending Freight',
    status: 'Status',
    quoteNumber: 'QUOTE REF.#',
    projectSettings: 'PROJECT SETTINGS',
    expDate: 'EXPIRY DATE',
    totalPrice: 'TOTAL PRICE',
    styles: 'STYLES',
    cm: 'CM#:',
    buttonText: {
      order: 'ORDER',
    },
    noStyles: 'No styles (parts only)',
    duplicateProjectFromQuote: {
      modal: {
        title: 'Warning',
        bodyMain: 'Do you really want to create a new project based on this quote?',
        bodySub: 'You will be redirected to the new project.',
      },
      errorNotice: 'Failed creating new project based on this quote.',
      successNotice: 'Your new project has been created',
    },
    specialShippingMethodRequestModal: {
      success: {
        title: 'Request managed',
        text: 'The applicant will receive an email notification shortly.',
      },
      error: {
        default: 'Your special shipping quote could not be completed at this time. Please try again.',
        soldOut:
          'Your special shipping quote could not be completed at this time. Some items in the quote are unavailable/sold out.',
      },
      title: 'Your special shipping quote request has been sent.',
      text: 'We will notify you by email when the quote is ready (within&nbsp;1&nbsp;business&nbsp;day).',
      action: 'Close',
    },
    completeLargeShipmentRequestModal: {
      title: 'Special Shipping Quote',
      freightCharge: '$ Amount ({currency})',
      currencyUSD: 'USD',
      currencyCAD: 'CAD',
      expiryDate: 'Expiry Date',
      freightCarrier: 'Carrier',
      destination: 'Destination',
      origin: 'Origin Plant ({material})',
      specialRequirements: {
        title: 'Special Requirements',
        description:
          'Accessorials additional fees will be automatically added by Next on top of the $ amount provided above.',
      },
      note: {
        label: 'Note',
        placeholder: {
          logisticNote: 'If you add a note and a private note, they will both be saved when submitting this form.',
          privateNote: 'Private notes can only be seen by Hadrian users.',
          userNote: "User's notes can only be seen by Hadrian users.",
        },
        button: {
          logisticNote: 'Note',
          privateNote: 'Private Note',
          userNote: "User's Note",
        },
        partialOrderButton: {
          logisticNote: 'Note from Logistics',
          privateNote: 'Private Note',
          userNote: 'Note from user',
        },
      },
    },
  },
  maintenance: {
    title: 'Website under maintenance.',
    body: "Sorry for the inconvenience but we're performing maintenance at the moment. We'll come back as soon as possible.",
  },
  networkError: {
    title: 'Network error',
    body: 'Please try again in a couple minutes or contact your network administrator.',
    button: 'TRY AGAIN',
  },
  userList: {
    usersList: 'Users List',
    disabled: 'Disabled',
    name: 'Name',
    creationDate: 'Created date',
    create: 'Create user',
    role: 'Role',
    phoneNumber: 'Phone Number',
    emailAddress: 'Email Address',
    organizations: 'Organizations',
    users: 'Users',
    notApplicable: 'N/A',
    searchPlaceholder: 'Search',
    searchTooltip: 'Search users by Name, Role, Phone Number or Email',
    filterPlaceholder: 'Select an organization',
    onlyHadrianUsers: 'Show Hadrian users only',
    noDisabledUsers: 'No disabled users',
    noUpcomingDisabledUsers: 'No disabled users correspond to your search',
    noActiveUsers: 'No users',
    noUpcomingActiveUsers: 'No users correspond to your search',
    restoreUserModal: {
      modalTitle: 'Restore user',
      mainText: 'Are you sure you want to restore this user?',
      subtext: 'The user will be moved to the users list.',
      rightButtonText: 'restore',
    },
    restoreUserNotification: {
      success: 'The user has been successfully restored.',
      error: 'An error occurred while trying to restore the user.',
    },
  },
  passwordValidation: {
    tooShort: 'Your password must be at least eight characters in length.',
    noSpecialCharacters: 'Your password must contain at least one special character.',
    noUppercaseLetters: 'Your password must contain at least one uppercase letter.',
    noLowercaseLetters: 'Your password must contain at least one lowercase letter.',
    noNumbers: 'Your password must contain at least one number.',
    noLeadingOrTrailingWhitespace: 'Your password cannot contain leading or trailing whitespace.',
  },
  inputSection: {
    maxCharacters: 'Maximum of {0} characters.',
  },
  pdfLoader: {
    warning:
      'Do not close this tab until your PDF is generated. <br /> You can go back to Project Center while it is loading.',
  },
  resourceCenter: {
    title: 'Resource Center',
    close: 'Close',
    notice: 'Selected document will open in a new tab of your browser.',
  },
  resourcePageAdmin: {
    title: 'Resource Admin',
    uploadNewDoc: 'Upload new document',
    fileName: 'File Name',
    displayOrder: 'Display Order',
    notice: 'Only PDF file format is accepted',
    resourceCenterDisplayName: 'Resource Center Display Name',
    lastUpdated: 'Last Updated',
    size: 'Size',
    fileNameSpecifications: 'Some characters such as +, ?, #, ( ), [ ], “, / cannot be used in the file name.',
  },
  tagInput: {
    instructions: 'Add a comma after each item.',
    maxItemCountLabel: 'Limit: {count}',
  },
  inputDate: {
    selectADate: 'Select a date',
  },
  projectInConflict: {
    seconds: 'seconds',
    title: 'Another user is currently editing the project.',
    tryingAgainIn: '{seconds} seconds...',
    retrying: 'Retrying...',
    quoteOrder: {
      body: 'Please stand by, you will be able to resume quoting or ordering when the project is updated.',
    },
    projectSettings: {
      body: 'Please stand by, you will be able to resume editing the project shortly.',
    },
  },
};

export default en;
