import { resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "cancel-confirm-modal__body" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 1,
  class: "cancel-confirm-modal__warning"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notice = _resolveComponent("notice")!
  const _component_hadrianModal = _resolveComponent("hadrianModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_hadrianModal, {
      ref: "cancelConfirmModal",
      title: _ctx.title,
      onRightButtonClick: _ctx.onRightButtonClicked,
      onLeftButtonClick: _ctx.cancel,
      leftButtonText: _ctx.leftButtonText,
      rightButtonText: _ctx.rightButtonText,
      variant: _ctx.variant,
      hideLeftButton: _ctx.hideLeftButton,
      hideHeader: !_ctx.title
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.iconDivClasses)
          }, [
            (!!_ctx.icon)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), {
                  key: 0,
                  class: _normalizeClass(_ctx.iconClasses)
                }, null, 8, ["class"]))
              : _createCommentVNode("", true)
          ], 2),
          (_ctx.bodyMain)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "cancel-confirm-modal__body-main",
                innerHTML: _ctx.bodyMain
              }, null, 8, _hoisted_2))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(["cancel-confirm-modal__body-sub", _ctx.bodySubClasses]),
            innerHTML: _ctx.bodySub
          }, null, 10, _hoisted_3),
          (_ctx.warningText)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_notice),
                _createTextVNode(" " + _toDisplayString(this.warningText), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ], undefined, true),
      _: 1
    }, 8, ["title", "onRightButtonClick", "onLeftButtonClick", "leftButtonText", "rightButtonText", "variant", "hideLeftButton", "hideHeader"])
  ]))
}