import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0866d460"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.isClickable)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: _ctx.toUrl,
        class: _normalizeClass(["hadrian-router-link", _ctx.customClass]),
        disabled: _ctx.disabled || undefined
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.mainLabel), 1)
        ], undefined, true),
        _: 1
      }, 8, ["to", "class", "disabled"]))
    : (_openBlock(), _createElementBlock("span", {
        key: 1,
        class: "hadrian-router-link hadrian-router-link__inactive-label",
        disabled: _ctx.disabled || undefined
      }, _toDisplayString(_ctx.getAlternateLabel), 9, _hoisted_1))
}