import { NewRelicBrowser } from '@/interfaces/newRelicBrowser';
import User from '@/models/user/user';
import { RouteLocation } from 'vue-router';

declare global {
  interface Window {
    newrelic: NewRelicBrowser;
  }
}

enum NewRelicCustomAttributes {
  UserId = 'userId',
}

export enum NewRelicEvents {
  QuoteOrderStep = 'QuoteOrderStep',
}

export type NewRelicEventAttributes<T> = Record<keyof T, string | number | boolean | undefined | null>;

export interface NewRelicQuoteOrderStepAttributes {
  step: string;
  projectId: string;
  entityId: string;
  context: 'quote' | 'order';
  productType: string;
  weight: number | undefined;
  projectPartCount: number | undefined;
  projectPartsAreAdded: boolean;
  selectedRoomCount: number | undefined;
}

export class NewRelicService {
  private userId: string;
  private timers: Record<string, number>;

  constructor() {
    this.userId = '';
    this.timers = {};
  }

  /**
   * Sets the user ID for NewRelic.
   * @param user - The user to set the ID for.
   */
  setUserId(user: User): void {
    if (!this.isNewRelicConfigured) {
      return;
    }

    this.userId = user.id;
    window.newrelic.setCustomAttribute(NewRelicCustomAttributes.UserId, this.userId);
  }

  /**
   * Sets the user ID back to null.
   */
  resetUserId(): void {
    if (!this.isNewRelicConfigured) {
      return;
    }

    this.userId = 'unauthenticated';
    window.newrelic.setCustomAttribute(NewRelicCustomAttributes.UserId, this.userId);
  }

  /**
   * Saves a page view interaction to NewRelic.
   * @param to - The current route.
   */
  pageView(to: RouteLocation): void {
    if (!this.isNewRelicConfigured) {
      return;
    }
    const interaction = window.newrelic.interaction();
    interaction.setName(to.fullPath);
    interaction.setAttribute(NewRelicCustomAttributes.UserId, this.userId);
    interaction.save();
  }

  /**
   * Saves a timestamp for a unique event name. This timestamp will be used
   * later to calculate an elapsed time, and send that to NewRelic.
   * @param event - The name of the event to save a timestamp for.
   */
  startEventTimer(event: NewRelicEvents): void {
    if (!this.isNewRelicConfigured) {
      return;
    }
    this.timers[event] = Date.now();
    // eslint-disable-next-line no-console
    console.debug('Start event timer', event, this.timers[event]);
  }

  /**
   * Calculates the elapsed time between the current time and the saved timestamp, and then sends
   * the custom event to NewRelic.
   * @param event - The name of the event to compute an elapsed time for.
   * @param attributes - Any additional attributes to send to NewRelic.
   */
  endEventTimer<T>(event: NewRelicEvents, attributes: NewRelicEventAttributes<T>): void {
    if (!this.isNewRelicConfigured) {
      return;
    }
    const elapsedTime = Date.now() - this.timers[event];
    delete this.timers[event];
    if (isNaN(elapsedTime)) {
      return;
    }
    // eslint-disable-next-line no-console
    console.debug('End event timer', event, { ...attributes, elapsedTime });
    this.event<T>(event, { ...attributes, elapsedTime });
  }

  /**
   * Resets all timers to null.
   */
  resetTimers(): void {
    this.timers = {};
  }

  /**
   * Sends a custom event to NewRelic.
   * @param event The name of the event to send to NewRelic.
   * @param attributes Any additional attributes to send to NewRelic.
   */
  private event<T>(event: NewRelicEvents, attributes: NewRelicEventAttributes<T>): void {
    if (!this.isNewRelicConfigured) {
      return;
    }

    const normalizedAttributes: Record<string, string | number> = {};
    for (const [key, value] of Object.entries(attributes)) {
      if (typeof value === 'boolean') {
        normalizedAttributes[key] = value ? 1 : 0;
        continue;
      }
      if ((typeof value === 'string' || typeof value === 'number') && value != null) {
        normalizedAttributes[key] = value;
      }
    }

    window.newrelic.addPageAction(event, normalizedAttributes);
  }

  private get isNewRelicConfigured(): boolean {
    return typeof window.newrelic === 'object';
  }
}

const newRelicService = new NewRelicService();
export default newRelicService;
