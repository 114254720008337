import { vxManager } from '@/store/store';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'hadrianPage',
  props: {
    // Classes to add to the header wrapper.
    headerClass: {
      type: String,
      default: '',
    },
    // Classes to add to the body wrapper.
    bodyClass: {
      type: String,
    },
    // If false, sets a max-width of 1300px on the page body along with auto
    // horizontal margins and horizontal padding.
    fullPageWidth: {
      type: Boolean,
      default: true,
    },
    // By default, there is 40 pixels of padding-bottom added to every page to
    // account for the environment footer. You can skip this padding by setting
    // `noEnvironmentPadding` to true.
    noEnvironmentPadding: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    addEnvironmentPadding(): boolean {
      return vxManager.appStateModule.showEnvironmentFooter && !this.noEnvironmentPadding;
    },
  },
});
