export default class LockEndUserInfo {
  static areEqual(lockEndUserInfo1: LockEndUserInfo, lockEndUserInfo2: LockEndUserInfo): boolean {
    return (
      lockEndUserInfo1.companyName === lockEndUserInfo2.companyName &&
      lockEndUserInfo1.contactName === lockEndUserInfo2.contactName &&
      lockEndUserInfo1.address === lockEndUserInfo2.address &&
      lockEndUserInfo1.phoneNumber === lockEndUserInfo2.phoneNumber &&
      lockEndUserInfo1.keyNumber === lockEndUserInfo2.keyNumber
    );
  }

  companyName = '';
  contactName = '';
  address = '';
  phoneNumber = '';
  keyNumber = '';
  existingKeyingSystem: boolean;
}
