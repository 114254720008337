import Organization from '@/models/permission/organization';
import { action, mutation, Module, VuexModule } from 'vuex-class-component';

@Module()
export class OrganizationsModule extends VuexModule {
  private distributors: Organization[] = [];
  private salesAgencies: Organization[] = [];
  private allOrganizations: Organization[] = [];

  @action({ mode: 'mutate' })
  public async setOrganizations(organizations: Organization[]): Promise<void> {
    this._setOrganizations(organizations);
    this._setDistributors(organizations.filter(org => !org.isSalesAgency));
    this._setSalesAgencies(organizations.filter(org => org.isSalesAgency));
  }

  get getDistributors(): Organization[] {
    return this.distributors;
  }

  get getSalesAgencies(): Organization[] {
    return this.salesAgencies;
  }

  get getOrganizations(): Organization[] {
    return this.allOrganizations;
  }

  @mutation private _setDistributors(distributors: Organization[]) {
    this.distributors = distributors;
  }

  @mutation private _setSalesAgencies(salesAgencies: Organization[]) {
    this.salesAgencies = salesAgencies;
  }

  @mutation private _setOrganizations(organizations: Organization[]) {
    this.allOrganizations = organizations;
  }
}
