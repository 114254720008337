import FirstLogin from '@/enums/firstLogin';
import PermissionTypes from '@/enums/permissionTypes';
import EmailHelper from '@/helpers/emailHelper';
import PasswordHelper, { PasswordValidationResult } from '@/helpers/passwordHelper';
import Toast from '@/helpers/toast';
import urlBuilder from '@/helpers/urlBuilder';
import { SignInResponse, UserData } from '@/models/signIn/signInResponse';
import disableUser from '@/shared/icons/disableUser/disableUser.vue';
import iconErrorLoginFailed from '@/shared/icons/iconErrorLoginFailed/iconErrorLoginFailed.vue';
import hadrianRouterLink from '@/shared/navigation/hadrianRouterLink/hadrianRouterLink.vue';
import hadrianCheckbox from '@/shared/viewParts/hadrianCheckbox/hadrianCheckbox.vue';
import hadrianLoader from '@/shared/viewParts/hadrianLoader/hadrianLoader.vue';
import hadrianTooltip from '@/shared/viewParts/hadrianTooltip/hadrianTooltip.vue';
import inputGroup from '@/shared/viewParts/inputGroup/inputGroup.vue';
import { vxManager } from '@/store/store';
import errorModal from '@/views/errorModal/errorModal.vue';
import jwt_decode from 'jwt-decode';
import { defineComponent } from 'vue';
import AuthenticationService from '../../../endpoints/authenticationService';
import LoginErrors from '../../../enums/loginErrors';
import authenticationPage from '../authenticationPage/authenticationPage.vue';

import IServerError from '../../../interfaces/IServerError';
import paths from '../../../router/paths';

enum LoginSteps {
  Initial,
  EmailEntered,
  Disabled,
}

const TRANSITION_DURATION = 300;

export default defineComponent({
  name: 'login',
  components: {
    errorModal,
    hadrianLoader,
    iconErrorLoginFailed,
    hadrianRouterLink,
    hadrianTooltip,
    disableUser,
    inputGroup,
    hadrianCheckbox,
    authenticationPage,
  },
  props: {
    emailAutoFill: {
      type: String,
      default: '',
    },
    passwordAutoFill: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      email: '',
      password: '',
      keepMeLoggedIn: false,
      error: '',
      paths,
      pendingLogin: false,
      LoginSteps,
      currentStep: LoginSteps.Initial,
      userIsDisabled: false,
      pendingHadrianLogin: false,
    };
  },
  async mounted() {
    if (vxManager.userModule.getUserInfo) {
      this.$router.push((this.$route.query.redirect as any) || vxManager.userModule.getRedirectOnLogin || '/');
    } else if (!!this.$route.query.redirect && vxManager.userModule.getRedirectOnLogin !== this.$route.query.redirect) {
      vxManager.userModule.changeRedirectOnLogin(this.$route.query.redirect as any);
    }
    this.fillEmailAndPassword();
    await this.getCodeRefreshTokenAfterOffice365Login();
    this.keepMeLoggedIn = vxManager.userModule.getKeepMeLoggedIn;
  },
  watch: {
    keepMeLoggedIn(newValue: boolean) {
      vxManager.userModule.changeKeepMeLoggedIn(newValue);
    },
  },
  computed: {
    disableContinue(): boolean | null {
      if (!this.enableSecondStep) {
        return true;
      }
      return null;
    },
    disableLogin(): boolean | null {
      if (!this.allowLogin) {
        return true;
      }

      return null;
    },
    enableSecondStep(): boolean {
      return EmailHelper.emailIsPotentiallyValid(this.email);
    },
    passwordValidationResult(): PasswordValidationResult {
      return PasswordHelper.validatePassword(this.password);
    },
    invalidPasswordMessage(): string {
      if (this.passwordValidationResult.isValid) {
        return '';
      }
      return this.passwordValidationResult.printableErrors[0];
    },
    allowLogin(): boolean {
      return this.enableSecondStep && !!this.passwordValidationResult.isValid;
    },
    hadrianUserLogin(): boolean {
      return EmailHelper.emailIsForHadrianUser(this.email);
    },
    emailInput() {
      return this.$refs.emailInput as HTMLInputElement;
    },
    passwordInput() {
      return this.$refs.passwordInput as HTMLInputElement;
    },
    office365Button() {
      return this.$refs.office365Button as HTMLElement;
    },
  },
  methods: {
    fillEmailAndPassword() {
      this.email = this.emailAutoFill;
      this.password = this.passwordAutoFill;
    },
    advanceStep() {
      if (!this.enableSecondStep) {
        return;
      }

      this.currentStep = LoginSteps.EmailEntered;

      const elementToFocus = this.hadrianUserLogin ? this.office365Button : this.passwordInput;

      setTimeout(() => {
        (elementToFocus as HTMLElement).focus();
      }, TRANSITION_DURATION);
    },
    backToEmail() {
      this.currentStep = LoginSteps.Initial;
      this.userIsDisabled = false;

      setTimeout(() => {
        (this.$refs.emailInput as HTMLInputElement).select();
      }, TRANSITION_DURATION);
    },
    backToPassword() {
      this.currentStep = LoginSteps.EmailEntered;
      this.userIsDisabled = false;
    },
    forgotPasswordUrl(): string {
      return `${paths.forgotPassword}/${this.email}`;
    },
    async getCodeRefreshTokenAfterOffice365Login() {
      if (!this.$route.query.code) {
        return;
      }
      this.keepMeLoggedIn = true;
      this.pendingHadrianLogin = true;
      try {
        const data = await AuthenticationService.getCodeRefreshToken(this.$route.query.code as string);
        const idData = jwt_decode(data.id_token);
        const email = (idData as any).email;
        const userName = (idData as any)['cognito:username'];
        const hasLoggedInSuccessfully = 'id_token' in data;
        if (hasLoggedInSuccessfully && (email || userName)) {
          try {
            const response = await AuthenticationService.Office365Login(email, userName, true);
            response.tokens = { token: data.id_token, refreshToken: data.refresh_token };
            response.isHadrianUser = true;
            /* there's a HTTP redirection within the login method below; don't expect any code to work after this call within the logical if */
            await this.login(response);
            // not gonna be called
          } catch {
            this.loginErrors();
          }
        } else {
          if (email) {
            await AuthenticationService.Office365Login(email, userName, false);
          }
          this.loginErrors(data);
        }
      } catch (ex: any) {
        await vxManager.userModule.logout();
        this.currentStep = LoginSteps.Initial;
        this.pendingHadrianLogin = false;
      }
    },
    async submitLogin() {
      if (this.currentStep === LoginSteps.Initial) {
        this.advanceStep();
        return;
      }

      if (this.hadrianUserLogin) {
        return;
      }

      if (!this.allowLogin) {
        return;
      }

      this.pendingLogin = true;
      try {
        let data;
        try {
          data = await AuthenticationService.loginUser(this.email, this.password);
        } catch (error: any) {
          const errorCode = error?.response?.status ?? 400;
          if (errorCode === 401) {
            this.currentStep = LoginSteps.Disabled;
            this.userIsDisabled = true;
          } else if (!error?.response?.status) {
            this.$router.push({ path: paths.networkError });
          }
        }
        try {
          if ('tokens' in data && 'token' in data.tokens) {
            await this.login(data);
          } else {
            this.loginErrors(data);
          }
        } catch (error: any) {
          this.currentStep = LoginSteps.Disabled;
          this.userIsDisabled = true;
        }
      } finally {
        this.pendingLogin = false;
      }
    },
    async office365SignIn() {
      const url = urlBuilder(process.env.VUE_APP_AMAZON_AUTH_URL, {
        identity_provider: 'HadrianOffice365Provider',
        redirect_uri: process.env.VUE_APP_AMAZON_LOGIN_REDIRECT_URI,
        response_type: 'CODE',
        client_id: process.env.VUE_APP_AMAZON_CLIENT_ID,
        scope: 'aws.cognito.signin.user.admin email openid profile',
      });

      try {
        window.location.href = url;
      } catch (error: any) {
        this.error = 'login.errors.office365';
        this.currentStep = LoginSteps.Disabled;
        this.userIsDisabled = true;
      }
    },
    loginErrors(data?: IServerError) {
      switch (data?.label) {
        case LoginErrors.UserNotFound:
          this.error = 'login.errors.notFound';
          break;
        case LoginErrors.PasswordChange:
          this.redirectUser(FirstLogin.regularUserFirstLogin);
          return;
        case LoginErrors.PasswordIncorrect:
          this.error = 'login.errors.incorrectPassword';
          this.passwordInput.select();
          break;
        default:
          this.error = 'login.errors.unknown';
      }

      Toast.error({ body: this.$t(this.error) });
      this.pendingHadrianLogin = false;
      this.pendingLogin = false;
    },
    async login(response: SignInResponse) {
      const storage = this.keepMeLoggedIn ? localStorage : sessionStorage;
      storage.setItem('token', response.tokens.token);
      storage.setItem('refreshToken', response.tokens.refreshToken);

      const isFirstLogin = !response.userData.user.isConfirmed;
      const isEnabled = response.userData.user.enabled;
      if (!isEnabled && !isFirstLogin) {
        vxManager.userModule.logout({ addLoginToHistory: false });
        this.pendingHadrianLogin = false;
        this.currentStep = LoginSteps.Disabled;
        this.userIsDisabled = true;
        return;
      }

      if (isFirstLogin) {
        if (response.isHadrianUser) {
          this.redirectUser(FirstLogin.hadrianUserFirstLogin, response.userData);
        } else {
          this.redirectUser(FirstLogin.regularUserFirstLogin, response.userData);
        }
      } else {
        this.redirectUser(FirstLogin.notFirstLogin, response.userData);
      }
    },
    async redirectUser(firstLogin: FirstLogin, userData?: UserData) {
      if (firstLogin === FirstLogin.regularUserFirstLogin) {
        Toast.warning({ body: this.$t('login.passwordChangeRedirect') });
        this.$router.push({
          path: `${paths.changePassword}/${this.email}`,
          params: { password: this.password },
        });
      } else {
        const success = await vxManager.userModule.login(userData);
        if (!success) {
          Toast.error({ body: this.$t('login.errors.unknown') });
          return;
        }

        if (firstLogin === FirstLogin.hadrianUserFirstLogin) {
          this.$router.push({ path: paths.profile });
        } else {
          if (!!this.$route.query.redirect || !!vxManager.userModule.getRedirectOnLogin) {
            this.$router.push((this.$route.query.redirect as any) || vxManager.userModule.getRedirectOnLogin || '/');
          } else if (
            userData?.user.permissions.findIndex(x => x.permissionType === PermissionTypes.AccessProjects) !== -1
          ) {
            this.$router.push(paths.projects);
          } else {
            this.$router.push(paths.orders);
          }
        }
      }
    },
  },
});
