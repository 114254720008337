import { defineComponent } from 'vue';

export default defineComponent({
  name: 'h-showmore',
  props: {
    paginationDetails: {
      required: true,
      type: String,
    },
  },
});
