import HadrianBase from '@/models/hadrianBase';

export default class ArrayHelper {
  /**
   * Helper method to append or replace items in an array.
   * @param a The array on which the operation will be performed
   * @param toAdd The values to be added/replaced in the array.
   * @param propKey If this value is provided, the added elements will keep their original value for this key.
   * @param propValue If this value is provided, the added elements will
   * have this value instead of their original value for the provided key.
   */
  public static addOrReplace<T extends HadrianBase, K extends keyof T, V extends T[K]>(
    a: T[],
    toAdd: T[],
    propKey?: K,
    propValue?: V
  ) {
    toAdd.forEach(elementToAdd => {
      const index = a.findIndex(el => el.id === elementToAdd.id);
      if (index === -1) {
        a.push(elementToAdd);
      } else {
        if (propKey) {
          elementToAdd[propKey] = propValue ? propValue : a[index][propKey];
        }
        a[index] = elementToAdd;
      }
    });
  }

  public static async asyncForEach(array: any[], callback: (object: any, index: number, array: any[]) => void) {
    for (let i = 0; i < array.length; i++) {
      await callback(array[i], i, array);
    }
  }

  /**
   * Helper method to join an array of strings with a separator.
   *
   * @param array - The array to be joined.
   * @param separator - The separator to be used.
   * @param lastSeparator - The separator to be used for the last element.
   *
   * @returns A string containing the joined elements.
   */
  public static join(array: (number | string)[], separator: string, lastSeparator?: string): string {
    if (array.length <= 1) {
      return array.join('');
    }

    return (
      array.slice(0, array.length - 1).join(separator) +
      (lastSeparator ? lastSeparator : separator) +
      array[array.length - 1]
    );
  }
}
