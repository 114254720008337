import HttpService from '@/endpoints/HttpService';
import HttpResponse from '@/interfaces/HttpResponse';
import { ProjectPatchOperation } from '@/models/project/projectApiInterfaces';
import { ProjectSettingsResponse } from '@/models/project/projectSettings';

const projectSettingsUrl = `${process.env.VUE_APP_API_PREFIX}projectSettings`;

export default class ProjectSettingsService {
  public static async patchProjectSettings(
    operation: ProjectPatchOperation
  ): Promise<HttpResponse<ProjectSettingsResponse>> {
    const params = operation.applyToAllRooms ? '?isAppliedToAllRooms=true' : '';
    const response = await HttpService.patchWithLoading(
      `${projectSettingsUrl}/${operation.projectId}${params}`,
      operation.operations
    );
    return response;
  }
}
