import HttpService from '@/endpoints/HttpService';
import urlBuilder from '@/helpers/urlBuilder';
import { UserData } from '@/models/signIn/signInResponse';
import Axios from 'axios';

export default class AuthenticationService {
  public static async loginUser(username: string, password: string): Promise<any> {
    const res = await HttpService.post('api/authentication/signin', {
      user: {
        username,
        password,
      },
      settings: { timezoneId: Intl.DateTimeFormat().resolvedOptions().timeZone },
    });
    return res.data;
  }

  public static async Office365Login(username: string, cognitoUserName: string, hasLoggedIn: boolean): Promise<any> {
    const res = await HttpService.post('api/authentication/Office365Login', {
      user: {
        username,
        cognitoUserName,
        hasLoggedIn,
      },
      settings: { timezoneId: Intl.DateTimeFormat().resolvedOptions().timeZone },
    });
    return res.data;
  }

  public static async changePassword(username: string, newPassword: string, password: string): Promise<any> {
    const res = await HttpService.post('/api/authentication/passwordChallenge', {
      newPassword,
      username,
      password,
    });
    return res.data;
  }

  public static async confirmForgotPassword(
    username: string,
    newPassword: string,
    confirmationCode: string
  ): Promise<any> {
    const res = await HttpService.post('/api/authentication/confirmForgotPassword', {
      newPassword,
      confirmationCode,
      username,
    });
    return res.data;
  }

  public static async forgotPassword(email: string): Promise<any> {
    const res = await HttpService.post(`/api/authentication/forgotPassword?email=${encodeURIComponent(email)}`);
    return res.data;
  }

  public static async getCodeRefreshToken(code: string): Promise<any> {
    const url = urlBuilder(process.env.VUE_APP_AMAZON_TOKEN_URL, {
      grant_type: 'authorization_code',
      redirect_uri: process.env.VUE_APP_AMAZON_LOGIN_REDIRECT_URI,
      code,
      client_id: process.env.VUE_APP_AMAZON_CLIENT_ID,
    });
    const res = await HttpService.post(url, null, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });

    return res.data;
  }

  public static async refreshToken(refreshToken: string): Promise<any> {
    const url = `${process.env.VUE_APP_SERVER_URL}/api/authentication/refreshToken`;
    const res = await Axios.post(url, {
      refreshToken,
    });
    return res.data;
  }

  public static async fetchSignInUserData(): Promise<UserData> {
    const res = await HttpService.get('/api/authentication/getUserData');
    return res.data;
  }

  // false means the user has never logged in
  public static async getUserFirstLogin(email: string): Promise<boolean> {
    const res = await HttpService.get(`/api/authentication/invitationConfirmed?email=${encodeURIComponent(email)}`);
    return res.data;
  }
}
