import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "maintenance-content" }
const _hoisted_2 = { class: "maintenance-content__logo" }
const _hoisted_3 = { class: "maintenance-content__title" }
const _hoisted_4 = { class: "maintenance-content__body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_iconErrorUnderMaintenance = _resolveComponent("iconErrorUnderMaintenance")!
  const _component_authenticationPage = _resolveComponent("authenticationPage")!

  return (_openBlock(), _createBlock(_component_authenticationPage, { showHeader: false }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_iconErrorUnderMaintenance, { class: "maintenance-content__logo" })
        ]),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('maintenance.title')), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('maintenance.body')), 1),
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hardRefresh && _ctx.hardRefresh(...args))),
          class: "btn btn-primary btn-round",
          type: "button"
        }, _toDisplayString(_ctx.$t('generic.refresh')), 1)
      ])
    ], undefined, true),
    _: 1
  }))
}