import paths from '@/router/paths';
import projectCenterBlack from '@/shared/icons/project-center-black/project-center-black.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'errorModal',
  components: {
    projectCenterBlack,
  },
  props: {
    showLogo: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
    },
    iconClass: {
      type: String,
    },
    actionLabel: {
      type: String,
    },
    onActionClick: {
      type: Function,
    },
    useAlphaBackDrop: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goToHomePage() {
      this.$router.push({ path: paths.home });
    },
    onClick() {
      if (this.onActionClick) {
        this.onActionClick();
      }
      this.$emit('closeModal');
    },
    onBackdropClick() {
      if (this.onActionClick) {
        this.onActionClick();
      }
      this.$emit('closeModal');
    },
  },
});
