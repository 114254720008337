import HttpService from '@/endpoints/HttpService';
import HttpResponse from '@/interfaces/HttpResponse';
import LockersData from '@/models/lockers/lockersData';
import LockersDefault from '@/models/lockers/lockersDefaults';
import Style from '@/models/lockers/style';
import { LockersStyleCreationRequest } from '@/models/project/projectApiInterfaces';

export enum LockersPath {
  Defaults = '/defaults',
  Static = '/static',
}

const lockersUrl = `${process.env.VUE_APP_API_PREFIX}lockers`;
const lockerStylesUrl = `${process.env.VUE_APP_API_PREFIX}LockersStyles`;

export default class LockersService {
  public static async getStaticLockersData(): Promise<LockersData> {
    const response = await HttpService.get(this.makeUrl(LockersPath.Static), { cached: true });
    return response.data;
  }

  public static async getLockersDefaults(): Promise<LockersDefault> {
    const response = await HttpService.get(this.makeUrl(LockersPath.Defaults), { cached: true });
    return response.data;
  }

  public static async createLockerStyle(request: LockersStyleCreationRequest): Promise<HttpResponse<Style>> {
    const res = await HttpService.post(lockerStylesUrl, request);
    return res;
  }

  public static async updateLockerStyle(request: LockersStyleCreationRequest): Promise<HttpResponse<Style>> {
    const res = await HttpService.put(`${lockerStylesUrl}/${request.id}`, request);
    return res;
  }

  public static async deleteLockerStyle(lockerStyleId: number) {
    const res = await HttpService.deleteWithLoading(`${lockerStylesUrl}/${lockerStyleId}`);
    return res.data;
  }

  private static makeUrl(path: LockersPath): string {
    return `${lockersUrl}${path}`;
  }
}
