import ProjectDetailsService from '@/endpoints/projectDetailsService';
import MaterialCategories from '@/enums/materialCategories';
import { getFormattedMeasurementValueForLabel } from '@/helpers/measurementTypeHelper';
import DefaultsQueryParams from '@/interfaces/IDefaultsQueryParams';
import DoorHeightDefault from '@/models/allowedValues/DoorHeightDefault';
import HardwareDefault from '@/models/allowedValues/HardwareDefault';
import HingeDefault from '@/models/allowedValues/HingeDefault';
import LatchDefault from '@/models/allowedValues/LatchDefault';
import MaterialDefault from '@/models/allowedValues/MaterialDefault';
import PartitionDefault from '@/models/allowedValues/PartitionDefault';
import SeriesDefault from '@/models/allowedValues/SeriesDefault';
import Finish from '@/models/finish/finish';
import FinishType from '@/models/finish/finishType';
import DoorHinge from '@/models/hardware/doorHinge';
import HardwareMaterial from '@/models/hardware/hardwareMaterial';
import HardwarePackage from '@/models/hardware/hardwarePackage';
import Latch from '@/models/hardware/latch';
import NoSightLine from '@/models/hardware/noSightLine';
import Material from '@/models/material/material';
import Option from '@/models/option';
import AffOptions from '@/models/partition/affOptions';
import Partition from '@/models/partition/partition';
import { ProjectProductTypes } from '@/models/project/projectApiInterfaces';
import { ProjectSettingsStaticData } from '@/models/project/projectSettings';
import Series from '@/models/series/series';

import { action, mutation, Module, VuexModule } from 'vuex-class-component';

export class AllowedNoSightLine extends NoSightLine {
  readonly isMandatory: boolean;
}

export class AllowedOption extends Option {
  readonly isMandatory: boolean;
  conditionalMandatory?: boolean;
  isConditionFulfilled?: boolean;
}

@Module()
export class ProjectSettingsModule extends VuexModule {
  private doorHinges: DoorHinge[] = [];
  private finishTypes: FinishType[] = [];
  private hardwareMaterials: HardwareMaterial[] = [];
  private hardwarePackages: HardwarePackage[] = [];
  private materials: Material[] = [];
  private noSightLines: NoSightLine[] = [];
  private options: Option[] = [];
  private partitions: Partition[] = [];
  private series: Series[] = [];
  private defaultMaterial: string;
  private materialDefaults: MaterialDefault;
  private seriesDefaults: SeriesDefault;
  private partitionDefaults: PartitionDefault;
  private allPartitionsDefaults: PartitionDefault[];
  private allDoorHeightDefaults: DoorHeightDefault[];
  private hardwareDefaults: HardwareDefault;
  private hingeDefaults: HingeDefault;
  private latchDefaults: LatchDefault;
  private latches: Latch[] = [];
  private affOptions: AffOptions[] = [];
  private affOptionRespectingAdaStandard: AffOptions;
  private projectSettingsDefaultImage: string;

  @action({ mode: 'mutate' })
  public hydrateProject(data: ProjectSettingsStaticData) {
    this.setProjectSettingsStaticData(data);
  }

  @action({ mode: 'mutate' })
  public async fetchDefaultsForMaterial(material: Material | undefined): Promise<void> {
    if (!material) {
      return Promise.reject();
    }
    if (this.getMaterialIdForDefault !== material.id) {
      const response = await ProjectDetailsService.getDefaultsForMaterial(material.id);
      this.setMaterialDefaults(response);
    }
  }

  @action({ mode: 'mutate' })
  public async fetchDefaultsForSeries(params: DefaultsQueryParams): Promise<void> {
    if (!params.material || !params.series) {
      return Promise.reject();
    }
    if (this.getSeriesIdForDefault !== params.series.id || this.getMaterialIdForDefault !== params.material.id) {
      if (this.getMaterialIdForDefault !== params.material.id) {
        await this.fetchDefaultsForMaterial(params.material);
      }
      if (this.getSeriesIdForDefault !== params.series.id) {
        const response = await ProjectDetailsService.getDefaultsForSeries(params.material.id, params.series.id);
        this.setSeriesDefaults(response);
      }
    }
  }

  @action({ mode: 'mutate' })
  public async fetchDefaultsForPartition(params: DefaultsQueryParams): Promise<void> {
    if (!params.material || !params.series || !params.partition) {
      return Promise.reject();
    }
    if (this.getMaterialIdForDefault !== params.material.id) {
      await this.fetchDefaultsForMaterial(params.material);
      await this.fetchDefaultsForSeries(params);
    } else if (this.getSeriesIdForDefault !== params.series.id) {
      await this.fetchDefaultsForSeries(params);
    }
    if (this.getPartitionIdForDefault !== params.partition.id) {
      const defaults = this.getSeriesDefaults.partitions.find(x => x.id === params.partition?.id);
      if (defaults) {
        this.setPartitionDefaults(defaults);
        this.setPartitionDefaults(defaults);
      }
    }
    this.setAllDoorHeightDefaults(this.getSeriesDefaults.doorHeights);
    this.setAllPartitionsDefaults(this.getSeriesDefaults.partitions);
  }

  @action({ mode: 'mutate' })
  public async fetchDefaultsForHardware(params: DefaultsQueryParams): Promise<void> {
    if (!params.material || !params.series || !params.partition || !params.hardware) {
      return Promise.reject();
    }
    if (this.getMaterialIdForDefault !== params.material.id) {
      await this.fetchDefaultsForMaterial(params.material);
      await this.fetchDefaultsForSeries(params);
      await this.fetchDefaultsForPartition(params);
    } else if (this.getSeriesIdForDefault !== params.series.id) {
      await this.fetchDefaultsForSeries(params);
      await this.fetchDefaultsForPartition(params);
    } else if (this.getPartitionIdForDefault !== params.partition.id) {
      await this.fetchDefaultsForPartition(params);
    }
    if (this.getHardwareIdForDefault !== params.hardware.id) {
      const response = await ProjectDetailsService.getDefaultsForHardware(
        params.material.id,
        params.series.id,
        params.partition.id,
        params.hardware.id
      );
      this.setHardwareDefaults(response);
    }
  }

  @action({ mode: 'mutate' })
  public async fetchDefaultsForHinge(params: DefaultsQueryParams): Promise<void> {
    if (!params.material || !params.series || !params.partition || !params.hardware || !params.hinge) {
      return Promise.reject('missing something');
    }
    if (this.getMaterialIdForDefault !== params.material.id) {
      await this.fetchDefaultsForMaterial(params.material);
      await this.fetchDefaultsForSeries(params);
      await this.fetchDefaultsForPartition(params);
      await this.fetchDefaultsForHardware(params);
    } else if (this.getSeriesIdForDefault !== params.series.id) {
      await this.fetchDefaultsForSeries(params);
      await this.fetchDefaultsForPartition(params);
      await this.fetchDefaultsForHardware(params);
    } else if (this.getPartitionIdForDefault !== params.partition.id) {
      await this.fetchDefaultsForPartition(params);
      await this.fetchDefaultsForHardware(params);
    } else if (this.getHardwareIdForDefault !== params.hardware.id) {
      await this.fetchDefaultsForHardware(params);
    }
    if (this.getHingeIdForDefault !== params.hinge.id) {
      const defaults = this.getHardwareDefaults.hinges.find(x => x.id === params.hinge?.id);
      if (defaults) {
        this.setHingeDefaults(defaults);
      }
    }
  }

  @action({ mode: 'mutate' })
  public async fetchDefaultsForLatch(params: DefaultsQueryParams): Promise<void> {
    if (!params.material || !params.series || !params.partition || !params.hardware || !params.hinge || !params.latch) {
      return Promise.reject('missing something again');
    }
    if (this.getMaterialIdForDefault !== params.material.id) {
      await this.fetchDefaultsForMaterial(params.material);
      await this.fetchDefaultsForSeries(params);
      await this.fetchDefaultsForPartition(params);
      await this.fetchDefaultsForHardware(params);
      await this.fetchDefaultsForHinge(params);
    } else if (this.getSeriesIdForDefault !== params.series.id) {
      await this.fetchDefaultsForSeries(params);
      await this.fetchDefaultsForPartition(params);
      await this.fetchDefaultsForHardware(params);
      await this.fetchDefaultsForHinge(params);
    } else if (this.getPartitionIdForDefault !== params.partition.id) {
      await this.fetchDefaultsForPartition(params);
      await this.fetchDefaultsForHardware(params);
      await this.fetchDefaultsForHinge(params);
    } else if (this.getHardwareIdForDefault !== params.hardware.id) {
      await this.fetchDefaultsForHardware(params);
      await this.fetchDefaultsForHinge(params);
    } else if (this.getHingeIdForDefault !== params.hinge.id) {
      await this.fetchDefaultsForHinge(params);
    }
    if (this.getLatchIdForDefault !== params.latch.id) {
      const defaults = this.getHingeDefaults.latches.find(x => x.id === params.latch?.id);
      if (defaults) {
        this.setLatchDefaults(defaults);
      }
    }
  }

  @action({ mode: 'mutate' })
  public async fetchAvailableSeries(material: Material | undefined): Promise<void> {
    return this.fetchDefaultsForMaterial(material);
  }

  @action({ mode: 'mutate' })
  public async fetchAvailableFinishes(material: Material | undefined): Promise<void> {
    return this.fetchDefaultsForMaterial(material);
  }

  @action({ mode: 'mutate' })
  public async fetchAvailablePartitions(params: DefaultsQueryParams): Promise<void> {
    return this.fetchDefaultsForSeries(params);
  }

  @action({ mode: 'mutate' })
  public async fetchAvailableHardwares(params: DefaultsQueryParams): Promise<void> {
    return this.fetchDefaultsForPartition(params);
  }

  @action({ mode: 'mutate' })
  public async fetchAvailableHinges(params: DefaultsQueryParams): Promise<void> {
    return this.fetchDefaultsForHardware(params);
  }
  @action({ mode: 'mutate' })
  public async fetchAvailableLatches(params: DefaultsQueryParams): Promise<void> {
    return this.fetchDefaultsForHardware(params);
  }
  @action({ mode: 'mutate' })
  public async fetchAvailableOptions(params: DefaultsQueryParams): Promise<void> {
    return this.fetchDefaultsForHardware(params);
  }
  @action({ mode: 'mutate' })
  public async fetchAvailableNoSights(params: DefaultsQueryParams): Promise<void> {
    return this.fetchDefaultsForLatch(params);
  }

  get getDoorHinges(): DoorHinge[] {
    return this.doorHinges;
  }

  get getAllowedDoorHinges(): DoorHinge[] {
    if (this.hardwareDefaults) {
      return this.doorHinges.filter(x => this.hardwareDefaults.hinges?.find(y => x.id === y.id));
    }
    return [];
  }

  get getDefaultHinges(): DoorHinge | undefined {
    if (!this.doorHinges) {
      return undefined;
    }

    for (const hinge of this.doorHinges) {
      if (hinge.id === this.hardwareDefaults.defaultHinge) {
        return hinge;
      }
    }
  }

  get getFinishTypes(): FinishType[] {
    return this.finishTypes;
  }

  get getAllowedFinishTypes(): (currentOrganizationId: string) => FinishType[] {
    return (currentOrganizationId: string) => {
      if (!this.materialDefaults) {
        return [];
      }

      return this.finishTypes
        .map(finishType => ({
          ...finishType,
          finishes: finishType.finishes.filter(finish => this.materialDefaults.finishes.includes(finish.id)),
        }))
        .filter(category => category.finishes.length > 0)
        .reduce(
          (acc, fc) => [
            ...acc,
            {
              ...fc,
              finishes: fc.finishes.filter(
                f =>
                  !f.needsPermission ||
                  (currentOrganizationId &&
                    f.allowedFor.map(x => x.id.toUpperCase()).includes(currentOrganizationId.toUpperCase()))
              ),
            },
          ],
          [] as FinishType[]
        );
    };
  }

  get getDefaultFinish(): Finish | undefined {
    for (const finishType of this.finishTypes) {
      for (const finish of finishType.finishes) {
        if (finish.id === this.materialDefaults.defaultFinish) {
          return finish;
        }
      }
    }
  }

  get getHardwarePackages(): HardwarePackage[] {
    return this.hardwarePackages;
  }

  get getHardwareMaterials(): HardwareMaterial[] {
    return this.hardwareMaterials;
  }

  get getAllowedHardwareMaterials(): HardwareMaterial[] {
    if (this.partitionDefaults) {
      return this.hardwareMaterials
        .filter(x => this.partitionDefaults.hardwares.includes(x.id))
        .sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));
    }
    return [];
  }

  get getDefaultHardware(): HardwareMaterial | undefined {
    return this.hardwareMaterials.find(x => x.id === this.partitionDefaults.defaultHardware);
  }

  get getMaterials(): Material[] {
    return this.materials.filter(value => {
      return value.isEnabled;
    });
  }

  get getMaterialsForDiscounts() {
    return (projectType: ProjectProductTypes | undefined): Material[] => {
      if (projectType === ProjectProductTypes.Partition) {
        return this.materials
          .filter(
            value =>
              (value.category === MaterialCategories.Metal ||
                value.category === MaterialCategories.Plastic ||
                value.category === MaterialCategories.Phenolic) &&
              value.isEnabled
          )
          .sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));
      } else if (projectType === ProjectProductTypes.Lockers) {
        // We skip the isEnabled condition here because the lockers material is set to false
        // since it should not show with other materials in sections of the website
        // where we show materials.
        return this.materials.filter(value => value.category === MaterialCategories.Lockers);
      } else if (projectType === ProjectProductTypes.Dryers) {
        return this.materials.filter(value => value.category === MaterialCategories.Dryers);
      }
      return [];
    };
  }

  get getDefaultMaterial(): Material | undefined {
    return this.materials.find(x => x.id === this.defaultMaterial);
  }

  get getSeries(): Series[] {
    return this.series;
  }

  get getAllowedSeries(): Series[] {
    if (this.materialDefaults) {
      return this.series.filter(x => this.materialDefaults.series.includes(x.id));
    }
    return [];
  }

  get getDefaultSeries(): Series | undefined {
    return this.series.find(x => x.id === this.materialDefaults?.defaultSeries);
  }

  get getNoSightLines(): NoSightLine[] {
    return this.noSightLines;
  }

  get getAllowedNoSightLines(): AllowedNoSightLine[] {
    if (!this.latchDefaults) {
      return [];
    }

    const mandatoryNoSightLineIds = this.latchDefaults.noSights
      .filter(noSightLine => noSightLine.isMandatory)
      .map(noSightLine => noSightLine.id);

    return this.noSightLines
      .filter(x => this.latchDefaults.noSights.some(y => y.id === x.id))
      .map(noSightLine => ({
        ...noSightLine,
        isMandatory: mandatoryNoSightLineIds.includes(noSightLine.id),
      }))
      .sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));
  }

  get getMandatoryNoSightLines(): NoSightLine[] {
    return this.noSightLines.filter(x => this.latchDefaults.noSights.find(y => y.id === x.id)?.isMandatory);
  }

  get getDefaultNoSightLines(): NoSightLine[] {
    return this.noSightLines.filter(
      x =>
        this.latchDefaults.defaultsNoSight.some(y => y === x.id) ||
        this.latchDefaults.noSights.find(y => y.id === x.id)?.isMandatory
    );
  }

  get getOptions(): Option[] {
    return this.options;
  }

  get getAllowedOptions(): AllowedOption[] {
    if (!this.hardwareDefaults) {
      return [];
    }

    const mandatoryOptionIds = this.hardwareDefaults.options
      ?.filter(option => option.isMandatory)
      ?.map(option => option.id);

    return (
      this.options
        ?.filter(x => this.hardwareDefaults.options?.some(y => y.id === x.id))
        ?.map(option => ({ ...option, isMandatory: mandatoryOptionIds.includes(option.id) })) ?? []
    );
  }

  get getDefaultOptions(): Option[] {
    return this.options?.filter(
      x =>
        this.hardwareDefaults.defaultOptions?.some(y => y === x.id) ||
        this.hardwareDefaults.options?.find(y => y.id === x.id)?.isMandatory
    );
  }

  get getMandatoryOptions(): Option[] {
    return this.options.filter(x => this.hardwareDefaults.options?.find(y => y.id === x.id)?.isMandatory);
  }

  get getPartitions(): Partition[] {
    return this.partitions;
  }

  get getAllowedPartitions(): Partition[] {
    if (this.seriesDefaults) {
      return this.partitions.filter(x => this.seriesDefaults.partitions.some(y => y.id === x.id));
    }
    return [];
  }

  get getAllowedDoorHeights(): number[] {
    if (this.seriesDefaults) {
      return this.seriesDefaults.doorHeights.map(x => x.doorHeight);
    }
    return [];
  }

  get getDefaultDoorHeight(): number {
    if (this.seriesDefaults) {
      return this.seriesDefaults?.defaultDoorHeight;
    }
    return 0;
  }

  get getDefaultPartitions(): Partition | undefined {
    for (const partition of this.partitions) {
      if (partition.id === this.seriesDefaults?.defaultPartition) {
        return partition;
      }
    }
  }

  get getAllPartitionsDefaults(): PartitionDefault[] {
    return this.allPartitionsDefaults ?? [];
  }

  get getAllDoorHeightDefaults(): number[] {
    return this.allDoorHeightDefaults ? this.allDoorHeightDefaults.map(x => x.doorHeight) : [];
  }

  get getDefaultAffOptions(): AffOptions | undefined {
    if (this.partitionDefaults) {
      return this.affOptions.find(x => x.id === this.partitionDefaults.defaultAffOptions);
    }
  }

  get getAffOptions(): AffOptions[] {
    return this.affOptions;
  }

  get getAffOptionRespectingAdaStandards(): AffOptions | undefined {
    return this.affOptionRespectingAdaStandard;
  }

  get getFormattedAffOptionRespectingAdaStandards(): string {
    return getFormattedMeasurementValueForLabel(this.getAffOptionRespectingAdaStandards?.value ?? 9);
  }

  get getAllowedAffOptions(): AffOptions[] {
    if (!!this.partitionDefaults && !!this.partitionDefaults.affOptions) {
      return this.affOptions
        .filter(x => this.partitionDefaults.affOptions.some(y => y.id === x.id))
        .sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));
    }
    return [];
  }

  get getProjectSettingsDefaultImage(): string {
    return this.projectSettingsDefaultImage;
  }

  private get getMaterialDefaults(): MaterialDefault {
    return this.materialDefaults;
  }

  private get getMaterialIdForDefault(): string {
    return this.materialDefaults?.id;
  }

  private get getSeriesIdForDefault(): string {
    return this.seriesDefaults?.id;
  }

  private get getPartitionIdForDefault(): string {
    return this.partitionDefaults?.id;
  }

  private get getHardwareIdForDefault(): string {
    return this.hardwareDefaults?.id;
  }

  private get getHingeIdForDefault(): string {
    return this.hingeDefaults?.id;
  }

  private get getLatchIdForDefault(): string {
    return this.latchDefaults?.id;
  }

  private get getHingeDefaults(): HingeDefault {
    return this.hingeDefaults;
  }

  private get getHardwareDefaults(): HardwareDefault {
    return this.hardwareDefaults;
  }

  private get getSeriesDefaults(): SeriesDefault {
    return this.seriesDefaults;
  }

  get getLatches(): Latch[] {
    return this.latches;
  }

  get getAllowedLatches(): Latch[] {
    if (this.hingeDefaults) {
      return this.latches.filter(x => this.hingeDefaults.latches.find(y => y.id === x.id));
    }
    return [];
  }

  get getDefaultLatch(): Latch | undefined {
    for (const latch of this.latches) {
      if (latch.id === this.hingeDefaults.defaultLatch) {
        return latch;
      }
    }
  }

  get getPartFinish() {
    return (finishId: string) => {
      return this.finishTypes.flatMap(x => x.finishes).find(o => o.id === finishId);
    };
  }

  get lockersMaterial() {
    return this.materials.find(x => x.category === MaterialCategories.Lockers);
  }

  get dryersMaterial() {
    return this.materials.find(x => x.category === MaterialCategories.Dryers);
  }

  @mutation private setProjectSettingsStaticData(data: ProjectSettingsStaticData) {
    this.materials = data.materials;
    this.series = data.series;
    this.doorHinges = data.doorHinges;
    this.partitions = data.partitions;
    this.hardwareMaterials = data.hardwareMaterials;
    this.latches = data.latches;
    this.finishTypes = data.finishTypes;
    this.noSightLines = data.noSightLines;
    this.options = data.options;
    this.affOptions = data.affOptions;
    this.affOptionRespectingAdaStandard = data.affOptionRespectingAdaStandards;
    this.defaultMaterial = data.defaultMaterialId;
    this.projectSettingsDefaultImage = data.defaultImage;
  }

  @mutation private setMaterialDefaults(defaults: MaterialDefault) {
    this.materialDefaults = defaults;
    if (this.seriesDefaults) {
      this.seriesDefaults.id = '';
    }
    if (this.partitionDefaults) {
      this.partitionDefaults.id = '';
    }
    if (this.hardwareDefaults) {
      this.hardwareDefaults.id = '';
    }
    if (this.hingeDefaults) {
      this.hingeDefaults.id = '';
    }
    if (this.latchDefaults) {
      this.latchDefaults.id = '';
    }
  }

  @mutation private setSeriesDefaults(defaults: SeriesDefault) {
    this.seriesDefaults = defaults;
    if (this.partitionDefaults) {
      this.partitionDefaults.id = '';
    }
    if (this.hardwareDefaults) {
      this.hardwareDefaults.id = '';
    }
    if (this.hingeDefaults) {
      this.hingeDefaults.id = '';
    }
    if (this.latchDefaults) {
      this.latchDefaults.id = '';
    }
  }

  @mutation private setPartitionDefaults(defaults: PartitionDefault) {
    this.partitionDefaults = defaults;
    if (this.hardwareDefaults) {
      this.hardwareDefaults.id = '';
    }
    if (this.hingeDefaults) {
      this.hingeDefaults.id = '';
    }
  }

  @mutation private setAllPartitionsDefaults(allDefaults: PartitionDefault[]) {
    this.allPartitionsDefaults = allDefaults;
    if (this.hardwareDefaults) {
      this.hardwareDefaults.id = '';
    }
    if (this.hingeDefaults) {
      this.hingeDefaults.id = '';
    }
    if (this.latchDefaults) {
      this.latchDefaults.id = '';
    }
  }

  @mutation private setAllDoorHeightDefaults(allDefaults: DoorHeightDefault[]) {
    this.allDoorHeightDefaults = allDefaults;
  }

  @mutation private setHardwareDefaults(defaults: HardwareDefault) {
    this.hardwareDefaults = defaults;
    if (this.hingeDefaults) {
      this.hingeDefaults.id = '';
    }
    if (this.latchDefaults) {
      this.latchDefaults.id = '';
    }
  }

  @mutation private setHingeDefaults(defaults: HingeDefault) {
    this.hingeDefaults = defaults;
  }

  @mutation private setLatchDefaults(defaults: LatchDefault) {
    this.latchDefaults = defaults;
  }
}
