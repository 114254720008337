import ResourceCenterService from '@/endpoints/resourceCenterService';
import {
  ResourceCenterChangeDisplayOrderRequest,
  ResourceCenterFileDeleteRequest,
  ResourceCenterFileUploadRequest,
} from '@/models/resourceCenter/resourceCenterFileRequests';
import ResourceCenterObject from '@/models/resourceCenter/resourceCenterObject';
import { action, Module, VuexModule } from 'vuex-class-component';

@Module()
export class ResourceCenterModule extends VuexModule {
  @action({ mode: 'mutate' })
  public async fetchAllFiles(): Promise<ResourceCenterObject[]> {
    return await ResourceCenterService.getAllFiles();
  }

  @action({ mode: 'mutate' })
  public async uploadFile(uploadFileRequest: ResourceCenterFileUploadRequest): Promise<ResourceCenterObject[]> {
    return await ResourceCenterService.uploadFile(uploadFileRequest);
  }

  @action({ mode: 'mutate' })
  public async deleteFile(deleteFileRequest: ResourceCenterFileDeleteRequest): Promise<ResourceCenterObject[]> {
    return await ResourceCenterService.deleteFile(deleteFileRequest);
  }

  @action({ mode: 'mutate' })
  public async changeDisplayOrder(
    changeDisplayorder: ResourceCenterChangeDisplayOrderRequest
  ): Promise<ResourceCenterObject[]> {
    return await ResourceCenterService.changeDisplayOrder(changeDisplayorder);
  }
}
