import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "resource-center-wrapper" }
const _hoisted_2 = { class: "resource-center__notice" }
const _hoisted_3 = {
  key: 0,
  class: "resource-links"
}
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_hadrianModal = _resolveComponent("hadrianModal")!

  return (_openBlock(), _createBlock(_component_hadrianModal, {
    class: "resource-center__modal",
    ref: "resourceCenterModal",
    title: _ctx.$t('resourceCenter.title'),
    hideHeaderClose: true,
    hideLeftButton: true,
    rightButtonClass: "resource-center__modal-right-button",
    rightButtonText: _ctx.$t('resourceCenter.close'),
    variant: "thin-2"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('resourceCenter.notice')), 1),
        (!!_ctx.allFiles && _ctx.allFiles.length > 0)
          ? (_openBlock(), _createElementBlock("ol", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allFiles, (file) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: file.key
                }, [
                  _createElementVNode("a", {
                    href: file.url,
                    target: "_blank"
                  }, _toDisplayString(file.key.replace(/.pdf/i, '')), 9, _hoisted_4)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ])
    ], undefined, true),
    _: 1
  }, 8, ["title", "rightButtonText"]))
}