import { HadrianUpdatesData, HadrianUpdateType } from '@/shared/viewParts/hadrianUpdates/hadrianUpdates';
import Axios from 'axios';
import HttpService from './HttpService';

export interface AppVersion {
  timestamp: string;
}
const usersUrl = `${process.env.VUE_APP_API_PREFIX}Updates`;

export default class UpdateService {
  public static async getFrontendVersion(): Promise<AppVersion> {
    // With our default Axios config, the backend url will be prepended to every
    // request. As we want a relative request, pointing towards the S3 bucket,
    // I'll create an empty config to make my request.
    const response = await Axios.create({
      headers: {
        'Cache-Control': 'no-store',
        Expires: '0',
      },
    }).get(`/app-version.json`);

    return await response.data;
  }

  public static async getUpdates(queryParameters: HadrianUpdateType) {
    const response = await HttpService.get(usersUrl + `/${queryParameters}`);
    return response.data.entities;
  }

  public static async saveUserDismiss(queryParameters: HadrianUpdateType) {
    const response = await HttpService.post(usersUrl + `/${queryParameters}`);
  }
}
