import DistributorsService from '@/endpoints/distributorsService';
import LockerRoomModes from '@/enums/lockerRoomModes';
import PermissionTypes from '@/enums/permissionTypes';
import { MiniProfilerHelper } from '@/helpers/miniProfilerHelper';
import PermissionsHelper from '@/helpers/permissionsHelper';
import * as RoutingHelper from '@/helpers/routingHelper';
import Toast from '@/helpers/toast';
import { UpdateHelper } from '@/helpers/updateHelper';
import urlBuilder from '@/helpers/urlBuilder';
import i18n from '@/i18n';
import paths from '@/router/paths';
import { PersistentDataKey } from '@/store/persistentDataModule/persistentDataModule';
import { vxManager } from '@/store/store';
import Login from '@/views/authentication/login/login.vue';
import { debounce } from 'lodash';
import { createRouter, createWebHistory, NavigationGuard } from 'vue-router';
import newRelicService from '@/services/newRelic';

const ifNotAuthenticated: NavigationGuard = async (_to, _from, next) => {
  if (!vxManager.userModule.getStorageContainsToken) {
    next();
    return;
  }
  next(RoutingHelper.userHomePage());
};

const ifAuthenticated: NavigationGuard = (to, _from, next) => {
  if (!vxManager.userModule.getStorageContainsToken) {
    let url = '/';
    if (to) {
      url = urlBuilder(paths.login, { redirect: to.fullPath });
    }
    next(url);
    return;
  }

  next();
};

const canAccessResourceAdmin: NavigationGuard = (to, _from, next) => {
  if (!vxManager.userModule.getStorageContainsToken) {
    let url = '/';
    if (to) {
      url = urlBuilder(paths.login, { redirect: to.fullPath });
    }
    next(url);
    return;
  }

  if (!vxManager.userModule.hasPermission(PermissionTypes.ResourceCenterAdmin)) {
    next(paths.projects);
    return;
  }

  next();
};

const onlyInDev: NavigationGuard = async (_to, _from, next) => {
  const serverUrl = vxManager.appStateModule.getServerUrl;

  if (serverUrl.includes('api-dev') || serverUrl.includes('localhost')) {
    next();
  } else {
    const organizations = await DistributorsService.getAllOrganizations();
    next(RoutingHelper.userHomePage(organizations));
    Toast.error({ body: i18n.global.t('errors.redirectToHomepage') });
  }
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
  routes: [
    {
      path: paths.networkError,
      name: 'networkError',
      component: () => import('@/views/networkError/networkError.vue'),
    },
    {
      path: paths.login,
      name: 'login',
      component: Login,
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: paths.loginEmailAutoFill,
      name: 'loginEmailAutoFill',
      component: () => import('@/views/authentication/login/login.vue'),
      beforeEnter: ifNotAuthenticated,
      props: true,
    },
    {
      path: paths.loginAutoFill,
      name: 'loginAutoFill',
      component: () => import('@/views/authentication/login/login.vue'),
      beforeEnter: ifNotAuthenticated,
      props: true,
    },
    {
      path: paths.forgotPassword,
      name: 'forgotPassword',
      component: () => import('@/views/authentication/forgotPassword/forgotPassword.vue'),
    },
    {
      path: paths.confirmForgotPassword,
      name: 'confirmForgotPassword',
      component: () => import('@/views/authentication/confirmForgotPassword/confirmForgotPassword.vue'),
    },
    {
      path: paths.changePassword,
      name: 'changePassword',
      component: () => import('@/views/authentication/changePassword/changePassword.vue'),
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: paths.changePasswordEmailAutoFill,
      name: 'changePasswordEmailAutoFill',
      component: () => import('@/views/authentication/changePassword/changePassword.vue'),
      beforeEnter: ifNotAuthenticated,
      props: true,
    },
    {
      path: paths.setUpAccount,
      name: 'setUpAccount',
      component: () => import('@/views/authentication/changePassword/changePassword.vue'),
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: paths.setUpAccountEmailAutoFill,
      name: 'setUpAccountEmailAutoFill',
      component: () => import('@/views/authentication/changePassword/changePassword.vue'),
      beforeEnter: ifNotAuthenticated,
      props: true,
    },
    {
      path: paths.forgotPasswordEmailAutoFill,
      name: 'forgotPasswordEmailAutoFill',
      component: () => import('@/views/authentication/forgotPassword/forgotPassword.vue'),
      props: true,
    },
    {
      path: paths.confirmForgotPasswordEmailAutoFill,
      name: 'confirmForgotPasswordEmailAutoFill',
      component: () => import('@/views/authentication/confirmForgotPassword/confirmForgotPassword.vue'),
      props: true,
    },
    {
      path: paths.unauthenticatedTermsConditions,
      name: 'unauthenticatedTermsConditions',
      component: () => import('@/views/unauthenticatedTermsConditions/unauthenticatedTermsConditions.vue'),
    },
    {
      path: paths.unauthenticatedLegalNotice,
      name: 'unauthenticatedLegalNotice',
      component: () => import('@/views/unauthenticatedLegalNotice/unauthenticatedLegalNotice.vue'),
    },
    {
      path: paths.unauthenticatedPrivacyPolicy,
      name: 'unauthenticatedPrivacyPolicy',
      component: () => import('@/views/unauthenticatedPrivacyPolicy/unauthenticatedPrivacyPolicy.vue'),
    },
    {
      path: paths.createRoom,
      name: 'Create room',
      component: () => import('@/views/rooms/createRoom/createRoom.vue'),
      beforeEnter: ifAuthenticated,
      props: route => ({
        stepFromUrl: parseInt(route.params.step as string, 10),
        projectId: (route.params.projectId as string).toLowerCase(),
      }),
    },
    {
      path: paths.createLockerRoom,
      name: 'Create locker room',
      component: () => import('@/views/lockers/lockerRoomCreation/lockerRoomCreation.vue'),
      beforeEnter: ifAuthenticated,
      props: route => ({
        stepFromUrl: parseInt(route.params.step as string, 10),
        projectId: (route.params.projectId as string).toLowerCase(),
        mode: LockerRoomModes.Create,
      }),
    },
    {
      path: paths.addStyleToRoom,
      name: 'Add style to room',
      component: () => import('@/views/lockers/lockerRoomCreation/lockerRoomCreation.vue'),
      beforeEnter: ifAuthenticated,
      props: route => ({
        stepFromUrl: parseInt(route.params.step as string, 10),
        projectId: (route.params.projectId as string).toLowerCase(),
        roomId: (route.params.roomId as string).toLowerCase(),
        mode: LockerRoomModes.AddStyle,
      }),
    },
    {
      path: paths.editLockerRoomConfiguration,
      name: 'Edit a locker room configuration',
      component: () => import('@/views/lockers/lockerRoomCreation/lockerRoomCreation.vue'),
      beforeEnter: ifAuthenticated,
      props: route => ({
        stepFromUrl: parseInt(route.params.step as string, 10),
        projectId: (route.params.projectId as string).toLowerCase(),
        roomId: (route.params.roomId as string).toLowerCase(),
        configurationId: parseInt(route.params.configurationId as string, 10),
        mode: LockerRoomModes.Edit,
      }),
    },
    {
      path: paths.createProject,
      name: 'Create project',
      component: () => import('@/views/projects/createProject/createProject.vue'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: `${paths.createProject}/:initialStep`,
      name: 'Create project step',
      component: () => import('@/views/projects/createProject/createProject.vue'),
      beforeEnter: ifAuthenticated,
      props: route => ({ initialStep: parseInt(route.params.initialStep as string, 10) }),
    },
    {
      path: paths.createLockerStyle,
      name: 'Create locker style',
      component: () => import('@/views/projects/createProject/createProject.vue'),
      beforeEnter: ifAuthenticated,
      props: route => ({ projectId: (route.params.projectId as string).toLowerCase() }),
    },
    {
      path: `${paths.createLockerStyle}/:initialStep`,
      name: 'Create locker style step',
      component: () => import('@/views/projects/createProject/createProject.vue'),
      beforeEnter: ifAuthenticated,
      props: route => ({
        projectId: (route.params.projectId as string).toLowerCase(),
        initialStep: parseInt(route.params.initialStep as string, 10),
      }),
    },
    {
      path: paths.editLockerStyle,
      name: 'Edit locker style',
      component: () => import('@/views/projects/createProject/createProject.vue'),
      beforeEnter: ifAuthenticated,
      props: route => ({
        projectId: (route.params.projectId as string).toLowerCase(),
        lockerStyleId: (route.params.lockerStyleId as string).toLowerCase(),
      }),
    },
    {
      path: `${paths.editLockerStyle}/:initialStep`,
      name: 'Edit locker style step',
      component: () => import('@/views/projects/createProject/createProject.vue'),
      beforeEnter: ifAuthenticated,
      props: route => ({
        projectId: (route.params.projectId as string).toLowerCase(),
        lockerStyleId: (route.params.lockerStyleId as string).toLowerCase(),
        initialStep: parseInt(route.params.initialStep as string, 10),
      }),
    },
    {
      path: paths.editRoom,
      name: 'Edit room',
      component: () => import('@/views/rooms/createRoom/editRoom/editRoom.vue'),
      beforeEnter: ifAuthenticated,
      props: route => ({
        projectId: (route.params.projectId as string).toLowerCase(),
        roomId: (route.params.roomId as string).toLowerCase(),
      }),
    },
    {
      path: paths.unauthenticatedRoomDesigner,
      name: 'Get room drawing',
      component: () => import('@/views/rooms/roomDesigner/roomDesignerNoAuth.vue'),
      props: route => ({
        projectId: (route.params.projectId as string).toLowerCase(),
        roomId: (route.params.roomId as string).toLowerCase(),
      }),
    },
    {
      path: paths.home,
      name: 'home',
      component: () => import('@/views/projects/projectsList/projectsList.vue'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: paths.profile,
      name: 'profile',
      redirect: paths.userSettings,
      beforeEnter: ifAuthenticated,
    },
    {
      path: paths.articles,
      name: 'articles',
      component: () => import('@/views/activity/articlesList/articlesList.vue'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: paths.releaseNotes,
      name: 'releaseNotes',
      component: () => import('@/views/activity/releaseNotesList/releaseNotesList.vue'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: paths.expediteRequests,
      name: 'expediteRequests',
      component: () => import('@/views/activity/expediteRequests/expediteRequests.vue'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: paths.releaseNote,
      name: 'releaseNote',
      component: () => import('@/views/releaseNote/releaseNote.vue'),
      beforeEnter: ifAuthenticated,
      props: true,
    },
    {
      path: paths.projects,
      name: 'projectsList',
      component: () => import('@/views/projects/projectsList/projectsList.vue'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: paths.projectDetails,
      name: 'projectDetails',
      component: () => import('@/views/projects/projectDetails/projectDetails.vue'),
      beforeEnter: ifAuthenticated,
      props: route => ({
        projectId: (route.params.projectId as string).toLowerCase(),
      }),
    },
    {
      path: paths.projectDetailsTab,
      name: 'projectDetailsTab',
      component: () => import('@/views/projects/projectDetails/projectDetails.vue'),
      beforeEnter: ifAuthenticated,
      props: route => ({
        projectId: (route.params.projectId as string).toLowerCase(),
        openTab: route.params.openTab,
      }),
    },
    {
      path: paths.quoteCreation,
      name: 'quoteCreation',
      component: () => import('@/views/quoteCreation/quoteCreation.vue'),
      beforeEnter: ifAuthenticated,
      props: route => ({
        projectId: (route.params.projectId as string).toLowerCase(),
        quoteStep: parseInt(route.params.quoteStep as string, 10),
      }),
    },
    {
      path: paths.quoteCreationAddressStep,
      name: 'quoteCreationAddressStep',
      component: () => import('@/views/quoteCreation/quoteCreation.vue'),
      beforeEnter: ifAuthenticated,
      props: route => ({
        projectId: (route.params.projectId as string).toLowerCase(),
        quoteStep: 2,
        addressStepView: route.params.view,
      }),
    },
    {
      path: paths.orderProject,
      name: 'Order project',
      component: () => import('@/views/orders/orderProject/orderProject.vue'),
      beforeEnter: ifAuthenticated,
      props: route => ({
        projectId: (route.params.projectId as string).toLowerCase(),
        orderStep: parseInt(route.params.orderStep as string, 10),
      }),
    },
    {
      path: paths.orderProjectAddressStep,
      name: 'orderProjectAddressStep',
      component: () => import('@/views/orders/orderProject/orderProject.vue'),
      beforeEnter: ifAuthenticated,
      props: route => ({
        projectId: (route.params.projectId as string).toLowerCase(),
        orderStep: 2,
        addressStepView: route.params.view,
      }),
    },
    {
      path: paths.orderPartial,
      name: 'Order partial',
      component: () => import('@/views/orders/orderProject/orderProject.vue'),
      beforeEnter: ifAuthenticated,
      props: route => ({
        projectId: (route.params.projectId as string).toLowerCase(),
        orderId: (route.params.orderId as string).toLowerCase(),
        orderStep: parseInt(route.params.orderStep as string, 10),
      }),
    },
    {
      path: paths.orders,
      name: 'orders',
      component: () => import('@/views/orders/orders.vue'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: paths.organizations,
      name: 'organizations',
      component: () => import('@/views/organizations/organizations.vue'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: paths.organization,
      name: 'organization',
      component: () => import('@/views/organization/organization.vue'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: paths.organizationGeneralPreferences,
      name: 'organizationGeneralPreferences',
      component: () => import('@/views/organization/organization.vue'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: paths.users,
      name: 'users',
      component: () => import('@/views/users/users.vue'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: paths.userDetails,
      name: 'userDetails',
      component: () => import('@/views/userDetails/userDetails.vue'),
      beforeEnter: ifAuthenticated,
      props: route => ({
        userId: (route.params.userId as string).toLowerCase(),
      }),
    },
    {
      path: paths.createUser,
      name: 'Create user',
      component: () => import('@/views/createUser/createUser.vue'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: paths.news,
      name: 'news',
      component: () => import('@/views/news/news.vue'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: paths.contactUs,
      name: 'Contact us',
      component: () => import('@/views/contactUs/contactUs.vue'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: paths.termsConditions,
      name: 'Terms and Conditions',
      component: () => import('@/views/termsConditions/termsConditions.vue'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: paths.legalNotice,
      name: 'Legal notice',
      component: () => import('@/views/legalNotice/legalNotice.vue'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: paths.privacyPolicy,
      name: 'Privacy Policy',
      component: () => import('@/views/privacyPolicy/privacyPolicy.vue'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: paths.userSettings,
      name: 'User settings',
      component: () => import('@/views/userSettings/userSettings.vue'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: paths.userSettingsPersonalInfos,
      name: 'User settings personal infos',
      component: () => import('@/views/userSettings/userSettings.vue'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: paths.userSettingsPartitionPreferences,
      name: 'User settings partitions preferences',
      component: () => import('@/views/userSettings/userSettings.vue'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: paths.pdfLoader,
      name: 'PDF Loader',
      component: () => import('@/views/pdfLoader/pdfLoader.vue'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: paths.resourceAdmin,
      name: 'Resource Admin',
      component: () => import('@/views/resourceAdmin/resourceAdmin.vue'),
      beforeEnter: canAccessResourceAdmin,
    },
    {
      path: paths.icons,
      name: 'Icons',
      component: () => import('@/views/icons/icons.vue'),
      beforeEnter: onlyInDev,
    },
    {
      path: paths.components,
      name: 'Components',
      component: () => import('@/views/components/components.vue'),
      beforeEnter: onlyInDev,
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'Not Found',
      redirect: { name: 'projectsList' },
      beforeEnter: ifAuthenticated,
    },
    {
      path: paths.releaseNotePreview,
      name: 'release_note_preview',
      component: () => import('@/views/releaseNotePreview/releaseNotePreview.vue'),
      beforeEnter: ifAuthenticated,
      props: route => ({
        id: parseInt(route.params.id as string, 10),
      }),
    },
    {
      path: paths.article,
      name: 'article',
      component: () => import('@/views/article/article.vue'),
      beforeEnter: ifAuthenticated,
      props: route => ({
        id: parseInt(route.params.id as string, 10),
      }),
    },
    {
      path: paths.articlePreview,
      name: 'article_preview',
      component: () => import('@/views/article/article.vue'),
      beforeEnter: ifAuthenticated,
      props: route => ({
        id: parseInt(route.params.id as string, 10),
        isPreview: true,
      }),
    },
  ],
});

router.onError(err => {
  if (err.name === 'ChunkLoadError') {
    UpdateHelper.showUpdateModal();
  }
});

const isProfilingEnabled =
  process.env.VUE_APP_PROFILING_ENABLED_FOR_GOD_EMPERORS === 'true' || process.env.VUE_APP_PROFILING_ENABLED === 'true';

if (isProfilingEnabled) {
  router.afterEach(() => {
    MiniProfilerHelper.clearData();
  });
}

// This is call after hooks, it cannot change the navigation so it is safe to redirect here without altering the history
router.afterEach((to, _from) => {
  const path = vxManager.persistentDataModule.getDataById<string>(PersistentDataKey.REDIRECTION);
  if (path) {
    vxManager.persistentDataModule.clearData(PersistentDataKey.REDIRECTION, true);
    router.push(path);
  }
  newRelicService.pageView(to);
  if (
    !vxManager.userModule.userPermissionsFinishedLoading &&
    process.env.VUE_APP_ORGANIZATIONS_ROUTER_HOOK_ENABLED === 'false'
  ) {
    PermissionsHelper.addToQueue(() => debouncedRedirectUserIfAccessForbidden(path || to.path));
    return;
  }

  debouncedRedirectUserIfAccessForbidden(path || to.path);
});

export async function redirectUserToHomePageWithNotification() {
  const organizations = await DistributorsService.getAllOrganizations();
  router.push(RoutingHelper.userHomePage(organizations));
  Toast.error({ body: i18n.global.t('errors.redirectToHomepage') });
}

const debouncedRedirectUserIfAccessForbidden = debounce(redirectUserIfAccessForbidden, 500);

async function redirectUserIfAccessForbidden(to?: string) {
  if (to != null && !(await RoutingHelper.userCanAccessRoute(to))) {
    redirectUserToHomePageWithNotification();
  }
}

export default router;
