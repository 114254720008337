import iconHelp from '@/shared/icons/help/help.vue';
import hadrianTooltip from '@/shared/viewParts/hadrianTooltip/hadrianTooltip.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'hadrianLabel',
  props: {
    text: {
      type: String,
      required: true,
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
    htmlFor: {
      type: String,
    },
    noMargin: {
      type: Boolean,
      default: false,
    },
    helpTooltip: {
      type: String,
    },
  },
  components: {
    iconHelp,
    hadrianTooltip,
  },
});
