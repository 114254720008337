import { PANEL_THICKNESS, PILASTER_THICKNESS } from '@/helpers/drawing/DrawingConstants';
import RdPanelPilaster from './rdPanelPilaster';
import RdPilaster from './rdPilaster';
import RdRoomUnit from './rdRoomUnit';
import RdWallPanel from './rdWallPanel';
import RdWallPilaster from './rdWallPilaster';

export default class RdDragRoomUnit {
  static PostAdjusment = 1.625;
  static PostWidthAndLength = 1.25;
  static WallMountPilasterToDoorGap = 0.75;
  static units: RdRoomUnit[];

  public static extensionPilasterDrawingWidth(pilaster: RdPilaster, pilasterThickness: number) {
    return pilaster.isInline ? pilaster.width : pilasterThickness;
  }

  public static extensionTdPilasterDrawingWidth(pilaster?: RdWallPilaster) {
    return pilaster ? pilaster.width + pilaster.wallGap : 0;
  }

  public static precedingUnit(unit: RdRoomUnit): RdRoomUnit | undefined {
    if (unit.index === 0) {
      return undefined;
    }

    return RdDragRoomUnit.units[unit.index - 1];
  }

  public static followingUnit(unit: RdRoomUnit): RdRoomUnit | undefined {
    if (unit.index === RdDragRoomUnit.units.length - 1) {
      return undefined;
    }

    return RdDragRoomUnit.units[unit.index + 1];
  }

  public static panelPosX(unit: RdRoomUnit, panel: RdWallPanel): number {
    const preceding = RdDragRoomUnit.precedingUnit(unit);
    if (!!unit.alcoveParts && unit.isNextToRightWall) {
      if (preceding?.leftPanel) {
        return preceding.leftPanel.positionX + preceding.width;
      }
      if (preceding?.wallPilaster) {
        return (
          preceding.wallPilaster.positionX -
          preceding.wallPilaster.wallGap -
          preceding.wallPilaster.obstructionDepth +
          preceding.width
        );
      }
      if (preceding) {
        const prevPrev = RdDragRoomUnit.precedingUnit(preceding);
        if (prevPrev?.panel) {
          return prevPrev.panel.positionX + preceding.width;
        }
      }
      if (!!preceding?.extraWideExtensions && !preceding.extraWideExtensions.isRight) {
        return (
          preceding.extraWideExtensions.panel.positionX -
          (preceding.extraWideExtensions.obstructionDepth ?? 0) -
          preceding.extraWideExtensions.panel.leftGap +
          preceding.width
        );
      }
      if (preceding) {
        const precedingpreceding = RdDragRoomUnit.precedingUnit(preceding);
        if (precedingpreceding?.panel) {
          return precedingpreceding.panel.positionX + preceding.width;
        }
      }
    }

    if (panel === unit.leftPanel) {
      return 0;
    }

    if (preceding) {
      return RdDragRoomUnit.panelPosX(preceding, panel) + unit.width;
    }
    return unit.width;
  }

  public static panelPilasterPosX(unit: RdRoomUnit, pilaster: RdPanelPilaster): number {
    if (unit.leftPanel?.panelPilaster === pilaster) {
      return (
        RdDragRoomUnit.panelPosX(unit, unit.leftPanel as RdWallPanel) -
        ((unit.leftPanel?.panelPilaster?.width ?? 0) - (unit.leftPanel?.panelPilaster?.offset ?? 0))
      );
    }

    return (
      RdDragRoomUnit.panelPosX(unit, unit.panel as RdWallPanel) -
      ((unit.panel?.panelPilaster?.width ?? 0) - (unit.panel?.panelPilaster?.offset ?? 0))
    );
  }

  public static doorPosX(unit: RdRoomUnit, pilasterThickness: number): number {
    if (unit.alcoveParts) {
      return unit.panel?.positionX ?? unit.leftPanel?.positionX ?? 0;
    }

    // #Standard horizontal doors
    if (!!unit.extraWideExtensions && !unit.extraWideExtensions.isRight) {
      return (
        unit.extraWideExtensions.pilaster.positionX +
        RdDragRoomUnit.extensionPilasterDrawingWidth(unit.extraWideExtensions.pilaster, pilasterThickness) -
        (unit.extraWideExtensions.pilaster.isInline ? 0 : pilasterThickness * 0.5) +
        RdDragRoomUnit.extensionTdPilasterDrawingWidth(unit.extraWideExtensions.tdPilaster)
      );
    }

    if (unit.wallPilaster) {
      return unit.door.positionX;
    }

    if (unit.leftPanel) {
      return unit.leftPanel.panelPilaster.positionX + unit.leftPanel.panelPilaster.width;
    }

    const preceding = RdDragRoomUnit.precedingUnit(unit);

    if (!preceding) {
      return unit.door.width;
    }

    if (preceding.panel?.rightSidePilaster) {
      return preceding.panel.rightSidePilaster.positionX + preceding.panel.rightSidePilaster.width;
    }

    if (preceding.extendedDepthExtensions) {
      return preceding.extendedDepthExtensions.pilaster.positionX + preceding.extendedDepthExtensions.pilaster.width;
    }

    if (preceding.alcoveParts?.splitPilaster) {
      return preceding.alcoveParts.splitPilaster.positionX + preceding.alcoveParts.splitPilaster.width;
    }
    return (preceding.panel?.panelPilaster.positionX ?? 0) + (preceding.panel?.panelPilaster?.width ?? 0);
  }

  public static rightWallPilasterPosX(unit: RdRoomUnit): number {
    const pilasterThickness = PILASTER_THICKNESS();
    const panelThickness = PANEL_THICKNESS();

    const drawingWidth = unit.rightWallPilaster?.isWallMounted ? pilasterThickness : unit.rightWallPilaster?.width ?? 0;
    const preceding = RdDragRoomUnit.precedingUnit(unit);

    if (!unit.rightWallPilaster) {
      return 0;
    }
    let alcoveOffset = 0;
    if (RdDragRoomUnit.followingUnit(unit)) {
      alcoveOffset = unit.rightWallPilaster.isHingedOnAlcovePilaster ? pilasterThickness / 2 : panelThickness / 2;
    }

    if (unit.leftPanel) {
      return (
        unit.leftPanel.positionX +
        unit.width -
        drawingWidth -
        unit.rightWallPilaster.wallGap -
        alcoveOffset -
        unit.rightWallPilaster.obstructionDepth
      );
    }
    if (!!unit.wallPilaster && !preceding) {
      return (
        unit.wallPilaster.positionX -
        unit.wallPilaster.wallGap +
        unit.width -
        drawingWidth -
        unit.rightWallPilaster.wallGap -
        alcoveOffset -
        unit.wallPilaster.obstructionDepth -
        unit.rightWallPilaster.obstructionDepth
      );
    }

    return (
      (preceding?.panel?.positionX ?? 0) +
      unit.width -
      drawingWidth -
      unit.rightWallPilaster.wallGap -
      alcoveOffset -
      unit.rightWallPilaster.obstructionDepth
    );
  }

  public static extraWidePanelPosX(unit: RdRoomUnit, pilasterThickness: number) {
    const preceding = RdDragRoomUnit.precedingUnit(unit);
    if (!unit.extraWideExtensions) {
      return 0;
    }

    if (unit.alcoveParts) {
      return unit.door.positionX;
    }

    if (unit.extraWideExtensions.isRight) {
      return (
        unit.door.positionX +
        unit.door.width +
        unit.extraWideExtensions.panel.leftGap +
        RdDragRoomUnit.extensionPilasterDrawingWidth(unit.extraWideExtensions.pilaster, pilasterThickness) +
        RdDragRoomUnit.extensionTdPilasterDrawingWidth(unit.extraWideExtensions.tdPilaster)
      );
    } else if (unit.isNextToLeftWall) {
      if (!!preceding && !!unit.wallPilaster) {
        return unit.wallPilaster.positionX + unit.wallPilaster.width + unit.extraWideExtensions.panel.leftGap;
      }
      return (unit.extraWideExtensions.obstructionDepth ?? 0) + unit.extraWideExtensions.panel.leftGap;
    }

    if (unit.wallPilaster) {
      if (!unit.wallPilaster.isInlineMounted) {
        if (unit.wallPilaster.isPost) {
          return (
            unit.wallPilaster.positionX -
            RdDragRoomUnit.PostAdjusment +
            RdDragRoomUnit.PostWidthAndLength +
            unit.extraWideExtensions.panel.leftGap
          );
        } else {
          return (
            unit.wallPilaster.positionX +
            RdDragRoomUnit.WallMountPilasterToDoorGap +
            pilasterThickness +
            unit.extraWideExtensions.panel.leftGap
          );
        }
      }
      return unit.wallPilaster.positionX + unit.wallPilaster.width + unit.extraWideExtensions.panel.leftGap;
    }

    if (preceding?.panel?.rightSidePilaster) {
      return (
        preceding.panel.rightSidePilaster.positionX +
        preceding.panel.rightSidePilaster.width +
        unit.extraWideExtensions.panel.leftGap
      );
    }

    if (unit.leftPanel) {
      return (
        unit.leftPanel.panelPilaster.positionX +
        unit.leftPanel.panelPilaster.width +
        unit.extraWideExtensions.panel.leftGap
      );
    }

    if (preceding?.extendedDepthExtensions) {
      return (
        preceding.extendedDepthExtensions.pilaster.positionX +
        preceding.extendedDepthExtensions.pilaster.width +
        unit.extraWideExtensions.panel.leftGap
      );
    }

    if (preceding?.alcoveParts?.splitPilaster) {
      return (
        preceding?.alcoveParts?.splitPilaster.positionX +
        preceding?.alcoveParts?.splitPilaster.width +
        unit.extraWideExtensions.panel.leftGap
      );
    }

    return (
      (preceding?.panel?.panelPilaster.positionX ?? 0) +
      (preceding?.panel?.panelPilaster.width ?? 0) +
      unit.extraWideExtensions.panel.leftGap
    );
  }

  public static extraWidePilasterPosX(unit: RdRoomUnit, pilasterThickness: number) {
    if (!unit.extraWideExtensions) {
      return 0;
    }

    if (unit.alcoveParts) {
      return unit.door.positionX;
    }

    if (unit.extraWideExtensions?.isRight) {
      return (
        unit.extraWideExtensions.panel.positionX -
        unit.extraWideExtensions.panel.leftGap -
        RdDragRoomUnit.extensionPilasterDrawingWidth(unit.extraWideExtensions.pilaster, pilasterThickness) +
        (unit.extraWideExtensions.pilaster.isInline ? 0 : pilasterThickness * 0.5)
      );
    }

    return (
      unit.extraWideExtensions.panel.positionX +
      unit.extraWideExtensions.panel.rightGap +
      unit.extraWideExtensions.panel.width +
      (unit.extraWideExtensions.pilaster.isInline ? 0 : pilasterThickness * 0.5)
    );
  }

  public static extendedDepthPilasterPosX(unit: RdRoomUnit) {
    if (!unit.extendedDepthExtensions) {
      return 0;
    }

    if (unit.isNextToLeftWall) {
      return (unit.panel?.positionX ?? 0) - unit.extendedDepthExtensions.pilaster.offset;
    }

    const preceding = RdDragRoomUnit.precedingUnit(unit);
    return (
      (preceding?.panel?.positionX ?? 0) -
      (unit.extendedDepthExtensions.pilaster.width - unit.extendedDepthExtensions.pilaster.offset)
    );
  }

  public static extendedDepthPanelPosX(unit: RdRoomUnit): number {
    if (!unit.extendedDepthExtensions) {
      return 0;
    }

    const preceding = RdDragRoomUnit.precedingUnit(unit);
    if (unit.isNextToLeftWall) {
      return unit.panel?.positionX ?? 0;
    }
    return preceding?.panel?.positionX ?? 0;
  }

  public static frontWallPilasterPosX(unit: RdRoomUnit): number {
    if (!unit.alcoveParts) {
      return 0;
    }

    const panelX = unit.leftPanel?.positionX ?? unit.panel?.positionX ?? 0;
    if (unit.alcoveParts.frontWallPilaster?.isInlineMounted) {
      return panelX;
    } else if (unit.alcoveParts.frontWallPilaster?.isPost) {
      const inSwingMultiplier = (unit.door.isInSwing ? 1 : -1) * (unit.isNextToLeftWall ? 1 : -1);
      return panelX + RdDragRoomUnit.PostAdjusment * inSwingMultiplier;
    } else {
      const inSwingMultiplier = (unit.door.isInSwing ? 1 : -1) * (unit.isNextToLeftWall ? 1 : -1);
      const insideWallMount = inSwingMultiplier < 0 ? -5.5 : 0.5; // Pilaster Width + PilasterThickness
      return panelX + RdDragRoomUnit.WallMountPilasterToDoorGap * inSwingMultiplier + insideWallMount;
    }
  }

  public static splitPilastersPosX(unit: RdRoomUnit): number {
    if (!unit?.alcoveParts?.splitPilaster) {
      return 0;
    }
    return (
      ((unit.panel ?? unit.leftPanel)?.positionX ?? 0) -
      (unit.isNextToRightWall ? unit.alcoveParts.splitPilaster.width : 0) +
      (unit.isNextToRightWall ? unit.alcoveParts.splitPilaster.offset : -unit.alcoveParts.splitPilaster.offset)
    );
  }

  public static offUnitPilasterPosX(unit: RdRoomUnit) {
    return unit.leftPanel?.positionX ?? unit.panel?.positionX ?? 0;
  }

  public static frontPanelPilasterPosX(unit: RdRoomUnit) {
    return unit.leftPanel?.positionX ?? unit.panel?.positionX ?? 0;
  }

  public static frontPanelPosX(unit: RdRoomUnit) {
    if (!unit.alcoveParts?.frontPanel) {
      return 0;
    }

    if (unit.isNextToLeftWall) {
      return (unit.alcoveParts.frontPanel.obstructionDepth ?? 0) + unit.alcoveParts.frontPanel.wallGap;
    } else {
      return (unit.leftPanel?.positionX ?? 0) + PILASTER_THICKNESS() / 2 + unit.alcoveParts.frontPanel.pilasterGap;
    }
  }
}
