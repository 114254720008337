import PermissionTypes from '@/enums/permissionTypes';
import filteredSelector from '@/shared/viewParts/filteredSelector/filteredSelector.vue';
import { FilteredSelectorItem, IconColors, SearchMode } from '@/shared/viewParts/filteredSelector/filteredSelector';
import { vxManager } from '@/store/store';
import { defineComponent } from 'vue';
import i18n from '@/i18n';
import { OrderStatusTypes } from '../orderStatus/orderStatus';
import orderStatusColor from './orderStatusColor/orderStatusColor.vue';

export const ALL_ORDER_STATUS: FilteredSelectorItem[] = [
  {
    value: OrderStatusTypes.Ordered,
    label: i18n.global.t('orders.status.ordered'),
    icon: orderStatusColor,
    iconColor: IconColors.Green,
  },
  {
    value: OrderStatusTypes.Planning,
    label: i18n.global.t('orders.status.planning'),
    icon: orderStatusColor,
    iconColor: IconColors.Yellow,
  },
  {
    value: OrderStatusTypes.InProduction,
    label: i18n.global.t('orders.status.production'),
    icon: orderStatusColor,
    iconColor: IconColors.Pink,
  },
  {
    value: OrderStatusTypes.Ready,
    label: i18n.global.t('orders.status.ready'),
    icon: orderStatusColor,
    iconColor: IconColors.Turquoise,
  },
  {
    value: OrderStatusTypes.Shipped,
    label: i18n.global.t('orders.status.shipped'),
    icon: orderStatusColor,
    iconColor: IconColors.Blue,
  },
  {
    value: OrderStatusTypes.Cancelled,
    label: i18n.global.t('orders.status.canceled'),
    icon: orderStatusColor,
    iconColor: IconColors.Gray,
  },
  {
    value: OrderStatusTypes.Failed,
    label: i18n.global.t('orders.status.failed'),
    icon: orderStatusColor,
    iconColor: IconColors.Red,
  },
];

export default defineComponent({
  name: 'orderStatusSelector',
  components: {
    filteredSelector,
  },
  props: {
    value: {
      type: Object as () => FilteredSelectorItem,
    },
    permissions: {
      type: Array as () => PermissionTypes[],
      default: () => [] as PermissionTypes[],
    },
  },
  data() {
    return {
      input: '',
      internalValue: this.value as FilteredSelectorItem | undefined,
      SearchMode,
      OrderStatusTypes,
    };
  },
  methods: {
    select(orderStatus: FilteredSelectorItem | undefined) {
      if (!orderStatus) {
        this.internalValue = undefined;
        return;
      }
      this.internalValue = this.allTypes.find(x => x.value === orderStatus);
    },
    getOrderStatusName(orderStatus: FilteredSelectorItem | undefined) {
      if (!orderStatus) {
        return undefined;
      }

      return orderStatus.label;
    },
  },
  computed: {
    allTypes(): FilteredSelectorItem[] {
      return vxManager.userModule.userCanSeeFailedOrders ? ALL_ORDER_STATUS : ALL_ORDER_STATUS.slice(0, -1);
    },
  },
  watch: {
    value() {
      this.internalValue = this.value;
    },
    internalValue() {
      if (this.value === this.internalValue) {
        return;
      }
      this.$emit('input', this.internalValue);
    },
  },
});
