import PaginationFormatter from '@/helpers/paginationHelper';
import chevronLeft from '@/shared/icons/chevronLeft/chevronLeft.vue';
import chevronRight from '@/shared/icons/chevronRight/chevronRight.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'h-pagination',
  compatConfig: {
    MODE: 2,
    COMPONENT_V_MODEL: false,
  },
  components: {
    chevronLeft,
    chevronRight,
  },
  props: {
    total: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    modelValue: {
      type: Number,
      required: true,
    },
    align: {
      type: String,
      default: 'start',
      validator: (prop: any) => ['start', 'center', 'end'].includes(prop),
    },
    displayLimit: {
      type: Number,
      default: 7,
    },
    withBorder: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    maxDistanceWithEdge() {
      return Math.floor(this.displayLimit / 2);
    },
    pageNumber() {
      return Math.ceil(this.total / this.perPage);
    },
    canGoToPreviousPage() {
      return this.modelValue > 1;
    },
  },
  methods: {
    isEllipsis(content: string) {
      return content === '...';
    },
    canGoToNextPage() {
      return this.modelValue < this.pageNumber;
    },
    setToPreviousPage() {
      if (this.canGoToPreviousPage) {
        this.setPage(this.modelValue - 1);
      }
    },
    setToNextPage() {
      if (this.canGoToNextPage()) {
        this.setPage(this.modelValue + 1);
      }
    },
    setPage(number: number) {
      this.$emit('update:modelValue', number);
    },
    getPagesRange(): string[] {
      return PaginationFormatter.getPagesRange(
        this.pageNumber,
        this.displayLimit,
        this.modelValue,
        this.maxDistanceWithEdge
      );
    },
  },
});
