import { action, mutation, Module, VuexModule } from 'vuex-class-component';

export enum PersistentDataKey {
  REDIRECTION = 'forceRedirection',
  UNCOLLAPSED_ROOM = 'uncollapsedRoom',
}

interface PersistentData {
  readonly id: PersistentDataKey;
  value: any;
}

@Module()
export class PersistentDataModule extends VuexModule {
  private datas: PersistentData[] = [];

  @action({ mode: 'mutate' })
  public async replaceData(data: PersistentData) {
    this.setData(data);
  }

  @action({ mode: 'mutate' })
  public async insertData(data: PersistentData) {
    const existingData: PersistentData | undefined = this.datas.find(e => e.id === data.id);
    if (existingData) {
      existingData.value.push(data.value);
      this.setData(existingData);
    } else {
      data.value = [data.value];
      this.setData(data);
    }
  }

  @action({ mode: 'mutate' })
  public async removeData(data: PersistentData) {
    const existingData: PersistentData | undefined = this.datas.find(e => e.id === data.id);
    if (existingData) {
      existingData.value.splice(data.value.indexOf(data.value), 1);
      this.setData(existingData);
    }
  }

  @action({ mode: 'mutate' })
  public async clearData(id: string, removeFromDatas: boolean) {
    const existingData: PersistentData | undefined = this.datas.find(e => e.id === id);
    if (existingData) {
      existingData.value = undefined;
      if (removeFromDatas) {
        this.unsetData(id);
      }
    }
  }

  get getDataById() {
    return <T>(id: string): T | undefined => {
      return (this.datas.find(e => e.id === id)?.value as T) ?? undefined;
    };
  }

  @mutation private setData(data: PersistentData) {
    const existingData: any | undefined = this.datas.find(e => e.id === data.id);
    if (existingData) {
      existingData.value = data.value;
    } else {
      this.datas.push(data);
    }
  }

  @mutation private unsetData(id: string) {
    const existingDataIndex: number = this.datas.findIndex(e => e.id === id);
    if (existingDataIndex !== -1) {
      this.datas.splice(existingDataIndex, 1);
    }
  }
}
