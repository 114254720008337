import { LockersBaseEntityWithImage } from './lockersBaseEntity';

export default class LockPreparationType extends LockersBaseEntityWithImage {
  readonly isDefault: boolean;
  readonly shortLabel: string;
  readonly availableWithPunchedOutDoors: boolean;
}

export enum LockPreparationTypeIds {
  Padlock = 1,
  Combination = 2,
  Key = 3,
  Digital = 4,
}
