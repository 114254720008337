import { LockersBaseEntityWithImage } from './lockersBaseEntity';

export default class LockersSeries extends LockersBaseEntityWithImage {
  readonly shortLabel: string;
  readonly description: string;
  readonly isEnabled: boolean;
  readonly isDefault: boolean;
}

export enum LockersSeriesIds {
  Emperor = 1,
  Gladiator,
  Specialty,
  BootRacks,
}
