import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "hadrian-modal__outer",
  tabindex: "-1"
}
const _hoisted_2 = {
  ref: "content",
  class: "hadrian-modal__content"
}
const _hoisted_3 = {
  key: 0,
  class: "hadrian-modal__header"
}
const _hoisted_4 = { class: "hadrian-modal__title" }
const _hoisted_5 = {
  class: "hadrian-modal__body",
  tabindex: "-1",
  ref: "modal-body"
}
const _hoisted_6 = {
  type: "submit",
  ref: "submit-trap",
  class: "hadrian-modal__submit-trap"
}
const _hoisted_7 = {
  key: 2,
  class: "hadrian-modal__footer"
}
const _hoisted_8 = { class: "hadrian-modal__footer-container" }
const _hoisted_9 = { class: "hadrian-modal__footer-container" }
const _hoisted_10 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_hadrianLoader = _resolveComponent("hadrianLoader")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.isForm ? 'form' : 'div'), {
    class: _normalizeClass(["hadrian-modal", _ctx.classes]),
    ref: "modal",
    onSubmit: _withModifiers(_ctx.onSubmit, ["prevent"]),
    onDisabled: _ctx.rightButtonDisabled,
    tabindex: "-1"
  }, {
    default: _withCtx(() => [
      _createVNode(_Transition, { name: "hadrian-modal__fade" }, {
        default: _withCtx(() => [
          (_ctx.showModal || _ctx.forceRenderDom)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", {
                  class: _normalizeClass(["hadrian-modal__wrapper", { 'hadrian-modal__wrapper--visible': _ctx.showModal }]),
                  onMousedown: _cache[3] || (_cache[3] = ($event: any) => (_ctx.mousedownEvent = $event)),
                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onClickOut && _ctx.onClickOut(...args))),
                  tabindex: "-1"
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["hadrian-modal__dialog modal-dialog", { 'hadrian-modal__dialog--scrollable': _ctx.scrollable && !_ctx.isContextMenu }])
                  }, [
                    _createElementVNode("div", _hoisted_2, [
                      (_ctx.hasHeaderSlot && !_ctx.hideHeader)
                        ? (_openBlock(), _createElementBlock("header", _hoisted_3, [
                            _createElementVNode("div", {
                              class: _normalizeClass(_ctx.headerClass)
                            }, [
                              _renderSlot(_ctx.$slots, "modal-header")
                            ], 2)
                          ]))
                        : (!_ctx.hasHeaderSlot && !_ctx.hideHeader)
                          ? (_openBlock(), _createElementBlock("header", {
                              key: 1,
                              class: _normalizeClass(["hadrian-modal__header", _ctx.headerClass])
                            }, [
                              _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.title), 1),
                              (!_ctx.hideHeaderClose)
                                ? (_openBlock(), _createElementBlock("button", {
                                    key: 0,
                                    type: "button",
                                    class: "btn btn-round btn-inverted",
                                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hide && _ctx.hide(...args)))
                                  }, _toDisplayString(_ctx.$t('labels.close')), 1))
                                : _createCommentVNode("", true)
                            ], 2))
                          : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("button", _hoisted_6, null, 512),
                        _renderSlot(_ctx.$slots, "default")
                      ], 512),
                      (_ctx.hasFooterSlot && !_ctx.hideFooter)
                        ? (_openBlock(), _createElementBlock("footer", _hoisted_7, [
                            _createElementVNode("div", _hoisted_8, [
                              _renderSlot(_ctx.$slots, "modal-footer")
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (!_ctx.hasFooterSlot && !_ctx.hideFooter)
                        ? (_openBlock(), _createElementBlock("footer", {
                            key: 3,
                            class: _normalizeClass(["hadrian-modal__footer", { 'no-border': _ctx.hideBorder }])
                          }, [
                            _createElementVNode("div", _hoisted_9, [
                              _createVNode(_component_hadrianLoader, {
                                isLoading: _ctx.isLoading,
                                semiTransparent: ""
                              }, null, 8, ["isLoading"]),
                              (!_ctx.hideLeftButton)
                                ? (_openBlock(), _createElementBlock("button", {
                                    key: 0,
                                    type: "button",
                                    class: _normalizeClass(["btn btn-round btn-primary-outline hadrian-modal__left-button", [_ctx.leftButtonClass, { 'hadrian-modal__right-button': _ctx.hideRightButton }]]),
                                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.leftButtonClick && _ctx.leftButtonClick(...args)))
                                  }, _toDisplayString(_ctx.computedLeftButtonText), 3))
                                : _createCommentVNode("", true),
                              (_ctx.showCenterButton)
                                ? (_openBlock(), _createElementBlock("button", {
                                    key: 1,
                                    type: "button",
                                    class: "btn btn-round btn-primary hadrian-modal__center-button",
                                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.centerButtonClick && _ctx.centerButtonClick(...args)))
                                  }, _toDisplayString(_ctx.centerButtonText), 1))
                                : _createCommentVNode("", true),
                              (!_ctx.hideRightButton)
                                ? (_openBlock(), _createElementBlock("button", {
                                    key: 2,
                                    type: "submit",
                                    class: _normalizeClass(["btn btn-round btn-primary hadrian-modal__right-button", [_ctx.rightButtonClass, { 'hadrian-modal__left-button': _ctx.hideLeftButton }]]),
                                    disabled: _ctx.rightButtonDisabled
                                  }, _toDisplayString(_ctx.computedRightButtonText), 11, _hoisted_10))
                                : _createCommentVNode("", true)
                            ])
                          ], 2))
                        : _createCommentVNode("", true)
                    ], 512)
                  ], 2)
                ], 34),
                (_ctx.showModal)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["hadrian-modal__backdrop", { 'hadrian-modal__backdrop--transparent': _ctx.noOpacityBackdrop }])
                    }, null, 2))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ], undefined, true),
        _: 3
      })
    ], undefined, true),
    _: 3
  }, 40, ["class", "onSubmit", "onDisabled"]))
}