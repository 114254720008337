import EventBus from '@/eventBus';
import Events from '@/events';
import hadrianModal from '@/shared/viewParts/hadrianModal/hadrianModal.vue';
import orderStatus from '@/shared/viewParts/orderStatus/orderStatus.vue';
import { OrderStatusTypes } from '@/shared/viewParts/orderStatus/orderStatus';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'orderStatusLegendModal',
  components: {
    hadrianModal,
    orderStatus,
  },
  created() {
    EventBus.$on(Events.showOrderStatusModal, this.show);
  },
  beforeUnmount() {
    EventBus.$off(Events.showOrderStatusModal, this.show);
  },
  computed: {
    legendOrderStatus(): string[] {
      return [
        OrderStatusTypes.Ordered,
        OrderStatusTypes.Planning,
        OrderStatusTypes.InProduction,
        OrderStatusTypes.Ready,
        OrderStatusTypes.Shipped,
        OrderStatusTypes.Canceled,
      ];
    },
  },
  methods: {
    show() {
      (this.$refs.orderStatusLegendModal as any).show();
    },
    cancel() {
      this.$emit('close');
    },
  },
});
