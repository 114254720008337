<template>
  <svg
    version="1.1"
    class="hadrian-icons projects"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="-49 197.901 512 398.197"
    style="enable-background: new -49 197.901 512 398.197"
    xml:space="preserve"
    fill="currentColor"
  >
    <path
      d="M263.461,473.734H150.539c-8.511,0-15.434,6.923-15.434,15.434v34.634c0,8.511,6.923,15.435,15.434,15.435h112.923
      c8.511,0,15.434-6.923,15.434-15.435v-34.634C278.895,480.658,271.972,473.734,263.461,473.734z M259.051,519.393H154.948v-25.814
      h104.103V519.393z"
    />
    <path
      d="M457.976,387.958l0.159-0.08L383.73,240.774c-6.015-11.89-18.025-19.275-31.346-19.275h-14.141v-13.675
      c0-5.48-4.442-9.922-9.922-9.922H85.68c-5.48,0-9.922,4.442-9.922,9.922v13.675H61.617c-13.321,0-25.331,7.385-31.346,19.275
      l-74.405,147.104l0.159,0.08C-47.163,393.207-49,399.363-49,405.939v155.409c0,19.162,15.59,34.751,34.752,34.751h442.497
      c19.162,0,34.751-15.59,34.751-34.751V405.939C463,399.363,461.163,393.207,457.976,387.958z M338.242,243.548h14.141
      c4.959,0,9.43,2.751,11.671,7.179l60.93,120.462h-41.431v-37.066c0-5.48-4.442-9.922-9.922-9.922h-12.275v-53.227
      c0-5.48-4.442-9.922-9.922-9.922h-13.192L338.242,243.548L338.242,243.548z M363.71,344.044v27.144h-52.359
      c-8.984,0-17.174,5.293-20.865,13.482l-14.296,31.71c-0.136,0.299-0.435,0.493-0.764,0.493H138.575
      c-0.329,0-0.628-0.194-0.764-0.494l-14.295-31.708c-3.692-8.19-11.882-13.483-20.866-13.483H50.291v-27.144H363.71L363.71,344.044z
      M95.602,217.746h222.796v43.305H95.602V217.746z M341.512,280.895v43.305H72.488v-43.305H341.512z M49.946,250.727
      c2.24-4.429,6.712-7.179,11.671-7.179h14.141v17.503H62.566c-5.48,0-9.922,4.442-9.922,9.922v53.227H40.369
      c-5.48,0-9.922,4.442-9.922,9.922v37.066h-41.431L49.946,250.727z M440.951,561.348c0,7.004-5.698,12.701-12.701,12.701H-14.248
      c-7.004,0-12.703-5.699-12.703-12.701V405.939c0-7.003,5.698-12.701,12.703-12.701H102.65c0.329,0,0.63,0.194,0.765,0.495
      l14.295,31.708c3.692,8.19,11.881,13.481,20.865,13.481h136.85c8.984,0,17.174-5.292,20.865-13.48l14.296-31.709v-0.001
      c0.136-0.3,0.435-0.494,0.764-0.494h116.898c7.004,0,12.701,5.699,12.701,12.701v155.409H440.951z"
    />
  </svg>
</template>
