import LockerRoomConfiguration from './lockerRoomConfiguration';

export enum LockerBaseType {
  Unknown,
  BoxBase,
  ZBase,
}

export interface LockerRoomBases {
  baseType: LockerBaseType;
  basesFollowRda: boolean;
  boxBase: BoxBase;
  zBase: ZBase;
}

export class LockerRoomBasesViewModel {
  baseType: LockerBaseType;
  boxBasesAreDirty: boolean;
  zBasesAreDirty: boolean;
  boxBase: BoxBase;
  zBase: ZBaseViewModel;

  constructor() {
    (this.baseType = LockerBaseType.Unknown),
      (this.boxBasesAreDirty = false),
      (this.zBasesAreDirty = false),
      (this.boxBase = {
        frontBacks: 0,
        boxBaseSides: 0,
        backToBack32: 0,
        corner4x10: 0,
        corner4x4: 0,
      }),
      (this.zBase = new ZBaseViewModel());
  }
}

interface BoxBase {
  frontBacks: number;
  boxBaseSides: number;
  backToBack32: number;
  corner4x10: number;
  corner4x4: number;
}

interface ZBase {
  base60: number;
  ends: number;
  splicePlates: number;
  zBaseCorners: number;
  concreteScrews: number;
  expansionTrims: number;
}

export class ZBaseViewModel {
  static fromData(config: LockerRoomConfiguration): ZBaseViewModel {
    const viewModel = new this();
    viewModel._base60 = config.bases.zBase.base60;
    viewModel._ends = config.bases.zBase.ends;
    viewModel._splicePlates = config.bases.zBase.splicePlates;
    viewModel._zBaseCorners = config.bases.zBase.zBaseCorners;
    viewModel._concreteScrews = config.bases.zBase.concreteScrews;
    viewModel._expansionTrims = config.bases.zBase.expansionTrims;
    return viewModel;
  }

  private _base60: number;
  private _ends: number;
  private _splicePlates: number;
  private _zBaseCorners: number;
  private _concreteScrews: number;
  private _expansionTrims: number;

  constructor() {
    this.base60 = 0;
    this.ends = 0;
    this.splicePlates = 0;
    this.zBaseCorners = 0;
    this.concreteScrews = 0;
    this.expansionTrims = 0;
  }

  public toRequest(): ZBase {
    return {
      base60: this.base60,
      ends: this.ends,
      splicePlates: this.splicePlates,
      zBaseCorners: this.zBaseCorners,
      concreteScrews: this.concreteScrews,
      expansionTrims: this.expansionTrims,
    };
  }

  public get base60(): number {
    return this._base60;
  }

  public set base60(value: number) {
    this._base60 = value;
    this.splicePlates = value;
    this.concreteScrews = this.getDefaultConcreteScrews();
  }

  public get ends(): number {
    return this._ends;
  }

  public set ends(value: number) {
    this._ends = value;
    this.concreteScrews = this.getDefaultConcreteScrews();
  }

  public get splicePlates(): number {
    return this._splicePlates;
  }

  public set splicePlates(value: number) {
    this._splicePlates = value;
  }

  public get zBaseCorners(): number {
    return this._zBaseCorners;
  }

  public set zBaseCorners(value: number) {
    this._zBaseCorners = value;
  }

  public get concreteScrews(): number {
    return this._concreteScrews;
  }

  public set concreteScrews(value: number) {
    this._concreteScrews = value;
  }

  public get expansionTrims(): number {
    return this._expansionTrims;
  }

  public set expansionTrims(value: number) {
    this._expansionTrims = value;
  }

  public getDefaultConcreteScrews(): number {
    return this.base60 * 4 + this.ends * 2;
  }
}
