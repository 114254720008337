import { AppStaticData } from '@/models/appStaticData/appStaticData';
import { DrawingPropertiesStaticData } from '@/models/drawing/drawingProperties';
import HttpService from './HttpService';

const BASE_URL = process.env.VUE_APP_API_PREFIX;

const HydrateService = {
  getDrawingProperties: async (): Promise<DrawingPropertiesStaticData> => {
    const response = await HttpService.get(BASE_URL + 'hydrate/drawingProperties');
    return response.data.entity;
  },
  getStaticData: async (): Promise<AppStaticData> => {
    const response = await HttpService.get(BASE_URL + 'hydrate/staticData');
    return response.data.entity;
  },
};

export default HydrateService;
