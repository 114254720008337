import BracketTypeIds from '@/enums/BracketTypeIds';
import HadrianBaseWithImage from '../HadrianBaseWithImage';

export enum RdBracketTypeTypes {
  Brackets = 'Stirrup brackets',
  Channels = 'Continuous channels',
}

export enum RdBracketTypeShapes {
  U,
  F,
  H,
  None,
}

export default class RdBracketType extends HadrianBaseWithImage {
  readonly displayLabel: RdBracketTypeTypes;
  readonly displayOrder: number;
  readonly label: string;
  readonly legend: string;
  readonly materialCategory: number;
  readonly shape: RdBracketTypeShapes;
  readonly typeId: BracketTypeIds;
}
