import PermissionsService from '@/endpoints/permissionsService';
import PermissionsHelper, { HadrianPermissions } from '@/helpers/permissionsHelper';
import Group from '@/models/permission/group';
import Permission from '@/models/permission/permission';
import { action, mutation, Module, VuexModule } from 'vuex-class-component';

interface OrganizationStatusRequest {
  readonly status: HadrianPermissions;
  readonly id: string;
}

@Module()
export class PermissionsModule extends VuexModule {
  private permissions: Permission[] = [];
  private organizationsPermissionsStatus: { [id: string]: HadrianPermissions } = {};
  private permissionGroups: Group[] = [];

  @action({ mode: 'mutate' })
  public async fetchPermissions(): Promise<void> {
    const response = await PermissionsService.getPermissions();
    this.addPermissions(response);
  }

  @action({ mode: 'mutate' })
  public async fetchOrganizationPermissions(organizationId?: string): Promise<void> {
    if (organizationId == null) {
      return;
    }

    const response = await PermissionsService.getOrganizationPermissions(organizationId);
    const status = PermissionsHelper.mapPermissions(response);
    this.setOrganizationPermissionsStatus({ id: organizationId, status });
  }

  @action({ mode: 'mutate' })
  public async setupPermissionGroups(groups: Group[]): Promise<void> {
    this.addPermissionGroups(groups);
  }

  get getPermissions() {
    return this.permissions;
  }

  get getOrganizationPermissions() {
    return (id: string) => {
      return this.organizationsPermissionsStatus[id];
    };
  }

  get getPermissionGroups() {
    return this.permissionGroups;
  }

  get getGroupPermissionsById() {
    return (id: string) => {
      return this.permissionGroups.find(group => group.id === id)?.permissions ?? [];
    };
  }

  get getGroupIsHadrianUserFromId() {
    return (id: string) => {
      return this.permissionGroups.find(group => group.id === id)?.isHadrianUser ?? false;
    };
  }
  @mutation private addPermissions(permissions: Permission[]) {
    this.permissions = permissions;
  }

  @mutation private setOrganizationPermissionsStatus(request: OrganizationStatusRequest) {
    this.organizationsPermissionsStatus[request.id] = request.status;
  }

  @mutation private addPermissionGroups(permissionGroups: Group[]) {
    this.permissionGroups = permissionGroups;
  }
}
