import { MILIMETERS_TO_INCHES } from '@/constants/measurementUnits';
import MeasurementTypes from '@/enums/measurementTypes';
import { FractionsHelper } from '@/helpers/fractionsHelper';
import { RoundingHelper } from '@/helpers/roundingHelper';
import { BFormSelectOption } from '@/interfaces/BFormSelectOption';
import MeasurementOption from '@/models/userSettings/measurementOption';
import { vxManager } from '@/store/store';

export function getFormattedMeasurementValueForLabel(imperialValueToFormat: number | string | null): string {
  const userSettings = vxManager.userSettingsModule?.getUserSettings;
  if (imperialValueToFormat == null || !userSettings) {
    return '';
  }

  const formatter = userSettings.metricMeasurementOn ? formatMetricMeasure : formatImperialMeasure;
  return formatter(imperialValueToFormat);
}

export function formatImperialMeasure(
  imperialValueToFormat: number | string,
  valueInFeet = false,
  verbose = false
): string {
  return FractionsHelper.getFormattedValueWithFraction(imperialValueToFormat as number, valueInFeet, verbose);
}

export function formatMetricMeasure(imperialValueToFormat: number | string): string {
  const valueInMilimeters =
    FractionsHelper.getNumberFromInchesString(imperialValueToFormat.toString()) / MILIMETERS_TO_INCHES;
  return RoundingHelper.roundToNumberOfDecimals(valueInMilimeters, 0).toString() + ' mm';
}

export function getFormattedSelectableOptionForBFormSelectInputs(imperialValueToFormat: number): BFormSelectOption {
  const value = FractionsHelper.getFormattedValueWithFraction(imperialValueToFormat);
  const text = getFormattedMeasurementValueForLabel(imperialValueToFormat);
  return { value, text };
}

export function convertMetricMeasurementOn(metricMeasurementOn: boolean): MeasurementTypes {
  return metricMeasurementOn ? MeasurementTypes.Metric : MeasurementTypes.Imperial;
}

export function getMetricMeasurementOn(measurementType: MeasurementTypes): boolean {
  return measurementType === MeasurementTypes.Metric;
}

export function getMeasurementType(measurementOption: MeasurementOption): MeasurementTypes {
  if (measurementOption.measurementType.toLowerCase() === MeasurementTypes.Metric) {
    return MeasurementTypes.Metric;
  }
  return MeasurementTypes.Imperial;
}
