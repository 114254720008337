import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_errorModal = _resolveComponent("errorModal")!

  return (_ctx.isVisible)
    ? (_openBlock(), _createBlock(_component_errorModal, {
        key: 0,
        showLogo: _ctx.showLogo,
        title: _ctx.title,
        text: _ctx.text,
        iconClass: _ctx.iconClass,
        actionLabel: _ctx.actionLabel,
        onActionClick: _ctx.onActionClick,
        useAlphaBackDrop: _ctx.useAlphaBackDrop,
        onCloseModal: _ctx.hide
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon)))
        ], undefined, true),
        _: 1
      }, 8, ["showLogo", "title", "text", "iconClass", "actionLabel", "onActionClick", "useAlphaBackDrop", "onCloseModal"]))
    : _createCommentVNode("", true)
}