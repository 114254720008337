import { RoundingHelper } from './roundingHelper';

const SLASH = '/';
const SPACE = ' ';
const INCHES_SYMBOL = '"';
const FEET_SYMBOL = "'";
const INCHES_SYMBOL_VERBOSE = ' in';
const FEET_SYMBOL_VERBOSE = ' ft';

export class FractionsHelper {
  public static getFormattedValueWithFraction(valueInImperial: number, valueInFeet = false, verbose = false): string {
    const inchesDecimal = valueInImperial % 1;
    const hasDecimal = inchesDecimal > 0.0001;

    let formattedValue: string;
    const fraction = hasDecimal ? FractionsHelper.getFormattedInchesFractionFromDecimalValue(inchesDecimal) : '';

    if (!hasDecimal || (hasDecimal && fraction.length < 6)) {
      formattedValue = FractionsHelper.getFormattedValueInInches(valueInImperial, inchesDecimal, hasDecimal);
    } else {
      formattedValue = valueInImperial.toString();
    }

    return valueInFeet
      ? formattedValue + (verbose ? FEET_SYMBOL_VERBOSE : FEET_SYMBOL)
      : formattedValue + (verbose ? INCHES_SYMBOL_VERBOSE : INCHES_SYMBOL);
  }

  public static getNumberFromInchesString(label: string): number {
    const parts = label.split(SPACE);
    if (parts.length === 1) {
      return FractionsHelper.getNumberFromFractionString(parts[0]);
    } else if (parts.length === 2) {
      return parseFloat(parts[0]) + FractionsHelper.getNumberFromFractionString(parts[1]);
    }
    return 0;
  }

  public static getNumberFromFractionString(fraction: string): number {
    const values = fraction.split(SLASH);

    let result = 0;
    if (values.length === 1) {
      result = parseFloat(values[0]);
    } else if (values.length === 2) {
      result = parseFloat(values[0]) / parseFloat(values[1]);
    } else {
      result = 0;
    }

    if (isNaN(result)) {
      result = 0;
    }

    return result;
  }

  public static getFormattedInchesFractionFromDecimalValue(decimalValue: number): string {
    if (Number.isInteger(decimalValue)) {
      return `${decimalValue}`;
    }

    let numerator = decimalValue;

    const rounded = RoundingHelper.roundToNumberOfDecimals(numerator, 9);
    const scaleup = Math.pow(10, rounded.toString().split('.')[1].length);
    numerator = Math.round(numerator * scaleup);
    let denominator = scaleup;

    const commonDivisor = RoundingHelper.getCommonDivisor(numerator, denominator);
    numerator /= commonDivisor;
    denominator /= commonDivisor;
    if (denominator < 0) {
      numerator *= -1;
      denominator *= -1;
    }
    return Math.floor(numerator) + '/' + Math.floor(denominator);
  }

  private static getFormattedValueInInches(value: number, inchesDecimal: number, hasDecimal: boolean): string {
    const inchesInteger = Math.floor(value);
    const hasInteger = inchesInteger > 0 || !hasDecimal;

    let formattedValue = '';
    if (hasInteger) {
      formattedValue += inchesInteger.toString();
    }
    if (hasInteger && hasDecimal) {
      formattedValue += ' ';
    }
    if (hasDecimal) {
      formattedValue += FractionsHelper.getFormattedInchesFractionFromDecimalValue(inchesDecimal);
    }
    return formattedValue;
  }
}
