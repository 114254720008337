import { SS_CHANNELS_ID } from '@/constants/hardwareOptions';
import HardwareMaterialTypes from '@/enums/hardwareMaterialTypes';
import MaterialCategories from '@/enums/materialCategories';
import RoomSetting from '@/models/room/roomSetting';

export class RoomSettingsHelper {
  public static isRoomMetal(roomSettings: RoomSetting | undefined) {
    return roomSettings?.material.category === MaterialCategories.Metal;
  }

  public static isRoomPhenolic(roomSettings: RoomSetting | undefined) {
    return roomSettings?.material.category === MaterialCategories.Phenolic;
  }

  public static canMaterialChangeFrontHardware(roomSettings: RoomSetting | undefined) {
    return (
      roomSettings?.material.category === MaterialCategories.Metal ||
      roomSettings?.material.category === MaterialCategories.Phenolic
    );
  }

  public static hasPlasticStainlessChannels(roomSettings: RoomSetting | undefined) {
    return (
      roomSettings?.hardwarePackage.hardwareMaterial.hardwareMaterialType === HardwareMaterialTypes.StainlessChannels
    );
  }

  public static hasMetalStainlessSteelChannels(roomSettings: RoomSetting | undefined): boolean {
    return !!roomSettings?.hardwarePackage.options.some(x => x.id === SS_CHANNELS_ID);
  }

  public static hasPlasticAluminumConnections(roomSettings: RoomSetting | undefined) {
    return (
      roomSettings?.hardwarePackage.hardwareMaterial.hardwareMaterialType ===
        HardwareMaterialTypes.AluminumChannelsBrackets ||
      roomSettings?.hardwarePackage.hardwareMaterial.hardwareMaterialType === HardwareMaterialTypes.AluminumChannels
    );
  }
}
