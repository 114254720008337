import cancel from '@/shared/icons/cancel/cancel.vue';
import search from '@/shared/icons/search/search.vue';
import { defineComponent } from 'vue';
import hadrianTooltip from '../hadrianTooltip/hadrianTooltip.vue';

enum Keycodes {
  Enter = 13,
}

export default defineComponent({
  name: 'searchInput',
  components: {
    search,
    cancel,
    hadrianTooltip,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    noBorders: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      focused: false,
      showTooltip: false,
    };
  },
  created() {
    this.search = this.value;
  },
  computed: {
    inputRef(): HTMLInputElement {
      return this.$refs.input as HTMLInputElement;
    },
  },
  methods: {
    focus() {
      this.inputRef.focus();
    },
    clearFilter(): void {
      this.search = '';
      this.$emit('input', this.search);
      this.focus();
    },
    keydownOnItem(event: KeyboardEvent) {
      if (event.keyCode === Keycodes.Enter) {
        this.focused = false;
        this.$emit('input', this.search);
      } else {
        this.showTooltip = false;
        this.focused = true;
      }
    },
    emitSearch() {
      this.$emit('input', this.search);
    },
    showTooltipOnBlur() {
      if (this.search) {
        this.showTooltip = this.focused;
      } else {
        this.emitSearch();
      }
    },
    noToolTip() {
      this.focused = false;
      this.showTooltip = false;
    },
  },
  watch: {
    value() {
      this.search = this.value;
    },
  },
});
