import iconErrorUnderMaintenance from '@/shared/icons/iconErrorUnderMaintenance/iconErrorUnderMaintenance.vue';
import authenticationPage from '../authentication/authenticationPage/authenticationPage.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'maintenance',
  components: {
    iconErrorUnderMaintenance,
    authenticationPage,
  },
  methods: {
    hardRefresh() {
      location.reload();
    },
  },
});
