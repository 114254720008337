<template>
  <svg
    class="hadrian-icons cancel"
    width="17px"
    height="17px"
    viewBox="0 0 17 17"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs></defs>
    <g id="SYMBOLS">
      <g id="multiply">
        <g transform="translate(0.000000, 0.000000)">
          <path
            d="M9.41579832,8.00409163 L15.7157983,1.71409163 C16.1079205,1.32196946 16.1079205,0.686213802 15.7157983,0.294091629 C15.3236762,-0.0980305431 14.6879205,-0.0980305431 14.2957983,0.294091629 L8.00579832,6.59409163 L1.71579832,0.294091629 C1.32367615,-0.0980305331 0.687920503,-0.0980305284 0.295798335,0.29409164 C-0.0963238333,0.686213808 -0.0963238381,1.32196946 0.295798324,1.71409163 L6.59579832,8.00409163 L0.295798324,14.2940916 C0.106485674,14.4818582 0,14.7374541 0,15.0040916 C0,15.2707292 0.106485674,15.526325 0.295798324,15.7140916 C0.483564924,15.9034043 0.739160788,16.00989 1.00579832,16.00989 C1.27243586,16.00989 1.52803172,15.9034043 1.71579832,15.7140916 L8.00579832,9.41409163 L14.2957983,15.7140916 C14.4835649,15.9034043 14.7391608,16.00989 15.0057983,16.00989 C15.2724359,16.00989 15.5280317,15.9034043 15.7157983,15.7140916 C15.905111,15.526325 16.0115966,15.2707292 16.0115966,15.0040916 C16.0115966,14.7374541 15.905111,14.4818582 15.7157983,14.2940916 L9.41579832,8.00409163 Z"
            id="Path"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
