import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "hadrian-label__mandatory"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_iconHelp = _resolveComponent("iconHelp")!
  const _component_hadrianTooltip = _resolveComponent("hadrianTooltip")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.htmlFor ? 'label' : 'div'), {
    class: _normalizeClass(["hadrian-label", { 'hadrian-label--with-margin': !_ctx.noMargin }]),
    for: _ctx.htmlFor
  }, {
    default: _withCtx(() => [
      (_ctx.mandatory)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, "*"))
        : _createCommentVNode("", true),
      _createTextVNode(_toDisplayString(_ctx.text) + " ", 1),
      (!!_ctx.helpTooltip)
        ? (_openBlock(), _createBlock(_component_hadrianTooltip, {
            key: 1,
            tooltipText: _ctx.helpTooltip,
            class: "hadrian-label__help-tooltip dark",
            anchor: "left"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_iconHelp)
            ], undefined, true),
            _: 1
          }, 8, ["tooltipText"]))
        : _createCommentVNode("", true)
    ], undefined, true),
    _: 1
  }, 8, ["class", "for"]))
}