import UpdateService from '@/endpoints/updateService';
import i18n from '@/i18n';
import paths from '@/router/paths';
import { showCommonErrorModal } from '@/services/modals';
import iconErrorPCNUpdated from '@/shared/icons/iconErrorPCNupdated/iconErrorPCNUpdated.vue';
import { vxManager } from '@/store/store';

const FRONTEND_UPDATE_INTERVAL_MS = 3 * 60 * 1000;
const FRONTEND_AUTO_REFRESH_DELAY = 2 * 60 * 1000;
const MAINTENANCE_MODE = process.env.VUE_APP_MAINTENANCE_MODE === 'true';

export const UpdateHelper = {
  initialize: async () => {
    try {
      // The only way the next line can throw is in dev mode, running with `yarn
      // serve`. If that's the case, an exception will be thrown and we won't
      // set up the periodic version check
      if (!vxManager.appStateModule.frontendVersion) {
        // If no frontend version have been stored before, store the current one
        await UpdateHelper.storeCurrentVersion();
      }
      vxManager.appStateModule.setUpdateModalIsOpen(false);
      setInterval(async () => UpdateHelper.checkFrontendVersion(), FRONTEND_UPDATE_INTERVAL_MS);
    } catch {
      // do nothing
    }
  },

  loggedIn: () => vxManager.userModule.getStorageContainsToken,

  storeCurrentVersion: async () => {
    vxManager.appStateModule.updateFrontendVersion((await UpdateService.getFrontendVersion()).timestamp);
  },

  checkFrontendVersion: async () => {
    try {
      const deployedFrontendVersion = (await UpdateService.getFrontendVersion()).timestamp;
      const currentVersion = vxManager.appStateModule.frontendVersion;

      if (!currentVersion) {
        // There is no current version in the store.
        // - Store the current one and check again later
        await UpdateHelper.storeCurrentVersion();
        return;
      }

      // We perform an update if
      //   (1) Fetching the deployed app version didn't throw
      //   (2) The deployed app version is different than the one in the store.
      if (deployedFrontendVersion !== currentVersion) {
        UpdateHelper.showUpdateModal(deployedFrontendVersion);
      }
    } catch {
      // do nothing
    }
  },

  showUpdateModal: async (deployedFrontendVersion?: string) => {
    if (vxManager.appStateModule.updateModalIsOpen) {
      return;
    }

    if (MAINTENANCE_MODE || !UpdateHelper.loggedIn()) {
      // No need to show the modal, as the user isn't interacting with the site.
      UpdateHelper.refreshFrontend(deployedFrontendVersion);
    } else {
      vxManager.appStateModule.setUpdateModalIsOpen(true);

      showCommonErrorModal({
        showLogo: false,
        title: i18n.global.t('updateModal.main'),
        text: i18n.global.t('updateModal.sub'),
        icon: iconErrorPCNUpdated,
        actionLabel: i18n.global.t('generic.ok'),
        onActionClick: () => UpdateHelper.refreshFrontend(deployedFrontendVersion),
        useAlphaBackDrop: true,
      });

      setTimeout(() => {
        UpdateHelper.refreshFrontend(deployedFrontendVersion);
      }, FRONTEND_AUTO_REFRESH_DELAY);
    }
  },

  refreshFrontend: async (deployedFrontendVersion?: string) => {
    if (deployedFrontendVersion) {
      // Refresh store frontend version only when the frontend is actually
      // refreshed and the user is logged out.
      // This ensures that the user does not dismiss the modal by refreshing
      // the page.
      vxManager.appStateModule.updateFrontendVersion(deployedFrontendVersion);
    }

    // Both setting `window.location.href` and reloading the page will trigger
    // all the frontend assets to be redownloaded.
    if (UpdateHelper.loggedIn()) {
      const redirectPath = window.location.pathname;
      // After logout, navigate to login page and set redirect path to ensure
      // the user ends up on the same page they were on before being logged out
      await vxManager.userModule.logout({ redirectPath: redirectPath });
    } else if (MAINTENANCE_MODE) {
      window.location.href = paths.login;
    } else {
      window.location.reload();
    }
  },
};
