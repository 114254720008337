import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "related-parts-table"
}
const _hoisted_2 = { class: "related-parts-table__row" }
const _hoisted_3 = { class: "related-parts-table__cell parts-cell" }
const _hoisted_4 = { class: "related-parts-table__cell" }
const _hoisted_5 = { class: "related-parts-table__cell" }
const _hoisted_6 = { class: "related-parts-table__cell" }
const _hoisted_7 = { class: "related-parts-table__body" }
const _hoisted_8 = { class: "related-parts-table__cell parts-cell" }
const _hoisted_9 = { class: "part-cell__container" }
const _hoisted_10 = { class: "part-cell__image" }
const _hoisted_11 = ["src", "alt"]
const _hoisted_12 = { class: "part-cell__content" }
const _hoisted_13 = { class: "part-cell__content" }
const _hoisted_14 = { class: "number" }
const _hoisted_15 = { class: "name" }
const _hoisted_16 = {
  key: 0,
  class: "ratio"
}
const _hoisted_17 = { class: "related-parts-table__cell" }
const _hoisted_18 = { class: "related-parts-table__cell" }
const _hoisted_19 = { class: "related-parts-table__cell" }
const _hoisted_20 = { class: "related-parts-table__counter-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_help = _resolveComponent("help")!
  const _component_hadrianTooltip = _resolveComponent("hadrianTooltip")!
  const _component_quantityInput = _resolveComponent("quantityInput")!

  return (!!_ctx.temporaryParts && !!_ctx.partsData)
    ? (_openBlock(), _createElementBlock("table", _hoisted_1, [
        _createElementVNode("thead", {
          class: _normalizeClass(["related-parts-table__header", { 'related-parts-table__header--related': _ctx.isSuggestedTable }])
        }, [
          _createElementVNode("tr", _hoisted_2, [
            _createElementVNode("th", _hoisted_3, _toDisplayString(_ctx.$t('modal.suggestedParts.tableHeaders.parts')), 1),
            _createElementVNode("th", _hoisted_4, _toDisplayString(_ctx.$t('modal.suggestedParts.tableHeaders.listPrice')), 1),
            _createElementVNode("th", _hoisted_5, [
              _createTextVNode(_toDisplayString(_ctx.$t(_ctx.quantityOriginalHeaderKey)) + " ", 1),
              _createVNode(_component_hadrianTooltip, {
                tooltipText: _ctx.$t('modal.suggestedParts.originalQtyToolTip'),
                anchor: "right",
                verticalPosition: "below"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_help, { class: "related-parts-table__tooltip" })
                ], undefined, true),
                _: 1
              }, 8, ["tooltipText"])
            ]),
            _createElementVNode("th", _hoisted_6, [
              _createTextVNode(_toDisplayString(_ctx.$t(_ctx.quantityAddOrRemoveHeaderKey)) + " ", 1),
              (!_ctx.isSuggestedTable)
                ? (_openBlock(), _createBlock(_component_hadrianTooltip, {
                    key: 0,
                    tooltipText: _ctx.$t(_ctx.quantityAddOrRemoveHeaderTooltipKey),
                    anchor: "right",
                    verticalPosition: "below"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_help, { class: "related-parts-table__tooltip" })
                    ], undefined, true),
                    _: 1
                  }, 8, ["tooltipText"]))
                : _createCommentVNode("", true)
            ])
          ])
        ], 2),
        _createElementVNode("tbody", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partList, (part, index) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: part.part.id,
              class: "related-parts-table__row"
            }, [
              _createElementVNode("td", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("img", {
                      src: part.part.image || require('@/assets/images/parts-placeholder.png'),
                      alt: `Image part ${part.part.id}`
                    }, null, 8, _hoisted_11)
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, _toDisplayString(`${_ctx.$t('modal.suggestedParts.partNumber')}${part.part.partNumber}`), 1),
                      _createElementVNode("div", _hoisted_15, _toDisplayString(part.part.label), 1),
                      (!_ctx.isSuggestedTable)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(_ctx.$tc('modal.suggestedParts.ratioText', part.ratio, { partNumber: part.parentPartCode })), 1))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ])
              ]),
              _createElementVNode("td", _hoisted_17, _toDisplayString(_ctx.$filters.formatCurrency(part.part.listPrice)), 1),
              _createElementVNode("td", _hoisted_18, _toDisplayString(_ctx.originalQuantity(part.part)), 1),
              _createElementVNode("td", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, [
                  _createVNode(_component_quantityInput, {
                    id: part.part.id,
                    maximum: _ctx.getMaximumQuantity(index),
                    minimum: _ctx.minQuantity,
                    disabled: false,
                    hideChevrons: false,
                    small: true,
                    color: _ctx.getQuantityColor(index),
                    value: _ctx.getCount(index),
                    onInput: ($event: any) => (_ctx.updateCounter($event, index))
                  }, null, 8, ["id", "maximum", "minimum", "color", "value", "onInput"])
                ])
              ])
            ]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}