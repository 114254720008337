import { GetAllowedProductTypes } from '@/constants/projectTypes';
import PermissionTypes from '@/enums/permissionTypes';
import { ProjectProductTypes } from '@/models/project/projectApiInterfaces';
import filteredSelector from '@/shared/viewParts/filteredSelector/filteredSelector.vue';
import { FilteredSelectorItem, SearchMode } from '@/shared/viewParts/filteredSelector/filteredSelector';

export interface TypeDropdownItem {
  id: ProjectProductTypes;
}

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'productTypeSelector',
  components: {
    filteredSelector,
  },
  props: {
    value: {
      type: Object as () => TypeDropdownItem,
    },
    eagerFetch: {
      type: Boolean,
      default: false,
    },
    nullable: {
      type: Boolean,
      default: false,
    },
    permissions: {
      type: Array as () => PermissionTypes[],
      default: () => [] as PermissionTypes[],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      input: '',
      internalValue: this.value as TypeDropdownItem | undefined,
      SearchMode,
    };
  },
  methods: {
    select(productType: TypeDropdownItem | undefined) {
      if (!productType || typeof productType === 'string') {
        this.internalValue = undefined;
        return;
      }
      this.internalValue = productType;
    },
    getProductTypeName(productType: TypeDropdownItem | undefined) {
      if (!productType) {
        return undefined;
      }

      return this.allTypes.find(x => x.value.id === productType.id)?.label;
    },
  },
  computed: {
    allTypes(): FilteredSelectorItem[] {
      return GetAllowedProductTypes();
    },
  },
  watch: {
    value() {
      this.internalValue = this.value;
    },
    internalValue() {
      if (this.value === this.internalValue) {
        return;
      }
      this.$emit('input', this.internalValue);
    },
  },
});
