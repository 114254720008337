import i18n from '@/i18n';
import iconToastInfo from '@/shared/icons/toastInfo/toastInfo.vue';
import iconToastError from '@/shared/icons/toastError/toastError.vue';
import iconToastSuccess from '@/shared/icons/toastSuccess/toastSuccess.vue';
import iconToastWarning from '@/shared/icons/toastWarning/toastWarning.vue';
import hSpinner from '@/shared/viewParts/ui/h-spinner/h-spinner.vue';
import hToast from '@/shared/viewParts/ui/h-toast/h-toast.vue';
import { Component } from 'vue';
import { TranslateResult } from 'vue-i18n';
import { PluginOptions, useToast } from 'vue-toastification';
import { RouteLocationRaw } from 'vue-router';

const toast = useToast();

const DEFAULT_FEEDBACK_TIMEOUT = 4000;
export const DEFAULT_TOAST_TIMEOUT = 8000;
const DEFAULT_LOADER_TIMEOUT = 5000;

export interface HToastOptions {
  title?: string;
  body?: string | TranslateResult;
  icon?: Component;
  iconPosition?: 'left' | 'right';
  timeout?: number | false;
  closeable?: boolean;
  link?: string | RouteLocationRaw;
  linkText?: string;
  hideProgressBar?: boolean;
}

const DEFAULT_OPTIONS: HToastOptions = {
  timeout: DEFAULT_TOAST_TIMEOUT,
  closeable: true,
  iconPosition: 'left',
};

type ToastId = string | number;

function show(options: HToastOptions): ToastId {
  options = { ...DEFAULT_OPTIONS, ...options };

  const componentOptions = { component: hToast, props: options };
  const toastOptions: PluginOptions = {
    timeout: options.timeout,
    hideProgressBar: options.link ? true : options.hideProgressBar,
  };
  if (!options.closeable) {
    toastOptions.closeButton = false;
    toastOptions.draggable = false;
  }

  return toast(componentOptions, toastOptions);
}

function info(options: HToastOptions): ToastId {
  return show({
    ...options,
    timeout: options.timeout ?? DEFAULT_FEEDBACK_TIMEOUT,
    icon: iconToastInfo,
    title: options.title || i18n.global.t('toasts.info').toString(),
  });
}

function success(options: HToastOptions): ToastId {
  return show({
    ...options,
    timeout: options.timeout ?? DEFAULT_FEEDBACK_TIMEOUT,
    icon: iconToastSuccess,
    title: options.title || i18n.global.t('toasts.success').toString(),
  });
}

function warning(options: HToastOptions): ToastId {
  return show({
    ...options,
    icon: iconToastWarning,
    title: options.title || i18n.global.t('toasts.warning').toString(),
  });
}

function error(options: HToastOptions): ToastId {
  return show({ ...options, icon: iconToastError, title: options.title || i18n.global.t('toasts.error').toString() });
}

function loader(body: string): ToastId {
  return show({
    body,
    icon: hSpinner,
    iconPosition: 'right',
    closeable: false,
    timeout: DEFAULT_LOADER_TIMEOUT,
    hideProgressBar: true,
  });
}

/**
 * Dismisses the parent toast when a click event is triggered inside it.
 * @param event - The click event.
 */
function dismissParentToast(event: MouseEvent) {
  const container = (event.target as Element).closest('.Vue-Toastification__toast');
  if (!container) {
    return;
  }

  const toastElement = container.querySelector('.h-toast');
  const toastId = toastElement?.getAttribute('toast-id');
  if (toastId == null) {
    return;
  }

  toast.dismiss(toastId);
}

const Toast = {
  show,
  info,
  success,
  warning,
  error,
  loader,
  dismissParentToast,
};

export default Toast;
