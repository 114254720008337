import hadrianTooltip from '@/shared/viewParts/hadrianTooltip/hadrianTooltip.vue';
import { vxManager } from '@/store/store';

class Status {
  public statusColor: string;
  public statusLabel: string;
  public statusText: string;
}

export enum OrderStatusTypes {
  Ordered = 'ordered',
  Shipped = 'shipped',
  InProduction = 'production',
  Planning = 'planning',
  Failed = 'failed to upload',
  Canceled = 'canceled',
  Cancelled = 'cancelled',
  Completed = 'completed',
  Ready = 'ready',
  PendingRequest = 'pendingrequest',
  OrderReady = 'orderready',
  Expired = 'expired',
  Invalid = 'invalid',
}

enum OrderStatusColor {
  Green = 'green',
  Blue = 'blue',
  Pink = 'pink',
  Turquoise = 'turquoise',
  TurquoiseNoWidth = 'turquoise-no-width',
  Yellow = 'yellow',
  Red = 'red',
  Gray = 'gray',
  Orange = 'orange',
  Purple = 'purple',
}

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'orderStatus',
  components: {
    hadrianTooltip,
  },
  props: {
    currentStatus: {
      type: String,
    },
    jdeSalesOrderNumber: {
      type: String,
    },
    tooltipEnabled: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    tooltipIsEnabled(): boolean {
      if (this.status === OrderStatusTypes.Failed && this.cansSeeFailedOrder) {
        return false;
      }
      return this.tooltipEnabled;
    },
    status(): string {
      if (this.currentStatus) {
        return String(this.currentStatus).toLowerCase();
      }

      if (this.jdeSalesOrderNumber) {
        return OrderStatusTypes.Ordered;
      }

      return OrderStatusTypes.Failed;
    },
    cansSeeFailedOrder() {
      return vxManager.userModule.userCanSeeFailedOrders;
    },
    computedStatus(): Status {
      switch (this.status) {
        case OrderStatusTypes.Ordered:
          return {
            statusColor: OrderStatusColor.Green,
            statusLabel: this.$t('orders.status.ordered'),
            statusText: this.$t('orders.statusLegendDescription.ordered'),
          };
        case OrderStatusTypes.Shipped:
          return {
            statusColor: OrderStatusColor.Blue,
            statusLabel: this.$t('orders.status.shipped'),
            statusText: this.$t('orders.statusLegendDescription.shipped'),
          };
        case OrderStatusTypes.InProduction:
          return {
            statusColor: OrderStatusColor.Pink,
            statusLabel: this.$t('orders.status.production'),
            statusText: this.$t('orders.statusLegendDescription.production'),
          };
        case OrderStatusTypes.Planning:
          return {
            statusColor: OrderStatusColor.Yellow,
            statusLabel: this.$t('orders.status.planning'),
            statusText: this.$t('orders.statusLegendDescription.planning'),
          };
        case OrderStatusTypes.Failed:
          if (this.cansSeeFailedOrder) {
            return {
              statusColor: OrderStatusColor.Red,
              statusLabel: this.$t('orders.status.failed'),
              statusText: '',
            };
          } else {
            return {
              statusColor: OrderStatusColor.Green,
              statusLabel: this.$t('orders.status.ordered'),
              statusText: this.$t('orders.statusLegendDescription.ordered'),
            };
          }
        case OrderStatusTypes.Canceled:
          return {
            statusColor: OrderStatusColor.Gray,
            statusLabel: this.$t('orders.status.canceled'),
            statusText: this.$t('orders.statusLegendDescription.canceled'),
          };
        case OrderStatusTypes.Cancelled:
          return {
            statusColor: OrderStatusColor.Gray,
            statusLabel: this.$t('orders.status.canceled'),
            statusText: this.$t('orders.statusLegendDescription.canceled'),
          };
        case OrderStatusTypes.Completed:
          return {
            statusColor: OrderStatusColor.Blue,
            statusLabel: this.$t('orders.status.shipped'),
            statusText: this.$t('orders.statusLegendDescription.shipped'),
          };
        case OrderStatusTypes.Ready:
          return {
            statusColor: OrderStatusColor.Turquoise,
            statusLabel: this.$t('orders.status.ready'),
            statusText: this.$t('orders.statusLegendDescription.ready'),
          };
        case OrderStatusTypes.PendingRequest:
          return {
            statusColor: OrderStatusColor.Blue,
            statusLabel: this.$t('orders.status.pendingRequest'),
            statusText: this.$t('orders.statusLegendDescription.pendingRequest'),
          };
        case OrderStatusTypes.OrderReady:
          return {
            statusColor: OrderStatusColor.TurquoiseNoWidth,
            statusLabel: this.$t('orders.status.orderReady'),
            statusText: '',
          };
        case OrderStatusTypes.Expired:
          return {
            statusColor: OrderStatusColor.Gray,
            statusLabel: this.$t('orders.status.expired'),
            statusText: '',
          };
        case OrderStatusTypes.Invalid:
          return {
            statusColor: OrderStatusColor.Gray,
            statusLabel: this.$t('orders.status.invalid'),
            statusText: '',
          };
        default:
          return {
            statusColor: OrderStatusColor.Green,
            statusLabel: this.$t('orders.status.ordered'),
            statusText: this.$t('orders.statusLegendDescription.ordered'),
          };
      }
    },
  },
});
