import ProjectPartsService from '@/endpoints/projectPartsService';
import HttpResponse from '@/interfaces/HttpResponse';
import PartTypes from '@/models/part/partTypes';
import ProjectParts, { CUDProjectPartsResponse } from '@/models/part/projectParts';
import ProjectPartsCreation from '@/models/part/projectPartsCreation';
import UpdatePartQuantityOperation from '@/models/part/updatePartQuantityOperation';
import {
  DeleteProjectPartRequest,
  ProjectsResponse,
  ProjectCustomLengthPartRemovalRequest,
} from '@/models/project/projectApiInterfaces';
import { action, mutation, Module, VuexModule } from 'vuex-class-component';

@Module()
export class ProjectPartsModule extends VuexModule {
  private activeProjectParts: ProjectParts[] = [];
  private orderedProjectParts: ProjectParts[] = [];
  private orderParts: ProjectParts[] = [];
  private partTypes: PartTypes[] = [];

  @action({ mode: 'mutate' })
  public async setupPartTypes(partTypes: PartTypes[]): Promise<void> {
    this.setProjectPartTypes(partTypes);
  }

  @action({ mode: 'mutate' })
  public async fetchProjectParts(projectId: string): Promise<ProjectsResponse> {
    const response = await ProjectPartsService.getProjectParts(projectId);
    this.setActiveProjectParts(response.filter((x: { projectId: string }) => x.projectId === projectId));
    this.setOrderedProjectParts(response.filter((x: { projectId: string }) => x.projectId !== projectId));
    return response;
  }

  @action({ mode: 'mutate' })
  public async fetchOrderParts(orderId: string): Promise<ProjectsResponse> {
    const response = await ProjectPartsService.getOrderParts(orderId);
    this.setOrderParts(response);
    return response;
  }

  @action({ mode: 'mutate' })
  public async createProjectParts(parts: ProjectPartsCreation): Promise<CUDProjectPartsResponse> {
    const response = await ProjectPartsService.addProjectPart(parts);
    this.addProjectPart(response.projectParts);

    return response;
  }

  @action({ mode: 'mutate' })
  public async postProjectSuggestedParts(parts: ProjectPartsCreation[]): Promise<CUDProjectPartsResponse> {
    const response = await ProjectPartsService.postProjectSuggestedParts(parts);
    this.addProjectPart(response.projectParts);

    return response;
  }

  @action({ mode: 'mutate' })
  public async editProjectPartQuantity(
    operation: UpdatePartQuantityOperation
  ): Promise<HttpResponse<CUDProjectPartsResponse>> {
    const res = await ProjectPartsService.patchProjectPart(operation.partId, operation.quantity);
    this.addProjectPart(res.data.projectParts);
    return res;
  }

  @action({ mode: 'mutate' })
  public async deleteProjectPart(request: DeleteProjectPartRequest): Promise<CUDProjectPartsResponse> {
    const res = await ProjectPartsService.deleteProjectPart(request);
    this.removeProjectPart(request.partId);
    return res.data;
  }

  @action({ mode: 'mutate' })
  public async removeCustomLengthPart(
    request: ProjectCustomLengthPartRemovalRequest
  ): Promise<CUDProjectPartsResponse> {
    const res = await ProjectPartsService.removeCustomLengthPart(request);
    this.removeProjectPartById(request.partId);
    return res.data;
  }

  @action({ mode: 'mutate' })
  public async clearState(): Promise<void> {
    this.clearProjectPartsData();
  }

  @action({ mode: 'mutate' })
  public async manualSetActiveProjectParts(parts: ProjectParts[]): Promise<void> {
    this.setActiveProjectParts(parts);
  }

  @mutation
  private setOrderedProjectParts(parts: ProjectParts[]) {
    this.orderedProjectParts = parts;
  }

  @mutation
  private setActiveProjectParts(parts: ProjectParts[]) {
    this.activeProjectParts = parts;
  }

  @mutation
  private setProjectPartTypes(partTypes: PartTypes[]) {
    this.partTypes = partTypes;
  }

  @mutation
  private setOrderParts(parts: ProjectParts[]) {
    this.orderParts = parts;
  }

  @mutation
  private addProjectPart(parts: ProjectParts[]) {
    this.activeProjectParts = [...parts];
  }

  @mutation
  private removeProjectPart(partId: string) {
    this.activeProjectParts = this.activeProjectParts.filter(p => p.id !== partId);
  }

  @mutation
  private removeProjectPartById(partId: string) {
    this.activeProjectParts = this.activeProjectParts.filter(p => p.partId !== partId);
  }

  @mutation
  private clearProjectPartsData() {
    this.activeProjectParts = [];
    this.orderParts = [];
    this.partTypes = [];
  }

  get allProjectParts(): ProjectParts[] {
    return this.activeProjectParts.concat(this.orderedProjectParts);
  }

  get allActiveProjectParts(): ProjectParts[] {
    return this.activeProjectParts;
  }

  get allOrderedProjectParts(): ProjectParts[] {
    return this.orderedProjectParts;
  }

  get allPartTypes(): PartTypes[] {
    return this.partTypes;
  }

  get allOrderParts(): ProjectParts[] {
    return this.orderParts;
  }
}
