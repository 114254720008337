import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, vModelText as _vModelText, withKeys as _withKeys, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "editable-field" }
const _hoisted_2 = {
  key: 3,
  class: "editable-field__max-characters"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_edit = _resolveComponent("edit")!
  const _component_cancel = _resolveComponent("cancel")!
  const _component_checkmark = _resolveComponent("checkmark")!
  const _directive_autofocus = _resolveDirective("autofocus")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      (_ctx.hasTitle)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleEditMode && _ctx.toggleEditMode(...args))),
            class: _normalizeClass(["view-mode", { 'edit-mode': _ctx.isInEditMode }])
          }, [
            _renderSlot(_ctx.$slots, "title"),
            _createVNode(_component_edit, { class: "editable-field__edit-icon edit with-title" })
          ], 2))
        : _createCommentVNode("", true),
      (!_ctx.isInEditMode)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleEditMode && _ctx.toggleEditMode(...args))),
            type: "button",
            class: _normalizeClass(["editable-field__edit-button view-mode", { header: _ctx.isHeader, 'edit-mode': _ctx.isInEditMode }])
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.value), 1),
            (!_ctx.hasTitle)
              ? (_openBlock(), _createBlock(_component_edit, {
                  key: 0,
                  class: "editable-field__edit-icon edit"
                }))
              : _createCommentVNode("", true)
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.isInEditMode)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            onKeyup: _cache[6] || (_cache[6] = _withKeys(
//@ts-ignore
(...args) => (_ctx.saveEditedValue && _ctx.saveEditedValue(...args)), ["enter"])),
            class: "edit-mode editable-field__input-container"
          }, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentEditValue) = $event)),
              onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.toggleEditMode()), ["esc"])),
              class: "input"
            }, null, 544), [
              [_vModelText, _ctx.currentEditValue],
              [_directive_autofocus]
            ]),
            (!_ctx.isDirty)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  type: "button",
                  class: "editable-field__icon-container",
                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (this.toggleEditMode && this.toggleEditMode(...args)))
                }, [
                  _createVNode(_component_cancel)
                ]))
              : _createCommentVNode("", true),
            (_ctx.isDirty)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  type: "button",
                  class: "editable-field__icon-container",
                  onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (this.saveEditedValue && this.saveEditedValue(...args)))
                }, [
                  _createVNode(_component_checkmark)
                ]))
              : _createCommentVNode("", true)
          ], 32))
        : _createCommentVNode("", true),
      (_ctx.maximumInputLength && _ctx.showMaxLengthLabel && _ctx.isInEditMode)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('inputSection.maxCharacters', [_ctx.maximumInputLength])), 1))
        : _createCommentVNode("", true)
    ])
  ])), [
    [_directive_click_outside, _ctx.saveEditedValue]
  ])
}