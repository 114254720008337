<script src="./filteredSelector.ts"></script>
<style lang="scss" scoped src="./filteredSelector.scss"></style>

<template>
  <div v-click-outside="hideSuggestions" class="filtered-selector">
    <div
      v-bind:class="{ error: isInputInError, 'filtered-selector__wrapper--disabled': disabled }"
      class="filtered-selector__wrapper"
    >
      <component
        v-if="selectedItem"
        :is="selectedItem.icon"
        class="filtered-selector__selected-suggestion-icon"
        :class="getIconColorClass(selectedItem.iconColor || IconColors.Gray)"
      />
      <input
        ref="input"
        v-model="selectorName"
        v-on:blur="hideSuggestions()"
        v-on:focus="onFocus()"
        class="filtered-selector__input"
        v-bind:placeholder="placeHolder"
        type="text"
        v-on:click="onClick()"
        :disabled="disabled"
        :tabindex="disabled ? '-1' : '0'"
        autocomplete="no-autocomplete"
      />
      <!-- autocomplete override : https://stackoverflow.com/questions/12374442/chrome-ignores-autocomplete-off -->
      <div
        class="filtered-selector__arrow"
        :disabled="disabled"
        v-if="!selectorName || !isNullable"
        v-on:click="onClickArrows()"
      >
        <div class="up-down-arrow-container">
          <upDownArrow class="up-down-arrow"></upDownArrow>
        </div>
      </div>
      <button
        type="button"
        v-if="selectorName && isNullable"
        :disabled="disabled"
        class="filtered-selector__clear-filter"
        @click="clearFilter()"
      >
        <cancel />
      </button>
      <hadrianLoader :isLoading="showLoading" semiTransparent />
      <div class="filtered-selector__suggestions" v-if="showSuggestions && trimmedSuggestionList.length != 0">
        <div
          v-for="suggestion in trimmedSuggestionList"
          v-on:keydown="keydownOnItem($event, suggestion)"
          tabindex="0"
          class="filtered-selector__suggestion"
          :class="{ 'filtered-selector__suggestion--disabled': suggestion.disabled }"
          v-bind:key="suggestion.label"
          v-on:click="selectSuggestion(suggestion, $event)"
        >
          <component
            :is="suggestion.icon"
            class="filtered-selector__suggestion-icon"
            :class="getIconColorClass(suggestion.iconColor || IconColors.Gray)"
          />
          <slot name="left-span" :suggestion="suggestion">{{ suggestion.leftSpan }}</slot>
          {{ suggestion.label }}
        </div>
      </div>
    </div>
  </div>
</template>
