export const priceDecimalPattern = /^[0-9.,]+$/i;
const numberPattern = /^[0-9.]+$/i;

export default class StringHelper {
  public static wordsAreEqual(value1: string, value2: string): boolean {
    const words1 = value1.toUpperCase().split(' ');
    const words2 = value2.toUpperCase().split(' ');
    return words1.length === words2.length && words1.every(word => words2.includes(word));
  }

  public static stripWithPattern(inputText: string, pattern: RegExp): string {
    let newString: string = inputText;
    if (!pattern.test(inputText)) {
      newString = '';
      for (const letter of inputText) {
        if (pattern.test(letter)) {
          newString += letter;
        }
      }
    }
    return newString;
  }

  public static stripValidFileName(inputText: string): string {
    return inputText.normalize('NFD').replace(/[^a-zA-Z0-9_&\s]+/g, '');
  }

  public static cropText(inputText: string, maxLength: number, cropEndStyle = '...'): string {
    if (inputText.length <= maxLength) {
      return inputText;
    }
    return inputText.slice(0, maxLength) + cropEndStyle;
  }

  public static stripSpaces(inputText: string): string {
    return inputText.replace(' ', '');
  }

  public static stripDecimal(inputText: string, totalDecimal: number): string {
    let result = '';
    let currentDecimalCount = 0;
    let decimalFound = false;
    for (const letter of inputText) {
      if (letter === '.' || letter === ',') {
        if (!decimalFound) {
          decimalFound = true;
          result += letter;
        }
      } else {
        result += letter;
        if (decimalFound) {
          currentDecimalCount++;
          if (currentDecimalCount === totalDecimal) {
            break;
          }
        }
      }
    }
    return result;
  }

  public static ToStringWithArgs(inputText: string, args: string[]): string {
    for (let i = 0; i < args.length; i++) {
      inputText = inputText.replace('{' + i + '}', args[i]);
    }
    return inputText;
  }

  public static isNumberFormatValid(value: string, decimals = 2) {
    if (!value) {
      return true;
    }

    const ex = new RegExp(`^(\\d{0,3})(,\\d{3})*(\\.\\d{1,${decimals}})?$`);
    return ex.test(value);
  }

  public static replaceCommaForDot(inputText: string) {
    return inputText.replace(',', '.');
  }

  public static stripNonNumeric(inputText: string): string {
    return StringHelper.stripWithPattern(inputText, /^\d+$/);
  }

  public static formatterForPrecision(precision: number): (input: string) => string {
    return (input: string) => {
      input = StringHelper.stripWithPattern(input, numberPattern);

      if (precision > 0) {
        return StringHelper.stripDecimal(input, precision);
      } else {
        input = input.replace(/\.|,/, '');
        return input;
      }
    };
  }

  public static positiveIntegerFormatter(input: string): string {
    return StringHelper._baseIntegerFormatter(input, false);
  }

  public static positiveIntegerFormatterWithMaximum(maximum: number): (input: string) => string {
    return (input: string) => {
      return StringHelper._baseIntegerFormatter(input, false, maximum);
    };
  }

  public static positiveIntegerFormatterWithMinimumAndMaximum(
    minimum: number,
    maximum: number
  ): (input: string) => string {
    return (input: string) => {
      return StringHelper._baseIntegerFormatter(input, false, maximum, minimum);
    };
  }

  public static integerFormatter(input: string): string {
    return StringHelper._baseIntegerFormatter(input, true);
  }

  public static capNumber(input: string, max: number) {
    const value = parseInt(input, 10);
    if (value > max) {
      return Math.floor(value / 10);
    }
    return value;
  }

  public static stripHtml(input: string) {
    const div = document.createElement('div');
    div.innerHTML = input;
    const text = div.textContent || div.innerText || '';
    return text;
  }

  public static containsOnlyWhitespace(input: string | null): boolean {
    return !input?.trim();
  }

  private static _baseIntegerFormatter(
    input: string,
    allowNegativeNumbers: boolean,
    maximum: number | null = null,
    minimum: number | null = null
  ): string {
    input = input.replace(/[^\d-]/g, '');

    if (input[0] === '-' && allowNegativeNumbers) {
      input = '-' + input.replace(/-/g, '');
    } else {
      input = input.replace(/-/g, '');
    }

    if (input === '' || (input === '-' && allowNegativeNumbers)) {
      return input;
    }
    let resultInteger = parseInt(input, 10);
    if (maximum && resultInteger > maximum) {
      resultInteger = maximum;
    }

    if (minimum && resultInteger < minimum) {
      resultInteger = minimum;
    }

    return resultInteger.toLocaleString('en-US'); // 🇺🇸
  }
}
