import EventBus from '@/eventBus';
import Events from '@/events';

import { defineComponent } from 'vue';

enum KeyCodes {
  Backspace = 8,
  V = 86,
}

export default defineComponent({
  name: 'limitedTextarea',
  compatConfig: {
    MODE: 2,
    COMPONENT_V_MODEL: false,
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    maxLength: {
      type: Number,
      required: true,
    },
    customClass: {
      type: String,
      default: '',
    },
    onKeyUpCallback: {
      type: Function,
    },
    disabled: {
      type: Boolean,
    },
    onMount: {
      type: Function,
    },
    onValueWatch: {
      type: Function,
    },
    isMultiline: {
      type: Boolean,
      default: true,
    },
    inError: {
      type: Boolean,
      default: undefined,
    },
    // Only valid if `isMultiline` is true
    numRows: {
      type: Number,
    },
    id: {
      type: String,
      default: undefined,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      internalValue: '',
    };
  },
  mounted() {
    if (this.onMount) {
      this.onMount();
    }
    if (!this.modelValue) {
      return;
    }
    this.internalValue = this.modelValue;
    EventBus.$on(Events.forceResetToDefault, this.onDefault);
  },
  methods: {
    onKeyUp(event: KeyboardEvent) {
      if (this.onKeyUpCallback) {
        this.onKeyUpCallback(event, this.modelValue);
      }
    },
    onKeyDown(event: KeyboardEvent) {
      if (this.validateTextLength()) {
        return;
      }
      this.internalValue = this.internalValue.slice(0, this.maxLength);
      if (this.shouldKeyboardEventBePrevented(event)) {
        event.preventDefault();
        return;
      }
    },
    onBlur() {
      this.internalValue = this.internalValue.trim();
    },
    validateTextLength(): boolean {
      return this.internalValue.length + 1 <= this.maxLength;
    },
    shouldKeyboardEventBePrevented(event: KeyboardEvent) {
      const meta = event.metaKey || event.ctrlKey;
      const { keyCode } = event;
      return (!meta || keyCode === KeyCodes.V) && keyCode !== KeyCodes.Backspace;
    },
    onDefault(id: string) {
      if (id === this.id) {
        this.internalValue = this.modelValue;
      }
    },
    getValue(): string {
      return this.internalValue;
    },
  },
  computed: {
    inputClasses(): any {
      const classes = {
        'in-error': this.inError != null && this.inError,
      };
      if (this.customClass) {
        classes[this.customClass] = true;
      }
      return classes;
    },
  },
  watch: {
    internalValue() {
      this.internalValue = this.internalValue.slice(0, this.maxLength);
      this.$emit('update:modelValue', this.internalValue.slice(0, this.maxLength));
    },
    modelValue() {
      this.internalValue = this.modelValue;
      if (this.onValueWatch) {
        this.onValueWatch();
      }
    },
  },
});
