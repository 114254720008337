import ProjectParts from '@/models/part/projectParts';
import { SuggestedPartsData, TemporaryParts } from '@/models/part/suggestedParts';
import help from '@/shared/icons/help/help.vue';
import hadrianTooltip from '@/shared/viewParts/hadrianTooltip/hadrianTooltip.vue';
import quantityInput from '@/shared/viewParts/quantityInput/quantityInput.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'relatedPartsTable',
  components: {
    help,
    hadrianTooltip,
    quantityInput,
  },
  props: {
    isSuggestedTable: {
      type: Boolean,
      default: false,
    },
    isRemovingPart: {
      type: Boolean,
      default: false,
    },
    partsData: {
      type: Object as () => SuggestedPartsData,
      required: true,
    },
    temporaryParts: {
      type: Object as () => TemporaryParts,
      required: true,
    },
  },
  data() {
    return {
      maxQuantity: 99999,
      minQuantity: 0,
    };
  },
  computed: {
    quantityOriginalHeaderKey(): string {
      return this.isSuggestedTable
        ? 'modal.suggestedParts.tableHeaders.suggestedQty'
        : 'modal.suggestedParts.tableHeaders.originalQty';
    },
    quantityAddOrRemoveHeaderKey(): string {
      return this.isRemovingPart
        ? 'modal.suggestedParts.tableHeaders.qtyRemoved'
        : 'modal.suggestedParts.tableHeaders.qtyAdded';
    },
    quantityAddOrRemoveHeaderTooltipKey(): string {
      return this.isRemovingPart ? 'modal.suggestedParts.qtyRemovedToolTip' : 'modal.suggestedParts.qtyAddedToolTip';
    },
    partList() {
      return this.isSuggestedTable ? this.partsData?.suggestedParts : this.partsData?.autoAddedParts;
    },
  },
  methods: {
    originalQuantity(currentSuggestedPart: ProjectParts): number {
      const existingProjectPart = this.partsData?.parts.find(p => {
        return p.partNumber === currentSuggestedPart.partNumber && p.label === currentSuggestedPart.label;
      });

      return existingProjectPart ? existingProjectPart.quantity : 0;
    },
    updateCounter(quantity: any, index: number) {
      if (this.isSuggestedTable) {
        this.temporaryParts.suggested[index].quantity = quantity;
      } else {
        this.temporaryParts.autoAdded[index].quantity = quantity;
      }
    },
    getMaximumQuantity(index: number) {
      if (this.isSuggestedTable) {
        return this.maxQuantity;
      } else {
        return this.isRemovingPart
          ? this.originalQuantity(this.partsData?.autoAddedParts[index].part)
          : this.maxQuantity;
      }
    },
    getQuantityColor(index: number) {
      if (this.isRemovingPart) {
        return 'orange';
      }

      if (this.isSuggestedTable && this.temporaryParts.suggested[index].quantity === 0) {
        return 'white';
      }

      return 'blue';
    },
    getCount(index: number) {
      if (this.isSuggestedTable) {
        return this.temporaryParts.suggested[index].quantity;
      }

      if (this.isRemovingPart) {
        return Math.min(
          this.originalQuantity(this.partsData.autoAddedParts[index].part),
          this.temporaryParts.autoAdded[index].quantity
        );
      }

      return this.temporaryParts.autoAdded[index].quantity;
    },
  },
});
