import i18n from '@/i18n';
import { CreationTypes, ProjectProductTypes } from '@/models/project/projectApiInterfaces';
import { FilteredSelectorItem } from '@/shared/viewParts/filteredSelector/filteredSelector';
import { vxManager } from '@/store/store';

export const ProjectProductAllTypes: FilteredSelectorItem[] = [
  {
    label: i18n.global.t('projects.partitions').toString(),
    value: { id: ProjectProductTypes.Partition },
    key: CreationTypes.Partitions,
  },
  {
    label: i18n.global.t('projects.lockers').toString(),
    value: { id: ProjectProductTypes.Lockers },
    key: CreationTypes.Lockers,
  },
  {
    label: i18n.global.t('projects.dryers').toString(),
    value: { id: ProjectProductTypes.Dryers },
    key: CreationTypes.Dryers,
  },
];

export function GetAllowedProductTypes(): FilteredSelectorItem[] {
  const productTypes = [] as FilteredSelectorItem[];

  if (vxManager.userModule.userCanCreatePartitions) {
    productTypes.push(ProjectProductAllTypes[0]);
  }

  if (vxManager.userModule.userCanCreateLockers) {
    productTypes.push(ProjectProductAllTypes[1]);
  }

  if (vxManager.userModule.userCanCreateDryers) {
    productTypes.push(ProjectProductAllTypes[2]);
  }

  return productTypes;
}
