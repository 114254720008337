import EditableField from '@/interfaces/EditableField';
import hadrianEditableField from '@/shared/viewParts/hadrianEditableField/hadrianEditableField.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'hadrianTitle',
  components: {
    hadrianEditableField,
  },
  props: {
    title: {
      type: String,
    },
    isProjectDetails: {
      type: Boolean,
    },
    isStarred: {
      type: Boolean,
    },
    isEditable: {
      type: Boolean,
    },
    onEditSaved: {
      type: Function,
    },
    autoSave: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getStarredClass() {
      return {
        starred: this.isStarred,
        unstarred: !this.isStarred,
        fas: this.isStarred,
        far: !this.isStarred,
        'fa-star': true,
      };
    },
    onTitleSaved(roomName: EditableField) {
      this.onEditSaved && this.onEditSaved(roomName.field);
    },
    getShowStar() {
      // Star never shown for now
      return this.isProjectDetails && false;
    },
  },
});
