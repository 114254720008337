<template>
  <svg
    version="1.1"
    class="hadrian-icons organizations"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 406.602 357"
    style="enable-background: new 0 0 406.602 357"
    xml:space="preserve"
  >
    <path
      d="M0,322.5C0,341.524,15.477,357,34.5,357h337.602c19.023,0,34.5-15.477,34.5-34.5V58.707
      c-0.39-6.626-4.289-10.659-10.428-10.703L285.801,48V34.5c0-19.023-15.477-34.5-34.5-34.5h-96c-19.023,0-34.5,15.477-34.5,34.5V48
      H10.5C4.71,48,0,52.71,0,58.5V322.5z M141.801,34.5c0-7.444,6.056-13.5,13.5-13.5h96c7.444,0,13.5,6.056,13.5,13.5V48h-123V34.5z
      M381.53,69l-37.924,113.768c-1.836,5.521-6.98,9.232-12.802,9.232h-69.004v-13.5c0-5.79-4.71-10.5-10.5-10.5h-96
      c-5.79,0-10.5,4.71-10.5,10.5V192H75.797c-5.819,0-10.965-3.71-12.806-9.232L25.067,69H381.53z M240.801,189v27h-75v-27H240.801z
      M21,123.203l22.071,66.206C47.773,203.52,60.925,213,75.797,213h69.004v13.5c0,5.79,4.71,10.5,10.5,10.5h96
      c5.79,0,10.5-4.71,10.5-10.5V213h69.004c14.873,0,28.024-9.48,32.726-23.592l22.071-66.206V322.5c0,7.444-6.056,13.5-13.5,13.5H34.5
      c-7.444,0-13.5-6.056-13.5-13.5V123.203z"
    />
  </svg>
</template>
