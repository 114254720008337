import HttpService from '@/endpoints/HttpService';
import HardwareDefault from '@/models/allowedValues/HardwareDefault';
import MaterialDefault from '@/models/allowedValues/MaterialDefault';
import SeriesDefault from '@/models/allowedValues/SeriesDefault';
import HardwarePackage from '@/models/hardware/hardwarePackage';

enum Suffixes {
  HardwarePackages = 'HardwarePackages',
  Defaults = 'Defaults',
}

export default class ProjectDetailsService {
  public static async getHardwarePackagesPossibilities(): Promise<HardwarePackage[]> {
    const url = this.getPossibilitiesUrl(Suffixes.HardwarePackages);
    const response = await HttpService.get(url, { cached: true });
    return response.data.entities;
  }

  public static async getDefaultsForMaterial(materialId: string): Promise<MaterialDefault> {
    const url = this.getPossibilitiesUrl(Suffixes.Defaults).concat(`/forMaterial?materialId=${materialId}`);
    const response = await HttpService.get(url, { cached: true });
    return response.data.entity;
  }

  public static async getDefaultsForSeries(materialId: string, seriesId: string): Promise<SeriesDefault> {
    const url = this.getPossibilitiesUrl(Suffixes.Defaults).concat(
      `/forSeries?materialId=${materialId}&seriesId=${seriesId}`
    );
    const response = await HttpService.get(url, { cached: true });
    return response.data.entity;
  }

  public static async getDefaultsForHardware(
    materialId: string,
    seriesId: string,
    partitionId: string,
    hardwareId: string
  ): Promise<HardwareDefault> {
    const url = this.getPossibilitiesUrl(Suffixes.Defaults).concat(
      `/forHardware?materialId=${materialId}&seriesId=${seriesId}&partitionId=${partitionId}&hardwareId=${hardwareId}`
    );
    const response = await HttpService.get(url, { cached: true });
    return response.data.entity;
  }

  private static getPossibilitiesUrl(suffix: Suffixes) {
    return `${process.env.VUE_APP_SERVER_URL}${process.env.VUE_APP_API_PREFIX}${suffix}`;
  }
}
