import HttpService from '@/endpoints/HttpService';
import Permission from '@/models/permission/permission';

const permissionsUrl = `${process.env.VUE_APP_API_PREFIX}permissions`;

export default class PermissionsService {
  public static async getPermissions(): Promise<Permission[]> {
    const res = await HttpService.get(permissionsUrl);
    return res.data.entities;
  }

  public static async getOrganizationPermissions(organizationId: string): Promise<Permission[]> {
    const res = await HttpService.getWithLoading(`${permissionsUrl}/organization/${organizationId}`);
    return res.data.entities;
  }
}
