import EventBus from '@/eventBus';
import Events from '@/events';
import ResourceCenterObject from '@/models/resourceCenter/resourceCenterObject';
import hadrianModal from '@/shared/viewParts/hadrianModal/hadrianModal.vue';
import { vxManager } from '@/store/store';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'resourceCenterViewer',
  components: {
    hadrianModal,
  },
  data() {
    return {
      allFiles: [] as ResourceCenterObject[] | undefined,
    };
  },
  created() {
    EventBus.$on(Events.openResourceCenterModal, this.listenerOpenModal);
  },
  beforeUnmount() {
    EventBus.$off(Events.openResourceCenterModal, this.listenerOpenModal);
  },
  methods: {
    async listenerOpenModal() {
      this.allFiles = await vxManager.resourceCenterModule.fetchAllFiles();
      (this.$refs.resourceCenterModal as any).show();
    },
  },
});
