import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "order-status-legend" }
const _hoisted_2 = { class: "order-status-legend__modal-content" }
const _hoisted_3 = { class: "order-status-legend__status-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_orderStatus = _resolveComponent("orderStatus")!
  const _component_hadrianModal = _resolveComponent("hadrianModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_hadrianModal, {
      ref: "orderStatusLegendModal",
      leftButtonText: _ctx.$t('labels.close'),
      onLeftButtonClick: _ctx.cancel,
      leftButtonClass: "order-status-legend__modal-footer-button",
      hideRightButton: "",
      hideHeader: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("ul", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.legendOrderStatus, (status, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                class: "order-status-legend__status-list-item"
              }, [
                _createVNode(_component_orderStatus, {
                  currentStatus: status,
                  tooltipEnabled: false
                }, null, 8, ["currentStatus"])
              ]))
            }), 128))
          ])
        ])
      ], undefined, true),
      _: 1
    }, 8, ["leftButtonText", "onLeftButtonClick"])
  ]))
}