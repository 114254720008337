import i18n from '@/i18n';
import { vxManager } from '@/store/store';
import moment, { Moment } from 'moment-timezone';

// Values taken from https://momentjs.com/docs/#/displaying/
enum DateFormat {
  // 09/04/1986
  Short = 'L',
  // Sep 4, 1986
  AbbreviatedMonth = 'll',
  // September 4, 1986
  FullMonth = 'LL',
  // 09/04/1986 8:30 PM
  ShortWithTime = 'L LT',
  // 09/04/1986 • 8:30 PM
  ShortWithTimeDotSeparated = 'L [•] LT',
  // 09/04/1986 - 8:30 PM
  ShortWithTimeDashSeparated = 'L [-] LT',
  // 09/04/1986
  // 8:30 PM
  ShortWithTimeLineBreak = 'L [\n] LT',
  // September 4th, 1986
  DateWithOrdinal = 'MMMM Do, YYYY',
  // 9:30 PM
  LongTime = 'LT',
  // Tue, May 17
  ShortDayMonth = 'ddd, MMM D',
  TimeZone = 'Z',
  ISO8601 = 'YYYY-MM-DDTHH:mm:ss.SSSZ',
  ISO8601Short = 'YYYY-MM-DD',
}

// Test that a string starts with YYYY-MM-DD, to prevent the output of new Date().toString() from accidentally being passed.
// Use .toISOString() instead.
const looksLikeISO8601 = (dateToFormat: string | Date | Moment): boolean => {
  if (typeof dateToFormat !== 'string') {
    return true;
  }

  const validatingRegex = /^[\d]{4}-[\d]{2}-[\d]{2}/;
  return validatingRegex.test(dateToFormat);
};

const formatDate = (
  dateToFormat?: string | Date | Moment | null,
  format = DateFormat.Short,
  forceTimezoneId?: string
) => {
  if (!dateToFormat) {
    return '';
  }

  if (!looksLikeISO8601(dateToFormat)) {
    return '';
  }

  if (!vxManager.userSettingsModule.getUserSettings && !forceTimezoneId) {
    return '';
  }

  const timezoneId = forceTimezoneId ?? vxManager.userSettingsModule.userTimezoneId;
  return moment.tz(dateToFormat, timezoneId).format(format);
};

const labelDate = (startDate: string | undefined, endDate: string | undefined): string => {
  const hasStartDate = !!startDate;
  const hasEndDate = !!endDate;

  if (hasStartDate && hasEndDate) {
    return `${formatDate(startDate)} - ${formatDate(endDate)}`.toUpperCase();
  } else if (hasStartDate) {
    return `${formatDate(startDate).toUpperCase()} ${i18n.global.t('generic.dateFilterLater')}`;
  } else if (hasEndDate) {
    return `${formatDate(endDate).toUpperCase()} ${i18n.global.t('generic.dateFilterBefore')}`;
  }

  return '';
};

function addDays(date: Date, days: number): Date {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

function addWorkingDays(date: Date, days: number): Date {
  const addend = days > 0 ? 1 : -1;

  for (let i = 1; i <= Math.abs(days); i++) {
    date.setDate(date.getDate() + addend);

    if (date.getDay() === 6 && addend === 1) {
      date.setDate(date.getDate() + 2);
    } else if (date.getDay() === 6 && addend === -1) {
      date.setDate(date.getDate() - 1);
    } else if (date.getDay() === 0 && addend === 1) {
      date.setDate(date.getDate() + 1);
    } else if (date.getDay() === 0 && addend === -1) {
      date.setDate(date.getDate() - 2);
    }
  }

  return date;
}

export { formatDate, labelDate, DateFormat };

const DateHelper = {
  formatDate,
  labelDate,
  DateFormat,
  addDays,
  addWorkingDays,
};

export default DateHelper;
