import QuotesService, { QuoteLeadTimeRequest, QuoteShippingInfoResponse } from '@/endpoints/quoteService';
import ArrayHelper from '@/helpers/arrayHelper';
import * as QuoteAndOrderHelper from '@/helpers/quoteAndOrderHelper';
import { quoteItemsRequestToQueryString } from '@/helpers/quotesHelper';

import ShippingAddress from '@/models/addresses/shippingAddress';
import DryersShippingRatesResponse from '@/models/dryers/dryersShippingRates';
import CreationViewModel from '@/models/orders/CreationViewModel';
import { LargeShipmentRequest } from '@/models/orders/CreationViewModel';
import { OrderFreightProviderRequest } from '@/models/orders/OrderAPIInterfaces';
import Project from '@/models/project/project';
import QuoteCreationRequest, {
  QuotesListResponse,
  QuoteAddressChangeRequest,
  QuotePatchRequest,
  QuotePutShippingMethodOptionsRequest,
} from '@/models/quotes/QuoteAPIInterfaces';
import Quote from '@/models/quotes/quote';
import FreightCarrier from '@/models/shipping/freightCarrier';
import FreightProvider from '@/models/shipping/freightProvider';
import ShippingMethod, { sortedShippingMethodTypes } from '@/models/shipping/shippingMethod';
import { action, mutation, Module, VuexModule } from 'vuex-class-component';

interface AddQuotesToProject {
  readonly projectId: string;
  readonly quotes: Quote[];
}

interface AddQuotesItemToProject {
  readonly projectId: string;
  readonly quotes: QuotesListResponse;
}

export interface QuoteItemsRequest {
  readonly InitialProjectId: string;
  readonly Page: number;
  readonly PerPage: number;
  readonly forceUpdate: boolean;
}

export interface QuoteUpdateRequest {
  readonly quoteId: string;
  readonly projectId: string;
}

@Module()
export class QuotesModule extends VuexModule {
  private quotesByProject: { [projectId: string]: Quote[] } = {};
  private quotesItemsByProject: { [projectId: string]: QuotesListResponse } = {};
  private shippingMethods: ShippingMethod[] = [];

  @action({ mode: 'mutate' })
  public async clearState() {
    this.clearQuotesData();
  }

  @action({ mode: 'mutate' })
  public async fetchQuotesForProject(request: QuoteItemsRequest): Promise<QuotesListResponse> {
    if (this.quotesItemsByProject[request.InitialProjectId] && !request.forceUpdate) {
      const { entities, pendingEntities, ...rest } = this.quotesItemsByProject[request.InitialProjectId];
      const startIndex = request.PerPage * (request.Page - 1);
      const endIndex = startIndex + request.PerPage;
      const cachedEntities = entities.slice(startIndex, endIndex);

      if (cachedEntities.length === request.PerPage) {
        return {
          entities: cachedEntities,
          pendingEntities,
          ...rest,
        };
      }
    }

    await QuotesService.validateQuotesForProject(request.InitialProjectId);
    const response = await QuotesService.getQuotesForProject(quoteItemsRequestToQueryString(request));
    this.addQuotesItem({ projectId: request.InitialProjectId, quotes: response });
    return response;
  }

  @action({ mode: 'mutate' })
  public async createQuote(viewModel: CreationViewModel): Promise<Quote> {
    const request: QuoteCreationRequest = {
      additionalNotes: viewModel.additionalNotes,
      projectId: viewModel.projectId,
      projectPartsAreAdded: viewModel.projectPartsAreAdded,
      projectPartsAreMasterKeys: viewModel.projectPartsAreMasterKeys,
      selectedRoomsId: viewModel.selectedRoomsId,
      title: viewModel.title,
      specialCharges: QuoteAndOrderHelper.unwrapSpecialChargesFromViewModel(viewModel),
      specialChargesDescription: viewModel.specialChargesDescription?.trim(),
      numberPlateSequence: viewModel.numberPlateSequence,
      lockEndUserInfo: viewModel.lockEndUserInfo,
      extraKeys: viewModel.extraKeys,
    };
    const response = await QuotesService.createQuote(request);
    this.addQuotes({ projectId: request.projectId, quotes: [response.data.entity] });
    return response.data.entity;
  }

  @action({ mode: 'mutate' })
  public async patchQuote<T extends keyof CreationViewModel>(requests: Array<QuotePatchRequest<T>>): Promise<Quote> {
    const response = await QuotesService.patchQuote<T>(requests);
    this.addQuotes({ projectId: requests[0].projectId, quotes: [response.data.entity] });
    return response.data.entity;
  }

  @action({ mode: 'mutate' })
  public async updateLeadTimeAndGetQuoteShippingInfo(
    request: QuoteLeadTimeRequest
  ): Promise<QuoteShippingInfoResponse> {
    const response = await QuotesService.updateLeadTimeAndGetShippingInfo(request);

    this.addQuotes({ projectId: request.projectId, quotes: [response.quote] });

    response.shippingMethods.sort((lh: ShippingMethod, rh: ShippingMethod) => {
      const lhSortedIndex = sortedShippingMethodTypes.indexOf(lh.label);
      const rhSortedIndex = sortedShippingMethodTypes.indexOf(rh.label);
      return lhSortedIndex - rhSortedIndex;
    });
    this.setQuoteShippingMethods(response.shippingMethods);

    return response;
  }

  @action({ mode: 'mutate' })
  public async putShippingMethodOptions(request: QuotePutShippingMethodOptionsRequest): Promise<void> {
    const response = await QuotesService.putShippingMethodOptions(request);
    this.addQuotes({ projectId: request.projectId, quotes: [response.entity] });
  }

  @action({ mode: 'mutate' })
  public async changeQuoteAddress(request: QuoteAddressChangeRequest): Promise<ShippingAddress> {
    const response = await QuotesService.changeQuoteAddress(request);
    this.addQuotes({ projectId: request.projectId, quotes: [response.entity] });
    return response.entity.shippingOptions.selectedAddressToShipTo;
  }

  @action({ mode: 'mutate' })
  public async deleteQuote(quoteId: string): Promise<void> {
    await QuotesService.deleteQuote(quoteId);
  }

  @action({ mode: 'mutate' })
  public async fetchFreightProviders(
    orderFreightProviderRequest: OrderFreightProviderRequest
  ): Promise<FreightProvider[]> {
    if (!orderFreightProviderRequest.freightProvidersToUpdate) {
      return (await QuotesService.getFreightProvidersForQuote(orderFreightProviderRequest)).filter(
        x => x.carrierGuarantee !== 'G5PM'
      );
    } else {
      return (await QuotesService.updateFreightProvidersForQuote(orderFreightProviderRequest)).filter(
        x => x.carrierGuarantee !== 'G5PM'
      );
    }
  }

  @action({ mode: 'mutate' })
  public async fetchLargeShipmentRequestsForQuotes(quoteId: string): Promise<LargeShipmentRequest[]> {
    return await QuotesService.getLargeShipmentsForQuotes(quoteId);
  }

  @action({ mode: 'mutate' })
  public async fetchQuoteFreightCarriers(quoteId: string): Promise<FreightCarrier[]> {
    return await QuotesService.getQuoteFreightCarriers(quoteId);
  }

  @action({ mode: 'mutate' })
  public async fetchOrderFreightCarriers(orderId: string): Promise<FreightCarrier[]> {
    return await QuotesService.getOrderFreightCarriers(orderId);
  }

  @action({ mode: 'mutate' })
  public async fetchShippingRatesForQuote(quoteId: string): Promise<DryersShippingRatesResponse> {
    return await QuotesService.getDryerShippingRatesForQuote(quoteId);
  }

  @action({ mode: 'mutate' })
  public async duplicateQuoteAsNewProject(quoteId: string): Promise<Project> {
    return await QuotesService.duplicateQuoteAsNewProject(quoteId);
  }

  get getQuotesForProject() {
    return (projectId: string) => {
      return this.quotesByProject[projectId] ? this.quotesByProject[projectId] : [];
    };
  }

  get getQuoteForProjectById() {
    return (projectId: string, quoteId: string) => {
      const quotesList = this.quotesByProject[projectId] ? this.quotesByProject[projectId] : [];
      return quotesList.find(quote => quote.id === quoteId);
    };
  }

  get getQuotesListForProject() {
    return (projectId: string) => {
      return this.quotesItemsByProject[projectId] ? this.quotesItemsByProject[projectId] : [];
    };
  }

  get getQuoteListItemForProjectById() {
    return (projectId: string, quoteId: string) => {
      const quotesList = this.quotesItemsByProject[projectId] ? this.quotesItemsByProject[projectId].entities : [];
      return quotesList.find(quote => quote.id === quoteId);
    };
  }

  get getQuoteShippingMethods(): ShippingMethod[] {
    return this.shippingMethods;
  }

  @mutation private addQuotes(request: AddQuotesToProject) {
    if (this.quotesByProject[request.projectId] === undefined) {
      this.quotesByProject[request.projectId] = [];
    }
    ArrayHelper.addOrReplace(this.quotesByProject[request.projectId], request.quotes);
  }

  @mutation private addQuotesItem(request: AddQuotesItemToProject) {
    if (this.quotesItemsByProject[request.projectId] === undefined) {
      this.quotesItemsByProject[request.projectId] = request.quotes;
      return;
    }
    const { entities, ...rest } = request.quotes;
    ArrayHelper.addOrReplace(this.quotesItemsByProject[request.projectId].entities, request.quotes.entities);
    const allEntities = [...this.quotesItemsByProject[request.projectId].entities];
    this.quotesItemsByProject[request.projectId] = {
      entities: allEntities,
      ...rest,
    };
  }

  @mutation private setQuoteShippingMethods(shippingMethods: ShippingMethod[]) {
    this.shippingMethods = shippingMethods;
  }

  @mutation private clearQuotesData() {
    this.quotesByProject = {};
    this.quotesItemsByProject = {};
    this.shippingMethods = [];
  }
}
