import LockersService from '@/endpoints/lockersService';
import Finish from '@/models/finish/finish';
import BenchLengths from '@/models/lockers/benchLengths';
import BenchStyles from '@/models/lockers/benchStyles';
import Fastener from '@/models/lockers/fastener';
import HookType from '@/models/lockers/hookType';
import LatchOption from '@/models/lockers/latchOption';
import Lock from '@/models/lockers/lock';
import LockPreparationType, { LockPreparationTypeIds } from '@/models/lockers/lockPreparationType';
import LockerBanks from '@/models/lockers/lockerBanks';
import LockerRoomType from '@/models/lockers/lockerRoomTypes';
import LockersData from '@/models/lockers/lockersData';
import LockersDefault, { TierDefaults } from '@/models/lockers/lockersDefaults';
import LockersSeries, { LockersSeriesIds } from '@/models/lockers/lockersSeries';
import PackagingOption from '@/models/lockers/packagingOption';
import PlateMaterial from '@/models/lockers/plateMaterial';
import Style from '@/models/lockers/style';
import Tier from '@/models/lockers/tier';
import Type, { BodyFinishSelectionType } from '@/models/lockers/type';
import Project from '@/models/project/project';
import { LockersStyleCreationRequest, StyleViewModel } from '@/models/project/projectApiInterfaces';
import { orderBy } from 'lodash';
import { action, mutation, Module, VuexModule } from 'vuex-class-component';
import { vxManager } from '../store';
import LockersBootRacksWidth from '@/models/lockers/lockersBootRacksWidths';
import LockersBootRacksShelvesQuantity from '@/models/lockers/lockersBootRacksShelvesQuantity';

export interface TierWithDefaults extends Tier {
  disabled: boolean;
}

interface RemoveStyleRequest {
  readonly project: Project;
  readonly styleId: number;
}

const DEFAULT_STARTER_TRIM_WIDTH = 2;
const DEFAULT_EXPANSION_TRIM_WIDTH = 5;

@Module()
export class LockersModule extends VuexModule {
  private get getTiersAndMeasurements() {
    return (series: LockersSeries, type: Type, hasPunchedOut: boolean): TierDefaults => {
      return this.lockersDefaultsData.series[series.id].types[type.id].tiersAndMeasurements[+hasPunchedOut];
    };
  }

  get allowedTiers() {
    return (series: LockersSeries, type: Type, hasPunchedOut: boolean): TierWithDefaults[] => {
      const tiersAndMeasurements = this.getTiersAndMeasurements(series, type, hasPunchedOut);
      if (!tiersAndMeasurements) {
        return [] as TierWithDefaults[];
      }

      const tiersIds = Object.keys(tiersAndMeasurements).map(tierId => parseInt(tierId, 10));
      return this.lockersStaticData.tiers.map(tier => ({ ...tier, disabled: !tiersIds.includes(tier.id) }));
    };
  }

  get allowedWidths() {
    return (series: LockersSeries, type: Type, hasPunchedOut: boolean, tiers: number): number[] => {
      return Object.keys(this.getTiersAndMeasurements(series, type, hasPunchedOut)[tiers]).map(rawDepth =>
        parseInt(rawDepth, 10)
      );
    };
  }

  get allowedDepths() {
    return (series: LockersSeries, type: Type, hasPunchedOut: boolean, tiers: number, width: number): number[] => {
      const tiersAndMeasurements = this.getTiersAndMeasurements(series, type, hasPunchedOut)[tiers][width];
      if (!tiersAndMeasurements) {
        return [] as number[];
      }

      return Object.keys(tiersAndMeasurements).map(rawHeight => parseInt(rawHeight, 10));
    };
  }

  get allowedHeights() {
    return (
      series: LockersSeries,
      type: Type,
      hasPunchedOut: boolean,
      tiers: number,
      width: number,
      depth: number
    ): number[] => {
      const tiersAndMeasurementsDepths = this.getTiersAndMeasurements(series, type, hasPunchedOut)[tiers][width];
      if (!tiersAndMeasurementsDepths) {
        return [] as number[];
      }

      return this.getTiersAndMeasurements(series, type, hasPunchedOut)[tiers][width][depth] ?? ([] as number[]);
    };
  }

  get doesHeightAllowRecessTrims() {
    return (height: number | undefined): boolean => {
      return this.lockersStaticData.heights.find(h => h.id === height)?.allowsRecessParts ?? false;
    };
  }

  get getFastenersDefaults(): (packagingOption: PackagingOption) => Fastener[] {
    return packagingOption => {
      const packagingOptionDefaults = this.lockersDefaultsData.packagingOptions[packagingOption.id];
      return this.lockersStaticData.fasteners.filter(fastener =>
        packagingOptionDefaults.fasteners.includes(fastener.id)
      );
    };
  }

  get getDefaultFastener(): (packagingOption: PackagingOption) => Fastener {
    return packagingOption => {
      const allowedFasteners = this.getFastenersDefaults(packagingOption);
      return allowedFasteners.find(fastener => fastener.isDefault) ?? allowedFasteners[0];
    };
  }

  get getHookTypes(): (series: LockersSeries) => HookType[] {
    return series =>
      orderBy(
        this.lockersStaticData.hookTypes.filter(ht => ht.lockersSeriesId === series.id),
        ht => ht.displayOrder
      );
  }

  get defaultHook(): (series: LockersSeries) => HookType {
    return series => {
      const hooks = this.getHookTypes(series);
      return hooks.find(h => h.isDefault) ?? hooks[0];
    };
  }

  get isHookTypeAvailable(): (hookType: HookType | null, series: LockersSeries) => boolean {
    return (hookType, series) => {
      if (!hookType) {
        return false;
      }

      const hookTypes = this.getHookTypes(series);
      return hookTypes.some(type => type.id === hookType.id);
    };
  }

  get getLockerRoomTypes(): LockerRoomType[] {
    return this.lockersStaticData?.roomTypes ?? [];
  }

  get defaultLockerRoomType(): LockerRoomType {
    return this.getLockerRoomTypes.find(h => h.isDefault) ?? this.getLockerRoomTypes[0];
  }

  get isLatchOptionAvailable(): (style: StyleViewModel, latchOption: LatchOption | null) => boolean {
    return (style, latchOption) => {
      if (!style.tier || !style.lockPreparation || !style.series || !latchOption) {
        return false;
      }

      // This is very hacky, as we're hardcoding the fact there is just one
      // latch option as well as the rules on when to show it. However, I don't
      // want to invest time in a more robust solution without more examples of
      // latch options, especially since I think positive latch will be the only
      // choice for the foreseeable future.
      const showPositiveLatch =
        style.series.id === LockersSeriesIds.Emperor &&
        style.tier.id < 5 &&
        style.lockPreparation.id === LockPreparationTypeIds.Padlock;
      return showPositiveLatch;
    };
  }

  get latchOptions(): (style: StyleViewModel) => LatchOption[] {
    return style => this.lockersStaticData.latchOptions.filter(lp => this.isLatchOptionAvailable(style, lp));
  }

  get getLockerSeriesWithDefaults(): LockersSeries[] {
    return this.lockersStaticData.lockersSeries.map(series => ({
      ...series,
    }));
  }

  get getEnabledLockerSeriesWithDefaults(): LockersSeries[] {
    return this.getLockerSeriesWithDefaults.filter(series => series.isEnabled);
  }

  get defaultSeries(): LockersSeries {
    return this.getLockerSeriesWithDefaults.find(series => series.isDefault) ?? this.getLockerSeriesWithDefaults[0];
  }

  get getTypeDefaults(): (series: LockersSeries) => Type[] {
    return series => {
      const seriesDefaults = this.lockersDefaultsData.series[series.id];

      return this.lockersStaticData.types
        .filter(type => type.id in seriesDefaults.types)
        .map(type => ({
          ...type,
          default: seriesDefaults.types[type.id].default,
          solidSidesEnabled: seriesDefaults.types[type.id].solidSidesEnabled,
        }));
    };
  }

  get getDefaultType(): (series: LockersSeries) => Type {
    return series => {
      const defaults = this.getTypeDefaults(series);
      return defaults.find(type => type.isDefault) ?? defaults[0];
    };
  }

  get getDefaultDoorFinish(): Finish {
    return this.lockersDefaultsData.finishDefaults.defaultDoorFinish;
  }

  get getDefaultFrameAndTrimFinish(): Finish {
    return this.lockersDefaultsData.finishDefaults.defaultFrameFinish;
  }

  get getDefaultBodyFinish(): (type: Type, frameFinish: Finish | null) => Finish | null {
    return (type, frameFinish) => {
      if (!frameFinish) {
        return null;
      }

      if (type.bodyFinishSelectionType === BodyFinishSelectionType.MatchFrameColor) {
        return frameFinish ?? this.getDefaultFrameAndTrimFinish;
      }

      return this.lockersDefaultsData.finishDefaults.bodyFinishTypeMapping[type.bodyFinishSelectionType];
    };
  }

  get getDefaultBootRacksFrameFinish(): Finish {
    return this.lockersDefaultsData.finishDefaults.defaultBootRacksFrameFinish;
  }

  get getDefaultBootRacksShelvesFinish(): Finish {
    return this.lockersDefaultsData.finishDefaults.defaultBootRacksShelvesFinish;
  }

  get getLockPreparationTypes(): LockPreparationType[] {
    return this.lockersStaticData.lockPreparationTypes;
  }

  get defaultLockPreparation(): LockPreparationType {
    const prepTypes = this.lockersStaticData.lockPreparationTypes;
    return prepTypes.find(p => p.isDefault) ?? prepTypes[0];
  }

  get getStandardLocks(): (lockPreparation: LockPreparationType) => Lock[] {
    return lockPreparation =>
      this.lockersStaticData.locks.filter(lock => lock.lockPreparationTypesId === lockPreparation.id);
  }

  get getAdaLocks(): (lockPreparation: LockPreparationType) => Lock[] {
    return lockPreparation =>
      this.lockersStaticData.locks.filter(lock => lock.lockPreparationTypesId === lockPreparation.id && lock.isAda);
  }

  get allLocks(): Lock[] {
    return this.lockersStaticData?.locks ?? [];
  }

  get getPackagingOptions(): PackagingOption[] {
    return this.lockersStaticData.packagingOptions.map(option => ({ ...option }));
  }

  get getDefaultPackagingOption(): PackagingOption {
    return this.getPackagingOptions.find(opt => opt.isDefault) ?? this.getPackagingOptions[0];
  }

  get getPlateMaterials(): PlateMaterial[] {
    return this.lockersStaticData.plateMaterials;
  }

  get getBenchLengths(): BenchLengths[] {
    return orderBy(this.lockersStaticData.benchLengths, x => x.displayOrder);
  }

  get getBenchStyles(): BenchStyles[] {
    return orderBy(this.lockersStaticData.benchStyles, x => x.displayOrder);
  }

  get defaultPlateMaterial(): PlateMaterial {
    return this.lockersStaticData.plateMaterials.find(pm => pm.isDefault) ?? this.lockersStaticData.plateMaterials[0];
  }

  get bootRacksWidths(): LockersBootRacksWidth[] {
    return this.lockersStaticData.bootRacksWidths;
  }

  get getAllowedBootRacksShelvesQuantities(): (type: Type) => LockersBootRacksShelvesQuantity[] {
    return (type: Type) => this.lockersStaticData.bootRacksShelvesQuantities.filter(q => q.typeId === type.id);
  }

  get getDefaultBootRacksWidth(): number {
    return this.lockersStaticData.bootRacksWidths[0].id;
  }

  get getDefaultBootRacksShelvesQuantity(): (type: Type) => number | null {
    return (type: Type) => this.getAllowedBootRacksShelvesQuantities(type)[0]?.quantity || null;
  }

  private lockersStaticData: LockersData;
  private lockersDefaultsData: LockersDefault;

  @action({ mode: 'mutate' })
  public async fetchStaticLockersData() {
    const response = await LockersService.getStaticLockersData();
    this.setStaticData(response);
  }

  @action({ mode: 'mutate' })
  public async fetchLockersDefaultsData() {
    const response = await LockersService.getLockersDefaults();
    this.setDefaultsData(response);
  }

  @action({ mode: 'mutate' })
  public async createLockerStyle(request: LockersStyleCreationRequest): Promise<Style> {
    const response = await LockersService.createLockerStyle(request);
    return response.data;
  }

  @action({ mode: 'mutate' })
  public async updateLockerStyle(request: LockersStyleCreationRequest): Promise<Style> {
    const response = await LockersService.updateLockerStyle(request);
    return response.data;
  }

  @action({ mode: 'mutate' })
  public async deleteLockerStyle(lockerStyleId: number): Promise<void> {
    await LockersService.deleteLockerStyle(lockerStyleId);
    const currentProject = vxManager.projectsModule.getCurrentProject;
    if (currentProject) {
      const removeRooms: RemoveStyleRequest = {
        project: currentProject,
        styleId: lockerStyleId,
      };
      this.removeStyle(removeRooms);
    }
  }

  get getBanks(): (style: Style) => LockerBanks[] {
    return style => {
      return this.lockersStaticData.banks.filter(bank => style.compatibleBankSizes?.includes(bank.id));
    };
  }

  get getDefaultBanks(): (style: Style) => LockerBanks | null {
    return style => {
      if (!style.compatibleBankSizes) return null;

      const allBanks = this.getBanks(style);

      const maxCompatibleBankSize = Math.max(...style.compatibleBankSizes);
      const defaultBank = allBanks.find(bank => bank.isDefault) ?? allBanks[allBanks.length - 1];
      const bankSize = Math.min(maxCompatibleBankSize, defaultBank.id);
      return allBanks.find(bank => bank.id === bankSize) ?? allBanks[allBanks.length - 1];
    };
  }

  get starterTrimWidths(): number[] {
    return this.lockersStaticData.starterTrimWidths.map(w => w.id);
  }

  get preferredStarterTrimWidth(): number {
    const starterTrims = this.lockersStaticData.starterTrimWidths;
    const preferredTrim = starterTrims.find(trim => trim.preferredByRoomDesignAssistant);
    return preferredTrim?.id ?? DEFAULT_STARTER_TRIM_WIDTH;
  }

  get expansionTrimWidths(): number[] {
    return this.lockersStaticData.expansionTrimWidths.map(w => w.id);
  }

  get preferredExpansionTrimWidth(): number {
    const expansionTrims = this.lockersStaticData.expansionTrimWidths;
    const preferredTrim = expansionTrims.find(trim => trim.preferredByRoomDesignAssistant);
    return preferredTrim?.id ?? DEFAULT_EXPANSION_TRIM_WIDTH;
  }

  get uFillerWidths(): number[] {
    return this.lockersStaticData.uFillerWidths.map(w => w.id);
  }

  get trimHeight(): (style: Style) => number {
    return style => {
      const heights = this.lockersStaticData.heights;
      const matchingHeight = heights.find(height => height.id === style.height) ?? heights[0];
      return matchingHeight.trimHeight;
    };
  }

  get uFillerHeight(): (style: Style) => number {
    return style => {
      const heights = this.lockersStaticData.heights;
      const matchingHeight = heights.find(height => height.id === style.height) ?? heights[0];
      return matchingHeight.uFillerHeight;
    };
  }

  @mutation private setStaticData(data: LockersData) {
    this.lockersStaticData = data;
  }

  @mutation private setDefaultsData(data: any) {
    this.lockersDefaultsData = data;
  }

  @mutation
  private removeStyle(request: RemoveStyleRequest) {
    const { project, styleId } = request;
    const index = project.lockersStyles.findIndex(lockerStyle => lockerStyle.id === styleId);
    project.lockersStyles.splice(index, 1);
  }
}
