import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "show-more" }
const _hoisted_2 = { class: "show-more__details" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.paginationDetails), 1),
    _createElementVNode("button", {
      type: "button",
      class: "btn btn-round btn-primary",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
    }, [
      _renderSlot(_ctx.$slots, "btnText", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.$t('generic.showMore')), 1)
      ])
    ])
  ]))
}