<template>
  <svg
    version="1.1"
    class="hadrian-icons search"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      fill="currentColor"
      d="M21.7,20.3L18,16.6c1.2-1.5,2-3.5,2-5.6c0-5-4-9-9-9c-5,0-9,4-9,9c0,5,4,9,9,9c2.1,0,4.1-0.7,5.6-2l3.7,3.7
      c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3C22.1,21.3,22.1,20.7,21.7,20.3z M4,11c0-3.9,3.1-7,7-7c3.9,0,7,3.1,7,7
      c0,1.9-0.8,3.7-2,4.9c0,0,0,0,0,0s0,0,0,0c-1.3,1.3-3,2-4.9,2C7.1,18,4,14.9,4,11z"
    />
  </svg>
</template>
