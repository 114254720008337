import NumberFormatter from '@/helpers/numberFormatter';
import StringHelper from '@/helpers/stringHelper';
import chevronLeft from '@/shared/icons/chevronLeft/chevronLeft.vue';
import chevronRight from '@/shared/icons/chevronRight/chevronRight.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'quantityInput',
  components: {
    chevronLeft,
    chevronRight,
  },
  props: {
    value: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
    },
    minimum: {
      type: Number,
      default: 0,
    },
    maximum: {
      type: Number,
    },
    hideChevrons: {
      type: Boolean,
      default: false,
    },
    allowButtons: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String as () => 'orange' | 'blue' | 'white',
      default: 'white',
    },
    small: {
      type: Boolean,
      default: false,
    },
    optional: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      StringHelper,
      NumberFormatter,
    };
  },
  computed: {
    isIncrementAllowed(): boolean {
      return !this.maximum || this.value < this.maximum;
    },
    isDecrementAllowed(): boolean {
      return this.value > this.minimum;
    },
    customClasses(): any {
      return {
        'integer-input__input-group--orange': this.color === 'orange',
        'integer-input__input-group--blue': this.color === 'blue',
        'integer-input__input-group-small': this.small,
      };
    },
    transformInputValue() {
      if (this.optional && !this.value) {
        return (input: string) => (input == null || input === '0' ? '' : input);
      }
      return StringHelper.positiveIntegerFormatterWithMinimumAndMaximum(this.minimum, this.maximum ?? Infinity);
    },
  },
  methods: {
    onInput(input: string): void {
      const value = NumberFormatter.strictParseInt(input);
      const emittedValue = Number.isNaN(value) ? (this.optional ? null : this.minimum) : value;
      this.$emit('input', emittedValue);
    },
    increment(): void {
      if (this.isIncrementAllowed) {
        this.$emit('input', this.value + 1);
      }
    },
    decrement(): void {
      if (this.isDecrementAllowed) {
        this.$emit('input', this.value - 1);
      }
    },
  },
});
