import { defineComponent } from 'vue';

export default defineComponent({
  name: 'h-option',
  props: {
    to: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});
