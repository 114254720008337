import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-930c01b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "switch-checkbox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.emitValue && _ctx.emitValue(...args))),
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event)),
      type: "checkbox"
    }, null, 544), [
      [_vModelCheckbox, _ctx.value]
    ]),
    _createElementVNode("label", {
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.emitValue && _ctx.emitValue(...args))),
      class: "switch-label"
    }, ".")
  ]))
}