import MaterialCategories from '@/enums/materialCategories';
import DeviceHelper from '../deviceHelper';

export const INCHES_TO_PX = 16;
export const MIN_OBSTRUCTION_GAP = 4;
export const BACK_WALL_OVERFLOW = 6;
export const UNIT_LABEL_TOP = 600;
export const UNIT_ICON_DIMENSION = 70;
export const EXTRA_LEG_LENGTH = 24;
export const BOWL_CENTER_INCHES_Y = 20;
export const MEASURE_LINE_STROKE_WIDTH = 2;
export const FASTENER_LABEL_OFFSET = 60;
export const INCHES_TO_MM = 25.4;
export const METRIC_LABEL_TOP_OFFSET = 20;
export const METRIC_LABEL_TOP_OFFSET_BOTH_INCHES_PREFERRED = 18;
export const METRIC_LABEL_TOP_OFFSET_BOTH_INCHES_PREFERRED_NO_BOX = 10;
export const METRIC_LABEL_TOP_OFFSET_BOTH_METRIC_PREFERRED = -40;
export const METRIC_BOX_TOP_OFFSET = -5;
export const STANDARD_GAP_OFFSET = 3.75;
export const MEASURE_LINE_OFFSET = 1.5625;
export const SMALL_VERTICAL_OFFSET = -7.8125;
export const MEDIUM_VERTICAL_OFFSET = -10;
export const BOLT_LOCATION_COLOR = 'purple';
export const BOLT_LOCATION_SIDE_OFFSET = 14;
export const MIN_BOLT_WIDTH_NO_OFFSET = 5;
export const MIN_DRAWING_ZOOM = 0.075;
export const MAX_DRAWING_ZOOM = 2;
export const BOLT_LOCATION_TEXT_OFFSET = -15;
export const BOLT_LOCATION_LINE_WIDTH = 3;
export const MAX_BOLT_LOCATION_DRAW_SIZE = 8;
export const MIN_BOLT_LOCATION_DRAW_SIZE = 6;
export const BOLT_LOCATION_LAYER_OFFSET = 13;
export const BOWL_MEASURE_VERTICAL_OFFSET = -20.625;
export const BIG_VERTICAL_OFFSET = -400 / 16;
export const MEASURE_CHAIN_OFFSET = 15.625;
export const PILASTER_LEGEND_OFFSET = 100;
export const DOOR_LEGEND_OFFSET = PILASTER_LEGEND_OFFSET;
export const MIN_PILASTER_WIDTH_FOR_NO_MEASUREMENT_X_OFFSET = 5;
export const VERTICAL_MEASURE_OFFSET = -100 / 16;
export const DEPTH_UNIT_MEASURE_OFFSET = 150 / 16;
export const MEASURE_LINE_END_OFFSET = 50;
export const HALF_MEASURE_LINE_WIDTH = 1 / 16;
export const LABEL_VERTICAL_OFFSET_X = 50 / 16;
export const LABEL_HORIZONTAL_OFFSET_Y = 175 / 16;
export const LABEL_START_HORIZONTAL_X = 200 / 16;
export const BEAM_LONG_SIDE = 56;
export const BEAM_SHORT_SIDE = 32;
export const BOWL_CENTER_VERTICAL_OFFSET = 50;
export const FRONT_BRACKET_EXTRA_LEG_LENGTH = 24;
export const FASTENER_COLOR = 'blue';
export const FASTER_STROKE_WIDTH = 2;
export const LEGEND_RADIUS = 25;
export const LEGEND_OFFSET = 60;
export const LEGEND_TAG_OFFSET = 5;
export const LEGEND_WH_TAG_OFFSET = 105;
export const BOWL_CENTER_CIRCLE_STROKE_WIDTH = 7;
export const BOWL_CENTER_RADIUS = 40;
export const GRAY_350 = '#BBBBBB';
export const UNIT_LABEL_BOX_PADDING = 20;
export const UNIT_LABEL_STROKE_WIDTH = 7;
export const UNIT_LABEL_FONT_SIZE = 50;
export const PANEL_LEGEND_OFFSET = 80 / 16;
export const PANEL_MEASURE_OFFSET = 55 / 16;
export const LABEL_FONT = 'SF Compact Text';
let currentPilasterThickness = 1.25;
export function SetPilasterThickness(MaterialCategory: number) {
  switch (MaterialCategory) {
    case MaterialCategories.Metal:
      currentPilasterThickness = 1.25;
      break;
    case MaterialCategories.Phenolic:
      currentPilasterThickness = 0.75;
      break;
    default:
      currentPilasterThickness = 1;
      break;
  }
}
export function PILASTER_THICKNESS() {
  return currentPilasterThickness;
}

export const CLEARANCE_PLASTIC_STAINLESS = 4.75;
export const CLEARANCE_PLASTIC_ALUMINUM = 3.75;
export const CLEARANCE_METAL = 4;
export const CLEARANCE_PHENOLIC = 3.75;
export const WALL_MOUNT_PILASTER_TO_DOOR = 0.75;
export const POST_THICKNESS = 1.25;
export const POST_LEGEND_OFFSET_X = 45;
export const POST_LEGEND_OFFSET_Y = -45;
let currentPanelThickness = 1;
export function SetPanelThickness(MaterialCategory: number) {
  switch (MaterialCategory) {
    case MaterialCategories.Phenolic:
      currentPanelThickness = 0.5;
      break;
    default:
      currentPanelThickness = 1;
      break;
  }
}
export function PANEL_THICKNESS() {
  return currentPanelThickness;
}

let currentDoorThickness = 1;
export function SetDoorThickness(MaterialCategory: number) {
  switch (MaterialCategory) {
    case MaterialCategories.Phenolic:
      currentDoorThickness = 0.75;
      break;
    default:
      currentDoorThickness = 1;
      break;
  }
}
export function DOOR_THICKNESS() {
  return currentDoorThickness;
}

export const CURTAIN_WAVE_HEIGHT = 10;
export const CURTAIN_STRAIGHT_START = 5;
let currentHeadrailThickness = 1;
export function SetHeadrailThickness(MaterialCategory: number) {
  switch (MaterialCategory) {
    case MaterialCategories.Phenolic:
      currentHeadrailThickness = 0.75;
      break;
    default:
      currentHeadrailThickness = 1;
      break;
  }
}
export function HEADRAIL_THICKNESS() {
  return currentHeadrailThickness;
}
export const STABILIZER_BAR_THICKNESS = 1;
export const OBSTRUCTION_THICKNESS = 6 * INCHES_TO_PX;
export const VERTICAL_OBSTRUCTION_MEASURE_OFFSET = OBSTRUCTION_THICKNESS / INCHES_TO_PX + 1;
export const OBSTRUCTION_OFFSET = 7;
export const WALL_STROKE_WIDTH = 7 / INCHES_TO_PX;
export const WALL_LINE_RATIO = 48.84;
export const FLAT_STOP_WIDTH = 3 * PILASTER_THICKNESS();
export const POST_LEGEND_LEFT = 1.5;
export const POST_LEGEND_RIGHT = -4.5;
export const ALCOVE_RIGHT_CHAIN_OFFSET = SMALL_VERTICAL_OFFSET * 2;
export const NO_HINGE_X_OFFSET = 6;
export const NO_HINGE_Y_OFFSET = 7.5;
export const GRAB_BAR_LEGEND_OFFSET = -80;
export const GRAB_BAR_LEGEND = 'GB';
export const ALCOVE_CHANNEL_LEGEND_OFFSET = 7;
export const ALCOVE_CHANNEL_LEGEND = 'AC';
export const ALCOVE_OFF_UNIT_PILASTER_OVERLAP_WIDTH = 5;
export const ALCOVE_OFF_UNIT_PILASTER_OVERLAP_OFFSET = 5;
export const SCREENS_DISTANCE = 16;
export const SCREENS_LABEL_OFFSET_VERTICAL = 40;
export const SCREENS_LABEL_OFFSET_HORIZONTAL = -100;
export const SCREENS_MEASURE_OFFSET_HORIZONTAL = 6;
export const SCREENS_SUPPORT_MEASURE_OFFSET_HORIZONTAL = 8;
export const SCREEN_MIN_LENGTH_MEASURE = 24;
export const SCREEN_DEFAULT_LENGTH_MEASURE = 28;
export const SCREENS_DISCLAIMER_OFFSET_VERTICAL = 35;
export const SCREENS_DISCLAIMER_OFFSET_HORIZONTAL = 2;
export const SCREENS_POST_CUT_ON_SITE_OFFSET_VERTICAL = 125;
export const SCREENS_POST_CUT_ON_SITE_HEIGHT = 70;
export const SCREENS_POST_CUT_ON_SITE_PADDING = 20;
export const SCREENS_POST_CUT_WARNING_SIGN_VERTICAL_OFFSET = 1;
export const SCREENS_POST_CUT_WARNING_SIGN_HORIZONTAL_OFFSET = -2;
export const SCREENS_PANEL_OFFSET = 0.3;
export const SCREENS_PANEL_OFFSET_SINGLE_EAR = 0.7;
export const SCREENS_UNITS_OFFSET = 25;
export const SCREENS_WALL_OFFSET = 100;
export const SCREENS_POST_WIDTH = 1.75;
export const SCREENS_PILASTER_WIDTH = 5;
export const SCREENS_SUPPORT_OFFSET = 0.375;
export const SCREENS_LONG_BRACKET_LEGEND = 'DS';
export const F_BRACKET_LEGEND = 'B';
export const H_BRACKET_LEGEND = 'A';
export const SCREENS_H_BRACKET_LEGEND = 'AS';
export const PLASTIC_H_CHANNEL_LEGEND = 'HC';
export const WRAP_HINGE_LEGEND = 'WH';
export const SCREENS_SINGLE_EAR = 'single ear';
export const SELECTABLE_BOX_HEIGHT = 60;
export const BOX_HEIGHT = 55;
export const BOX_TOP_OFFSET_BOTH_INCHES_PREFERRED = -8;
export const BOX_TOP_OFFSET_BOTH_METRIC_PREFERRED = -70;
export const INCHES_LABEL_TOP_OFFSET_BOTH_INCHES_PREFERRED = -45;
export const INCHES_LABEL_TOP_OFFSET_BOTH_METRIC_PREFERRED = 16;
export const BOX_TOP_OFFSET_BOTH_METRIC_PREFERRED_NO_BOX = -55;
export const INCHES_LABEL_TOP_OFFSET_BOTH_METRIC_PREFERRED_NO_BOX = 6;
export const INCHES_LABEL_TOP_OFFSET = 30;
export const EXTENSION_LABEL_OFFSET = 6;
export const MIN_PILASTER_WIDTH_CEILING_HUNG = 5;
export const PHENOLIC_ALCOVE_CHANNEL_X_OFFSET = 2;
export const MIN_WALL_PILASTER_WIDTH_PHENOLIC = 1.5; // TODO: Add constants for other materials
export const MIN_OFFSET_PHENOLIC_EDGE = 0.5;
export const MIN_OFFSET_PHENOLIC_DOOR = 1.5;
export const NOTE_STROKE_COLOR = '#8e8d8d';
export const NOTE_PRODUCTION_COLOR = '#f06800';
export const NOTE_STROKE_WIDTH = 5;
export const NOTE_DEFAULT_ANCHOR_OFFSET = 200;
export const NOTE_PADDING = 20;
export const NOTE_WIDTH = 800;
export const NOTE_CIRCLE_RADIUS = 30;
export const NOTE_INNER_CIRCLE_RADIUS = 18;
export const NOTE_CIRCLE_INNER_PADDING = NOTE_CIRCLE_RADIUS - NOTE_INNER_CIRCLE_RADIUS;
export const NOTE_ANCHOR_OPTIONS: fabric.ICircleOptions = {
  borderColor: NOTE_STROKE_COLOR,
  hasBorders: true,
  fill: 'white',
  stroke: NOTE_STROKE_COLOR,
  strokeWidth: NOTE_STROKE_WIDTH,
  hasControls: false,
  selectable: false,
  evented: false,
  originX: 'center',
  originY: 'center',
};
export const NOTE_MENU_ITEM_HEIGHT = 41;
export const RADIAN_TO_DEGREE_MULTIPLIER = 0.0174532925;
export const FC_PILASTER_WARNING = ' FCP ';
export const FC_PILASTER_WARNING_FONT_COLOR = 'white';
export const FC_PILASTER_WARNING_FONT_WEIGHT = 'bold';
export const FC_PILASTER_WARNING_TEXT_BG_COLOR = '#FF000066';
export const FC_PILASTER_LEGEND = 'FCP';
export const FC_PILASTER_LEGEND_NAME = 'FC Pilaster Legend';
export const WM_PILASTER_LEGEND = 'WM';
export const WM_PILASTER_LEGEND_NAME = 'WM Pilaster Legend';
export const AP_PILASTER_LEGEND = '• Alcove Pilaster •';
export const AP_PILASTER_LEGEND_NAME = 'Alcove Pilaster Legend';
export const AP_PILASTER_FONT_SIZE = 24;
export const AP_PILASTER_FONT_COLOR = 'red';
export const FC_PILASTER_MAX_OFFSET_WIDTH = 5;
export const FC_PILASTER_BASE_OFFSET = 0.75;
export const FC_PILASTER_OFFSET_COEFFICIENT = 2;
export const FC_PILASTER_FONT_SIZE = 22;
export const FC_PILASTER_FONT_COLOR = 'red';
export const MENU_STROKE_COLOR = '#d5d5d5';
export const MENU_STROKE_WIDTH = 5;
export const MENU_CORNER_RADIUS = 15;
export const MENU_SHADOW_OFFSET = 15;
export const MENU_SHADOW_BLUR = 20;
export const MENU_SHADOW_COLOR = '#aaaaaa';
export const MENU_ICON_SIZE = 50;
export const MENU_ITEM_PADDING_Y = DeviceHelper.isTouchScreen() ? 60 : 20;
export const MENU_ITEM_PADDING_X = DeviceHelper.isTouchScreen() ? 70 : 20;
export const MENU_ITEM_HEIGHT = MENU_ITEM_PADDING_Y + MENU_ICON_SIZE + MENU_ITEM_PADDING_Y;
export const MENU_ICON_SPACING = 20;
export const CUTOUT_OFFSET = 1.5;
export const MEASURE_SMALL_OFFSET = 6;
export const MEASURE_OFFSET_SMALL_THRESHOLD = 3;
export const MEASURE_OFFSET_BIG_THRESHOLD = 6.5;
export const INLINE_ALCOVE_OFFSET_FROM_ADJECENT_UNIT = 0.5;
export const REINFORCING_CHANNEL_HEIGHT = 0.75;
export const REINFORCING_CHANNEL_WIDTH = 2.25;
export const REINFORCING_CHANNEL_EDGE = 0.75;
export const LEFT_WALL_MOUNT_MEASURE_OFFSET = 22;
export const RIGHT_WALL_MOUNT_MEASURE_OFFSET = 10;
export const FRONT_WALL_MOUNT_MEASURE_OFFSET = 20;
export const METAL_CHANNEL_LENGTH = 3.0;
export const METAL_BRACKET_LENGTH = 2.25;
export const PLASTIC_ALU_CHANNEL_LENGTH = 2.75;
export const PLASTIC_SS_CHANNEL_LENGTH = 1.75;
export const PLASTIC_CHANNEL_LENGTH = 2.25; // We don't differenciate steel and alu in the drawing for now, average both...
export const PLASTIC_BRACKET_LENGTH = 2.25;
export const METAL_POST_BRACKET_LENGTH = 1.5;
export const TRANSOM_PANEL_LEGEND = 'TR';
