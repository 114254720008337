import { defineComponent } from 'vue';
import paths from '../../../router/paths';
import projectCenterBlack from '@/shared/icons/project-center-black/project-center-black.vue';

export default defineComponent({
  name: 'authenticationPage',
  components: {
    projectCenterBlack,
  },
  props: {
    showHeader: {
      default: true,
      type: Boolean,
    },
    showLinks: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      paths,
    };
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
});
