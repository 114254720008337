import { action, mutation, Module, VuexModule } from 'vuex-class-component';

import ScreensService from '@/endpoints/screensService';
import RoomScreenHardware from '@/models/room/screens/roomScreenHardware';
import ScreenTypeOptions from '@/models/room/screens/roomScreenTypeOptions';

@Module()
export class RoomScreenModule extends VuexModule {
  private roomScreenTypes: ScreenTypeOptions[] = [];

  @action({ mode: 'mutate' })
  public async setScreenTypes(screenTypes: ScreenTypeOptions[]): Promise<void> {
    this.setScreenTypesInternal(screenTypes);
  }

  @action({ mode: 'mutate' })
  public async clearState(): Promise<void> {
    this.clearAppStateData();
  }

  get getScreenTypeOptions(): ScreenTypeOptions[] {
    return this.roomScreenTypes;
  }

  get screenHardwareOptionsForType(): (id: string) => RoomScreenHardware[] {
    return (screenTypeId: string) => {
      const screenType = this.roomScreenTypes.find(option => option.type.id === screenTypeId);
      return screenType ? screenType.hardwares : [];
    };
  }

  get getScreenType() {
    return (screenTypeId: string) => {
      return this.roomScreenTypes.find((s: ScreenTypeOptions) => s.type.id === screenTypeId);
    };
  }

  @mutation
  private setScreenTypesInternal(value: ScreenTypeOptions[]) {
    this.roomScreenTypes = value;
  }

  @mutation
  private clearAppStateData() {
    this.roomScreenTypes = [];
  }
}
