import { DisplayedExpediteRequestStatusData } from '@/interfaces/expediteRequest';
import { action, Module, mutation, VuexModule } from 'vuex-class-component';

@Module()
export class ExpediteRequestsModule extends VuexModule {
  private displayedExpediteRequestStatus: DisplayedExpediteRequestStatusData | undefined;

  @action({ mode: 'mutate' })
  public async setDisplayedExpediteRequestStatusData(data: DisplayedExpediteRequestStatusData) {
    this.setDisplayedExpediteRequestStatus(data);
  }

  get displayedStatus(): DisplayedExpediteRequestStatusData | undefined {
    return this.displayedExpediteRequestStatus;
  }

  @mutation private setDisplayedExpediteRequestStatus(data: DisplayedExpediteRequestStatusData) {
    this.displayedExpediteRequestStatus = data;
  }
}
