import checkmark from '@/shared/icons/checkmark/checkmark.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'hadrianCheckbox',
  components: {
    checkmark,
  },
  props: {
    label: {
      type: String,
    },
    onChange: {
      type: Function as unknown as () => ((state: boolean) => void) | undefined,
    },
    initialValue: {
      type: Boolean,
      default: false,
    },
    noMargins: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    labelClass: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: this.value != undefined ? this.value : this.initialValue,
    };
  },
  methods: {
    toggle() {
      if (this.disabled && this.readOnly) {
        return;
      }

      this.selected = !this.selected;
      this.$emit('input', this.selected);
      if (this.onChange) {
        this.onChange(this.selected);
      }
    },
    clear() {
      this.selected = false;
    },
  },
  watch: {
    initialValue() {
      this.selected = this.initialValue;
    },
    value() {
      this.selected = this.value;
    },
  },
});
